import React, { useEffect, useRef, useState } from 'react';

import Navbar from './navbar';
import Home from './home';
import Footer from './footer';

const Index = () => {
    const [gridStyle, setgridStyle] = useState(true);
    document.title =" TimeLine | Inmoapp";
    const homeref = useRef(null);

    const callFilterSelection = (filterSet) => {
        if (homeref.current) {
            homeref.current.callFilterConfigSet(filterSet);
        }
    }

    return (
        <React.Fragment>            
            <div className="layout-wrapper landing">
                <Navbar callApi={callFilterSelection} setgridStyle={setgridStyle} />
                <Home ref={homeref} gridStyle={gridStyle} />
                {/* <Team /> */}
                {/* <Contact /> */}
                {/* <Cta /> */}
                <Footer />
                {/* <button onClick={() => toTop()} className="btn btn-danger btn-icon landing-back-top" id="back-to-top">
                    <i className="ri-arrow-up-line"></i>
                </button> */}
            </div>
        </React.Fragment>
    );
};

export default Index;