
// Entidades de perfiles de usuarios
export const SLUG_ADMIN = 'administrador'
export const SLUG_GERENTE = 'gerente'


// Entidades de tipos de tipos de objetos
export const VISITAAGENDADA = "VisitaAgendada"
export const CLIENTE = "Cliente"
export const PROPIEDAD_ACL = "Propiedad"
export const FRANQUICIA_ACL = "Franquicia"
export const RESERVA_ACL = "Reserva"


export const MENUITEM_MIFRANQUICIA = "mi-franquicia"



export const BROADCAST_LOGIN = "BROADCAST_LOGIN"
export const BROADCAST_LOGOUT = "BROADCAST_LOGOUT"



