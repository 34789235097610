import React from "react";
import { FilterComponent } from "../Ecommerce/EcommerceProducts/FilterComponent/FilterComponent";

export const ProductsFilter = ({ estados, tiposInmuebles, franquicias, onFilterChange }) => {
  return (
    <FilterComponent
      estados={estados}
      tiposinmuebles={tiposInmuebles}
      handler={onFilterChange}
      franquicias={franquicias}
    />
  );
};