import React, { forwardRef, useContext, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import {
  Card,
  CardBody,
  Col,
  Container,
  CardHeader,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Input,
  Label,
  FormFeedback,
  Form,
  Button,
  Alert,
  Spinner
} from "reactstrap";

// Redux
import { useDispatch, useSelector } from "react-redux";
import classnames from "classnames";
import { Link, useNavigate, useParams } from "react-router-dom";

//formik
import { useFormik } from "formik";
import * as Yup from "yup";

// Import React FilePond
import { registerPlugin } from "react-filepond";
// Import FilePond styles
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import { InputText } from "../../Forms/Inputs/InputText/InputText";
import { TabDatosBasicos } from "./components/TabDatosBasicos/TabDatosBasicos";
import { InputTextEditor } from "../../Forms/Inputs/InputTextEditor/InputTextEditor";
import { GaleriaDropzone } from "./components/GaleriaDropzone/GaleriaDropzone";
import { TabDatosNegociacion } from "./components/TabDatosNegociacion/TabDatosNegociacion";
import { InputDatetime } from "../../Forms/Inputs/InputDatetime/InputDatetime";
import {  clearResetNew, getpropiedaddetails, updatepropiedades } from "../../../store/actions";
import { InputSelect } from "../../Forms/Inputs/InputSelect/InputSelect";
import { DatosAreasComunes } from "./components/DatosAreasComunes/DatosAreasComunes";
import { statusChoices } from "../../../helpers/options";
import * as url from "../../../helpers/api_urls";
import { createApiMethods, createInmueble, getLoggedInUser, updateInmueble } from "../../../helpers/api_calls";
import { toast } from "react-toastify";
import { useAuth } from "../../../AuthContext";
import { TabContacto } from "./components/TabContacto/TabContacto";
import { GET_PROPIEDADES } from "../../../store/propiedades/propiedadesActionTypes";
import { AbilityContext } from "../../../helpers/acl/Can";
import { PROPIEDAD_ACL } from "../../../helpers/entities";
import { InputFile } from "../../Forms/Inputs/InputFile/InputFile";
import { ReservaModal } from "./ReservaModal";

// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

export const FileInput = forwardRef(({ onFilesChange,accept = 'image/*' }, ref) => {
  const inputRef = useRef(null)
  const handleFilesChange = (e) => {
    const files = e.target.files;
    onFilesChange(files);
  };

  useImperativeHandle(ref, () => ({
    toggle: () => inputRef.current.click()  // Pasar la función click como una función
  }));


  return (
    <input
      style={{display: 'none'}}
      ref={inputRef}
      type="file"
      onChange={handleFilesChange}
      accept={accept}
      multiple
    />
  );
});

FileInput.displayName = 'FileInput';

const EcommerceAddProduct = () => {
  document.title = "Formulario de Inmuebles | Inmoapp";
  const [isLoadingPropiedad, setisLoadingPropiedad] = useState(false);
  const history = useNavigate();
  const dispatch = useDispatch();
  const { idinmueble } = useParams();
  const [editarinmueble, seteditarinmueble] = useState(null);
  const [isLoading, setisLoading] = useState(false);
  const APIclientes = useMemo(() => createApiMethods(url.CLIENTES), []);
  const APIpropiedades = useMemo(() => createApiMethods(url.PROPIEDADESPUBLICAS), []);
  const APIpropiedadesPrivadas = useMemo(() => createApiMethods(url.PROPIEDADES), []);
  const [isEdit, setisEdit] = useState(false);
  const [isCreate, setisCreate] = useState(true);
  const { user } = useAuth();
  const dropZoneRef = useRef(null);
  const fileInputRef = useRef(null);
  const modalReservaRef = useRef(null);

  const { propiedades } = useSelector((state) => ({
    propiedades: state.Propiedades,
  }));

  const { isPropiedadesCreated, error, newPropiedad } = useSelector(state => ({
    isPropiedadesCreated: state.Propiedades.isPropiedadesCreated,
    error               : state.Propiedades.error,
    newPropiedad        : state.Propiedades.newPropiedad
  }))

  useEffect(() => {
    if(isPropiedadesCreated) {
      history(`/inmueble/${newPropiedad.id}`);
      dispatch(clearResetNew())
    }
  }, [isPropiedadesCreated,newPropiedad,history,dispatch])
  
  const ability = useContext(AbilityContext)
  useEffect(() => {
    if(idinmueble){
      setisLoadingPropiedad(true)
      APIpropiedadesPrivadas.getDetails(idinmueble).then((res)=>{
        let { imagenes } = res
        const objetoplano = convertirObjeto(res)
        let a = {
          ...objetoplano,
          obj_type: PROPIEDAD_ACL,
          franquicia_ : res.franquicia
        }

        seteditarinmueble(a)
        console.log(a)
        if(imagenes){
          dropZoneRef.current?.setuploadedFiles(imagenes)
        }

        const hability_authorization = ability.can('update',a)
        if(hability_authorization){
          setisEdit(true)
        } else {
          history(`/inmueble/${idinmueble}`);
        }

        setisLoadingPropiedad(false)
      })
      
    } else {
      setisEdit(false)
      setisCreate(true)
    }
  }, [])  


  const convertirObjeto = (obj)=>{
    const objetoPlano = {};
    for (const key in obj) {
      if (typeof obj[key] === "object" && obj[key] !== null) {
        const subObj = obj[key];
        objetoPlano[key] = subObj.id;
      } else {
        objetoPlano[key] = obj[key];
      }
    }
    return objetoPlano;
  }

  const [customActiveTab, setcustomActiveTab] = useState("1");
  const toggleCustom = (tab) => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab);
    }
  };


  const asesor = user.isAdmin() ? null: user.id
  const franquicia__ = user.isAdmin() ? null: user.franquicia()?.id
  const valoresIniciales = {
    nombre                       : editarinmueble ? editarinmueble.nombre              : undefined,
    descripcion                  : editarinmueble ? editarinmueble.descripcion         : undefined,
    numero_banios                : editarinmueble ? editarinmueble.numero_banios       : 0,
    habitaciones                 : editarinmueble ? editarinmueble.habitaciones        : 0,
    numero_plantas               : editarinmueble ? editarinmueble.numero_plantas      : 0,
    metros_cuadrados_terreno     : editarinmueble ? editarinmueble.metros_cuadrados_terreno : 0,
    metros_cuadrados_construccion: editarinmueble ? editarinmueble.metros_cuadrados_construccion : 0,
    metraje_total                : editarinmueble ? editarinmueble.metraje_total       : 0,
    fecha_publicacion            : editarinmueble ? editarinmueble.fecha_publicacion   : undefined,
    ptos_estacionamiento         : editarinmueble ? editarinmueble.ptos_estacionamiento: 0,
    direccion                    : editarinmueble ? editarinmueble.direccion           : undefined,
    precio                       : editarinmueble ? editarinmueble.precio              : undefined,
    franquicia                   : editarinmueble ? editarinmueble.franquicia          : franquicia__,
    tipo_inmueble                : editarinmueble ? editarinmueble.tipo_inmueble       : undefined,
    ciudad                       : editarinmueble ? editarinmueble.ciudad              : undefined,
    estado                       : editarinmueble ? editarinmueble.estado              : undefined,
    tipo_negociacion             : editarinmueble ? editarinmueble.tipo_negociacion    : undefined,
    status_inmueble              : editarinmueble ? editarinmueble.status_inmueble     : undefined,
    precio_inicial               : editarinmueble ? editarinmueble.precio_inicial      : undefined,
    antiguedad                   : editarinmueble ? editarinmueble.antiguedad          : undefined,
    vista                        : editarinmueble ? editarinmueble.vista               : undefined,
    hab_servicio                 : editarinmueble ? editarinmueble.hab_servicio        : 0,
    banio_servicio               : editarinmueble ? editarinmueble.banio_servicio      : 0,
    lavadero                     : editarinmueble ? editarinmueble.lavadero            : false,
    terraza_jardin               : editarinmueble ? editarinmueble.terraza_jardin      : false,
    meletero_bodega              : editarinmueble ? editarinmueble.meletero_bodega     : false,
    tanque_agua                  : editarinmueble ? editarinmueble.tanque_agua         : false,
    amoblado                     : editarinmueble ? editarinmueble.amoblado            : null,
    planta_electrica             : editarinmueble ? editarinmueble.planta_electrica    : false,
    posee_hipoteca               : editarinmueble ? editarinmueble.posee_hipoteca      : false,
    estudio                      : editarinmueble ? editarinmueble.estudio             : false,
    family_room                  : editarinmueble ? editarinmueble.family_room         : false,
    tanque_agua_comun            : editarinmueble ? editarinmueble.tanque_agua_comun   : false,
    ascensor                     : editarinmueble ? editarinmueble.ascensor            : false,
    seguridad                    : editarinmueble ? editarinmueble.seguridad           : false,
    parque_infantil              : editarinmueble ? editarinmueble.parque_infantil     : false,
    piscina                      : editarinmueble ? editarinmueble.piscina             : false,
    gym                          : editarinmueble ? editarinmueble.gym                 : false,
    bbq                          : editarinmueble ? editarinmueble.bbq                 : false,
    salon_fiestas                : editarinmueble ? editarinmueble.salon_fiestas       : false,
    cancha_deportiva             : editarinmueble ? editarinmueble.cancha_deportiva    : false,
    pozo_profundo                : editarinmueble ? editarinmueble.pozo_profundo       : false,
    planta_electrica_comun       : editarinmueble ? editarinmueble.planta_electrica_comun: false,
    asesor                        : editarinmueble ? editarinmueble.asesor              : asesor,
    cliente                        : editarinmueble ? editarinmueble.cliente              : undefined,
    cliente_nombre : undefined,
    cliente_tipo_contacto : undefined,
    cliente_contacto_valor : undefined,
    createNewCliente: false
  };

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: valoresIniciales,
    validationSchema: Yup.object({
        ciudad: Yup.number()
          .typeError("Seleccione una ciudad.")
          .required("Seleccionar una Ciudad es obligatorio"),
      
        estado: Yup.number()
          .typeError("Seleccione un estado de la lista.")
          .required("Seleccionar un estado es obligatorio."),

        franquicia: Yup.number()
          .typeError("Seleccione una franquicia de la lista.")
          .required("Seleccionar una franquicia es obligatorio."),     

          status_inmueble: Yup.number()
          .typeError("Seleccione un status de la lista.")
          .required("Seleccionar un status es obligatorio.")
    }),
    onSubmit: async (values) => {
      const formData = new FormData();
      for (const [key, value] of Object.entries(values)) {
        if (value !== null && value !== undefined && value !== "") {
          if (key !== "imagenes") {
            formData.append(key, value);
          } else {
            value.forEach((image, index) => {
              formData.append(`imagenes[${index}]`, image);
            });
          }
        }
      }

      if(editarinmueble){
        formData.append('id', editarinmueble.id);
        setisLoading(true)
        APIpropiedadesPrivadas.update(formData,{
          headers: {
              "Content-Type": "multipart/form-data"
          },
        }).then((res)=>{
          toast.success("El inmueble fue actualizado con éxito", { autoClose: 3000 });
          history(`/inmueble/${editarinmueble.id}`);
        }).catch((err)=>{
          toast.error("Algo ocurrió mientras se acutalizaba el inmueble", { autoClose: 3000 });
          toast.error(err, { autoClose: 3000 });
        }).finally(()=>{
          setisLoading(false)
        })
      } else {
        APIpropiedadesPrivadas.create(formData,{
          headers: {
              "Content-Type": "multipart/form-data"
          },
        }).then((res)=>{
          toast.success("El inmueble fue creado con éxito", { autoClose: 3000 });
          history(`/inmueble/${res.id}`);
        })
        .catch((err)=>{
          toast.error("Algo ocurrió mientras se creaba el inmueble", { autoClose: 2000 });
          toast.error(err, { autoClose: 3000 });
        })
        .finally(()=>{
          setisLoading(false)
        })
      }
    }
  });

  const handleButtonGuardar = () => () => {
    validation.submitForm()
  }

  const handleFilesChange = (files) => {
    if(files){
      dropZoneRef.current?.setselectedFiles(files)
    }
  };

  const handleClick = () => () => {
    if(fileInputRef.current.toggle){
      fileInputRef.current.toggle();
    }
  };

  return (  
    <div className="page-content">
      <Container fluid>
        <BreadCrumb title="Formulario de Inmueble" pageTitle="Editor de Inmueble" />
        {
          (isLoadingPropiedad || isLoading) &&
          <div style={{position: 'absolute',height:'100%',left: '50%', top: 350, zIndex:1, display: 'flex', flexDirection:'column'}}>
            <span className="fs-5 text">
              Cargando
            </span>
            <div className="text-center w-100">
              <Spinner color="primary"></Spinner>
            </div>
          </div>
        }
        <Row className={`${isLoadingPropiedad && 'bg-overlay-pattern'}`}>
          <Col lg={8}>
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
              }}>
              <Card>
                <CardBody>
                  <div className="mb-3">
                    <InputText
                      label="Nombre del Inmueble"
                      name         = "nombre"
                      validation   = {validation}>
                    </InputText>
                  </div>
                  <div>
                    <InputTextEditor
                      label="Descripcion del Inmueble"
                      name         = "descripcion"
                      validation   = {validation}>
                    </InputTextEditor>
                  </div>
                </CardBody>
              </Card>

              <Card>
                <CardHeader>
                  <h5 className="card-title mb-0">Imagenes del Inmueble</h5>
                </CardHeader>
                <CardBody>
                  <FileInput  ref={fileInputRef} onFilesChange={handleFilesChange} />

                  <GaleriaDropzone
                    ref={dropZoneRef}
                    externalClick={handleClick}
                    name="imagenes"
                    label="Galeria del Inmueble"
                    validation={validation}>
                  </GaleriaDropzone>
                </CardBody>
              </Card>

              <Card>
                <CardHeader>
                  <Nav className="nav-tabs-custom card-header-tabs border-bottom-0">
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: customActiveTab === "1",
                        })}
                        onClick={() => {
                          toggleCustom("1");
                        }}>
                        Caracteristícas del Inmueble
                      </NavLink>
                    </NavItem>                    
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: customActiveTab === "2",
                        })}
                        onClick={() => {
                          toggleCustom("2");
                        }}>
                        Áreas Comunes
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: customActiveTab === "3",
                        })}
                        onClick={() => {
                          toggleCustom("3");
                        }}
                      >
                        Datos de Negociacion
                      </NavLink>
                    </NavItem>
                    {
                      !isEdit &&
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: customActiveTab === "4",
                          })}
                          onClick={() => {
                            toggleCustom("4");
                          }}
                        >
                          Datos de Cliente
                        </NavLink>
                      </NavItem>
                    }
                  </Nav>
                </CardHeader>

                <CardBody>
                  <TabContent activeTab={customActiveTab}>
                    <TabPane tabId="1">
                      <Row>
                        <TabDatosBasicos validation={validation}></TabDatosBasicos>
                      </Row>
                    </TabPane>

                    <TabPane tabId="2">
                      <Row>
                        <DatosAreasComunes validation={validation}></DatosAreasComunes>
                      </Row>
                    </TabPane>

                    <TabPane tabId="3">
                      <Row>
                        <TabDatosNegociacion isEdit={isEdit} isCreate={isCreate} validation={validation}></TabDatosNegociacion>
                      </Row>
                    </TabPane>
                    {
                      (!isEdit) && 
                      <TabPane tabId="4">
                          <Row>
                            <TabContacto 
                              validation={validation}>
                            </TabContacto>
                          </Row>
                        </TabPane>
                    }
                  </TabContent>
                </CardBody>
              </Card>
            </Form>
          </Col>

          <Col lg={4}>
            <Card>
              {
                editarinmueble?.status_inmueble === 7 &&
                <CardHeader className="bg-soft-primary d-flex flex-column">
                  <span>
                    Este inmueble se encuentra reservado
                  </span>
                  <small>Mientras el inmueble este en reserva, no se puede mostrar ni editar.</small>
                </CardHeader>
              }
              <CardBody className="text-center d-grid">
                <Button
                  color="success" 
                  disabled={!validation.isValid || editarinmueble?.status_inmueble === 7 ||  propiedades.isLoading || isLoading || (
                    !validation.values.cliente && 
                    (validation.values.cliente_nombre === undefined || validation.values.cliente_nombre === '')
                  )}
                  onClick={handleButtonGuardar()} 
                  className="btn-label w-lg">
                    <i className="ri-check-double-line label-icon align-middle fs-16 me-2"></i> 
                    {
                      (propiedades.isLoading|| isLoading) ? (
                        <span className="d-flex align-items-center">
                            <span className="flex-grow-1 me-2">
                                Cargando...
                            </span>
                            <span className="spinner-border flex-shrink-0" role="status">
                                <span className="visually-hidden">Cargando...</span>
                            </span>
                        </span>
                      ) : (
                        <span className="d-flex align-items-center">
                            <span className="flex-grow-1 me-2">
                              { editarinmueble ? 
                                'Actualizar':
                                'Crear'
                              }
                            </span>
                        </span>
                      )
                    }
                </Button>
                {
                  isEdit &&
                  <Button
                    disabled={!validation.isValid || editarinmueble?.status_inmueble === 7 || propiedades.isLoading || isLoading || (
                      !validation.values.cliente && 
                      (validation.values.cliente_nombre === undefined || validation.values.cliente_nombre === '')
                    )}
                    onClick={() => {
                      console.log('hago click')
                      modalReservaRef.current.openForm()
                    }}
                    className="btn-label mt-1 w-lg"
                    color="primary">
                      <i className="ri-check-double-line label-icon align-middle fs-16 me-2"></i> 
                      Reservar
                  </Button>
                }
                <ReservaModal
                  inmueble={editarinmueble}
                  ref={modalReservaRef}>

                </ReservaModal>
              </CardBody>
            </Card>
            <Card>
              <CardHeader>
                <h5 className="card-title mb-0">Publicar</h5>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col  lg={12}>
                      <InputSelect
                          name="status_inmueble"
                          label="Status del inmueble"
                          validation={validation}
                          optionsProps={{
                          value: 'id',
                          label: 'nombre',
                          items: statusChoices || [],
                      }}
                      />
                  </Col>
                  {/* <Col  lg={12}>
                    <InputDatetime
                      label="Fecha de Publicación"
                      name="fecha_publicacion"
                      validation={validation}>
                    </InputDatetime>
                  </Col> */}
                </Row>
              </CardBody>
                <CardBody>
                  <div>
                    { validation.errors && (
                      Object.entries(validation.errors).map(([key, value], index) => {
                        return (
                          <Alert key={index} color="danger">
                            <div>
                              {value}
                            </div>
                          </Alert>
                        );
                      })
                    )}   

                    {
                      (
                        (
                          !validation.values.cliente && 
                          (validation.values.cliente_nombre === undefined || validation.values.cliente_nombre === '')
                        )
                      ) &&
                      <Alert color="danger">
                        <div>
                          Debe seleccionar un cliente para este inmueble o crear uno nuevo.
                        </div>
                      </Alert>
                    }

                    {
                      (
                        (
                          !validation.values.cliente && 
                          (validation.values.cliente_nombre !== undefined && validation.values.cliente_nombre !== '') &&
                          (validation.values.cliente_contacto_valor === undefined || validation.values.cliente_contacto_valor === '')
                        )
                      ) &&
                      <Alert color="danger">
                        <div>
                          Debe agregar un medio de contacto para el nuevo contacto.
                        </div>
                      </Alert>
                    }
                    </div>
                </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default EcommerceAddProduct;
