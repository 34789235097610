import React, { useRef } from 'react';
import { Row, Col, ButtonGroup, Button, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Input } from "reactstrap";
import SearchBox from '../../../../Components/SearchBox';
import FranquiciaFilter from '../../../../Components/FranquiciaFilter';
import { InputDatetime } from '../../../Forms/Inputs/InputDatetime/InputDatetime';

const KanbanBoardHeader = ({ searchTerm, onSearchChange, toggleColumnSettings, loadingFranquicia, franquiciasLit, handleFranquiciaSelection, validation }) => {
  const franquiciaFilterRef = useRef(null);
  const inputDatetimeRef = useRef(null);

  const handleFranquiciasFilter = () => {
    franquiciaFilterRef.current?.clickToggle?.();
  };

  const openDatePicker = () => {
    inputDatetimeRef.current?.open?.(); // Flatpickr tiene un método open
  };

  return (
    <Row className="g-3">
      <Col xs={12} md={3}>
        <UncontrolledDropdown className="input-group">
          <Input type="text" className="form-control" aria-label="Input de texto para busqueda de clientes" />
          <DropdownToggle tag="button" className="btn btn-success" type="button" data-bs-toggle="dropdown" aria-expanded="false">Filtros</DropdownToggle>
          <DropdownMenu className="dropdown-menu-end">
              <li><DropdownItem onClick={toggleColumnSettings}>Columnas</DropdownItem></li>
              <li><DropdownItem onClick={handleFranquiciasFilter}>Franquicias</DropdownItem></li>
              <li><DropdownItem onClick={openDatePicker}>Fechas</DropdownItem></li>
          </DropdownMenu>
        </UncontrolledDropdown>
        <InputDatetime
            placeholder={'Desde'}
            classNameTop='desktop-only'
                  ref={inputDatetimeRef}
                  name="date_range"
            range
            validation={validation}>
          </InputDatetime>
                <FranquiciaFilter
                  className='desktop-only'
                  ref={franquiciaFilterRef}
                  loadingFranquicia={loadingFranquicia}
                  franquiciasLit={franquiciasLit}
                  handleFranquiciaSelection={handleFranquiciaSelection}
                />

        <SearchBox className={'desktop-only'} searchTerm={searchTerm} onChange={onSearchChange} />
      </Col>
      <div className="col-md-auto ms-auto desktop-only">
        <div className="d-flex hastck gap-2 flex-wrap">
          <ButtonGroup style={{ marginLeft: '5px' }}>
            <Button
              style={{ height: 41 }}
              color="light"
              className="btn btn-light btn-sm"
              data-bs-toggle="tooltip"
              onClick={toggleColumnSettings}
              data-bs-placement="top"
              title="Filtro de Columnas">
              <i className="ri-layout-column-fill"></i>
              Columnas
            </Button>
            <FranquiciaFilter
              loadingFranquicia={loadingFranquicia}
              franquiciasLit={franquiciasLit}
              handleFranquiciaSelection={handleFranquiciaSelection}
            />
          </ButtonGroup>
          <InputDatetime
            placeholder={'Desde'}
            name="date_range"
            range
            validation={validation}>
          </InputDatetime>
        </div>
      </div>
    </Row>
  );
};

export default KanbanBoardHeader;