import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { Badge, Button, Card, CardBody, CardHeader, Col, Container, ListGroup, ListGroupItem, Row, Spinner, UncontrolledCollapse } from "reactstrap";
import { useAuth } from "../../AuthContext";
import ParamInput from "../Forms/Inputs/InputParam/InputParam";
import SimpleBar from "simplebar-react";
import { createApiMethods } from "../../helpers/api_calls";
import { FRANQUICIAS, INTEGRACIONES, PROPIEDADES, WEB_SYNC_URL } from "../../helpers/api_urls";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { APIClient } from "../../helpers/api_helper";

export const WordpressIntegration = ({integration,franquicia}) => {
    const api = new APIClient();
    const { user } = useAuth();
    const [configVars, setconfigVars] = useState({});
    const [currentPropsSynced, setCurrentPropsSynced] = useState(null);
    const [properties, setProperties] = useState([]);
    const [isLoadingActualsProps, setIsLoadingActualsProps] = useState(false);
    const [isLoadingSyncedProps, setIsLoadingSyncedProps] = useState(false);
    const apiPropiedades = useMemo(() => createApiMethods(PROPIEDADES), []);
    const [selectedProperties, setSelectedProperties] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
  
    const getPropiedadesSincronizadas = (config) => {
        if (config.list_endpoint && config.web_url && config.api_key) {
          setIsLoadingSyncedProps(true)
          api.get(`${config.web_url}${config.list_endpoint}`, {
            headers: {
              'X-API-Key': config.api_key
            }
          }).then((res) => {
            setCurrentPropsSynced(res.properties);
            toast.success('La lista de propiedades sincronizadas fue obtenida con éxito.');
          }).catch((e) => {
            toast.error('Ocurrió un error al obtener la lista de propiedades sincronizadas.');
            console.error(e);
          }).finally(() => {
            setIsLoadingSyncedProps(false)
          });
        }
    }

    useEffect(() => {
        getPropiedadesSincronizadas(configVars)
    }, [configVars]);

    useEffect(() => {
        setIsLoadingActualsProps(true)

        api.get(`${FRANQUICIAS}${franquicia}/propiedades_enlaces/?page_size=1000`).then((res) => {
          toast.success('Las propiedades seran sincronizadas proximamente.');
          setProperties(res.results);
        }).catch((e) => {
          toast.error('Ocurrió un error al consultar las propiedades disponibles.');
        }).finally(() => {
          setIsLoadingActualsProps(false)
        });

    }, []);

    const handlePropertySelection = (property) => {
        setSelectedProperties(prev => {
            const isSelected = prev.some(p => p.id === property.id);
            if (isSelected) {
                return prev.filter(p => p.id !== property.id);
            } else {
                return [...prev, property];
            }
        });
    };

    const handleSelectAll = () => {
        const selectableProperties = properties.filter(property => 
            !isPropertySynced(property.id) && 
            property.franquicia.configuraciones.contact_email
        );

        if (selectedProperties.length === selectableProperties.length) {
            setSelectedProperties([]);
        } else {
            setSelectedProperties(selectableProperties);
        }
    };

    const syncSelectedProperties = () => {
        if (configVars.list_endpoint && configVars.web_url && configVars.api_key) {
          setIsLoading(true);
          let ids = selectedProperties.map((i)=> i.id)
          api.create(`${INTEGRACIONES}${integration.id}/sync/`, {property_ids: ids}).then((res) => {
            toast.success('Las propiedades seran sincronizadas proximamente.');
          }).catch((e) => {
            toast.error('Ocurrió un error al cargar las propiedades');
          }).finally(() => {
            setIsLoading(false);
          });
        } else {
          toast.error('Para poder sincronizar propiedades deben estar configuradas una web-url, un url-endpoint y wordpress-auth-key')
        }
    };

    const isPropertySynced = (propertyId) => {
        return currentPropsSynced && currentPropsSynced.some(syncedProp => syncedProp.id == propertyId);
    };

    const isPropertySelectable = (property) => {
        return !isPropertySynced(property.id) && property.franquicia?.configuraciones?.contact_email;
    };

    return(
        <Container fluid>
            <Row>
                <Col md={3}>
                    <Card className="bg-soft-info">
                        <CardBody>
                            <ParamInput 
                                franquicia={user.user.franquicia} 
                                int={integration}
                                descripcion={'El endpoint de lista es necesaria para obtener la informacion sincronizada en la pagina web.'} 
                                name="list_endpoint" 
                                setter={setconfigVars}>
                            </ParamInput>
                        </CardBody>
                    </Card>
                </Col>
                <Col md={3}>
                    <Card className="bg-soft-info">
                        <CardBody>
                            <ParamInput 
                                franquicia={user.user.franquicia} 
                                descripcion={'Es necesario para sincronizar y cargar la informacion hacia la pagina web.'} 
                                int={integration}
                                name="upload_endpoint" 
                                setter={setconfigVars}>
                            </ParamInput>
                        </CardBody>
                    </Card>
                </Col>
                <Col md={3}>
                    <Card className="bg-soft-info">
                        <CardBody>
                            <ParamInput 
                                franquicia={user.user.franquicia} 
                                descripcion={'Es necesario para tener una url.'} 
                                int={integration}
                                name="web_url" 
                                setter={setconfigVars}>
                            </ParamInput>
                        </CardBody>
                    </Card>
                </Col>
                <Col md={3}>
                    <Card className="bg-soft-info">
                        <CardBody>
                            <ParamInput 
                                franquicia={user.user.franquicia} 
                                descripcion={'Es necesario para autenticar la comunicacion.'} 
                                int={integration}
                                name="api_key" 
                                setter={setconfigVars}>
                            </ParamInput>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col md={6}>
                    <Card>
                        <CardHeader className="d-flex justify-content-between align-items-center">
                            <span>Propiedades Disponibles</span>
                            <Button
                                color="primary" 
                                size="sm" 
                                onClick={handleSelectAll}>
                                {selectedProperties.length === properties.filter(isPropertySelectable).length 
                                    ? "Deseleccionar Todo" 
                                    : "Seleccionar Todo"}
                            </Button>
                        </CardHeader>
                        <SimpleBar style={{ maxHeight: "600px" }} className="pe-2">
                            <CardBody>
                                <ListGroup>
                                {
                                    isLoadingActualsProps ? (
                                    <div className="p-4 m2 text-center w-100">
                                        <Spinner />
                                    </div>
                                    ) : (
                                    (properties || []).map(property => (
                                        <div className="w-100" key={`prop_${property.id}__prop`}>
                                        <ListGroupItem key={property.id} className="d-flex justify-content-between align-items-center">
                                            <div className="d-flex flex-row w-100">
                                            <img 
                                                src={property.first_image_url} 
                                                alt={property.nombre} 
                                                className="me-2" 
                                                style={{ width: '50px', height: '50px', objectFit: 'cover' }} />
                                                <div className="d-flex flex-column">
                                                <span className="fw-bold cursor-pointer" id={`validationSubItem${property.id}`}>
                                                    {property.nombre}
                                                </span>
                                                <div>
                                                    <span
                                                    className="badge bg-primary m-1">
                                                    {property.franquicia.nombre}
                                                    </span>
                                                    {
                                                    property.franquicia?.configuraciones?.contact_email &&
                                                    <span
                                                        className="badge bg-success m-1">
                                                        Email
                                                    </span>
                                                    }
                                                    {
                                                    property.franquicia.configuraciones.contact_nro_tlf &&
                                                    <span
                                                        className="badge bg-success m-1">
                                                        Nro Telefono
                                                    </span>
                                                    }
                                                    {
                                                    property.franquicia.configuraciones.contact_nro_whatsapp &&
                                                    <span
                                                        className="badge bg-success m-1">
                                                        Whatsapp
                                                    </span>
                                                    }
                                                </div>
                                                </div>
                                            </div>
                                            <div className="d-flex flex-column">
                                            <Button
                                                size="sm"
                                                color={selectedProperties.some(p => p.id === property.id) ? "primary" : "secondary"}
                                                onClick={() => handlePropertySelection(property)}
                                                disabled={!isPropertySelectable(property)}>
                                                {isPropertySynced(property.id) ? "Sincronizada" : 
                                                selectedProperties.some(p => p.id === property.id) ? "Seleccionado" : "Seleccionar"}
                                            </Button>
                                            {
                                                !property.franquicia.configuraciones.contact_email &&
                                                <small className="text-danger text-error error">
                                                Debe configurar un email para poder sincronizar la propiedad. <Link to={`/franquicia/${property.franquicia.id}/`}>Click aqui</Link>
                                                </small>
                                            }
                                            </div>
                                        </ListGroupItem>
                                        <UncontrolledCollapse
                                            className="border-top border-top-dashed"
                                            toggler={`#validationSubItem${property.id}`}
                                            defaultOpen={property.isOpen === true}
                                        >
                                            <Card className="w-100 bg-primary text-white rounded">
                                            <CardBody>
                                                <p className="lh-1">La configuracion de estos parametros es necesaria para recibir las interacciones que generen los inmuebles en las paginas web de terceros.</p>

                                                <ListGroup>
                                                <ListGroupItem className="p-1">
                                                    <span className="badge bg-primary">Email:</span>
                                                    {
                                                    property.franquicia.configuraciones.contact_email ?
                                                    <span className="badge bg-success">
                                                        {property.franquicia.configuraciones.contact_email}
                                                    </span> :
                                                    <span className="badge bg-danger">No configurado</span>
                                                    }
                                                </ListGroupItem>
                                                <ListGroupItem className="p-1">
                                                    <span className="badge bg-primary">Telefono:</span>
                                                    {
                                                    property.franquicia.configuraciones.contact_nro_tlf ?
                                                    <span className="badge bg-success">
                                                        {property.franquicia.configuraciones.contact_nro_tlf}
                                                    </span> :
                                                    <span className="badge bg-danger">No configurado</span>
                                                    }
                                                </ListGroupItem>
                                                <ListGroupItem className="p-1">
                                                    <span className="badge bg-primary">Telefono Whatsapp:</span>
                                                    {
                                                    property.franquicia.configuraciones.contact_nro_whatsapp ?
                                                    <span className="badge bg-success">
                                                        {property.franquicia.configuraciones.contact_nro_whatsapp}
                                                    </span> :
                                                    <span className="badge bg-danger">No configurado</span>
                                                    }
                                                </ListGroupItem>
                                                <ListGroupItem className="p-1">
                                                    <span className="badge bg-primary">Pagina Web:</span>
                                                    {
                                                    property.franquicia.configuraciones.contact_website_url ?
                                                    <span className="badge bg-success">
                                                        {property.franquicia.configuraciones.contact_website_url}
                                                    </span> :
                                                    <span className="badge bg-danger">No configurado</span>
                                                    }
                                                </ListGroupItem>
                                                </ListGroup>
                                            </CardBody>
                                            </Card>
                                        </UncontrolledCollapse>
                                        </div>
                                    ))
                                    )
                                }
                                </ListGroup>
                            </CardBody>
                        </SimpleBar>
                    </Card>
                </Col>

                <Col md={6}>
                <Card>
                    <CardHeader className="d-flex justify-content-between align-items-center">
                        <span>
                            Propiedades Sincronizadas
                        </span>
                        <Button
                            color="secondary"
                            className="btn-sm"
                            onClick={syncSelectedProperties}
                            disabled={isLoading || selectedProperties.length === 0}>
                            {isLoading ? <div className="d-flex ">
                            <p className="text-dark mb-2">Cargando ...</p>

                            <Spinner size="sm" />
                            </div> : 'Sincronizar Seleccion'}
                        </Button>
                    </CardHeader>
                    <SimpleBar style={{ maxHeight: "600px" }} className="pe-2">
                    <CardBody>
                        <ListGroup>
                        {
                            isLoadingSyncedProps ? (
                            <div className="p-4 m2 text-center w-100">
                                <Spinner />
                            </div>
                            ) : (
                            (currentPropsSynced || []).map(property => (
                                <ListGroupItem key={property.id}>
                                {
                                    property.cover_image_url ?
                                    <img src={property.cover_image_url} alt={property.nombre} className="me-2" style={{ width: '50px', height: '50px', objectFit: 'cover' }} />:
                                    <img src={'https://inmobapp-public-bucket.s3.us-east-2.amazonaws.com/static/media/home-default.jpg'} alt={property.nombre} className="me-2" style={{ width: '50px', height: '50px', objectFit: 'cover' }} />

                                }
                                {property.title}
                                <Badge color="success" className="ms-2">Sincronizada</Badge>
                                </ListGroupItem>
                            ))
                            )
                        }
                        </ListGroup>
                    </CardBody>
                    </SimpleBar>
                </Card>
                </Col>
            </Row>
        </Container>
    )
}