import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Card, CardBody, CardHeader, Col, DropdownItem, DropdownMenu, DropdownToggle, FormFeedback, Row, Toast, UncontrolledDropdown } from 'reactstrap';
import * as Yup from 'yup';

//SimpleBar
import SimpleBar from "simplebar-react";
import { useProfile } from '../../../Components/Hooks/UserHooks';
import { useAuth } from '../../../AuthContext';
import { InputModal } from '../../Forms/Inputs/InputModal/InputModal';
import { InputText } from '../../Forms/Inputs/InputText/InputText';
import { useFormik } from 'formik';
import { InputSelect } from '../../Forms/Inputs/InputSelect/InputSelect';
import { APIClient, JSONToFormData, copyToClipboard, generateRandomPassword, tipoFranquiciasList } from '../../../helpers/api_helper';
import { useDispatch, useSelector } from 'react-redux';
import { createApiMethods } from '../../../helpers/api_calls';
import { FRANQUICIAS, TIPOSFRANQUICIAS } from '../../../helpers/api_urls';
import { getciudades, getestados } from '../../../store/actions';
import { InputFile } from '../../Forms/Inputs/InputFile/InputFile';
import { toast } from 'react-toastify';
import TableContainer from '../../../Components/Common/TableContainer';
import PaginationComponent from '../../Forms/Inputs/PaginationComponent/PaginationComponent';
import { Price, Published } from '../../Ecommerce/EcommerceProducts/EcommerceProductCol';
import { PROPIEDAD_ACL } from '../../../helpers/entities';
import Can, { AbilityContext } from '../../../helpers/acl/Can';
// import Can, { AbilityContext } from "../../../helpers/acl/Can";

const OverviewTab = ({franquiciaStore,detail}) => {
    const  franquicia_detail  = franquiciaStore
    const [isMaster, setisMaster] = useState(false);
    const [isComercial, setisComercial] = useState(false);
    const [isPersonal, setisPersonal] = useState(false);
    const [isMembresia, setisMembresia] = useState(false);
    const modalRef = useRef(null);
    const dispatch = useDispatch();
    const apiFranquicias = createApiMethods(FRANQUICIAS)
    const [tiposListSelected, settiposListSelected] = useState([]);
    const [ciudadesList, setciudadesList] = useState([]);
    const { user } = useAuth();
    const { franquicia } = user.user
    const [isLoading, setisLoading] = useState(false);
    const [propiedadesApiResponse, setpropiedadesApiResponse] = useState(null);
    const api = new APIClient();
    const ability = useContext(AbilityContext)

    useEffect(()=>{
        api.get(`${FRANQUICIAS}${detail.id}/propiedades/`).then((res)=>{
            setpropiedadesApiResponse(res)
        }).catch((e)=>{
            console.error(e)
        })
    },[])
    
    useEffect(() => {
        if(user.isAdmin()){
            settiposListSelected(tipoFranquiciasList)
        } else if(user.isFranquiciaGeneral()){
            settiposListSelected([tipoFranquiciasList[1],tipoFranquiciasList[2],tipoFranquiciasList[3]])
        } else if(user.isFranquiciaComercial()){
            settiposListSelected([tipoFranquiciasList[2],tipoFranquiciasList[3]])
        } else if(user.isFranquiciaPersonal()){
            settiposListSelected([tipoFranquiciasList[3]])
        }

        dispatch(getciudades({page_size: 1000}));
        dispatch(getestados({page_size: 1000}));
    }, [])

    const { propiedades } = useSelector((state) => ({
        propiedades: state.Propiedades,
    }));

    useEffect(() => {
        const setFranquiciaTypeStates = () => {
            if (user) {
                if (franquicia && franquicia.tipo_franquicia) {
                    const slugName = franquicia.tipo_franquicia.slug_name;
                    setisMaster(slugName === 'franquicia_general');
                    setisComercial(slugName === 'franquicia_comercial');
                    setisPersonal(slugName === 'franquicia_personal');
                    setisMembresia(slugName === 'membresia');
                } else {
                    setisMaster(user.isAdmin());
                    setisComercial(user.isAdmin());
                    setisPersonal(user.isAdmin());
                    setisMembresia(false);
                }
            }
        };
        
        setFranquiciaTypeStates();
      }, [ user]);

    const addFranquicia = () => {
        modalRef.current.toggle()
    }


    function itemFranquicia(item,key) {
        return <div className="vstack gap-3" key={key}>
            <div className="d-flex align-items-center">
                <div className="avatar-xs flex-shrink-0 me-3 rounded-circle" style={{overflow:'hidden', textAlign:'center'}}>
                    <img src={item.first_image_url} alt="" style={{height: '100%', width:'auto'}} />
                </div>
                <div className="flex-grow-1">
                    <h5 className="fs-13 mb-0"><Link to="#" className="text-body d-block">{item.nombre}</Link></h5>
                </div>
                <div className="flex-shrink-0">
                    <div className="d-flex align-items-center gap-1">
                        {/* <button type="button" className="btn btn-light btn-sm">Mensaje</button> */}
                        <UncontrolledDropdown>
                            <DropdownToggle type="button" className="btn btn-icon btn-sm fs-16 text-muted dropdown" tag="button">
                                <i className="ri-more-fill"></i>
                            </DropdownToggle>
                            <DropdownMenu>
                                <li><DropdownItem><i className="ri-eye-fill text-muted me-2 align-bottom"></i>Ver</DropdownItem></li>
                                {/* <li><DropdownItem><i className="ri-star-fill text-muted me-2 align-bottom"></i>Favourite</DropdownItem></li> */}
                                {/* <li><DropdownItem><i className="ri-delete-bin-5-fill text-muted me-2 align-bottom"></i>Delete</DropdownItem></li> */}
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </div>
                </div>
            </div>
        </div>;
    }
    
    const barraSuscripciones = <>
        {(isMaster) &&
            (
                <Card>
                    <CardHeader className="align-items-center d-flex border-bottom-dashed">

                        <h4 className="card-title mb-0 flex-grow-1">
                            Franquicias Comerciales
                        </h4>
                        <div className="flex-shrink-0">
                            <button
                                type="button"
                                className="btn btn-soft-primary btn-sm"
                                data-bs-toggle="modal"
                                data-bs-target="#inviteMembersModal">
                                <i className="ri-add-circle-line me-1 align-bottom"></i> Agregar nueva
                            </button>
                        </div>
                    </CardHeader>

                    <CardBody>
                        <SimpleBar data-simplebar style={{ height: "235px" }} className="mx-n3 px-3">
                            {(franquicia_detail?.franquicias_comerciales || []).map((item, key) => {
                                return <div key={key}>
                                    {itemFranquicia(item, key)}
                                </div>;
                            })}

                        </SimpleBar>

                    </CardBody>
                </Card>
            )}
        {(isComercial || isMaster) &&
            (
                <Card>
                    <CardHeader className="align-items-center d-flex border-bottom-dashed">

                        <h4 className="card-title mb-0 flex-grow-1">
                            Franquicias Personales
                        </h4>
                        <div className="flex-shrink-0">
                            <button
                                type="button"
                                onClick={ () => addFranquicia()}
                                className="btn btn-soft-primary btn-sm"
                                data-bs-toggle="modal"
                                data-bs-target="#inviteMembersModal">
                                <i className="ri-add-circle-line me-1 align-bottom"></i> Agregar nueva
                            </button>
                        </div>
                    </CardHeader>

                    <CardBody>
                        <SimpleBar data-simplebar style={{ height: "235px" }} className="mx-n3 px-3">
                            {(franquicia_detail?.franquicias_personales || []).map((item, key) => {
                                return <div key={key}>
                                    {itemFranquicia(item, key)}
                                </div>;
                            })}

                        </SimpleBar>

                    </CardBody>
                </Card>
            )}
        {(isPersonal || isComercial || isMaster) &&
            (
                <Card>
                    <CardHeader className="align-items-center d-flex border-bottom-dashed">

                        <h4 className="card-title mb-0 flex-grow-1">
                            Membresías
                        </h4>
                        <div className="flex-shrink-0">
                            <button
                                type="button"
                                onClick={ () => addFranquicia()}
                                className="btn btn-soft-primary btn-sm"
                                data-bs-toggle="modal"
                                data-bs-target="#inviteMembersModal">
                                <i className="ri-add-circle-line me-1 align-bottom"></i> Agregar nueva
                            </button>
                        </div>
                    </CardHeader>

                    <CardBody>
                        <SimpleBar data-simplebar style={{ height: "235px" }} className="mx-n3 px-3">
                            {(franquicia_detail?.membresias || []).map((item, key) => {
                                return <div key={key}>
                                    {itemFranquicia(item, key)}
                                </div>;
                            })}

                        </SimpleBar>

                    </CardBody>
                </Card>
            )}
    </>;

    const submitHandler = (values) => {
        modalRef.current.toggleLoading()
        values.slug_perfil = 'gerente'
        let jsonObj = JSONToFormData(values)
        apiFranquicias.create(jsonObj).then((res)=>{
            modalRef.current.toggle()
        }).catch((err)=>{
            toast.error(err)
        }).finally(()=>{
            modalRef.current.toggleLoading()
            validation.resetForm()
        })
    }
    
    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            image          : undefined,
            tipo_franquicia: null,
            ciudad         : null,
            estado         : null,
            direccion      : null,
            nombre         : null,
            descripcion    : null,
            first_name     : null,
            last_name      : null,
            email          : null,
            password       : null,
            img_perfil     : null,
            franquicia_master: user.franquicia && user.isFranquiciaGeneral() ? user.user.franquicia.id : null,
            franquicia_comercial: user.franquicia && user.isFranquiciaComercial() ? user.user.franquicia.id : null ,
            franquicia_personal: user.franquicia && user.isFranquiciaPersonal() ? user.user.franquicia.id : null ,
        },
        validationSchema: Yup.object({
            // image: Yup.mixed().notRequired(),
            nombre: Yup.string().required("Introduzca un nombre de Franquicia"),
            first_name: Yup.string().required("Introduzca un nombre de Asesor Asociado"),
            tipo_franquicia: Yup.mixed().required("Seleccione un tipo de franquicia"),
            last_name: Yup.string().required("Introduzca un apellido de Asesor Asociado"),
            email: Yup.string().email('Introduzca un email válido').required('Introduzca un email.'),
            password: Yup.string().required("Introduzca una contraseña"),

            image: Yup.mixed()                
                .test('fileRequired', 'Adjunta una foto de perfil.', function (value) {
                    return value !== undefined && value !== null
                })
                .test('fileSize', 'El tamaño del archivo es demasiado grande.', function (value) {
                    if(value !== undefined && value !== null)
                        return value.size <= 5 * 1024 * 1024;
                    else
                        return false
                })
                .test('fileType', 'Tipo de archivo no permitido.', function (value) {
                    if(value !== undefined && value !== null)
                        return value !== undefined && value.type.startsWith('image/');
                    else
                        return false
                }),

            img_perfil: Yup.mixed()
                .test('fileRequired', 'Adjunta una foto de perfil.', function (value) {
                    return value !== undefined && value !== null
                })
                .test('fileSize', 'El tamaño del archivo es demasiado grande.', function (value) {
                    if(value !== undefined && value !== null)
                        return value.size <= 5 * 1024 * 1024;
                    else
                        return false
                })
                .test('fileType', 'Tipo de archivo no permitido.', function (value) {
                    if(value !== undefined && value !== null)
                        return value !== undefined && value.type.startsWith('image/');
                    else
                        return false
                }),
        }),
        onSubmit: submitHandler,
    });

    const estadoValue = Number(validation.values.estado)
    useEffect(() => {
        if (propiedades.ciudades && propiedades.ciudades.results) {
            if (estadoValue !== null || estadoValue!== undefined || estadoValue!==0){
                let filters = propiedades.ciudades.results.filter((e) => e.estado.id === estadoValue)
                setciudadesList(filters)
            }
        }
    }, [estadoValue, propiedades.ciudades]);

    const modalAddFranquicia = <>
        <InputModal
            submitEvent={() => validation.submitForm()}
            ref={modalRef}>
                <Row>
                    <Col xl={12}>
                        <h6 className="mb-3">Datos de Franquicia</h6>
                    </Col>
                    <Col xl={3} style={{height: 'auto', margin: 'auto'}}>
                        <InputFile
                            placeholder="<span class='filepond--label-action'>Adjunta</span> una foto de franquicia."
                            name         = "image"
                            validation   = {validation}>
                        </InputFile>
                    </Col>
                    <Col xl={9} style={{height: 'auto', margin: 'auto'}}>
                        <InputText
                            label="Nombre"
                            name         = "nombre"
                            validation   = {validation}>
                        </InputText>
                        <InputSelect
                            name         = "tipo_franquicia"
                            label        = "Tipo"
                            validation   = {validation}
                            optionsProps = {
                                {
                                    value:'id',
                                    label:'nombre',
                                    items:tiposListSelected || []
                                }}>
                        </InputSelect>  
                    </Col>
                    <Col xl={6}>
                        <InputSelect
                            name         = "estado"
                            label        = "Estado"
                            validation   = {validation}
                            optionsProps = {
                                {
                                    value:'id',
                                    label:'nombre',
                                    items:propiedades.estados.results || []
                                }}>
                        </InputSelect>                                
                    </Col>
                    <Col xl={6}>
                        <InputSelect
                            disabled={!!!validation.values.estado}
                            name         = "ciudad"
                            label        = "Ciudades"
                            validation   = {validation}
                            optionsProps = {
                                {
                                    value:'id',
                                    label:'nombre',
                                    items:ciudadesList || []
                                }}>
                        </InputSelect>                                
                    </Col>
                    <Col xl={12}>
                        <h6 className="mb-3">Datos de Acceso</h6>
                    </Col>


                    <Col xl={9} style={{height: 'auto', margin: 'auto'}}>
                        <InputText
                            label="Nombre"
                            name         = "first_name"
                            validation   = {validation}>
                        </InputText>
                        <InputText
                            label="Apellido"
                            name         = "last_name"
                            validation   = {validation}>
                        </InputText>
                    </Col>
                    <Col xl={3} style={{height: 'auto', margin: 'auto'}}>
                        <InputFile
                            placeholder="<span class='filepond--label-action'>Adjunta</span> una foto de perfil."
                            name         = "img_perfil"
                            validation   = {validation}>
                        </InputFile>
                    </Col>
                    <Col xl={12}>
                        <InputText
                            label="Email"
                            name         = "email"
                            validation   = {validation}>
                        </InputText>
                    </Col>
                    <Col xl={7}>
                        <InputText
                            label="Contraseña"
                            name         = "password"
                            validation   = {validation}>
                        </InputText>
                    </Col>
                    <Col xl={5} className='d-flex flex-column-reverse'>
                    <div className="btn-group mt-4 mt-md-0" role="group" aria-label="Basic example">
                        <Button
                            onClick={()=> validation.setFieldValue('password',generateRandomPassword())}
                            className='btn btn-danger btn-block mb-3 btn-label waves-effect right waves-light'>
                                <i className=" ri-shield-keyhole-line label-icon align-middle fs-16 ms-2"></i>
                                Crear Clave
                        </Button>
                        <Button
                            onClick={()=> copyToClipboard(validation.values.password)}
                            className='btn btn-success btn-block mb-3 btn-label waves-effect right waves-light'>
                                <i className="ri-file-copy-line label-icon align-middle fs-16 ms-2"></i>
                                Copiar
                        </Button>
                    </div>
                    </Col>
                </Row>                
        </InputModal>
    </>
    
    const validationFilter = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: {
            estado       : null,
            ciudad       : null,
            banios       : null,
            franquicia   : null,
            min_precio   : 0,
            max_precio   : 0,
            tipo_inmueble: null,
        },
    });

    const columns = useMemo(
        () => [
          {
            Header: "#",
            Cell: (cell) => {
              return <input type="checkbox" className="productCheckBox form-check-input" value={cell.row.original.id}  />;
            },
          },
          {
            Header: "Inmueble",
            Cell: (product) => (
              <>
                <div className="d-flex align-items-center">
                  <div className="flex-shrink-0 me-3">
                    <div className="avatar-sm bg-light rounded p-1">
                      <img
                            src={product.row.original.first_image_url}
                            alt=""
                            className="img-fluid d-block"/>
                    </div>
                  </div>
                  <div className="flex-grow-1">
                    <h5 className="fs-14 mb-1">
                      <Link
                        to={`/inmueble/${product.row.original.id}`}
                        className="text-dark"
                      >
                        {" "}
                        {product.row.original.nombre}
                      </Link>
                    </h5>
                    <p className="text-muted mb-0">
                      {/* Category :{" "} */}
                      <span className="fw-medium">
                        {" "}
                        {product.row.original?.tipo_inmueble?.nombre}
                      </span>
                    </p>
                  </div>
                </div>
              </>
            ),
          },
          {
            Header: "Franquicia",
            accessor: "franquicia",
            filterable: false,
            Cell: (cellProps) => {
              return <>
                <div className="d-flex align-items-center">
                    <p className="text-muted mb-0">
                      {/* Category :{" "} */}
                      <span className="fw-medium">
                        {" "}
                        {cellProps.row.original?.franquicia?.nombre}
                      </span>
                    </p>
                </div>
              </>
            },
          },
          {
            Header: "Precio",
            accessor: "precio",
            filterable: false,
            Cell: (cellProps) => {
              return <Price {...cellProps} />;
            },
          },
          {
            Header: "Fecha publicacion",
            accessor: "fecha_publicacion",
            filterable: false,
            Cell: (cellProps) => {
              return <Published {...cellProps} />;
            },
          },
          {
            Header: " ",
            Cell: (cellProps) => {
              var propiedadRow = cellProps.row.original
              propiedadRow.obj_type = PROPIEDAD_ACL
              return (
                <UncontrolledDropdown>
                  <DropdownToggle
                    className="btn btn-soft-secondary btn-sm"
                    tag="button">
                    <i className="ri-more-fill" />
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-menu-end">
                    <DropdownItem
                      onClick={() => {
                        const productData = cellProps.row.original;
                        
                      }}>
                      <i className="ri-eye-fill align-bottom me-2 text-muted"></i>{" "}
                      Ver Detalles
                    </DropdownItem>
                        <Can I="update" this={propiedadRow} ability={ability}>
                          <DropdownItem
                            onClick={() => {
                              const productData = cellProps.row.original;
                              
                            }}>
                            <i className="ri-pencil-fill align-bottom me-2 text-muted"></i>{" "}
                            Editar
                          </DropdownItem>
                        </Can>  
    
                        {/* <Can I="delete" this={propiedadRow}>
                          <DropdownItem divider />
                          <DropdownItem
                            onClick={() => {
                              const productData = cellProps.row.original;
                              onClickDelete(productData);
                            }}>
                            <i className="ri-delete-bin-fill align-bottom me-2 text-muted"></i>{" "}
                            Eliminar
                          </DropdownItem>
                        </Can>      */}
    
                  </DropdownMenu>
                </UncontrolledDropdown>
              );
            },
          },
        ],
        []
    );


    const renderPaginationTodos = () => {
        return (
          <PaginationComponent
            activePage={propiedadesApiResponse.page_number}
            totalPages={propiedadesApiResponse.num_pages}
            onPageChange={() => {}}
          />
        );
    };

    return (
        <React.Fragment>
            {modalAddFranquicia}
            {
                propiedadesApiResponse &&
                <Row>
                    <Col md={12}>
                        <>

                        </>
                    </Col>
                </Row>
            }
            <Row>
                <Col xl={9} lg={8}>
                    <Card>
                        <CardHeader className="align-items-center d-flex">
                            <h4 className="card-title mb-0 flex-grow-1">Propiedades</h4>
                            <div className="flex-shrink-0">
                                {/* <UncontrolledDropdown className="card-header-dropdown">
                                    <DropdownToggle tag="a" className="text-reset dropdown-btn" href="#">
                                        <span className="text-muted">Reciente<i className="mdi mdi-chevron-down ms-1"></i></span>
                                    </DropdownToggle>
                                    <DropdownMenu className="dropdown-menu-end">
                                        <DropdownItem>Esta semana</DropdownItem>
                                        <DropdownItem>Principales</DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown> */}
                            </div>
                        </CardHeader>

                        {
                            propiedadesApiResponse &&
                            <CardBody>
                                <TableContainer
                                    paginatedResponse = {propiedadesApiResponse}
                                    apicallback       = {() => {}}
                                    columns           = {columns}
                                    filtersetValues   = {validationFilter.values}
                                    data              = {propiedadesApiResponse.results}
                                    customPageSize    = {1000}
                                    divClass          = "table-responsive mb-1"
                                    tableClass        = "mb-0 align-middle table-borderless"
                                    theadClass        = "table-light text-muted" />
                                {renderPaginationTodos()}
                            </CardBody>
                        }
                        

                    </Card>

                </Col>

                <Col xl={3} lg={4}>
                    {!isMembresia &&         
                        barraSuscripciones
                    }
                    <Card>
                        <CardHeader className="align-items-center d-flex border-bottom-dashed">
                            <h4 className="card-title mb-0 flex-grow-1">Documentos</h4>
                            <div className="flex-shrink-0">
                                <button type="button" className="btn btn-soft-primary btn-sm"><i className="ri-upload-2-fill me-1 align-bottom"></i> Argegar</button>
                            </div>
                        </CardHeader>

                        <CardBody>
                            <div className="vstack gap-2">
                                <div className="border rounded border-dashed p-2">
                                    <div className="d-flex align-items-center">
                                        <div className="flex-shrink-0 me-3">
                                            <div className="avatar-sm">
                                                <div className="avatar-title bg-light text-primary rounded fs-24">
                                                    <i className="ri-folder-zip-line"></i>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex-grow-1 overflow-hidden">
                                            <h5 className="fs-13 mb-1"><Link to="#" className="text-body text-truncate d-block">formatos_w.zip</Link></h5>
                                            <div>2.2MB</div>
                                        </div>
                                        <div className="flex-shrink-0 ms-2">
                                            <div className="d-flex gap-1">
                                                <button type="button" className="btn btn-icon text-muted btn-sm fs-18"><i className="ri-download-2-line"></i></button>
                                                <UncontrolledDropdown>
                                                    <DropdownToggle tag="button" className="btn btn-icon text-muted btn-sm fs-18 dropdown" type="button">
                                                        <i className="ri-more-fill"></i>
                                                    </DropdownToggle>
                                                    <DropdownMenu>
                                                        <li><DropdownItem><i className="ri-pencil-fill align-bottom me-2 text-muted"></i> Renombrar</DropdownItem></li>
                                                        <li><DropdownItem><i className="ri-delete-bin-fill align-bottom me-2 text-muted"></i> Eliminar</DropdownItem></li>
                                                    </DropdownMenu>
                                                </UncontrolledDropdown>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="border rounded border-dashed p-2">
                                    <div className="d-flex align-items-center">
                                        <div className="flex-shrink-0 me-3">
                                            <div className="avatar-sm">
                                                <div className="avatar-title bg-light text-primary rounded fs-24">
                                                    <i className="ri-file-ppt-2-line"></i>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex-grow-1 overflow-hidden">
                                            <h5 className="fs-13 mb-1"><Link to="#" className="text-body text-truncate d-block">perfiles_asesores.ppt</Link></h5>
                                            <div>2.4MB</div>
                                        </div>
                                        <div className="flex-shrink-0 ms-2">
                                            <div className="d-flex gap-1">
                                                <button type="button" className="btn btn-icon text-muted btn-sm fs-18"><i className="ri-download-2-line"></i></button>
                                                <UncontrolledDropdown>
                                                    <DropdownToggle tag="button" className="btn btn-icon text-muted btn-sm fs-18 dropdown" type="button">
                                                        <i className="ri-more-fill"></i>
                                                    </DropdownToggle>
                                                    <DropdownMenu>
                                                        <li><DropdownItem><i className="ri-pencil-fill align-bottom me-2 text-muted"></i> Renombrar</DropdownItem></li>
                                                        <li><DropdownItem><i className="ri-delete-bin-fill align-bottom me-2 text-muted"></i> Eliminar </DropdownItem></li>
                                                    </DropdownMenu>
                                                </UncontrolledDropdown>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="border rounded border-dashed p-2">
                                    <div className="d-flex align-items-center">
                                        <div className="flex-shrink-0 me-3">
                                            <div className="avatar-sm">
                                                <div className="avatar-title bg-light text-primary rounded fs-24">
                                                    <i className="ri-folder-zip-line"></i>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex-grow-1 overflow-hidden">
                                            <h5 className="fs-13 mb-1"><Link to="#" className="text-body text-truncate d-block">recursos_graficos.zip</Link></h5>
                                            <div>1.2MB</div>
                                        </div>
                                        <div className="flex-shrink-0 ms-2">
                                            <div className="d-flex gap-1">
                                                <button type="button" className="btn btn-icon text-muted btn-sm fs-18"><i className="ri-download-2-line"></i></button>
                                                <UncontrolledDropdown>
                                                    <DropdownToggle tag="button" className="btn btn-icon text-muted btn-sm fs-18 dropdown" type="button">
                                                        <i className="ri-more-fill"></i>
                                                    </DropdownToggle>
                                                    <DropdownMenu>
                                                        <li><DropdownItem><i className="ri-pencil-fill align-bottom me-2 text-muted"></i> Renombrar</DropdownItem></li>
                                                        <li><DropdownItem><i className="ri-delete-bin-fill align-bottom me-2 text-muted"></i> Eliminar</DropdownItem></li>
                                                    </DropdownMenu>
                                                </UncontrolledDropdown>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="border rounded border-dashed p-2">
                                    <div className="d-flex align-items-center">
                                        <div className="flex-shrink-0 me-3">
                                            <div className="avatar-sm">
                                                <div className="avatar-title bg-light text-primary rounded fs-24">
                                                    <i className="ri-image-2-line"></i>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex-grow-1 overflow-hidden">
                                            <h5 className="fs-13 mb-1"><Link to="#" className="text-body text-truncate d-block">apartamento_laboyera.png</Link></h5>
                                            <div>1.1MB</div>
                                        </div>
                                        <div className="flex-shrink-0 ms-2">
                                            <div className="d-flex gap-1">
                                                <button type="button" className="btn btn-icon text-muted btn-sm fs-18"><i className="ri-download-2-line"></i></button>
                                                <UncontrolledDropdown>
                                                    <DropdownToggle tag="button" className="btn btn-icon text-muted btn-sm fs-18 dropdown" type="button">
                                                        <i className="ri-more-fill"></i>
                                                    </DropdownToggle>
                                                    <DropdownMenu>
                                                        <li><DropdownItem><i className="ri-pencil-fill align-bottom me-2 text-muted"></i> Renombrar</DropdownItem></li>
                                                        <li><DropdownItem><i className="ri-delete-bin-fill align-bottom me-2 text-muted"></i> Eliminar</DropdownItem></li>
                                                    </DropdownMenu>
                                                </UncontrolledDropdown>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-2 text-center">
                                    <button type="button" className="btn btn-primary">Ver Todos</button>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default OverviewTab;
