import React, { useRef } from "react";
import { Button, Card, CardBody } from "reactstrap";
import { useSelector } from "react-redux";
import { PropertyReservaModal } from "./PropertyReservaModal";
import { ReservaModal } from "../Ecommerce/EcommerceProducts/ReservaModal";

export const PropertySubmitButtons = ({ validation, inmueble, isEdit }) => {
  const { propiedades, isLoading } = useSelector((state) => state.Propiedades);
  const modalReservaRef = useRef(null);

  return (
    <Card>
      <CardBody className="text-center d-grid">
        <Button
          color="success"
          disabled={!validation.isValid || propiedades.isLoading || isLoading}
          onClick={validation.handleSubmit}
          className="btn-label w-lg"
        >
          <i className="ri-check-double-line label-icon align-middle fs-16 me-2"></i>
          {isLoading ? "Cargando..." : isEdit ? "Actualizar" : "Crear"}
        </Button>
        {isEdit && (
          <Button
            onClick={() => {
              modalReservaRef.current.openForm()
            }}
            className="btn-label mt-1 w-lg"
            color="primary"
          >
            <i className="ri-check-double-line label-icon align-middle fs-16 me-2"></i>
            Reservar
          </Button>
        )}

        <ReservaModal
          inmueble={inmueble}
          ref={modalReservaRef}>
        </ReservaModal>
      </CardBody>
    </Card>
  );
};
