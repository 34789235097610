import React, { useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Col, Container, Row, Spinner } from "reactstrap";
import { PropertyDetailsForm } from "./PropertyDetailsForm";
import { PropertyImageUploadForm } from "./PropertyImageUploadForm";
import { PropertyStatusForm } from "./PropertyStatusForm";
import { PropertySubmitButtons } from "./PropertySubmitButtons";
import { PropertyTabsForm } from "./PropertyTabsForm";
import { usePropertyData, usePropertyForm } from "./hooksProperty";
import { useAuth } from "../../AuthContext";
import BreadCrumb from "../../Components/Common/BreadCrumb";

const EcommerceAddProperty = () => {
  const { idinmueble } = useParams();
  const history = useNavigate();
  const { user } = useAuth();
  
  const dropZoneRef = useRef(null);

  const { propertyData, isLoadingPropiedad, isEdit, handleSaveProperty } = usePropertyData(idinmueble, dropZoneRef, history);
  const { validation } = usePropertyForm(propertyData, isEdit, handleSaveProperty);

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb title="Formulario de Inmueble" pageTitle="Editor de Inmueble" />
        {(isLoadingPropiedad || validation.isSubmitting) && (
          <div style={{ position: 'absolute', height: '100%', left: '50%', top: 350, zIndex: 1, display: 'flex', flexDirection: 'column' }}>
            <span className="fs-5 text">Cargando</span>
            <div className="text-center w-100">
              <Spinner color="primary"></Spinner>
            </div>
          </div>
        )}
        <Row>
          <Col lg={8}>
            <form onSubmit={validation.handleSubmit}>
              <PropertyDetailsForm validation={validation} />
              <PropertyTabsForm validation={validation} isEdit={isEdit} />
              <PropertyImageUploadForm validation={validation} dropZoneRef={dropZoneRef} />
            </form>
          </Col>
          <Col lg={4}>
            <PropertySubmitButtons inmueble={propertyData} validation={validation} isEdit={isEdit} />
            <PropertyStatusForm validation={validation} />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default EcommerceAddProperty;
