import React, {useState} from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Card, CardBody, Col, Container, Row, Form, Input, Label, FormFeedback } from 'reactstrap';
import ParticlesAuth from '../ParticlesAuth';
import logoLight from "../../../assets/images/logo-light.png";

//formik
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { inmoAppLogoSinFondo } from '../../../assets/images';
import { useAuth } from '../../../AuthContext';
import { userChangeForm } from '../../../helpers/api_calls';
import { toast } from 'react-toastify';
import { createBrowserHistory } from 'history';

const BasicPasswCreate = () => {
    const { user } = useAuth();
    const [isLoading, setisLoading] = useState();
    document.title = "Crear Nueva Contraseña | Inmoapp";
    const history = createBrowserHistory();
	const navigate                             = useNavigate();
    // navigate(route_BUSQUEDA)

    const [passwordShow, setPasswordShow] = useState(false);
    const [confrimPasswordShow, setConfrimPasswordShow] = useState(false);    

    const validation = useFormik({
        enableReinitialize: true,

        initialValues: {
            old_password: '',
            new_password: "",
            confrim_password: "",
        },
        validationSchema: Yup.object({
            old_password: Yup.string()
                .min(8, 'Mínimo 8 caracteres')
                .required("Este campo es obligatorio"),
            new_password: Yup.string()
                .min(8, 'Mínimo 8 caracteres')
                .matches(RegExp('(.*[a-z].*)'), 'Al menos una letra en minuscula')
                .matches(RegExp('(.*[A-Z].*)'), 'Al menos una letra mayusculas')
                .matches(RegExp('(.*[0-9].*)'), 'Al menos un número')
                .required("Este campo es obligatorio"),
            confrim_password: Yup.string()
                .when("new_password", {
                    is: (val) => (val && val.length > 0 ? true : false),
                    then: Yup.string().oneOf(
                        [Yup.ref("new_password")],
                        "Ambas contraseñas deben ser identicas"
                    ),
                })
                .required("Confirmacion de contraseña es obligatorio"),
        }),
        onSubmit: (values) => {
            // navigate('/logout')
            setisLoading(true)
            userChangeForm(user.id,values).then((res)=>{
                toast.success("Contraseña actualizada con éxito", { autoClose: 3000 });
                setisLoading(false)
                navigate('/logout')
                window.location.reload();
            }).catch(()=>{
                toast.error("Ocurrió un error al actualizar la contraseña", { autoClose: 3000 });
            }).finally(()=>{
            })
        }
    });

    const resetForm = () => {
        validation.resetForm()
    }

    return (
        <ParticlesAuth>
            <div className="auth-page-content">
                <Container>

                    <Row className="justify-content-center">
                        <Col md={8} lg={6} xl={5}>
                            <Card className="mt-4">

                                <CardBody className="p-4">
                                    <div className="text-center mt-sm-5 mb-4 text-white-50">
                                            <div>
                                                <Link to="/" className="d-inline-block auth-logo">
                                                    <img src={inmoAppLogoSinFondo} alt="" height="120" />
                                                </Link>
                                            </div>
                                    </div>
                                    <div className="text-center mt-2">
                                        <h5 className="text-primary">Nueva Contraseña</h5>
                                        <p className="text-muted">Debe crear una nueva contraseña segura para poder acceder a las funcionalidades.</p>
                                    </div>

                                    <div className="p-2">
                                        <Form onSubmit={validation.handleSubmit} action="/auth-signin-basic">
                                            <div>
                                                <Label htmlFor="old_password" className="form-label">Contraseña anterior *</Label>
                                                <Input 
                                                    type        = "password" 
                                                    className   = "form-control"
                                                    id          = "old_password" 
                                                    name        = "old_password" 
                                                    onChange    = {validation.handleChange} 
                                                    onBlur      = {validation.handleBlur} 
                                                    value       = {validation.values.old_password}
                                                    placeholder = "Escribe tu contraseña actual" />
                                                    {validation.errors.old_password && validation.touched.old_password && (
                                                        <p>{validation.errors.old_password}</p>
                                                    )}
                                            </div>
                                            <div className="mb-3">
                                                <Label className="form-label" htmlFor="password-input">Contraseña *</Label>
                                                <div className="position-relative auth-pass-inputgroup">
                                                    <Input
                                                        type={passwordShow ? "text" : "password"}
                                                        className="form-control pe-5 password-input"
                                                        placeholder="Escriba una contraseña"
                                                        id="password-input"
                                                        name="new_password"
                                                        value={validation.values.new_password}
                                                        onBlur={validation.handleBlur}
                                                        onChange={validation.handleChange}
                                                        invalid={validation.errors.new_password && validation.touched.new_password ? true : false}
                                                    />
                                                    {validation.errors.new_password && validation.touched.new_password ? (
                                                        <FormFeedback type="invalid" className="d-block">{validation.errors.new_password}</FormFeedback>
                                                    ) : null}
                                                    <Button color="link" onClick={() => setPasswordShow(!passwordShow)} className="position-absolute end-0 top-0 text-decoration-none text-muted password-addon" type="button"
                                                        id="password-addon"><i className="ri-eye-fill align-middle"></i></Button>
                                                </div>
                                                <div id="passwordInput" className="form-text">Debe tener al menos 8 caracteres.</div>
                                            </div>

                                            <div className="mb-3">
                                                <Label className="form-label" htmlFor="confirm-password-input">Confirmar Contraseña *</Label>
                                                <div className="position-relative auth-pass-inputgroup mb-3">
                                                    <Input
                                                         type={confrimPasswordShow ? "text" : "password"}
                                                        className="form-control pe-5 password-input"
                                                        placeholder="Confirmar contraseña"
                                                        id="confirm-password-input"
                                                        name="confrim_password"                                                       
                                                        value={validation.values.confrim_password}
                                                        onBlur={validation.handleBlur}
                                                        onChange={validation.handleChange}
                                                        invalid={validation.errors.confrim_password && validation.touched.confrim_password ? true : false}
                                                    />
                                                    {validation.errors.confrim_password && validation.touched.confrim_password ? (
                                                        <FormFeedback type="invalid" className="d-block">{validation.errors.confrim_password}</FormFeedback>
                                                    ) : null}
                                                    <Button color="link" onClick={() => setConfrimPasswordShow(!confrimPasswordShow)} className="position-absolute end-0 top-0 text-decoration-none text-muted password-addon" type="button">
                                                    <i className="ri-eye-fill align-middle"></i></Button>
                                                </div>
                                            </div>

                                            <div id="password-contain" className="p-3 bg-light mb-2 rounded">
                                                <h5 className="fs-13">La contraseña debe tener:</h5>
                                                <p id="pass-length" className="invalid fs-12 mb-2">Mínimo <b>8 caracteres</b></p>
                                                <p id="pass-lower" className="invalid fs-12 mb-2">Letras <b>minusculas</b> (a-z)</p>
                                                <p id="pass-upper" className="invalid fs-12 mb-2">Letras <b>mayusculas</b> (A-Z)</p>
                                                <p id="pass-number" className="invalid fs-12 mb-0">Al menos un <b>numero</b> (0-9)</p>
                                            </div>

                                            <div className="mt-4">
                                                <Button 
                                                    disabled={isLoading}
                                                    color="success" 
                                                    className="w-100" 
                                                    type="submit">Crear Nueva Contraseña
                                                </Button>
                                            </div>
                                        </Form>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </ParticlesAuth>
    );
};

export default BasicPasswCreate;