import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import './RadioButtons.css';
import { Col, Row, Button } from 'reactstrap'; // Asegúrate de importar Button de 'reactstrap'

const RadioButtons = forwardRef(({ items, nameProp, search = false, valueProp, handler }, ref) => {
    const [selectedValue, setSelectedValue] = useState(null);

    useImperativeHandle(ref, () => ({
        selectedValue: selectedValue,
        reset: handleReset
    }));

    const handleSelect = (value) => {
        setSelectedValue(value);
    }

    const handleReset = () => {
        setSelectedValue(null); // Deselecciona cualquier opción seleccionada
    }

    useEffect(() => {
        handler(selectedValue)
    }, [selectedValue]);

    return (
        <Row>
            <Col xl={12}>
                <div className="btn-group w-100" role="group">
                    {items.map((item, index) => (
                        <div
                            key={index}
                            className="btn btn-sm btn-outline-secondary"
                            onClick={() => handleSelect(item[valueProp])}
                        >
                            <input
                                type="radio"
                                className="btn-check"
                                value={item[valueProp]}
                                checked={item[valueProp] === selectedValue}
                                onChange={() => handleSelect(item[valueProp])}
                                id={`radiobtn-${index}`}
                                autoComplete="off"
                            />
                            <label style={{ marginBottom: '0px' }} className='btn btn-sm'>
                                {item[nameProp]}
                            </label>
                        </div>
                    ))}
                </div>
                <div className="d-flex gap-2 align-items-right mt-2" style={{justifyContent: 'space-between'}}>
                    <Button
                        className='btn-sm btn-danger' 
                        onClick={handleReset}>
                        Limpiar
                    </Button>
                </div>      
            </Col>
        </Row>
    );
});

RadioButtons.displayName = 'RadioButtons';
export { RadioButtons };
