export const IS_LOADING = 'IS_LOADING';
export const IS_NOT_LOADING = 'IS_NOT_LOADING';
export const API_RESPONSE_SUCCESS = 'API_RESPONSE_SUCCESS';
export const API_RESPONSE_ERROR = 'API_RESPONSE_ERROR';
export const GET_PROPIEDADES = 'GET_PROPIEDADES';
export const GET_PROPIEDADES_DETAIL = 'GET_PROPIEDADES_DETAIL';
export const GET_ESTADOS = 'GET_ESTADOS';
export const GET_CIUDADES = 'GET_CIUDADES';
export const GET_FRANQUICIAS = 'GET_FRANQUICIAS';
export const GET_TIPOSINMUEBLES = 'GET_TIPOSINMUEBLES';
export const ADD_NEW_PROPIEDADES = 'ADD_NEW_PROPIEDADES';
export const ADD_PROPIEDADES_SUCCESS = 'ADD_PROPIEDADES_SUCCESS';
export const ADD_PROPIEDADES_FAIL = 'ADD_PROPIEDADES_FAIL';
export const UPDATE_PROPIEDADES = 'UPDATE_PROPIEDADES';
export const UPDATE_PROPIEDADES_SUCCESS = 'UPDATE_PROPIEDADES_SUCCESS';
export const UPDATE_PROPIEDADES_FAIL = 'UPDATE_PROPIEDADES_FAIL';
export const DELETE_PROPIEDADES = 'DELETE_PROPIEDADES';
export const DELETE_PROPIEDADES_SUCCESS = 'DELETE_PROPIEDADES_SUCCESS';
export const DELETE_PROPIEDADES_FAIL = 'DELETE_PROPIEDADES_FAIL';
export const GET_TIPOSNEGOCIACION = 'GET_TIPOSNEGOCIACION';
export const RESET_NEW = 'RESET_NEW';
