import React, {forwardRef, useEffect, useImperativeHandle, useRef, useState} from 'react';
import './InputRangeSlider.css';
import { FormFeedback, Label, Input, Button } from 'reactstrap';
import Nouislider from "nouislider-react";
import Cleave from 'cleave.js/react';
import { InputFormated } from '../InputFormated/InputFormated';


const InputRangeSlider = forwardRef(({ handler,minHandler,maxHandler,validation, minName, maxName, optionsProps, minValue, maxValue , placeHolderMin = "", placeHolderMax="",handlers,...props },ref) => {
    const minInput = useRef(null);
    const maxInput = useRef(null);
    const [valueMin, setvalueMin] = useState(null);
    const [valueMax, setvalueMax] = useState(null);
    const handleMinChange = (e) => {
        setvalueMin(e)
        validation.setFieldValue(minName,e)
    };

    const handleMaxChange = (e) => {
        setvalueMax(e)
        validation.setFieldValue(maxName,e)
    };
    
    useImperativeHandle(ref, () => ({
        reset: clearFilter,
    }));

    const clearFilter = () => {
        minInput.current.reset()
        maxInput.current.reset()
        handleMinChange(null)
        handleMaxChange(null)
        handler({
            minValue: 0,
            maxValue: 0
        })
    }

    const submitFilter = () => {
        // maxHandler(valueMax)
        // minHandler(valueMin)
        handler({
            minValue: valueMin,
            maxValue: valueMax
        })
    }
    return (
        <div>

            {
                props?.label !== " " &&
                <Label htmlFor={props?.name || null} className="form-label">
                    {props?.label || ' '} 
                    { props.required ? <span className="text-danger">*</span> : ' ' }    
                </Label>
            }

            <div className="d-flex gap-2 align-items-center">
                <InputFormated 
                    handler={handleMinChange}
                    format="number"
                    ref={minInput}
                    placeholder={placeHolderMin}
                    name={minName}
                    validation={validation}>
                </InputFormated>
                <span 
                    className="fw-semibold text-muted">
                        -
                </span>
                <InputFormated 
                    format="number"
                    placeholder={placeHolderMax}
                    handler={handleMaxChange}
                    ref={maxInput}
                    name={maxName}
                    validation={validation}>
                </InputFormated>
            </div>        
            <div className="d-flex gap-2 align-items-right mt-2" style={{justifyContent: 'space-between'}}>
                <Button
                    className='btn-sm btn-danger' 
                    onClick={clearFilter}>
                    Limpiar
                </Button>
                <Button
                    className='btn-sm btn-light' 
                    onClick={submitFilter}>
                    Aplicar
                </Button>
            </div>         
            {validation.touched[props?.name] && validation.errors[props?.name] ? (
                <FormFeedback type="invalid" className="d-block"><div>{validation.errors[props?.name]}</div></FormFeedback>
            ) : null}
        </div>
    );
});

InputRangeSlider.displayName = 'InputRangeSlider';
export { InputRangeSlider };
