import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import ReactDragListView from 'react-drag-listview/lib/index.js';
import Dropzone from "react-dropzone";
import { toast } from 'react-toastify';
import {
  Button,
  Card,
  CardBody,
  Col,
  Row
} from "reactstrap";
import { APIClient } from '../../../../../helpers/api_helper';
import './GaleriaDropzone.css';




const GaleriaDropzone = forwardRef(({validation,externalClick, ...props},ref) => {
  const [selectedFiles, setselectedFiles] = useState([]);
  const [uploadedFiles, setuploadedFiles] = useState([]);
  const api = new APIClient();
  const dropZoneRef = useRef(null)
  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  function handleExternalFiles(files){
    let myfiles = [...files]
    handleAcceptedFiles(myfiles)
  }

  function handleAcceptedFiles(files) {
    validation.setFieldValue(props?.name, [...selectedFiles, ...files]);

    const updatedFiles = files.map((file) => {
      const { path, name, size, type } = file;
      return {
        path,
        name,
        size,
        type,
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      };
    });

    let Tmp = [...selectedFiles, ...updatedFiles]
    setselectedFiles(Tmp);
  }

  useImperativeHandle(ref, () => ({
    setuploadedFiles,
    setselectedFiles: handleExternalFiles,
    selectedFiles
  }));

  const dragProps = {
    onDragEnd(fromIndex, toIndex) {
      const images = [...selectedFiles];
      const item = images.splice(fromIndex, 1)[0];
      images.splice(toIndex, 0, item);
      setselectedFiles(images);
    },
    nodeSelector: 'div',
    handleSelector: 'div'
  };

  function onMoveUp(index) {
    const newFiles = [...selectedFiles];
    if (index > 0) {
      // Swap files in the array
      const temp = newFiles[index];
      newFiles[index] = newFiles[index - 1];
      newFiles[index - 1] = temp;
  
      // Update state with new order
      setselectedFiles(newFiles);
  
      // Update validation.values[props?.name] to match the new order
      const validationFiles = [...validation.values[props?.name]];
      const tempValidation = validationFiles[index];
      validationFiles[index] = validationFiles[index - 1];
      validationFiles[index - 1] = tempValidation;
      validation.setFieldValue(props?.name, validationFiles);
    }
  
    console.log(validation.values[props?.name]);
    console.log(selectedFiles);
  }
  
  
  function onMoveDown(index) {
    const newFiles = [...selectedFiles];
    if (index < newFiles.length - 1) {
      // Swap files in the array
      const temp = newFiles[index];
      newFiles[index] = newFiles[index + 1];
      newFiles[index + 1] = temp;
  
      // Update state with new order
      setselectedFiles(newFiles);
  
      // Update validation.values[props?.name] to match the new order
      const validationFiles = [...validation.values[props?.name]];
      const tempValidation = validationFiles[index];
      validationFiles[index] = validationFiles[index + 1];
      validationFiles[index + 1] = tempValidation;
      validation.setFieldValue(props?.name, validationFiles);
    }
  
    console.log(validation.values[props?.name]);
    console.log(selectedFiles);
  }

  function moveToFirst(index) {
    // Verificar que el índice es válido y mayor que 0
    if (index > 0) {
      // Verificar que selectedFiles es un array
      if (!Array.isArray(selectedFiles)) {
        console.error('selectedFiles is not an array:', selectedFiles);
        return;
      }
  
      // Hacer una copia de selectedFiles y mover el archivo seleccionado a la primera posición
      const newFiles = [...selectedFiles];
      const [file] = newFiles.splice(index, 1);
      newFiles.unshift(file);
      setselectedFiles(newFiles);
  
      // Verificar que validation.values[props?.name] es un array
      const validationFiles = validation.values[props?.name];
      if (!Array.isArray(validationFiles)) {
        console.error(`validation.values[${props?.name}] is not an array:`, validationFiles);
        return;
      }
  
      // Hacer una copia de validationFiles y mover el archivo correspondiente a la primera posición
      const newValidationFiles = [...validationFiles];
      const [validationFile] = newValidationFiles.splice(index, 1);
      newValidationFiles.unshift(validationFile);
      validation.setFieldValue(props?.name, newValidationFiles);
  
      // Registro para verificar el orden correcto
      console.log("Updated validation files:", newValidationFiles);
      console.log("Updated selected files:", newFiles);
    }
  }
  
  
  

  const functionGetPortada = () => {
    if(uploadedFiles.length > 0){

      let lowerId = uploadedFiles[0];
      for (let i = 1; i < uploadedFiles.length; i++) {
        if (uploadedFiles[i].id < lowerId.id) {
          lowerId = uploadedFiles[i];
        }
      }

      return(
        <Col xl={6}>
          <Card>
              <img className="card-img-top img-fluid" src={lowerId.image} alt="Card cap" />
              <CardBody>
                  <p className="card-text">
                      Foto Portada
                  </p>
              </CardBody>
          </Card>            
        </Col>          
      )   

    } else {
      if(selectedFiles.length > 0){

        return(
          <Col xl={6}>
            <Card>
                <img className="card-img-top img-fluid" src={selectedFiles[0].preview} alt="Card cap" />
                <CardBody>
                    <p className="card-text">
                        Foto Portada
                    </p>
                </CardBody>
            </Card>            
          </Col>          
        )

      }
    }
  }

  const handleDeleteUploaded = (f, i) => {
    const newFiles = [...uploadedFiles];
  
    newFiles.splice(i, 1);
  
    dropZoneRef.current.open()
    api.delete(`eliminar-imagen/${f.id}/`)
      .then((res) => {
        toast.success('Se eliminó una imagen.');
        setuploadedFiles(newFiles);
      })
      .catch(console.error)
      .finally(() => {
      });
  };


  return (
    <>
      <div>
        <h5 className="fs-15 mb-1">{props?.label || ' '}</h5>
        <p className="text-muted">{props?.descripcion || ' '}</p>
        <Row>
          
          { functionGetPortada() }

          <Col xl={(selectedFiles.length > 0)? 6 : 12}>
            <Dropzone
              ref={dropZoneRef}
              multiple={true}
              onDrop={(acceptedFiles) => {
                handleAcceptedFiles(acceptedFiles);
              }}>

              {({ getRootProps , open, getInputProps }) => (
                <div className="dropzone dz-clickable">
                  <div className="dz-message needsclick" {...getRootProps()}>
                    <div className="mb-3">
                      <i className="display-4 text-muted ri-upload-cloud-2-fill" />
                    </div>
                    <h5>Arrastra imagenes en este campo para cargar.</h5>
                  </div>
                  <Button className="btn btn-light btn-lg btn-block w-100" onClick={externalClick}>
                    <i className="ri-image-line"></i> Cargar imagenes
                  </Button>                  
                </div>
              )}

            </Dropzone>
          </Col>
        </Row>


        <div className="list-unstyled mb-0" id="file-previews">
          <ReactDragListView {...dragProps}>
            {selectedFiles.map((f, i) => {
              return (
                <Card
                  className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                  key={i + "-file"}
                >
                  <div className="p-2">
                    <Row className="align-items-center">
                      <Col className="col-auto">
                        <img
                          data-dz-thumbnail=""
                          height="80"
                          className="avatar-sm rounded bg-light"
                          alt={f.name}
                          src={f.preview}
                        />
                      </Col>
                      <Col>
                        <span
                          className="text-muted font-weight-bold">
                          {f.name}
                        </span>
                        <p className="mb-0">
                          <strong>{f.formattedSize}</strong>
                        </p>
                      </Col>
                      <Col className="col-auto">
                        <button
                          type="button"
                          className="btn btn-info btn-sm ml-2"
                          onClick={() => {
                            const newFiles = selectedFiles.slice();
                            const updatedFiles = newFiles.filter(file => file.name !== f.name);
                            moveToFirst(i);
                          }}>
                          Portada
                        </button>
                        <button
                          type="button"
                          className="btn btn-danger btn-sm ml-2"
                          onClick={() => {
                            const newFiles = selectedFiles.slice();
                            const updatedFiles = newFiles.filter(file => file.name !== f.name);
                            setselectedFiles(updatedFiles);
                          }}>
                          Eliminar
                        </button>
                        <button
                          type="button"
                          className="btn btn-secondary btn-sm"
                          onClick={() => {
                            onMoveUp(i);
                          }}
                        >
                          <i className="ri-arrow-up-line"></i>
                        </button>
                        
                        <button
                          type="button"
                          className="btn btn-secondary btn-sm"
                          onClick={() => {
                            onMoveDown(i);
                          }}
                        >
                          <i className="ri-arrow-down-line"></i>
                        </button>
                      </Col>
                    </Row>
                  </div>
                </Card>
              );
            })}

            {uploadedFiles.map((f, i) => {
              return (
                <Card
                  className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                  key={i + "-file-preloaded"}
                >
                  <div className="p-2">
                    <Row className="align-items-center">
                      <Col className="col-auto">
                        <img
                          data-dz-thumbnail=""
                          height="80"
                          className="avatar-sm rounded bg-light"
                          alt={f.image}
                          src={f.image}
                        />
                      </Col>
                      <Col>
                        <p className="mb-0">
                          <strong>Imagen cargada previamente</strong>
                        </p>
                      </Col>
                      <Col className="col-auto">
                        <button
                          type="button"
                          className="btn btn-danger btn-sm ml-2"
                          onClick={() => {
                            handleDeleteUploaded(f,i)
                          }}>
                          Eliminar
                        </button>
                      </Col>
                    </Row>
                  </div>
                </Card>
              );
            })}            
          </ReactDragListView>
        </div>
      </div>
    </>
  );
});

GaleriaDropzone.displayName = 'GaleriaDropzone';
export { GaleriaDropzone };
