import React, { useState, useEffect, useMemo } from "react";
import { Container } from "reactstrap";
import { ToastContainer } from 'react-toastify';
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { useDispatch } from "react-redux";
import { createApiMethods } from "../../helpers/api_calls";
import { FRANQUICIAS, TIPOSINMUEBLES, PROPIEDADESPUBLICAS, INMUEBLES, ESTADOS } from "../../helpers/api_urls";
import { ProductsFilter } from "./ProductsFilter";
import { ProductsTabs } from "./ProductsTabs";
import { useAuth } from "../../AuthContext";

const EcommerceProducts = () => {
  const dispatch = useDispatch();
  const { user } = useAuth();
  const [activeTab, setActiveTab] = useState("1");
  const [filterOptions, setFilterOptions] = useState({});

  const apiMethods = useMemo(() => ({
    franquicias: createApiMethods(FRANQUICIAS),
    tiposInmuebles: createApiMethods(TIPOSINMUEBLES),
    propiedadesPublicas: createApiMethods(PROPIEDADESPUBLICAS),
    inmuebles: createApiMethods(INMUEBLES),
    estados: createApiMethods(ESTADOS),
  }), []);

  const [data, setData] = useState({
    franquiciasList: [],
    tiposInmueblesList: [],
    estadosList: [],
    propiedadesPublicas: { count: 0, results: [] },
    propiedadesPropias: { count: 0, results: [] },
    propiedadesInactivas: { count: 0, results: [] },
    propiedadesEnReserva: { count: 0, results: [] },
  });

  useEffect(() => {
    const fetchInitialData = async () => {
      try {

        const [
          franquicias, 
          estados, 
          tiposInmuebles, 
          propiedadesPublicas, 
          propiedadesPropias, 
          propiedadesInactivas,
          propiedadesReserva
        ] = await Promise.all([
          apiMethods.franquicias.getAll({ page_size: 1000 }), //franquicias
          apiMethods.estados.getAll({ page_size: 1000 }), //estados
          apiMethods.tiposInmuebles.getAll({ page_size: 1000 }), //tiposInmuebles
          apiMethods.propiedadesPublicas.getAll({ page_size: 10,status_inmueble: 1 }), //propiedadesPublicas
          apiMethods.inmuebles.getAll({ page_size: 10, status_inmueble: 1 }), //propiedadesPropias
          apiMethods.inmuebles.getAll({ page_size: 10, status_inmueble: 5 }), //propiedadesInactivas
          apiMethods.inmuebles.getAll({ page_size: 10, status_inmueble: 7 }) //propiedadesInactivas
        ]);

        setData({
          franquiciasList: franquicias.results,
          estadosList: estados.results,
          tiposInmueblesList: tiposInmuebles.results,
          propiedadesPublicas,
          propiedadesPropias,
          propiedadesInactivas,
          propiedadesEnReserva: propiedadesReserva
        });

      } catch (error) {
        console.error("Error fetching initial data:", error);
      }
    };

    fetchInitialData();
  }, [apiMethods]);

  const handleFilterChange = (newFilters) => {
    setFilterOptions(newFilters);
  };

  const handleTabChange = (tabId) => {
    setActiveTab(tabId);
  };

  return (
    <div className="page-content">
      <ToastContainer closeButton={false} limit={1} />
      <Container fluid>
        <BreadCrumb title="Inmuebles" pageTitle="Administracion" />
        <div className="row">
          <div className="col-xl-3 col-lg-4">
            <ProductsFilter
              estados={data.estadosList}
              tiposInmuebles={data.tiposInmueblesList}
              franquicias={data.franquiciasList}
              onFilterChange={handleFilterChange}
            />
          </div>
          <div className="col-xl-9 col-lg-8">
            <ProductsTabs
              setData={setData}
              activeTab={activeTab}
              onTabChange={handleTabChange}
              propiedadesPublicas={data.propiedadesPublicas}
              propiedadesPropias={data.propiedadesPropias}
              propiedadesInactivas={data.propiedadesInactivas}
              propiedadesReserva={data.propiedadesEnReserva}
              apiMethods={apiMethods}
              filterOptions={filterOptions}
              user={user}
            />
          </div>
        </div>
      </Container>
    </div>
  );
};

export default EcommerceProducts;