// components/ColumnSettingsModal.js
import React from 'react';
import { Button, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

const ColumnSettingsModal = ({
  isOpen,
  toggle,
  columns,
  defaultColumns,
  toggleDefaultColumn,
  applyColumnSettings,
}) => (
  <Modal isOpen={isOpen} toggle={toggle}>
    <ModalHeader toggle={toggle}>Configurar Columnas</ModalHeader>
    <ModalBody>
      <Form>
        {columns.map(column => (
          <FormGroup key={column.id} check>
            <Label check>
              <Input
                type="checkbox"
                checked={defaultColumns.includes(column.id)}
                onChange={() => toggleDefaultColumn(column.id)}
              />{' '}
              {column.title}
            </Label>
          </FormGroup>
        ))}
      </Form>
    </ModalBody>
    <ModalFooter>
      <Button color="secondary" onClick={toggle}>Cancelar</Button>
      <Button color="primary" onClick={applyColumnSettings}>Aplicar</Button>
    </ModalFooter>
  </Modal>
);

export default ColumnSettingsModal;
