import React, { useState } from 'react';
import './PublicCardTemplate.css';
import { Button, ButtonGroup, Card, CardBody, Col, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledButtonDropdown } from 'reactstrap';
import { Link, useNavigate } from 'react-router-dom';
import { Swiper, SwiperSlide } from "swiper/react";
import classnames from "classnames";
// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import { toast } from 'react-toastify';



export const PublicCardTemplate = ({item, className, detail = false,gridStyle}) => {
    const [thumbsSwiper, setThumbsSwiper] = useState(null);
    const API_HOME = process.env.REACT_APP_API_HOME

    const copyToClipboard = async (text) => {
        try {
          await navigator.clipboard.writeText(text);
          console.log('Content copied to clipboard');
        } catch (err) {
          console.error('Failed to copy: ', err);
        }
    }

    const handleShareCContacto = () => {
        console.log('handle contacto')
        console.log(item)
        const inmueble_link = `${API_HOME}pr/${item.id}/`
        copyToClipboard(inmueble_link).then((res)=>{
            toast.success('Se ha copiado el link con contacto')
        })
    }

    const handleShareSContacto = () => {
        console.log('handle sin contacto')
        const inmueble_link = `${API_HOME}pv/${item.id}/`
        copyToClipboard(inmueble_link).then((res)=>{
            toast.success('Se ha copiado el link sin contacto')
        })
    }

    const propiedadesIcons = {
        "id": <i className="ri-identifier-line"></i>,
        "nombre": <i className="ri-building-line"></i>,
        "ciudad": <i className="ri-city-line"></i>,
        "estado": <i className="ri-map-pin-line"></i>,
        "franquicia": <i className="ri-building-2-line"></i>,
        "tipo_inmueble": <i className="ri-home-2-line"></i>,
        "imagenes": <i className="ri-gallery-line"></i>,
        "precio_usd": <i className="ri-money-dollar-box-line"></i>,
        "first_image_url": <i className="ri-image-line"></i>,
        "visitas_agendadas_count": <i className="ri-eye-line"></i>,
        "precio_inicial": <i className="ri-money-dollar-circle-line"></i>,
        "status_inmueble": <i className="ri-checkbox-blank-circle-line"></i>,
        "antiguedad": <i className="ri-clock-line"></i>,
        "vista": <i className="ri-map-pin-time-line"></i>,
        "amoblado": <i className="ri-refrigerator-line"></i>,
        "posee_hipoteca": <i className="ri-bank-line"></i>,
        "family_room": <i className="ri-tv-2-line"></i>,
        "cubiculo": <i className="ri-store-line"></i>,
        "estudio": <i className="ri-book-2-line"></i>,
        "lavadero": <i className="ri-washing-machine-line"></i>,
        "terraza_jardin": <i className="ri-landscape-line"></i>,
        "meletero_bodega": <i className="ri-archive-line"></i>,
        "tanque_agua": <i className="ri-water-pump-line"></i>,
        "planta_electrica": <i className="ri-flashlight-line"></i>,
        "tanque_agua_comun": <i className="ri-water-tower-line"></i>,
        "ascensor": <i className="ri-elevator-line"></i>,
        "seguridad": <i className="ri-shield-line"></i>,
        "parque_infantil": <i className="ri-child-line"></i>,
        "piscina": <i className="ri-swimming-line"></i>,
        "gym": <i className="ri-dumbbell-line"></i>,
        "bbq": <i className="ri-grill-line"></i>,
        "salon_fiestas": <i className="ri-cup-line"></i>,
        "cancha_deportiva": <i className="ri-basketball-line"></i>,
        "pozo_profundo": <i className="ri-well-line"></i>,
        "planta_electrica_comun": <i className="ri-flashlight-line"></i>,
        "asesor": <i className="ri-user-line"></i>,
        "descripcion": <i className="ri-file-paper-2-line"></i>,
        "numero_banios": <i className="ri-door-fill"></i>,
        "banio_servicio": <i className="ri-bath-line"></i>,
        "medio_banio": <i className="ri-bath-line"></i>,
        "habitaciones": <i className="ri-hotel-bed-line"></i>,
        "hab_servicio": <i className="ri-hotel-bed-line"></i>,
        "numero_plantas": <i className="ri-building-line"></i>,
        "precio": <i className="ri-money-dollar-box-line"></i>,
        "metros_cuadrados_terreno": <i className="ri-map-pin-line"></i>,
        "metros_cuadrados_construccion": <i className="ri-building-2-line"></i>,
        "metraje_total": <i className="ri-area-line"></i>,
        "fecha_publicacion": <i className="ri-calendar-line"></i>,
        "codigo": <i className="ri-barcode-line"></i>,
        "ptos_estacionamiento": <i className="ri-car-line"></i>,
        "direccion": <i className="ri-map-pin-line"></i>,
        "created_at": <i className="ri-time-line"></i>,
        "cliente": <i className="ri-user-line"></i>,
        "autor": <i className="ri-user-line"></i>,
        "tipo_negociacion": <i className="ri-handshake-line"></i>,
        "obj_type": <i className="ri-file-paper-2-line"></i>
    }

    function formatearNumero(numero) {
        // Convierte el número a formato de moneda con separadores de miles y decimales
        const numeroFormateado = new Intl.NumberFormat('es-ES', { style: 'currency', currency: 'USD' }).format(numero);
      
        // Reemplaza el símbolo de moneda (€) con "$"
        return numeroFormateado.replace('€', '$');
      }
      
      
    const idElement = `${item.id}-card` 
    return (
        <Card id={idElement} className={`card-public-template ${className}`}>
            <CardBody>
                <div className="d-flex align-items-center mb-3 w-100">
                    <div>
                        {
                            item?.franquicia?.image ?
                            <img src={item.franquicia.image} alt="" className="avatar-xs rounded-circle" />:
                            <img src={item.first_image_url} alt="" className="avatar-xs rounded-circle" />
                        }
                    </div>
                    <div className="ms-2 flex-grow-1" style={{width: '30px'}}>
                        <h6 className="mb-1 fs-15 truncate">{item.nombre}</h6>
                        <Link to={`/propiedad/${item.id}`} className="link-dark">
                                <p className="mb-0 fs-14 text-muted truncate">{item?.franquicia?.nombre}</p>
                        </Link>                            
                    </div>
                    <div className="bookmark-icon">
                        <button type="button"
                            className={item.isActive ? "btn btn-icon active " : "btn btn-icon"}
                            data-bs-toggle="button"
                            aria-pressed="true"
                            onClick={(e) => console.log(e.target)}>
                            <i className="mdi mdi-cards-heart fs-16"></i>
                        </button>
                    </div>
                </div>
                {
                    !detail ? 
                    <div className={`overflow-hidden rounded inmob-img-parent ${gridStyle ? 'h-80':'h-800'}`}>
                        <img src={item.first_image_url} alt="" className="img-fluid inmob-img" />
                    </div>:
                    <div className={`overflow-hidden rounded`}>
                        <div className="product-img-slider sticky-side-div">
                        <Swiper
                            navigation={true}
                            thumbs={{ swiper: thumbsSwiper }}
                            className="swiper product-thumbnail-slider p-2 rounded bg-light">
                            <div className="swiper-wrapper">
                                {item.imagenes && item.imagenes.length > 0 ? (
                                    (item.imagenes || []).map((img,key) => (
                                        <SwiperSlide key={key}>
                                            <div className={`overflow-hidden rounded inmob-img-parent ${gridStyle ? 'h-80':'h-800'}`}>
                                                <img src={img.image} alt="" className="img-fluid inmob-img" />
                                            </div>                                              
                                        </SwiperSlide>  
                                    ))
                                ) : (
                                    <SwiperSlide>
                                        <img 
                                            src={item.first_image_url}
                                            alt="" 
                                            className="inmob-img d-block"  
                                        />                          
                                    </SwiperSlide>         
                                )}   
                            </div>
                        </Swiper>
                        
                        {
                            (item.imagenes && item.imagenes.length > 0) && (
                            <div className="product-nav-slider mt-2">
                                <Swiper
                                onSwiper={setThumbsSwiper}
                                slidesPerView={4}
                                freeMode={true}
                                watchSlidesProgress={true} 
                                spaceBetween={10}    
                                className="swiper product-nav-slider mt-2 overflow-hidden">
                                <div className="swiper-wrapper">        
                                {
                                    (item.imagenes || []).map((img, key) => (      
                                    <SwiperSlide key={key} className="rounded">
                                        <div className="nav-slide-item">
                                        <img 
                                            src={img.image}
                                            alt=""  
                                            className="img-fluid d-block rounded"
                                        />  
                                        </div>
                                    </SwiperSlide>         
                                    ))
                                }
                                </div>   
                                </Swiper>
                            </div>   
                            )
                        }
                        </div>
                    </div>                    
                }


                <div className="mt-3">
                    <p className="fw-medium mb-0 float-end"><i className="mdi mdi-identifier align-middle"></i> {item.id} </p>
                    <h5 className="text-primary">
                        <i className="mdi mdi-ethereum"></i> 
                        {formatearNumero(item.precio)}
                    </h5>
                    <h6 className="fs-15 mb-0">
                        <Link to={`/propiedad/${item.id}`} className="link-dark">{item?.tipo_inmueble?.nombre}</Link></h6>
                </div>
                {
                    detail &&
                    <div className="mt-3">
                        <Row>
                            <Col lg={8}>
                                <div dangerouslySetInnerHTML={{ __html: item.descripcion }}></div>
                            </Col>
                            <Col lg={4}>

                                <ul className="list-group">

                                    <li className="list-group-item">
                                        <div className="d-flex align-items-center">
                                            <div className="flex-grow-1">
                                                <div className="d-flex">
                                                    <div className="flex-shrink-0 avatar-xs">
                                                        <div className="avatar-title rounded r-icon">
                                                            {propiedadesIcons.habitaciones}
                                                        </div>
                                                    </div>
                                                    <div className="flex-shrink-0 ms-2 d-flex flex-column justify-content-center">
                                                        <h6 className="fs-14 mb-0">Habitaciones</h6>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex-shrink-0">
                                                <span className="text-danger">
                                                    {item['habitaciones']}
                                                </span>
                                            </div>
                                        </div>
                                    </li>

                                    <li className="list-group-item">
                                        <div className="d-flex align-items-center">
                                            <div className="flex-grow-1">
                                                <div className="d-flex">
                                                    <div className="flex-shrink-0 avatar-xs">
                                                        <div className="avatar-title rounded r-icon">
                                                            {propiedadesIcons.numero_banios}
                                                        </div>
                                                    </div>
                                                    <div className="flex-shrink-0 ms-2 d-flex flex-column justify-content-center">
                                                        <h6 className="fs-14 mb-0">Baños</h6>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex-shrink-0">
                                                <span className="text-danger">
                                                    {item['numero_banios']}
                                                </span>
                                            </div>
                                        </div>
                                    </li>

                                    <li className="list-group-item">
                                        <div className="d-flex align-items-center">
                                            <div className="flex-grow-1">
                                                <div className="d-flex">
                                                    <div className="flex-shrink-0 avatar-xs">
                                                        <div className="avatar-title rounded r-icon">
                                                            {propiedadesIcons.ptos_estacionamiento}
                                                        </div>
                                                    </div>
                                                    <div className="flex-shrink-0 ms-2 d-flex flex-column justify-content-center">
                                                        <h6 className="fs-14 mb-0">Ptos Estacionamiento</h6>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex-shrink-0">
                                                <span className="text-danger">
                                                    {item['ptos_estacionamiento']}
                                                </span>
                                            </div>
                                        </div>
                                    </li>

                                    <li className="list-group-item">
                                        <div className="d-flex align-items-center">
                                            <div className="flex-grow-1">
                                                <div className="d-flex">
                                                    <div className="flex-shrink-0 avatar-xs">
                                                        <div className="avatar-title rounded r-icon">
                                                            {propiedadesIcons.metros_cuadrados_terreno}
                                                        </div>
                                                    </div>
                                                    <div className="flex-shrink-0 ms-2 d-flex flex-column justify-content-center">
                                                        <h6 className="fs-14 mb-0">Mts Cuadrados</h6>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex-shrink-0">
                                                <span className="text-danger">
                                                    {item['metros_cuadrados_terreno']}
                                                </span>
                                            </div>
                                        </div>
                                    </li>

                                    <li className="list-group-item">
                                        <div className="d-flex align-items-center">
                                            <div className="flex-grow-1">
                                                <div className="d-flex">
                                                    <div className="flex-shrink-0 avatar-xs">
                                                        <div className="avatar-title rounded r-icon">
                                                            {propiedadesIcons.precio}
                                                        </div>
                                                    </div>
                                                    <div className="flex-shrink-0 ms-2 d-flex flex-column justify-content-center">
                                                        <h6 className="fs-14 mb-0">Precio</h6>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex-shrink-0">
                                                <span className="text-danger">
                                                    {formatearNumero(item['precio'])}
                                                </span>
                                            </div>
                                        </div>
                                    </li>

                                    <li className="list-group-item">
                                        <div className="d-flex align-items-center">
                                            <Button className='btn btn-light w-100'>Contactar</Button>
                                        </div>
                                    </li>

                                    <li className="list-group-item">
                                        <div className="d-flex align-items-center">
                                            <ButtonGroup className='w-100'>
                                                <UncontrolledButtonDropdown className='w-100'> 
                                                    <Button color="light" className='w-100'>Compartir</Button>
                                                    <DropdownToggle tag="button" className="btn btn-light" split>
                                                    </DropdownToggle>
                                                    <DropdownMenu >
                                                        <DropdownItem onClick={handleShareCContacto}>Link con contacto</DropdownItem>
                                                        <DropdownItem onClick={handleShareSContacto} >Link sin contacto </DropdownItem>
                                                    </DropdownMenu>
                                                </UncontrolledButtonDropdown>
                                            </ButtonGroup>
                                        </div>
                                    </li>
                                </ul>
                            </Col>
                        </Row>
                    </div>
                }
            </CardBody>
        </Card>        
    );
};
