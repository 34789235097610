import React, { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  UncontrolledCollapse,
  CardFooter,
  Button,
  OffcanvasBody,
  OffcanvasHeader,
  Row,
  Offcanvas,
  Col,
  Modal,
  ModalHeader,
  CardHeader,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";
import { formatDistanceToNow } from 'date-fns';
import { es } from 'date-fns/locale';
import { useFormik } from "formik";
import * as Yup from "yup";
import { InputTextarea } from "../../Forms/Inputs/inputTextarea/inputTextarea";
import { SEGUIMIENTOS } from "../../../helpers/api_urls";
import { createApiMethods } from "../../../helpers/api_calls";
import { toast } from "react-toastify";
import { BusquedasCard } from "../../DashboardCrm/BusquedasCard/BusquedasCard";


const LeadDiscover = ({handlerEditarBusqueda,mute = false,...props}) => {
  const [formularioNuevoSeguimiento, setformularioNuevoSeguimiento] = useState(false);
  const [formularioNuevoMensaje, setformularioNuevoMensaje] = useState(false);
  const apiSeguimientos = useMemo(() => createApiMethods(SEGUIMIENTOS), []);
  const [isLoading, setisLoading] = useState(false);
  const [SeguimientoItem, setSeguimientoItem] = useState(null);

  const toggleBottomCanvas = (selectionSeguimiento) => {
    setformularioNuevoSeguimiento(!formularioNuevoSeguimiento);
  };

  const toggleMensaje = () => {
    setformularioNuevoMensaje(!formularioNuevoMensaje);
  };

  const displaySeguimientoForm = (selectionSeguimiento) => () => {
    setSeguimientoItem(selectionSeguimiento)
    toggleBottomCanvas()
  }


  function formatDate(date) {
    const distance = formatDistanceToNow(new Date(date), { locale: es, addSuffix: true });
    return `${distance.charAt(0).toUpperCase()}${distance.slice(1)}`;
  }

  const validationSeguimiento = useFormik({
    enableReinitialize: true,
    initialValues: {
        cliente: SeguimientoItem?.cliente.id,
        nota     : null,
        audio  : null,
        busqueda: SeguimientoItem?.id
    },
    validationSchema: Yup.object({
      // nota: Yup.string()
      //   .typeError("Debe agregar una nota.")
      //   .required("Este campos es obligatorio"),
      }),
    onSubmit: async (values) => {
        const formData = new FormData();
      
        for (const [key, value] of Object.entries(values)) {
          if (value !== null && value !== undefined && value !== "") {
            if (key !== "imagenes") {
              formData.append(key, value);
            } else {
              value.forEach((image, index) => {
                formData.append(`imagenes[${index}]`, image);
              });
            }
          }
        }
        // console.log(values)
        setisLoading(true)
        apiSeguimientos.create(formData,{
            headers: {
                "Content-Type": "multipart/form-data"
            },
        }).then((res)=>{
          toast.success("El seguimiento se registro con éxito", { autoClose: 3000 });
          if(props.updateBusquedas){
            props.updateBusquedas()
          }
          setAudioURL(null);
          validationSeguimiento.resetForm()
        }).catch((err)=>{
          toast.error("Algo ocurrió mientras se creaba el seguimiento", { autoClose: 3000 });
        }).finally(()=>{
          setisLoading(false)
          toggleBottomCanvas()
        })
      }
  });

  const [recording, setRecording] = useState(false);
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [audioURL, setAudioURL] = useState('');
  const [mediaStream, setMediaStream] = useState(null);
  const [modal_center, setmodal_center] = useState(false);
  function tog_center() {
      setmodal_center(!modal_center);
  }

  const toggleStartRecording = async () => {
    await startRecording()
    tog_center()
  }

  const startRecording = async () => {
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    setMediaStream(stream);
    const recorder = new MediaRecorder(stream);
    setMediaRecorder(recorder);

    recorder.start();
    setRecording(true);

    const audioChunks = [];
    recorder.ondataavailable = (event) => {
      audioChunks.push(event.data);
    };

    recorder.onstop = () => {
      const audioBlob = new Blob(audioChunks);
      validationSeguimiento.setFieldValue('audio',audioBlob)
      const url = URL.createObjectURL(audioBlob);
      setAudioURL(url);
    };
  };

  const stopRecording = () => {
    if (mediaRecorder) {
      mediaRecorder.stop();
      setRecording(false);
    }

    if (mediaStream) {
      mediaStream.getTracks().forEach(track => track.stop());
      setMediaStream(null);
    }
    setmodal_center(false)
  };

  const validationMensaje = useFormik({
    enableReinitialize: true,
    initialValues: {
        mensaje     : null,
    },
    validationSchema: Yup.object({
      mensaje: Yup.string()
        .typeError("Debe agregar un mensaje.")
        .required("Este campo es obligatorio"),
      }),
    onSubmit: async (values) => {
      console.log(values)
        const formData = new FormData();
      
        for (const [key, value] of Object.entries(values)) {
          if (value !== null && value !== undefined && value !== "") {
            if (key !== "imagenes") {
              formData.append(key, value);
            } else {
              value.forEach((image, index) => {
                formData.append(`imagenes[${index}]`, image);
              });
            }
          }
        }

          // setisLoading(true)
          // apiBusquedas.create(values).then((res)=>{
          //   toast.success("La busquedafue creada con éxito", { autoClose: 3000 });
          //   // history(`/inmueble/${editarinmueble.id}`);
          // }).catch((err)=>{
          //   toast.error("Algo ocurrió mientras se creaba la busqueda", { autoClose: 3000 });
          // }).finally(()=>{
          //   setisLoading(false)
          //   setModal(false);
          // })

          // toggleMensaje()
          handleSendMensaje(values.mensaje)
      }
  });

  const handleSendMensaje = (mensaje) => {
    const phoneNumber = "+584143563118"; // Reemplaza esto con el número de teléfono al que deseas enviar el mensaje


    const url = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(mensaje)}`;
    window.open(url, '_blank');
};


  return (
    <>
        {(props?.busquedas || []).map((subitem, subitemkey) => (
          <div key={`search-${subitemkey}`}>
            <BusquedasCard editar={handlerEditarBusqueda} busqueda={subitem}></BusquedasCard>
          </div>
        ))}
    </>
  );
};

export default LeadDiscover;
