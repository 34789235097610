import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Card, CardBody, CardHeader, Col, Container, DropdownItem, DropdownMenu, DropdownToggle, Input, Label, Nav, NavItem, NavLink, Pagination, PaginationItem, PaginationLink, Progress, Row, TabContent, Table, TabPane, UncontrolledCollapse, UncontrolledDropdown } from 'reactstrap';
import classnames from 'classnames';
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay } from "swiper";

//Images
import profileBg from '../../../../assets/images/profile-bg.jpg';
import avatar1 from '../../../../assets/images/users/avatar-1.jpg';
import { projects, document } from '../../../../common/data';
import { createApiMethods, getUserDetails } from '../../../../helpers/api_calls';
import { CONTACTOS, DOCUMENTS } from '../../../../helpers/api_urls';
import { getLoggedinUser } from '../../../../helpers/api_helper';
import { useDispatch, useSelector } from 'react-redux';
import { ModalContactos } from '../Settings/components/ModalContactos/ModalContactos';
import DeleteModal from '../../../../Components/Common/DeleteModal';
import { toast } from 'react-toastify';
// import { getsolicitudservicio } from '../../../../store/actions';
// import { RequestPreview } from '../../SearchResults/RequestPreview/RequestPreview';

const SimplePage = () => {
    const [userAvailable, setuserAvailable] = useState();
    const apiContactos = useMemo(() => createApiMethods(CONTACTOS), []);
    const apiDocuments = useMemo(() => createApiMethods(DOCUMENTS), []);
    const [userFormDetails, setuserFormDetails] = useState(null);
    const [contactos, setcontactos] = useState([]);
    const [documents, setdocuments] = useState([]);
    const userProfileSession = getLoggedinUser();
    const [userProfileLoaded, setUserProfileLoaded] = useState(false);
    const dispatch = useDispatch();
    const [hoveredIndex, setHoveredIndex] = useState(null);


    const [pagination, setPagination] = useState({
        count: 0,
        page_number: 1,
        num_pages: 1,
        per_page: 10,
        next: null,
        previous: null,
    });

    // const { Solicitudservicio } = useSelector((state) => ({
    //     Solicitudservicio: state.Solicitudservicio,
    // }));

    // useEffect(() => {
    //     setPagination(Solicitudservicio.solicitudservicio)
    // }, [Solicitudservicio.solicitudservicio,setPagination]);

    useEffect(() => {
        if(userAvailable){
            // dispatch(getsolicitudservicio({page_size: 10,cliente_id: userAvailable.id}))
        }
    }, [dispatch,userAvailable]);

    useEffect(() => {
        if (userProfileSession && !userProfileLoaded) {
          setuserAvailable(userProfileSession);
          setUserProfileLoaded(true);

        }
    }, [userProfileSession, userProfileLoaded]);


    useEffect(() => {
        if(userAvailable){  
            getUserDetails(userAvailable.id).then((res)=>{
                setuserFormDetails(res)
            })
        }
    }, [userAvailable])
    useEffect(() => {
        apiContactos.getAll({page_size:1000}).then((res)=>{
            setcontactos(res.results)
        })
        apiDocuments.getAll({page_size:1000}).then((res)=>{
            setdocuments(res.results)
        })
    }, [])

    const iconMap = {
        'Instagram': 'bxl-instagram',
        'Whatsapp': 'bxl-whatsapp',
        'Teléfono': 'bx-phone',
        'Email': 'bxl-gmail',
        'Twitter': 'bxl-twitter'
    };

    SwiperCore.use([Autoplay]);

    const [activeTab, setActiveTab] = useState('1');
    const [activityTab, setActivityTab] = useState('1');

    const toggleTab = (tab) => {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    };

    const toggleActivityTab = (tab) => {
        if (activityTab !== tab) {
            setActivityTab(tab);
        }
    };

    const [activeFilters, setActiveFilters] = useState({});
    const pageUpdate = (options)=>{
        options = {...options,cliente_id: userAvailable.id}
        // dispatch(getsolicitudservicio(options))
    }
    const memoizedApiCallback = useCallback(pageUpdate, []);
    const onPageChange = (newPageIndex) => {
        memoizedApiCallback({ page: newPageIndex, page_size: pagination.per_page, ...activeFilters });
    };
    const renderPaginationItems = () => {
        const items = [];
        for (let i = 1; i <= pagination.num_pages; i++) {
          items.push(
            <PaginationItem key={i} active={i === pagination.page_number}>
              <PaginationLink onClick={() => onPageChange(i)}>{i}</PaginationLink>
            </PaginationItem>
          );
        }
        return items;
    };
    const fileIcons = {
        pdf: "ri-file-pdf-line",
        doc: "ri-file-word-line",
        docx: "ri-file-word-line",
        png: "ri-file-image-line",
        jpg: "ri-file-image-line",
        csv: "ri-file-excel-line",
    };

    document.title = "Perfil";
    function formatFileSize(size) {
        const i = size === 0 ? 0 : Math.floor(Math.log(size) / Math.log(1024));
        return (size / Math.pow(1024, i)).toFixed(2) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];
    }

    const downloadFile = (fileURL) => () => {
        fetch(fileURL, {
            method: 'GET'
        })
        .then((response) => response.blob())
        .then((blob) => {
            // Create blob link to download
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
    
            // Use the last part of the URL as the filename
            const fileName = fileURL.split("/").pop();
            link.setAttribute('download', fileName);
    
            // Append to html link element page
            document.body.appendChild(link);
    
            // Start download
            link.click();
    
            // Clean up and remove the link
            link.parentNode.removeChild(link);
        });
    }

    function formatDate(isoDate) {
        const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' };
        return new Date(isoDate).toLocaleString('es-ES', options);
    }

    const [modalState, setModalState] = useState(false);
    const toggleModal = () => () => {
        setModalState(!modalState);
    };

    const toggleModalChild = () => {
        setModalState(!modalState);
        apiContactos.getAll({page_size:1000}).then((res)=>{
            setcontactos(res.results)
        })
    };

    const [deleteItemContacto, setdeleteItemContacto] = useState();
    const [deleteModalContacto, setdeleteModalContacto] = useState(false);

    const onClickDeleteContacto = (item) => () => {
        setdeleteItemContacto(item)
        setdeleteModalContacto(true);
    };


    const handleDeleteContacto = () => {
        apiContactos.delete(deleteItemContacto).then((res)=>{
            toast.success("El contacto se ha eliminado con éxito", { autoClose: 3000 });
        }).catch((err)=>{
            toast.error("Ha ocurrido un error al intentar eliminar el contacto", { autoClose: 3000 });
        }).finally(()=>{
            apiContactos.getAll({page_size:1000}).then((res)=>{
                setdeleteModalContacto(false)
                setcontactos(res.results)
            })
        })
    };

    return (
        <React.Fragment>
            <DeleteModal
                show={deleteModalContacto}
                onDeleteClick={handleDeleteContacto}
                onCloseClick={() => setdeleteModalContacto(false)}/>
            <ModalContactos
                modalState={modalState} 
                toggleModal={() => {
                    setModalState(!modalState)
                }}>
            </ModalContactos>

            <div className="page-content">
                <Container fluid>
                    <div className="profile-foreground position-relative mx-n4 mt-n4">
                        <div className="profile-wid-bg">
                            <img src={profileBg} alt="" className="profile-wid-img" />
                        </div>
                    </div>
                    <div className="pt-4 mb-4 mb-lg-3 pb-lg-4">
                        <Row className="g-4">
                            <div className="col-auto">
                                <div className="avatar-lg">
                                    <img src={userAvailable?.first_image_url} alt="user-img" className="avatar-lg img-thumbnail rounded-circle" />
                                </div>
                            </div>
                            <Col>
                                <div className="p-2">
                                    <h3 className="text-white mb-1">{ userFormDetails ? userFormDetails.full_name: " "}</h3>
                                    <p className="text-white-75">{ userFormDetails ? userFormDetails.profile.nombre: " "}</p>
                                    <div className="hstack text-white-50 gap-1">
                                        {
                                            (userFormDetails && userFormDetails.franquicia) &&
                                            <>
                                                <div className="me-2"><i
                                                    className="ri-map-pin-user-line me-1 text-white-75 fs-16 align-middle"></i>{userFormDetails.franquicia?.ciudad?.nombre}
                                                    </div>
                                                <div><i
                                                    className="ri-building-line me-1 text-white-75 fs-16 align-middle"></i>{userFormDetails.franquicia?.estado?.nombre}
                                                </div>
                                            </>
                                        }
                                    </div>
                                </div>
                            </Col>

                            {/* <Col xs={12} className="col-lg-auto order-last order-lg-0">
                                <Row className="text text-white-50 text-center">
                                    <Col lg={6} xs={4}>
                                        <div className="p-2">
                                            <h4 className="text-white mb-1">24.3K</h4>
                                            <p className="fs-14 mb-0">Solicitudes de Servicio</p>
                                        </div>
                                    </Col>
                                    <Col lg={6} xs={4}>
                                        <div className="p-2">
                                            <h4 className="text-white mb-1">0</h4>
                                            <p className="fs-14 mb-0">Propuestas aprobadas</p>
                                        </div>
                                    </Col>
                                </Row>
                            </Col> */}
                        </Row>
                    </div>

                    <Row>
                        <Col lg={12}>
                            <div>
                                <div className="d-flex">
                                    <Nav pills className="animation-nav profile-nav gap-2 gap-lg-3 flex-grow-1"
                                        role="tablist">
                                        <NavItem>
                                            <NavLink
                                                href="#overview-tab"
                                                className={classnames({ active: activeTab === '1' })}
                                                onClick={() => { toggleTab('1'); }}
                                            >
                                                <i className="ri-airplay-fill d-inline-block d-md-none"></i> <span
                                                    className="d-none d-md-inline-block">Detalles Personales</span>
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                href="#activities"
                                                className={classnames({ active: activeTab === '2' })}
                                                onClick={() => { toggleTab('2'); }}
                                            >
                                                <i className="ri-list-unordered d-inline-block d-md-none"></i> <span
                                                    className="d-none d-md-inline-block">Documentos</span>
                                            </NavLink>
                                        </NavItem>
                                    </Nav>
                                    <div className="flex-shrink-0">
                                        <Link to="/perfil-config" className="btn btn-success"><i
                                            className="ri-edit-box-line align-bottom"></i> Editar Perfil</Link>
                                    </div>
                                </div>

                                <TabContent activeTab={activeTab} className="pt-4">
                                    <TabPane tabId="1">
                                        <Row>
                                            <Col xxl={3}>

                                                <Card>
                                                    <CardBody>
                                                        <div className="d-flex align-items-center mb-4">
                                                            <div className="flex-grow-1">
                                                                <h5 className="card-title mb-0">Contacto</h5>
                                                            </div>
                                                            <div className="flex-shrink-0">
                                                                <Button
                                                                    onClick={toggleModal()} 
                                                                    className="badge bg-light text-primary fs-12">
                                                                    <i className="ri-add-fill align-bottom me-1"></i> Agregar</Button>
                                                            </div>
                                                        </div>
                                                        {
                                                            (contactos || []).map((i,x) =>
                                                                <div key={x}>
                                                                    <div className="mb-3 d-flex">
                                                                        {(i.tipo.descripcion in iconMap) && 
                                                                            <div 
                                                                                className='d-flex'
                                                                                onMouseEnter={() => setHoveredIndex(x)}
                                                                                onMouseLeave={() => setHoveredIndex(null)}>
                                                                                <div className="avatar-xs d-block flex-shrink-0 me-3">
                                                                                    <span className="avatar-title rounded-circle fs-16 bg-dark text-light">
                                                                                        <i className={`bx ${iconMap[i.tipo.descripcion]}`}></i>
                                                                                    </span>
                                                                                </div>
                                                                                <div className="flex__centro">
                                                                                    {
                                                                                        hoveredIndex === x ? 
                                                                                            <button onClick={onClickDeleteContacto(i)} className='btn btn-sm btn-danger'>
                                                                                                Eliminar
                                                                                            </button> :
                                                                                            <button className='btn'>
                                                                                                <span>
                                                                                                    {i.tipo.descripcion}
                                                                                                </span>
                                                                                            </button>
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        }
                                                                        <Input 
                                                                            type="email" 
                                                                            className="form-control" 
                                                                            id="gitUsername"
                                                                            disabled={true} 
                                                                            placeholder="Username"
                                                                            defaultValue={i.valor} 
                                                                        />
                                                                    </div>                                          
                                                                </div>
                                                            )
                                                        }
                                                    </CardBody>
                                                </Card>

                                            </Col>
                                            <Col xxl={9}>
                                                <Card>
                                                    <CardBody>
                                                        <h5 className="card-title mb-3">Detalles</h5>
                                                        <Row>
                                                            <Col xs={6} md={4}>
                                                                <div className="d-flex mt-4">
                                                                    <div
                                                                        className="flex-shrink-0 avatar-xs align-self-center me-3">
                                                                        <div
                                                                            className="avatar-title bg-light rounded-circle fs-16 text-primary">
                                                                            <i className="ri-user-2-fill"></i>
                                                                        </div>
                                                                    </div>
                                                                    <div className="flex-grow-1 overflow-hidden">
                                                                        <p className="mb-1">Perfil :</p>
                                                                        <h6 className="text-truncate mb-0">
                                                                            {userFormDetails ? userFormDetails.profile.nombre : " "}
                                                                        </h6>
                                                                    </div>
                                                                    
                                                                </div>
                                                            </Col>

                                                            <Col xs={6} md={4}>
                                                                <div className="d-flex mt-4">
                                                                    <div
                                                                        className="flex-shrink-0 avatar-xs align-self-center me-3">
                                                                        <div
                                                                            className="avatar-title bg-light rounded-circle fs-16 text-primary">
                                                                            <i className="ri-global-line"></i>
                                                                        </div>
                                                                    </div>
                                                                    <div className="flex-grow-1 overflow-hidden">
                                                                        <p className="mb-1">Website :</p>
                                                                        <Link to="#" className="fw-semibold">www.velzon.com</Link>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </CardBody>
                                                </Card>

                                                <Row>
                                                    <Col lg={12}>
                                                        <Card>
                                                            <CardHeader className="align-items-center d-flex">
                                                                <h4 className="card-title mb-0  me-2">Actividad Reciente</h4>
                                                            </CardHeader>
                                                            <CardBody>


                                                                {/* <Pagination listClassName="justify-content-center" className="pagination-separated mb-0">
                                                                    <PaginationItem disabled={pagination.page_number === 1 || !pagination.page_number}>
                                                                        <PaginationLink onClick={() => onPageChange(pagination.page_number - 1)}>
                                                                        <i className="mdi mdi-chevron-left" />
                                                                        </PaginationLink>
                                                                    </PaginationItem>
                                                                    {renderPaginationItems()}
                                                                    <PaginationItem disabled={pagination.page_number === pagination.num_pages || !pagination.page_number}>
                                                                        <PaginationLink onClick={() => onPageChange(pagination.page_number + 1)}>
                                                                        <i className="mdi mdi-chevron-right" />
                                                                        </PaginationLink>
                                                                    </PaginationItem>
                                                                </Pagination> */}
                                                            </CardBody>
                                                        </Card>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </TabPane>

                                    <TabPane tabId="2">
                                        <Card>
                                            <CardBody>
                                                <Row>
                                                        <Col lg={12}>
                                                            <div className="table-responsive">
                                                                <Table className="table-borderless align-middle mb-0">
                                                                    <thead className="table-light">
                                                                        <tr>
                                                                            <th scope="col">Tipo</th>
                                                                            <th scope="col">Nombre</th>
                                                                            <th scope="col">Tamaño</th>
                                                                            <th scope="col">Subido</th>
                                                                            <th scope="col">Acciones</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {(documents || []).map((item, key) => (
                                                                            <tr key={key}>
                                                                                <td>
                                                                                    <div className="d-flex align-items-center">
                                                                                        <div className="avatar-sm">
                                                                                            <div
                                                                                                className={`avatar-title bg-soft-primary text-primary rounded fs-20`}>
                                                                                                <i className={fileIcons[item.file_type] || "ri-file-2-line"}></i>                             
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="ms-3 flex-grow-1">
                                                                                            <h6 className="fs-15 mb-0"><Link to="#">{item.file_type}</Link>
                                                                                            </h6>
                                                                                        </div>
                                                                                    </div>
                                                                                </td>
                                                                                <td>{item.descripcion}</td>
                                                                                <td>{formatFileSize(item.file_size)}</td>
                                                                                <td>{formatDate(item.uploaded_at)}</td>
                                                                                <td>
                                                                                    <UncontrolledDropdown direction='start'>
                                                                                        <DropdownToggle tag="a" className="btn btn-light btn-icon" id="dropdownMenuLink15" role="button">
                                                                                            <i className="ri-equalizer-fill"></i>
                                                                                        </DropdownToggle>
                                                                                        <DropdownMenu>
                                                                                            <DropdownItem onClick={downloadFile(item.upload)}><i className="ri-download-2-fill me-2 align-middle text-muted" />Descargar</DropdownItem>
                                                                                        </DropdownMenu>
                                                                                    </UncontrolledDropdown>
                                                                                </td>
                                                                            </tr>
                                                                        ))}
                                                                    </tbody>
                                                                </Table>
                                                            </div>
                                                        </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                    </TabPane>

                                    <TabPane tabId="4">
                                        <Card>
                                            <CardBody>
                                                <div className="d-flex align-items-center mb-4">
                                                    <h5 className="card-title flex-grow-1 mb-0">Documents</h5>
                                                    <div className="flex-shrink-0">
                                                        <Input className="form-control d-none" type="file" id="formFile" />
                                                        <Label htmlFor="formFile" className="btn btn-danger"><i className="ri-upload-2-fill me-1 align-bottom"></i> Upload
                                                            File</Label>
                                                    </div>
                                                </div>
                                                <Row>
                                                    <Col lg={12}>
                                                        <div className="table-responsive">
                                                            <Table className="table-borderless align-middle mb-0">
                                                                <thead className="table-light">
                                                                    <tr>
                                                                        <th scope="col">File Name</th>
                                                                        <th scope="col">Type</th>
                                                                        <th scope="col">Size</th>
                                                                        <th scope="col">Upload Date</th>
                                                                        <th scope="col">Action</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {(document || []).map((item, key) => (
                                                                        <tr key={key}>
                                                                            <td>
                                                                                <div className="d-flex align-items-center">
                                                                                    <div className="avatar-sm">
                                                                                        <div
                                                                                            className={`avatar-title bg-soft-${item.iconBackgroundClass} text-${item.iconBackgroundClass} rounded fs-20`}>
                                                                                            <i className={item.icon}></i>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="ms-3 flex-grow-1">
                                                                                        <h6 className="fs-15 mb-0"><Link to="#">{item.fileName}</Link>
                                                                                        </h6>
                                                                                    </div>
                                                                                </div>
                                                                            </td>
                                                                            <td>{item.fileType}</td>
                                                                            <td>{item.fileSize}</td>
                                                                            <td>{item.updatedDate}</td>
                                                                            <td>
                                                                                <UncontrolledDropdown direction='start'>
                                                                                    <DropdownToggle tag="a" className="btn btn-light btn-icon" id="dropdownMenuLink15" role="button">
                                                                                        <i className="ri-equalizer-fill"></i>
                                                                                    </DropdownToggle>
                                                                                    <DropdownMenu>
                                                                                        <DropdownItem><i className="ri-eye-fill me-2 align-middle text-muted" />View</DropdownItem>
                                                                                        <DropdownItem><i className="ri-download-2-fill me-2 align-middle text-muted" />Download</DropdownItem>
                                                                                        <DropdownItem divider />
                                                                                        <DropdownItem><i className="ri-delete-bin-5-line me-2 align-middle text-muted" />Delete</DropdownItem>
                                                                                    </DropdownMenu>
                                                                                </UncontrolledDropdown>
                                                                            </td>
                                                                        </tr>
                                                                    ))}
                                                                </tbody>
                                                            </Table>
                                                        </div>
                                                        <div className="text-center mt-3">
                                                            <Link to="#" className="text-success "><i
                                                                className="mdi mdi-loading mdi-spin fs-20 align-middle me-2"></i>
                                                                Load more </Link>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                    </TabPane>
                                </TabContent>
                            </div>
                        </Col>
                    </Row>

                </Container>
            </div>
        </React.Fragment>
    );
};

export default SimplePage;