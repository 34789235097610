import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import {
    Button,
    ButtonGroup,
    Card,
    CardBody,
    Col,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Nav,
    NavItem,
    NavLink,
    Offcanvas,
    OffcanvasBody,
    OffcanvasHeader,
    Row,
    TabContent,
    TabPane,
    Table,
    UncontrolledDropdown,
    UncontrolledTooltip
} from "reactstrap";
import SimpleBar from "simplebar-react";
import withRouter from '../../Components/Common/withRouter';
import { useInsertionEffect } from 'react';
import { Link } from 'react-router-dom';
import { extractInitials, str_dt, formatDateCustom, isClienteAuth, convertToAMPM } from '../../helpers/api_helper';
import LeadDiscover from '../Crm/CrmDeals/leadDiscover';
import DetalleEvento from './DetalleEvento';
import { InputModal } from '../Forms/Inputs/InputModal/InputModal';
import { useFormik } from 'formik';
import { createApiMethods, userChangeForm } from '../../helpers/api_calls';
// import { SelectDropdown } from '../Forms/Inputs/SelectDropdown/SelectDropdown';
import { toast } from 'react-toastify';
import { CLIENTES, USERS } from '../../helpers/api_urls';
import { InputSelect } from '../Forms/Inputs/InputSelect/InputSelect';
import * as Yup from "yup";
import { SelectDropdownCustom } from '../Forms/Inputs/SelectDropdownCustom/SelectDropdownCustom';
import classnames from "classnames";
import { useAuth } from '../../AuthContext';


const DetalleClienteSidebar = forwardRef(({
    reload,
    isOpen= false, 
    cliente
    },ref) => {
    const [show, setShow] = useState(isOpen);
    const [isLoading, setisLoading] = useState(false);
    const [usersFromFranquicia, setusersFromFranquicia] = useState([]);
    const apiUsers = useMemo(() => createApiMethods(USERS), []);
    const apiClientes = useMemo(() => createApiMethods(CLIENTES), []);
    const { user } = useAuth();
    const [client, setclient] = useState(cliente);

    const STATUS_CHOICES = [
        { id: 1, nombre: 'Captacion' },
        { id: 2, nombre: 'En busqueda' },
        { id: 3, nombre: 'Con Ruta/Urgente' },
        { id: 4, nombre: 'Cultivable/Pendiente' },
        { id: 5, nombre: 'En Auditoria' },
        { id: 6, nombre: 'Con Cierre' },
        { id: 7, nombre: 'Descartado' },
        { id: 8, nombre: 'Sin Contacto' },
    ];

    const statusValidation = useFormik({
        enableReinitialize: true,
        initialValues: {
            status: null,
        }
    });
    
    const stat = statusValidation.values.status
    useEffect(()=>{
        if(stat){
            let obj = {
                id:cliente?.id,
                status: stat
            }
            apiClientes.update(obj).then((res)=>{
                toast.success('Se actualizo el estatus del cliente')
                reload()
            })
        }
    },[stat])

    const  toggle =() => {
        setShow(!show)
    }

    useEffect(()=>{
        apiUsers.getAll({page_size:1000}).then((res)=>{
            setusersFromFranquicia(res.results)
        }).catch(toast.error)
        if(cliente?.autor === user.id){
            apiUsers.getDetails(cliente.id).then((res)=>{
                setclient(res)
            }).catch(toast.error)
        }
    },[])

    useEffect(()=>{
        if(cliente?.autor === user.id){
            apiClientes.getDetails(cliente.id).then((res)=>{
                setclient(res)
            }).catch(toast.error)
        }
        console.log(cliente)
    },[cliente])


    function formatMoney(number) {
        if(number){
            let numero = Number(number)
              // Formatea el número como dinero con dos decimales y separadores de miles
              return numero.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
        } else return null
    }    

    useEffect(() => {
        if (show && document.getElementById("sidebar-color-dark") && document.getElementById("sidebar-color-light")) {
            document.getElementById("sidebar-color-dark").checked = false;
            document.getElementById("sidebar-color-light").checked = false;
        }
    })


    const [navBadgeTab, setnavBadgeTab] = useState("1");
    const navBadgeToggle = (tab) => {
        if (navBadgeTab !== tab) {
            setnavBadgeTab(tab);
        }
    };
    useImperativeHandle(ref, () => ({
        toggle,
        setclient: (cli) => {
            setclient(cli)
        }
    }));
    const modalRef = useRef(null);
    const modalHistory = useRef(null);
    const canvasRef = useRef(null);

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            franquicia:  null,
            status: null
        },
        validationSchema: Yup.object({
            status: Yup.number()
                .typeError("Seleccione un estatus.")
                .required("Este campo es obligatorio."),
            franquicia: Yup.number()
                .typeError("Seleccione una franquicia.")
                .required("Este campo es obligatorio."),
        })
    });

    const { franquicia, status } = validation.values

    const submitUpdate =() => () => {
        let obj = {
            ...cliente,
            ...validation.values
        }
        apiClientes.update(obj).then((res)=>{
            console.log(res)
        })
    }

    const mapAsesor = (prop,index) =>{
        return <>
          <div className="d-flex align-items-center">
            <div className="flex-shrink-0 me-3">
              <div className="avatar-sm rounded">
                  <div className="avatar-title border bg-light text-primary rounded text-uppercase fs-16">
                    {extractInitials(prop.full_name)}
                  </div>
              </div>
            </div>
            <div className="flex-grow-1">
              <h5 className="fs-14 mb-1">
                <Link
                  to="#"
                  className="text-dark">
                  {" "}
                  {prop.full_name}
                </Link>
              </h5>
              <p className="text-muted mb-0">
                <span className="fw-medium">
                  {prop?.franquicia?.nombre}
                </span>
              </p>
            </div>
          </div>
        </>
    }    

    const handleReasignar = (cliente) =>{
        modalRef.current.toggle()
    }

    const handleHistorial = () =>{
        modalHistory.current.toggle()
    }

    const mapStatus = (prop,index) =>{
        return  <span key={index} className="custom-badge badge w-100 mb-1 rounded-pill border border-primary text-primary badge-soft-primary">
            { prop?.nombre }
        </span>
    }

    return (
        <React.Fragment>
            <InputModal
                nocontrol={true}
                ref={modalRef}
                size='modal-lg'
                title={`Reasignar ${cliente?.nombre}`}
                submitEvent={() => console.log('submit')}>
                    <Row>
                        <Col lg={12}>
                            <InputSelect
                                name="status"
                                label="Estatus"
                                validation={validation}
                                optionsProps={{
                                value: 'id',
                                label: 'nombre',
                                items:  [
                                    { id: 1, nombre: 'Captacion' },
                                    { id: 2, nombre: 'En busqueda' },
                                    { id: 3, nombre: 'Con Ruta/Urgente' },
                                    { id: 4, nombre: 'Cultibable/Pendiente' },
                                    { id: 5, nombre: 'En Auditoria' },
                                    { id: 6, nombre: 'Con Cierre' },
                                    { id: 7, nombre: 'Descartado' },
                                    { id: 8, nombre: 'Sin Contacto' }
                                ]
                                }}>
                            </InputSelect>
                        </Col>                        
                        <Col  lg={12}>
                            {/* <SelectDropdown
                                map={mapAsesor}
                                searchTerm={'full_name'}
                                name         = "franquicia"
                                label        = "Asesor"
                                validation   = {validation}
                                optionsProps = {
                                    {
                                        value:'id',
                                        label:'nombre',
                                        items:usersFromFranquicia || []
                                    }}>
                            </SelectDropdown>  */}
                        </Col>      
                        <Col lg={12}>
                            <div className="modal-footer mt-2">
                                <Link 
                                    to="#" 
                                    onClick={()=>{
                                        modalRef.current.toggle()
                                    }}
                                    className="btn btn-link link-primary fw-medium">
                                    <i className="ri-close-line me-1 align-middle"></i> Cancelar
                                </Link>
                                <Button
                                    disabled={!(franquicia !== null && status !== null)}
                                    onClick={submitUpdate()}
                                    color="primary">
                                    Guardar Cambios
                                </Button>
                            </div>                        
                        </Col>                  
                    </Row>
            </InputModal>

            <InputModal
                nocontrol={true}
                ref={modalHistory}
                size='modal-lg'
                title={`Historial de ${cliente?.nombre}`}
                submitEvent={() => console.log('submit')}>
                    <Row>
                        <Col lg={12}>
                            {
                                (client && client?.logs && client?.logs.length > 0) &&
                                <Col lg={12}>
                                    <div>
                                        <div className="timeline-2">
                                            {/* <div className="timeline-year">
                                                <p>12 Dec 2021</p>
                                            </div> */}
                                            <div className="timeline-continue">
                                            
                                                {
                                                    client.logs.map((log,k)=>{

                                                        return <Row key={k} className="timeline-right">
                                                            <Col xs={12}>
                                                                <div className="timeline-date">
                                                                    <p className="timeline-date" style={{marginLeft: '0px'}}>
                                                                        {formatDateCustom(log.datetime)}
                                                                    </p>
                                                                    <small>
                                                                        {convertToAMPM(log.datetime)}
                                                                    </small>
                                                                </div>
                                                            </Col>
                                                            <Col xs={12}>
                                                                <div className="timeline-box">
                                                                    <div className="table-card">
                                                                        <div className="table-responsive">
                                                                            <Table className="table-sm table-nowrap mb-0">
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th scope="col">Campo</th>
                                                                                        {/* <th scope="col">Anterior</th> */}
                                                                                        <th scope="col">Nuevo</th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                        {
                                                                                            log.changes.map((change,key)=>{
                                                                                                return <>
                                                                                                    <tr>
                                                                                                        <td className='text-elipsis' style={{width: '50%', height: '2rem'}}>
                                                                                                            <td style={{width: '50%'}}>
                                                                                                                <span className="badge badge-soft-primary">{change.field_name}</span>
                                                                                                                <i className="ri-arrow-right-line align-middle"></i>
                                                                                                            </td>

                                                                                                        </td>
                                                                                                        {/* <td className='text-elipsis'>
                                                                                                            {
                                                                                                                typeof change.old_value === 'object' ?
                                                                                                                change.old_value?.nombre : change.old_value
                                                                                                            }
                                                                                                        </td> */}
                                                                                                        <td className='text-elipsis' style={{width: '50%', height: '2rem'}}>
                                                                                                            <span id={`change_${k}_${key}`}>
                                                                                                                {
                                                                                                                    typeof change.new_value === 'object' ?
                                                                                                                    change.new_value?.nombre : change.new_value
                                                                                                                }
                                                                                                            </span>
                                                                                                            <UncontrolledTooltip 
                                                                                                                placement="top" 
                                                                                                                target={`change_${k}_${key}`}> 
                                                                                                                {
                                                                                                                    typeof change.new_value === 'object' ?
                                                                                                                    change.new_value?.nombre : change.new_value
                                                                                                                }
                                                                                                            </UncontrolledTooltip>

                                                                                                        </td>
                                                                                                    </tr>
                                                                                                </>
                                                                                            })
                                                                                        }                                                        
                                                                                </tbody>
                                                                            </Table>
                                                                        </div>    
                                                                    </div>                                                                                    
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    })
                                                }

                                            </div>
                                        </div>
                                    </div>
                                </Col>                                        
                            }
                        </Col>                        
               
                    </Row>
            </InputModal>            

            {isLoading === "enable" && <div id="preloader">
                <div id="status">
                    <div className="spinner-border text-primary avatar-sm" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            </div>}

            <div>
                <div className="customizer-setting d-none d-md-block">
                    <div onClick={toggle} className="btn-primary btn-rounded shadow-lg btn btn-icon btn-lg p-2">
                        <i className='mdi mdi-spin mdi-cog-outline fs-22'></i>
                    </div>
                </div>
                <Offcanvas 
                    offcanvasTransition={{ timeout: 300 }}
                    innerRef={canvasRef} 
                    isOpen={show} 
                    toggle={toggle} 
                    direction="end" 
                    className="offcanvas-end border-0">
                    <OffcanvasHeader className="d-flex align-items-center bg-primary bg-gradient p-3 offcanvas-header-dark" toggle={toggle}>
                        <span className="m-0 me-2 text-white">{cliente?.nombre}</span>
                    </OffcanvasHeader>
                    <OffcanvasBody className="p-0">
                        <SimpleBar className="h-100">
                            <Row>
                                <Col sm={12}>
                                    <Card id="contact-view-detail">
                                        <CardBody className="text-center">
                                            <div className='d-flex flex-row w-100'>
                                                <div className='img_pic' style={{paddingRight: '1rem'}}>
                                                    <div className="position-relative d-inline-block">
                                                        <div className="avatar-md rounded">
                                                            {
                                                                cliente &&
                                                                <div className="avatar-title border bg-light text-primary rounded text-uppercase fs-16">
                                                                    {extractInitials(cliente?.nombre)}
                                                                </div>
                                                            }
                                                        </div>                                            
                                                    </div>
                                                </div>
                                                <div className='info-box w-100 d-flex flex-column text-start'>
                                                    <h5 className="mt-1 mb-1">{cliente?.nombre || "Tonya Noble"}</h5>
                                                    <ul className="list-inline mb-0">
                                                    {
                                                        (cliente?.contactos_clientes || []).map((item, key) => {
                                                            const getIcon = (iconClass, bgColor, textColor) => (
                                                            <li key={key} className="list-inline-item avatar-xs">
                                                                <Link to="#" className={`avatar-title ${bgColor} ${textColor} fs-15 rounded`}>
                                                                <i id={`item_tool${key}`} className={iconClass}></i>
                                                                </Link>
                                                                {item.valor && (
                                                                    <UncontrolledTooltip placement="top" target={`item_tool${key}`}>
                                                                        {item.valor}
                                                                    </UncontrolledTooltip>
                                                                    )
                                                                }
                                                            </li>
                                                            );

                                                            if (item.tipo.id === 1) {
                                                                return getIcon("ri-phone-line", "bg-soft-success", "text-success");
                                                            } else if (item.tipo.id === 2) {
                                                                return getIcon("ri-mail-line", "bg-soft-danger", "text-danger");
                                                            } else if (item.tipo.id === 3) {
                                                                return getIcon("ri-question-answer-line", "bg-soft-primary", "text-primary");
                                                            } else {
                                                                return null;
                                                            }
                                                        })
                                                    }

                                                        
                                                    </ul>
                                                </div>
                                                <div className='actionsbox d-flex justify-content-center flex-column'>
                                                    <ButtonGroup>
                                                        <UncontrolledDropdown>
                                                            <DropdownToggle tag="button" className="btn btn-light btn-sm">
                                                                <i className="ri-more-2-fill"></i>
                                                            </DropdownToggle>
                                                            <DropdownMenu>
                                                                <DropdownItem onClick={() => handleReasignar(cliente)}>Reasignar </DropdownItem>
                                                                <DropdownItem onClick={() => handleHistorial()}>Ver historial </DropdownItem>
                                                            </DropdownMenu>
                                                        </UncontrolledDropdown>
                                                    </ButtonGroup>                                                    
                                                </div>
                                            </div>


                                            <div className="py-3 border border-dashed border-start-0 mt-4">
                                                <Row>
                                                    <Col lg={4} sm={6}>
                                                        <div>
                                                            <p className="mb-2 text-uppercase fw-semibold fs-12 text-muted">
                                                            Franquicia
                                                            </p>
                                                            <h5 className="mb-3 fs-14 mb-0 view-type">{ cliente?.franquicia.nombre }</h5>
                                                        </div>
                                                    </Col>
                                                    <Col lg={4} sm={6}>
                                                        <div>
                                                            <p className="mb-2 text-uppercase fw-semibold fs-12 text-muted">
                                                                Estatus
                                                            </p>
                                                            <SelectDropdownCustom
                                                                map={mapStatus}
                                                                disabled={!isClienteAuth(cliente,user)}
                                                                searchTerm={'nombre'}
                                                                name         = "status"
                                                                label        = "Estatus"
                                                                tag='span'
                                                                preselection={cliente?.status}
                                                                classNameSelected='custom-badge cursor-pointer badge w-100 mb-1 rounded-pill border border-primary text-primary'
                                                                validation   = {statusValidation}
                                                                optionsProps = {
                                                                    {
                                                                        value:'id',
                                                                        label:'nombre',
                                                                        items: STATUS_CHOICES || []
                                                                    }}>
                                                            </SelectDropdownCustom>                                                         

                                                        </div>
                                                    </Col>
                                                    <Col lg={4} sm={6}>
                                                        <div>
                                                            <p id="fecha_tooltip" className="mb-2 text-uppercase fw-semibold fs-12 text-muted">
                                                                Fecha
                                                            </p>
                                                            <UncontrolledTooltip
                                                                    placement="top" 
                                                                    target="fecha_tooltip"> 
                                                                    Fecha de creacion de cliente
                                                            </UncontrolledTooltip>
                                                            <h5 className=" mb-3 fs-14 mb-0 view-experience">
                                                            { str_dt(cliente?.created_at) }
                                                            </h5>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Nav tabs className="nav-tabs nav-justified mb-3">
                                                    <NavItem>
                                                        <NavLink style={{ cursor: "pointer" }} className={classnames({ active: navBadgeTab === "1", })} onClick={() => { navBadgeToggle("1"); }} >
                                                            <span className="badge bg-danger rounded-circle">{cliente?.busquedas.length}</span>
                                                            <br/>Busquedas
                                                        </NavLink>
                                                    </NavItem>
                                                    <NavItem>
                                                        <NavLink style={{ cursor: "pointer" }} className={classnames({ active: navBadgeTab === "2", })} onClick={() => { navBadgeToggle("2"); }} >
                                                            <span className="badge bg-danger rounded-circle">{cliente?.propiedades?.length}</span>
                                                            <br/>
                                                            Propiedades 
                                                        </NavLink>
                                                    </NavItem>
                                                    <NavItem>
                                                        <NavLink style={{ cursor: "pointer" }} className={classnames({ active: navBadgeTab === "3", })} onClick={() => { navBadgeToggle("3"); }} >
                                                            <span className="badge bg-danger rounded-circle">{cliente?.visitas.length}</span>
                                                            <br/>
                                                            Visitas 
                                                        </NavLink>
                                                    </NavItem>
                                                </Nav>

                                                <TabContent activeTab={navBadgeTab} className="text-muted">
                                                    <TabPane tabId="1" id="nav-badge-home">
                                                        {
                                                            (cliente && cliente.busquedas.length > 0) ?
                                                            <CardBody>
                                                                <LeadDiscover
                                                                    busquedas={cliente.busquedas} 
                                                                    mute={true}
                                                                    index={0} 
                                                                    />
                                                            </CardBody>:
                                                            <blockquote className="blockquote custom-blockquote blockquote-primary rounded mb-0">
                                                                <p className="text-dark mb-2">Busqueda sin resultados.</p>
                                                            </blockquote>
                                                        }
                                                    </TabPane>

                                                    <TabPane tabId="2" id="nav-badge-profile">
                                                        {
                                                            (cliente && cliente?.propiedades?.length > 0) ?
                                                            <CardBody>
                                                                {
                                                                    (cliente.propiedades || []).map((propiedad,key)=>{
                                                                        return <div key={key} className="d-flex align-items-center">
                                                                            <div className="flex-shrink-0 me-3">
                                                                                <div className="avatar-sm bg-light rounded p-1">
                                                                                <img
                                                                                        src={propiedad.first_image_url}
                                                                                        alt=""
                                                                                        className="img-fluid d-block"/>
                                                                                </div>
                                                                            </div>
                                                                            <div className="flex-grow-1">
                                                                                <h5 className="fs-14 mb-1">
                                                                                <Link
                                                                                    to={`/inmueble/${propiedad.id}`}
                                                                                    className="text-dark"
                                                                                >
                                                                                    {" "}
                                                                                    {propiedad.nombre}
                                                                                </Link>
                                                                                </h5>
                                                                                <p className="text-muted mb-0">
                                                                                {/* Category :{" "} */}
                                                                                <span className="fw-medium">
                                                                                    {" "}
                                                                                    {propiedad?.tipo_inmueble?.nombre} - {formatMoney(propiedad?.precio)}
                                                                                </span>
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                                                                                
                                                                    })
                                                                }
                                                            </CardBody>:
                                                            <blockquote className="blockquote custom-blockquote blockquote-primary rounded mb-0">
                                                                <p className="text-dark mb-2">Busqueda sin resultados.</p>
                                                            </blockquote>
                                                        }                                            
                                                    </TabPane>

                                                    <TabPane tabId="3" id="nav-badge-messages" >
                                                        {
                                                            (cliente && cliente.visitas.length > 0) ?
                                                            <CardBody>
                                                                {
                                                                    (cliente.visitas || []).map((visita,key)=>{
                                                                        return <DetalleEvento  event={visita} key={key} />
                                                                    })
                                                                }
                                                            </CardBody> :
                                                            <blockquote className="blockquote custom-blockquote blockquote-primary rounded mb-0">
                                                                <p className="text-dark mb-2">Busqueda sin resultados.</p>
                                                            </blockquote>
                                                        
                                                        }
                                                    </TabPane>
                                                </TabContent>
                                            </div>    
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </SimpleBar>
                    </OffcanvasBody>
                </Offcanvas>
            </div>
        </React.Fragment>
    );
});
DetalleClienteSidebar.displayName = 'DetalleClienteSidebar';

export default DetalleClienteSidebar