import React from 'react';

import './assets/scss/themes.scss';

import Route from './Routes';
import { ToastContainer } from 'react-toastify';
import { GuestGuard } from './helpers/acl/GuestGuard';
import { Spinner } from 'reactstrap';
import { AuthGuard } from './helpers/acl/AuthGuard';
import AclGuard from './helpers/acl/AclGuard';
import { DataProvider } from './DataContext';

const Guard = ({ children, authGuard, guestGuard }) => {
  if (guestGuard) {
    return <GuestGuard fallback={<Spinner />}>{children}</GuestGuard>
  } else if (!guestGuard && !authGuard) {
    return <>{children}</>
  } else {
    return <AuthGuard fallback={<Spinner />}>{children}</AuthGuard>
  }
}

const defaultACLObj = {
  action: 'manage',
  subject: 'all'
}

function App(props) {
  const { Component, pageProps } = props
  const aclAbilities = Component?.acl ?? defaultACLObj

  return (
    <React.Fragment>
      <ToastContainer closeButton={true} />
      <Guard authGuard={false} guestGuard={false}>
        <DataProvider>
        <AclGuard aclAbilities={aclAbilities} guestGuard={true} authGuard={true}>
          <Route />
        </AclGuard>
        </DataProvider>
      </Guard>           
    </React.Fragment>
  );
}

export default App;
