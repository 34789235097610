import { $in, within, $eq, eq, createFactory, BuildMongoQuery } from '@ucast/mongo2js';
import {
  createMongoAbility,
  AbilityBuilder,
} from '@casl/ability';
import { CLIENTE, FRANQUICIA_ACL, PROPIEDAD_ACL, RESERVA_ACL, SLUG_ADMIN, SLUG_GERENTE, VISITAAGENDADA } from './entities';
import { GASTOSRESERVAS } from './api_urls';


export const getHomeRoute = role => {
  return '/'
}
const conditionsMatcher = createFactory({ $in, $eq }, { in: within, eq });




export default function defineAbilityFor(user) {
  const { can, build, cannot } = new AbilityBuilder(createMongoAbility);
  const slug = user.profile.slug_name
  let franquicia = null

  if(user.franquicia)
    franquicia = user.franquicia

  function declareVisitaAgendada(){
    can('read', VISITAAGENDADA);
    can('update', VISITAAGENDADA,{'asesor.id':user.id});
    can('delete', VISITAAGENDADA,{'asesor.id':user.id});
  }

  function declareCliente(){
    can('read', CLIENTE,{
      'autor': user.id,
    });
    can('read', CLIENTE,{
      'franquicia.usuario_asignado.id': user.id
    });   

    
    if(franquicia){
      can('read', CLIENTE,{
        'franquicia.franquicia_comercial': franquicia.id
      });   
      can('update', CLIENTE,{
        'franquicia.franquicia_comercial.id': franquicia.id
      });
      can('update', CLIENTE,{
        'franquicia.personal.id': franquicia.id
      });      
    }
    can('update', CLIENTE,{
      'franquicia.usuario_asignado.id': user.id
    });      
    can('update', CLIENTE, {'autor': user.id});
    can('reasign', CLIENTE, {'autor': user.id});
    can('delete', CLIENTE, {'autor': user.id});
  }

  function declarePropiedad(){
    can('read', PROPIEDAD_ACL,{
      'franquicia.usuario_asignado.id': user.id
    });   
    can('update', PROPIEDAD_ACL,{
      'franquicia.usuario_asignado.id': user.id
    });
    can('update', PROPIEDAD_ACL,{
      'autor': user.id
    });
    can('update', PROPIEDAD_ACL,{
      'franquicia.franquicia_comercial': franquicia.id
    });
    can('update', PROPIEDAD_ACL,{
      'franquicia_.franquicia_comercial': franquicia.id
    });
    can('update', PROPIEDAD_ACL,{
      'franquicia.franquicia_personal': franquicia.id
    });
  }

  function declareFranquicia(){
    can('update', FRANQUICIA_ACL,{
      'franquicia_comercial.id': franquicia.id
    });
    can('update', FRANQUICIA_ACL,{
      'id': franquicia.id
    });
    can('update', FRANQUICIA_ACL,{
      'franquicia_personal.id': franquicia.id
    });
    can('update', FRANQUICIA_ACL,{
      'usuario_asignado.id': user.id
    });
  }

  function declareReserva(){
    can('update', RESERVA_ACL,{
      'franquicia_comercial': franquicia.id
    });
    can('update', RESERVA_ACL,{
      'franquicia_captadora.id': franquicia.id,
    });
  }

  function declareGastosReserva(){
    can('update', GASTOSRESERVAS,{
      'franquicia_comercial': franquicia.id
    });
  }

  switch(slug){
    case SLUG_ADMIN:
      can('manage', 'all');

    break;
    case SLUG_GERENTE:

      declareVisitaAgendada()
      declareCliente()
      declarePropiedad()
      declareFranquicia()
      declareReserva()
      declareGastosReserva()

    break;    
    default:
      console.error('No Slug cls.js')
  }

  return build({ 
    conditionsMatcher,
    detectSubjectType: object => object.obj_type 
  });
}