import classnames from "classnames";
import React, { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Card, CardBody, CardHeader, Col, Container, DropdownItem, DropdownMenu, DropdownToggle, Input, Label, Nav, NavItem, NavLink, Row, TabContent, TabPane, Table, UncontrolledDropdown } from 'reactstrap';
import { Swiper, SwiperSlide } from "swiper/react";
import * as Yup from "yup";

import { useFormik } from 'formik';
import { ToastContainer, toast } from 'react-toastify';
import { useAuth } from '../../../../AuthContext';
import DeleteModal from '../../../../Components/Common/DeleteModal';
import progileBg from '../../../../assets/images/profile-bg.jpg';
import { createApiMethods, getUserDetails, updateUser } from '../../../../helpers/api_calls';
import { APIClient, getLoggedinUser, handleError } from '../../../../helpers/api_helper';
import { CONTACTOS, DOCUMENTS, FRANQUICIAS } from '../../../../helpers/api_urls';
import ParamInput from '../../../Forms/Inputs/InputParam/InputParam';
import { InputText } from '../../../Forms/Inputs/InputText/InputText';
import { ChangePassword } from './components/ChangePassword/ChangePassword';
import { ModalAdjuntarFile } from './components/ModalAdjuntarFile/ModalAdjuntarFile';
import { ModalContactos } from './components/ModalContactos/ModalContactos';

const Settings = () => {
    const [configVars, setconfigVars] = useState({});
    const [activeTab, setActiveTab] = useState("1");
    const userProfileSession = getLoggedinUser();
    const [userAvailable, setuserAvailable] = useState();
    const [userFormDetails, setuserFormDetails] = useState(null);
    const [isLoading, setisLoading] = useState();
    const APIfranquicias = createApiMethods(FRANQUICIAS)
    const tabChange = (tab) => {
        if (activeTab !== tab) setActiveTab(tab);
    };
    const [userProfileLoaded, setUserProfileLoaded] = useState(false);
    const [modalState, setModalState] = useState(false);
    const [modalStateDocuments, setmodalStateDocuments] = useState();
    const [modalStateAsesor, setmodalStateAsesor] = useState(false);
    const [modalStateConductor, setmodalStateConductor] = useState(false);
    const apiContactos = useMemo(() => createApiMethods(CONTACTOS), []);
    const apiDocuments = useMemo(() => createApiMethods(DOCUMENTS), []);
    const [showButtonsOficialPrestador, setshowButtonsOficialPrestador] = useState(false);

    const [contactos, setcontactos] = useState([]);
    const [documents, setdocuments] = useState([]);
    const [deleteModal, setDeleteModal] = useState(false);
    const [deleteModalContacto, setdeleteModalContacto] = useState(false);
    const [deleteItem, setdeleteItem] = useState(null);
    const [deleteItemContacto, setdeleteItemContacto] = useState();
    const [hoveredIndex, setHoveredIndex] = useState(null);
    const { user } = useAuth();
    const api = new APIClient();
    const [profilePicUrl, setprofilePicUrl] = useState(null);
    const [franqProfilePicUrl, setfranqProfilePicUrl] = useState(null);
    const [webUrl, setWebUrl] = useState(null);

    const profileFranquiciaPic = (event) => {
        const file = event.target.files[0];
        if (file && file.type.match('image.*')) {

            const formData = new FormData();
            formData.append('image', file);
            const ID_FRANQUICIA = user?.user?.franquicia?.id 
    
            if(ID_FRANQUICIA){
                console.log(ID_FRANQUICIA)
                api.update(`/franquicias/${ID_FRANQUICIA}/update_image/`,formData,{
                    headers: {
                        "Content-Type": "multipart/form-data"
                    }
                }).then((res)=>{
                    console.log(res)
                    window.location.reload(true);
                }).finally(()=>{
                })
            } else {
                return
            }

        } else {
            alert("Please select a valid image file.");
        }

        if (!file) {
            alert("No file selected");
            return;
        }
    };

    const profilePicHandler = (event) => {
        const file = event.target.files[0];
        if (file && file.type.match('image.*')) {

            const formData = new FormData();
            formData.append('image', file);
    
            if(user?.user){
                api.update(`user/${user.user.id}/update_image/`,formData,{
                    headers: {
                        "Content-Type": "multipart/form-data"
                    }
                }).then((res)=>{
                    console.log(res)
                    window.location.reload(true);
                }).finally(()=>{
                })
            } else {
                return
            }

        } else {
            alert("Please select a valid image file.");
        }

        if (!file) {
            alert("No file selected");
            return;
        }
    };

    const iconMap = {
        'Instagram': 'bxl-instagram',
        'Whatsapp': 'bxl-whatsapp',
        'Teléfono': 'bx-phone',
        'Email': 'bxl-gmail',
        'Twitter': 'bxl-twitter'
    };

    const handleDeleteDocument = () => {
        apiDocuments.delete(deleteItem).then((res)=>{
            toast.success("El archivo se ha eliminado con éxito", { autoClose: 3000 });
        }).catch((err)=>{
            toast.error("Ha ocurrido un error al intentar eliminar el archivo", { autoClose: 3000 });
        }).finally(()=>{
            apiDocuments.getAll({page_size:1000}).then((res)=>{
                setdocuments(res.results)
                setDeleteModal(false);
            })
        })
    };

    const handleDeleteContacto = () => {
        apiContactos.delete(deleteItemContacto).then((res)=>{
            toast.success("El contacto se ha eliminado con éxito", { autoClose: 3000 });
        }).catch((err)=>{
            toast.error("Ha ocurrido un error al intentar eliminar el contacto", { autoClose: 3000 });
        }).finally(()=>{
            apiContactos.getAll({page_size:1000}).then((res)=>{
                setdeleteModalContacto(false)
                setcontactos(res.results)
            })
        })
    };
    
    const downloadFile = (fileURL) => () => {
        fetch(fileURL, {
            method: 'GET'
        })
        .then((response) => response.blob())
        .then((blob) => {
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement('a');
            link.href = url;

            const fileName = fileURL.split("/").pop();
            link.setAttribute('download', fileName);

            document.body.appendChild(link);

            link.click();

            link.parentNode.removeChild(link);
        });
    }

    const onClickDelete = (item) => () => {
        setdeleteItem(item)
        setDeleteModal(true);
    };

    const onClickDeleteContacto = (item) => () => {
        setdeleteItemContacto(item)
        setdeleteModalContacto(true);
    };
    
    const toggleModal = () => () => {
        setModalState(!modalState);
    };

    const toggleModalChild = () => {
        setModalState(!modalState);
        apiContactos.getAll({page_size:1000}).then((res)=>{
            setcontactos(res.results)
        })
    };

    const toggleModalDocuments = () => () => {
        setmodalStateDocuments(!modalStateDocuments);
    };

    const toggleModalChildDocuments = () => {
        setmodalStateDocuments(!modalStateDocuments);
        apiDocuments.getAll({page_size:1000}).then((res)=>{
            setdocuments(res.results)
        })
    };

    const toggleModalAsesor = () => () => {
        setmodalStateAsesor(!modalStateAsesor);
    };

    const toggleModalConductor = () => () => {
        setmodalStateConductor(!modalStateConductor);
    };

    useEffect(() => {
        if (userProfileSession && !userProfileLoaded) {
          setuserAvailable(userProfileSession);
          setUserProfileLoaded(true);
        }
    }, [userProfileSession, userProfileLoaded]);

    useEffect(() => {
        if(userAvailable){  
            getUserDetails(userAvailable.id).then((res)=>{
                setuserFormDetails(res)
            }).catch(handleError)
        }
    }, [userAvailable])

    useEffect(() => {
        apiContactos.getAll({page_size:1000}).then((res)=>{
            setcontactos(res.results)
        })

        apiDocuments.getAll({page_size:1000}).then((res)=>{
            setdocuments(res.results)
        })

        APIfranquicias.getDetails(user.user.franquicia.id).then((res) => {
            console.log(res)
        })

        const ID_FRANQUICIA = user.user.franquicia.id
        const ID_USER = user.user.id
        if(ID_USER){
            api.get(`/user/${ID_USER}/update_image/`).then((res)=>{
                setprofilePicUrl(res.image_url)
            })
        }

        if(ID_FRANQUICIA){
            api.get(`/franquicias/${ID_FRANQUICIA}/image/`).then((res)=>{
                setfranqProfilePicUrl(res)
            })
        }


    }, [])

    const getFormValue = (field) =>{
        return userFormDetails ? userFormDetails[field] : null
    }

    const getFormValueFiscal = (field) =>{
        return (userFormDetails && userFormDetails.identificacion_fiscal) ? userFormDetails.identificacion_fiscal[field] : null
    }

    const handleUpdateDatosBasicos = () => () => {
        validation.submitForm()
    }

    const validationFiscal = useFormik({
        enableReinitialize: true,
        initialValues: {
            nombre          : getFormValueFiscal('nombre'),
            direccion_fiscal: getFormValueFiscal('direccion_fiscal'),
            tipo            : getFormValueFiscal('tipo'),
            identificacion  : getFormValueFiscal('identificacion'),
        },
        validationSchema: Yup.object({
        }),
    });

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            first_name: getFormValue('first_name'),
            last_name : getFormValue('last_name'),
            email     : getFormValue('email'),
        },
        validationSchema: Yup.object({
        }),
        onSubmit: (values) => {
            values.identificacion_fiscal = validationFiscal.values
            let userFormUpdated = {...userFormDetails,...values}
            setisLoading(true)
            updateUser(userFormUpdated).then((res)=>{
                console.log(res)
            }).catch(console.log).finally(()=>{
                setisLoading(false)
            })
        }
    });

    const fileIcons = {
        pdf: "ri-file-pdf-line",
        doc: "ri-file-word-line",
        docx: "ri-file-word-line",
        png: "ri-file-image-line",
        jpg: "ri-file-image-line",
        csv: "ri-file-excel-line",
    };

    function formatFileSize(size) {
        const i = size === 0 ? 0 : Math.floor(Math.log(size) / Math.log(1024));
        return (size / Math.pow(1024, i)).toFixed(2) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];
    }

    document.title = "Configuraciones de Perfil | Transcar";

    function formatDate(isoDate) {
        const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' };
        return new Date(isoDate).toLocaleString('es-ES', options);
    }

    return (
        <React.Fragment>
            <ToastContainer />
            <DeleteModal
                show={deleteModalContacto}
                onDeleteClick={handleDeleteContacto}
                onCloseClick={() => setdeleteModalContacto(false)}/>
            <DeleteModal
                show={deleteModal}
                onDeleteClick={handleDeleteDocument}
                onCloseClick={() => setDeleteModal(false)}/>
            <ModalContactos 
                modalState={modalState} 
                toggleModal={toggleModalChild}>
            </ModalContactos>
            <ModalAdjuntarFile 
                modalState={modalStateDocuments} 
                toggleModal={toggleModalChildDocuments}>
            </ModalAdjuntarFile>

            
            <div className="page-content">
                <Container fluid>
                    <div className="position-relative mx-n4 mt-n4">
                        <div className="profile-wid-bg profile-setting-img">
                            <img src={progileBg} className="profile-wid-img" alt="" />
                            { showButtonsOficialPrestador &&
                                <div className="overlay-content" style={{padding: '5px'}}>
                                    <Button onClick={toggleModalAsesor()} style={{marginRight: '5px'}}>
                                        Quiero ser Asesor
                                    </Button>
                                    <Button onClick={toggleModalConductor()}>
                                        Quiero ser Prestador de Servicios
                                    </Button>
                                </div>
                            }
                        </div>
                    </div>
                    <Row>
                        <Col xxl={3}>

                            {
                                profilePicUrl &&
                                <Card className="mt-n5 mb-2">
                                    <CardBody className="p-4">
                                        <div className="text-center">
                                            <div className="profile-user position-relative d-inline-block mx-auto  mb-4">
                                                <img src={profilePicUrl}
                                                    className="rounded-circle avatar-xl img-thumbnail user-profile-image"
                                                    alt="user-profile" />
                                                <div className="avatar-xs p-0 rounded-circle profile-photo-edit">
                                                    <Input
                                                        id="profile-img-file-input"
                                                        type="file"
                                                        className="profile-img-file-input"
                                                        onChange={profilePicHandler}/>        

                                                    <Label htmlFor="profile-img-file-input"
                                                        className="profile-photo-edit avatar-xs">
                                                        <span className="avatar-title rounded-circle bg-light text-body">
                                                            <i className="ri-camera-fill"></i>
                                                        </span>
                                                    </Label>
                                                </div>
                                            </div>
                                            <h5 className="fs-16 mb-1">Imagen de Perfil</h5>
                                            <p className="text-muted mb-0">{userAvailable?.profile ? userAvailable?.profile.nombre : " " }</p>
                                        </div>
                                    </CardBody>
                                </Card>
                            }

                            {
                                franqProfilePicUrl &&
                                <Card className="mt-n5 mt-2">
                                    <CardBody className="p-4">
                                        <div className="text-center">
                                            <div className="profile-user position-relative d-inline-block mx-auto  mb-4">
                                                <img src={franqProfilePicUrl}
                                                    className="rounded-circle avatar-xl img-thumbnail user-profile-image"
                                                    alt="user-profile" />
                                                <div className="avatar-xs p-0 rounded-circle profile-photo-edit">
                                                    <Input
                                                        id="franquicia-img-file-input"
                                                        type="file"
                                                        className="profile-img-file-input"
                                                        onChange={profileFranquiciaPic}/>        

                                                    <Label htmlFor="franquicia-img-file-input"
                                                        className="profile-photo-edit avatar-xs">
                                                        <span className="avatar-title rounded-circle bg-light text-body">
                                                            <i className="ri-camera-fill"></i>
                                                        </span>
                                                    </Label>
                                                </div>
                                            </div>
                                            <h5 className="fs-16 mb-1">Imagen de Franquicia</h5>
                                        </div>
                                    </CardBody>
                                </Card>                            
                            }
                            

                            <Card>
                                <CardBody>
                                    <div className="d-flex align-items-center mb-4">
                                        <div className="flex-grow-1">
                                            <h5 className="card-title mb-0">Contacto</h5>
                                        </div>
                                        <div className="flex-shrink-0">
                                            <Button onClick={toggleModal()} className="badge bg-light text-primary fs-12"><i
                                                className="ri-add-fill align-bottom me-1"></i> Agregar</Button>
                                        </div>
                                    </div>

                                    {
                                        (contactos || []).map((i,x) =>
                                            <div key={x}>
                                                <div className="mb-3 d-flex">
                                                    {(i.tipo.descripcion in iconMap) && 
                                                        <div 
                                                            className='d-flex'
                                                            onMouseEnter={() => setHoveredIndex(x)}
                                                            onMouseLeave={() => setHoveredIndex(null)}>
                                                            <div className="avatar-xs d-block flex-shrink-0 me-3">
                                                                <span className="avatar-title rounded-circle fs-16 bg-dark text-light">
                                                                    <i className={`bx ${iconMap[i.tipo.descripcion]}`}></i>
                                                                </span>
                                                            </div>
                                                            <div className="flex__centro">
                                                                {
                                                                    hoveredIndex === x ? 
                                                                        <button onClick={onClickDeleteContacto(i)} className='btn btn-sm btn-danger'>
                                                                            Eliminar
                                                                        </button> :
                                                                        <button className='btn'>
                                                                            <span>
                                                                                {i.tipo.descripcion}
                                                                            </span>
                                                                        </button>
                                                                }
                                                            </div>
                                                        </div>
                                                    }
                                                    <Input 
                                                        type="email" 
                                                        className="form-control" 
                                                        id="gitUsername"
                                                        disabled={true} 
                                                        placeholder="Username"
                                                        defaultValue={i.valor} 
                                                    />
                                                </div>                                          
                                            </div>
                                        )
                                    }
                                </CardBody>
                            </Card>
                        </Col>

                        <Col xxl={9}>
                            <Card className="mt-xxl-n5">
                                <CardHeader>
                                    <Nav className="nav-tabs-custom rounded card-header-tabs border-bottom-0"
                                        role="tablist">
                                        <NavItem>
                                            <NavLink
                                                className={classnames({ active: activeTab === "1" })}
                                                onClick={() => {
                                                    tabChange("1");
                                                }}>
                                                <i className="fas fa-home"></i>
                                                Detalles Personales
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink to="#"
                                                className={classnames({ active: activeTab === "2" })}
                                                onClick={() => {
                                                    tabChange("2");
                                                }}
                                                type="button">
                                                <i className="far fa-user"></i>
                                                Cambiar Contraseña
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink to="#"
                                                className={classnames({ active: activeTab === "3" })}
                                                onClick={() => {
                                                    tabChange("3");
                                                }}
                                                type="button">
                                                <i className="far fa-envelope"></i>
                                                Documentos Cargados
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink to="#"
                                                className={classnames({ active: activeTab === "4" })}
                                                onClick={() => {
                                                    tabChange("4");
                                                }}
                                                type="button">
                                                <i className="far fa-envelope"></i>
                                                Parametros Externos
                                            </NavLink>
                                        </NavItem>
                                    </Nav>
                                </CardHeader>
                                <CardBody className="p-4">
                                    <TabContent activeTab={activeTab}>
                                        <TabPane tabId="1">
                                                <Row>
                                                    <Col lg={6} md={6} sm={12}>
                                                        <InputText
                                                            name="first_name"
                                                            label="Nombre"
                                                            validation={validation}
                                                        ></InputText>                                                        
                                                    </Col>
                                                    <Col lg={6} md={6} sm={12}>
                                                        <InputText
                                                            name="last_name"
                                                            label="Apellido"
                                                            validation={validation}
                                                        ></InputText>                                                        
                                                    </Col>
                                                    <Col lg={6} md={6} sm={12}>
                                                        <InputText
                                                            name="email"
                                                            disabled={true}
                                                            label="Email"
                                                            validation={validation}
                                                        ></InputText>                                                        
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col lg={12}>
                                                        <Button
                                                            color="success" 
                                                            disabled={isLoading}
                                                            onClick={handleUpdateDatosBasicos()} 
                                                            className="btn-label w-lg">
                                                                <i className="ri-check-double-line label-icon align-middle fs-16 me-2"></i> 
                                                                {
                                                                    (isLoading) ? (
                                                                        <span className="d-flex align-items-center">
                                                                            <span className="flex-grow-1 me-2">
                                                                                Cargando...
                                                                            </span>
                                                                            <span className="spinner-border flex-shrink-0" role="status">
                                                                                <span className="visually-hidden">Cargando...</span>
                                                                            </span>
                                                                        </span>
                                                                    ) : (
                                                                        <span className="d-flex align-items-center">
                                                                            <span className="flex-grow-1 me-2">
                                                                                Actualizar
                                                                            </span>
                                                                        </span>
                                                                    )
                                                                }
                                                        </Button>
                                                    </Col>
                                                </Row>
                                        </TabPane>

                                        <TabPane tabId="2">
                                            <ChangePassword>

                                            </ChangePassword>
                                        </TabPane>

                                        <TabPane tabId="3">
                                            <Card>
                                                {/* <CardBody> */}
                                                    <div className="d-flex align-items-center mb-4">
                                                        <h5 className="card-title flex-grow-1 mb-0">Archivos</h5>
                                                        <div className="flex-shrink-0">
                                                            <Input className="form-control d-none" type="file" id="formFile" />
                                                            <Button 
                                                                onClick={toggleModalDocuments()}
                                                                htmlFor="formFile" 
                                                                className="btn btn-danger">
                                                                    <i className="ri-upload-2-fill me-1 align-bottom"></i> 
                                                                    Cargar Archivos
                                                            </Button>
                                                        </div>
                                                    </div>
                                                    {/* <Row> */}
                                                        <Col lg={12}>
                                                            <div className="table-responsive">
                                                                <Table className="table-borderless align-middle mb-0">
                                                                    <thead className="table-light">
                                                                        <tr>
                                                                            <th scope="col">Tipo</th>
                                                                            <th scope="col">Nombre</th>
                                                                            <th scope="col">Tamaño</th>
                                                                            <th scope="col">Subido</th>
                                                                            <th scope="col">Acciones</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {(documents || []).map((item, key) => (
                                                                            <tr key={key}>
                                                                                <td>
                                                                                    <div className="d-flex align-items-center">
                                                                                        <div className="avatar-sm">
                                                                                            <div
                                                                                                className={`avatar-title bg-soft-primary text-primary rounded fs-20`}>
                                                                                                <i className={fileIcons[item.file_type] || "ri-file-2-line"}></i>                             
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="ms-3 flex-grow-1">
                                                                                            <h6 className="fs-15 mb-0"><Link to="#">{item.file_type}</Link>
                                                                                            </h6>
                                                                                        </div>
                                                                                    </div>
                                                                                </td>
                                                                                <td>{item.descripcion}</td>
                                                                                <td>{formatFileSize(item.file_size)}</td>
                                                                                <td>{formatDate(item.uploaded_at)}</td>
                                                                                <td>
                                                                                    <UncontrolledDropdown direction='start'>
                                                                                        <DropdownToggle tag="a" className="btn btn-light btn-icon" id="dropdownMenuLink15" role="button">
                                                                                            <i className="ri-equalizer-fill"></i>
                                                                                        </DropdownToggle>
                                                                                        <DropdownMenu>
                                                                                            <DropdownItem onClick={downloadFile(item.upload)}><i className="ri-download-2-fill me-2 align-middle text-muted" />Descargar</DropdownItem>
                                                                                            <DropdownItem divider />
                                                                                            <DropdownItem onClick={onClickDelete(item)}><i className="ri-delete-bin-5-line me-2 align-middle text-muted" />Eliminar</DropdownItem>
                                                                                        </DropdownMenu>
                                                                                    </UncontrolledDropdown>
                                                                                </td>
                                                                            </tr>
                                                                        ))}
                                                                    </tbody>
                                                                </Table>
                                                            </div>
                                                            {/* <div className="text-center mt-3">
                                                                <Link to="#" className="text-success "><i
                                                                    className="mdi mdi-loading mdi-spin fs-20 align-middle me-2"></i>
                                                                    Load more </Link>
                                                            </div> */}
                                                        </Col>
                                                    {/* </Row> */}
                                                {/* </CardBody> */}
                                            </Card>
                                        </TabPane>
                                        <TabPane tabId="4">

                                            <Swiper
                                                slidesPerView={4}
                                                spaceBetween={24}
                                                mousewheel={true}
                                                className="cryptoSlider">
                                                    <SwiperSlide className='bg-primary p-3 rounded-3 text-white'>
                                                        <ParamInput 
                                                            franquicia={userProfileSession.franquicia.id} 
                                                            descripcion={'Este parametro es necesario para recibir informacion de los formularios de contacto generados por las propiedades en sitios de terceros. Es un campo obligatorio.'} 
                                                            name="contact_email" 
                                                            setter={setconfigVars}>
                                                        </ParamInput> 
                                                    </SwiperSlide>
                                                    <SwiperSlide  className='bg-primary p-3 rounded-3 text-white'>
                                                        <ParamInput franquicia={userProfileSession.franquicia.id} descripcion={'El numero de telefono es usado para mostrar en la informacion de contacto de la franquicia en sitios de terceros.'} name="contact_nro_tlf" setter={setconfigVars}>
                                                        </ParamInput>
                                                    </SwiperSlide>
                                                    <SwiperSlide  className='bg-primary p-3 rounded-3 text-white'>
                                                        <ParamInput franquicia={userProfileSession.franquicia.id} descripcion={'El numero de Whatsapp es utilizado para conectar los formularios de contacto de whatsapp con el asesor de la franquicia.'} name="contact_nro_whatsapp" setter={setconfigVars}>
                                                        </ParamInput> 
                                                    </SwiperSlide>
                                                    <SwiperSlide  className='bg-primary p-3 rounded-3 text-white'>
                                                        <ParamInput franquicia={userProfileSession.franquicia.id} descripcion={'Utilizado para carga de RRSS de la franquicia en la pagina web donde se muestren las propiedades.'} name="contact_telegram_username" setter={setconfigVars}>
                                                        </ParamInput> 
                                                    </SwiperSlide>
                                                    <SwiperSlide className='bg-primary p-3 rounded-3 text-white'>
                                                        <ParamInput franquicia={userProfileSession.franquicia.id}  descripcion={'Url de direccion de pagina web de la franquicia en caso de disponer de una.'} name="contact_website_url" setter={setconfigVars}>
                                                        </ParamInput> 
                                                    </SwiperSlide>
                                                    <SwiperSlide  className='bg-primary p-3 rounded-3 text-white'>
                                                        <ParamInput franquicia={userProfileSession.franquicia.id} descripcion={'Direccion de Facebook en RRSS'} name="contact_facebook_url" setter={setconfigVars}>
                                                        </ParamInput> 
                                                    </SwiperSlide>
                                                    <SwiperSlide  className='bg-primary p-3 rounded-3 text-white'>
                                                        <ParamInput franquicia={userProfileSession.franquicia.id} descripcion={'Direccion de LinkedIn en RRSS.'} name="contact_linkedin_url" setter={setconfigVars}>
                                                        </ParamInput> 
                                                    </SwiperSlide>
                                                    <SwiperSlide  className='bg-primary p-3 rounded-3 text-white'>
                                                        <ParamInput franquicia={userProfileSession.franquicia.id} descripcion={'Direccion de Twitter/X en RRSS.'} name="contact_x_url" setter={setconfigVars}>
                                                        </ParamInput> 
                                                    </SwiperSlide>
                                                    <SwiperSlide  className='bg-primary p-3 rounded-3 text-white'>
                                                        <ParamInput franquicia={userProfileSession.franquicia.id} descripcion={'Direccion de Youtube en RRSS.'} name="contact_youtube_url" setter={setconfigVars}>
                                                        </ParamInput> 
                                                    </SwiperSlide>
                                                    <SwiperSlide className='bg-primary p-3 rounded-3 text-white'>
                                                        <ParamInput franquicia={userProfileSession.franquicia.id} descripcion={'Perfil de Instagram en RRSS.'}  name="contact_instagram_url" setter={setconfigVars}>
                                                        </ParamInput> 
                                                    </SwiperSlide>
                                                    <SwiperSlide className='bg-primary p-3 rounded-3 text-white'>
                                                        <ParamInput franquicia={userProfileSession.franquicia.id} descripcion={'Perfil de Tik Tok en RRSS.'}  name="contact_tik_tok_url" setter={setconfigVars}>
                                                        </ParamInput> 
                                                    </SwiperSlide>
                                            </Swiper>  

                                        </TabPane>
                                    </TabContent>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default Settings;

