import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import './SelectDropdown.css';
import { ButtonGroup, DropdownItem, DropdownMenu, DropdownToggle, ListGroup, ListGroupItem, UncontrolledDropdown } from 'reactstrap';
import SimpleBar from "simplebar-react";

const Componente = ({ className, map, disabled, validation, optionsProps, isMulti = false, selectedOptionMap = null, ...props }, ref) => {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [isLoading, setisLoading] = useState(false);

  const toggleLoading = () => {
    setisLoading(!isLoading);
  };

  useImperativeHandle(ref, () => ({
    toggleLoading, setisLoading
  }));

  const onOptionClicked = (value) => {
    if (isMulti) {
      const updatedSelection = selectedOptions.some(option => option[optionsProps?.value] === value[optionsProps?.value])
        ? selectedOptions.filter(option => option[optionsProps?.value] !== value[optionsProps?.value])
        : [...selectedOptions, value];
      
      setSelectedOptions(updatedSelection);
      validation.setFieldValue(props?.name, updatedSelection.map(option => option[optionsProps?.value]));
    } else {
      setSelectedOptions([value]);
      validation.setFieldValue(props?.name, value[optionsProps?.value]);
    }
  };

  useEffect(() => {
    let valor = validation.values[props?.name];
    if (valor && optionsProps && optionsProps.items.length > 0) {
      if (isMulti && Array.isArray(valor)) {
        const preselectedItems = optionsProps.items.filter((item) => valor.includes(item[optionsProps?.value]));
        setSelectedOptions(preselectedItems);
      } else {
        let itemPreselected = optionsProps.items.find((i) => i[optionsProps?.value] === valor);
        if (itemPreselected) setSelectedOptions([itemPreselected]);
      }
    }
  }, []);

  const filteredOptions = optionsProps.items.filter(item => {
    const lowerCaseTerm = searchTerm.toLowerCase();
    return item[props?.searchTerm].toLowerCase().includes(lowerCaseTerm);
  });

  const getDisplayValue = () => {
    if (selectedOptions.length === 0) return `${!isLoading ? props?.label : 'Cargando...'}`;
    if (selectedOptions.length === 1) return selectedOptionMap ? selectedOptionMap(selectedOptions[0]) : selectedOptions[0][props?.searchTerm];
    return `${selectedOptions.length} selected`;
  };

  return (
    <UncontrolledDropdown className={`w-100 ${className}`}>
      <DropdownToggle tag="div" type="menu" className={` ${!selectedOptionMap || (selectedOptionMap && selectedOptions.length === 0) ? 'btn btn-primary': ''} w-100`} disabled={disabled || isLoading}>
        {getDisplayValue()}
        {
          !selectedOptionMap &&
          <i className="mdi mdi-chevron-down"></i>
        }
      </DropdownToggle>
      <DropdownMenu className='w-100'>
        <div className="dropdown-header noti-title">
          <div className="d-flex justify-content-sm-end">
            <div className="search-box ms-2 w-100">
              <input
                type="text"
                className="form-control"
                placeholder="Search"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <i className="ri-search-line search-icon"></i>
            </div>
          </div>
        </div>
        <SimpleBar style={{ maxHeight: "300px" }} className="mx-n3 px-3">
          <ListGroup>
            {filteredOptions.map((option, index) => (
              <DropdownItem
                key={index}
                onClick={() => onOptionClicked(option)}
                active={selectedOptions.some(item => item[optionsProps?.value] === option[optionsProps?.value])}
              >
                <ListGroupItem>
                  {isMulti && (
                    <input
                      type="checkbox"
                      checked={selectedOptions.some(item => item[optionsProps?.value] === option[optionsProps?.value])}
                      onChange={() => {}}
                      className="me-2"
                    />
                  )}
                  {map(option)}
                </ListGroupItem>
              </DropdownItem>
            ))}
          </ListGroup>
        </SimpleBar>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

const SelectDropdown = forwardRef(Componente);
SelectDropdown.displayName = 'SelectDropdown';
export default SelectDropdown;
