import React, { forwardRef, useRef, useImperativeHandle } from 'react';
import './InputDatetime.css';
import { Label } from 'reactstrap';
import Flatpickr from "react-flatpickr";
import { Spanish } from "flatpickr/dist/l10n/es";

export const InputDatetime = forwardRef(({ validation, name, label, placeholder, range = false, time = false, className,classNameTop }, ref) => {
    const flatpickrRef = useRef(); // Referencia interna para Flatpickr

    // Exponer métodos al padre a través del ref
    useImperativeHandle(ref, () => ({
        open: () => {
            flatpickrRef.current?.flatpickr.open(); // Método para abrir el calendario
        },
        close: () => {
            flatpickrRef.current?.flatpickr.close(); // Método para cerrar el calendario
        },
        reset: () => {
            validation.setFieldValue(name, range ? [] : null); // Resetea el valor
        },
        setDate: (date) => {
            validation.setFieldValue(name, range ? [date] : date); // Establece una nueva fecha
        }
    }));

    function dateToISOString(date) {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');

        return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
    }

    return (
        <div className={`mb-3 ${classNameTop}`}>
            <div>
                {label && <Label className="form-label mb-0">{label}</Label>}
                <Flatpickr
                    ref={flatpickrRef} // Asocia la referencia interna
                    value={validation.values[name]}
                    className={`form-control ${className}`}
                    placeholder={placeholder}
                    onChange={(val) => {
                        if (val[0]) {
                            let formattedDate;
                            if (range) {
                                formattedDate = [dateToISOString(val[0]), val[1] ? dateToISOString(val[1]) : undefined];
                            } else {
                                formattedDate = dateToISOString(val[0]);
                            }
                            validation.setFieldValue(name, formattedDate);
                        }
                    }}
                    options={{
                        altFormat: "F j, Y",
                        altInput: true,
                        enableTime: time,
                        locale: Spanish,
                        dateFormat: "Y-m-d H:i",
                        mode: range ? 'range' : 'single',
                    }}
                />
            </div>
        </div>
    );
});

InputDatetime.displayName = 'InputDatetime';
