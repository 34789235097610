import React from "react";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  UncontrolledCollapse,
  CardFooter,
  Button
} from "reactstrap";
import avatar1 from "../../../assets/images/users/user-dummy-img.jpg";

import { Draggable } from 'react-beautiful-dnd';
import { formatDateCustom, convertToAMPM } from "../../../helpers/api_helper";

const KanbanClienteComp = ({ column, handleDetalle, handleDragStart, refProvided, ...props }) => {
  const index = generateUniqueId()
  return (
    <div className="kanban-column">
      {/* Encabezado de la columna */}
      <div className="kanban-column-header">
        <Card className="mb-0">
          <Link
            to="#"
            className="card-header bg-primary"
            id={"leadDiscovered" + index}
          >
            <h5 className="card-title text-white text-uppercase fw-semibold mb-1 fs-15">
              {column.title}
            </h5>
            <p className="text-muted mb-0">
              <span className="fw-medium text-white-50">{column.count}</span>
            </p>
          </Link>
        </Card>
      </div>
      
      {/* Contenido con scroll vertical */}
      <div className="kanban-column-content">
        <UncontrolledCollapse
          toggler={"#leadDiscovered" + index}
          defaultOpen={true}
        >
          {column.child.map((client, subitemkey) => (
            <Draggable
              key={client.id}
              draggableId={`${client.id}`}
              index={subitemkey}
            >
              {(provided) => (
                <div
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                >
                  {clienteCard(subitemkey, client, handleDetalle)}
                </div>
              )}
            </Draggable>
          ))}
        </UncontrolledCollapse>
      </div>
    </div>
  );
};


export default KanbanClienteComp;

const generatedIds = new Set(); // Set para almacenar los IDs generados

function generateUniqueId() {
  const charset = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let id;

  do {
    id = Array.from({ length: 8 }, () =>
      charset.charAt(Math.floor(Math.random() * charset.length))
    ).join('');
  } while (generatedIds.has(id)); // Reintenta si el ID ya existe

  generatedIds.add(id); // Añade el nuevo ID al conjunto
  return id;
}

// Ejemplo de uso
const id1 = generateUniqueId();
const id2 = generateUniqueId();



export function clienteCard( subitemkey, client, handleDetalle) {
  const index = generateUniqueId()
  return <Card className="mb-1 bg-soft-warning">
    <CardBody>
      <Link
        to="#"
        className="d-flex align-items-center"
        id={"leadInnerDiscovered" + index + subitemkey}
      >
        <div className="flex-shrink-0">
          <img
            src={avatar1}
            alt=""
            className="avatar-xs rounded-circle" />
        </div>
        <div className="flex-grow-1 ms-3">
          <h6 className="fs-14 mb-1">{client.nombre}</h6>
          <p className="text-muted mb-0">
            {client.franquicia.nombre}
          </p>
          <p className="text-muted mb-0">
            <small className="badge badge-soft-primary">{`${formatDateCustom(client.created_at)} ${convertToAMPM(client.created_at)}`}</small>
          </p>
        </div>
      </Link>
    </CardBody>
    <UncontrolledCollapse
      className="border-top border-top-dashed"
      toggler={"#leadInnerDiscovered" + index + subitemkey}
      defaultOpen={client.isOpen === true}
    >
      <CardFooter className="hstack gap-2 bg-soft-primary bg-light">
        <Button onClick={() => handleDetalle(client)} color="info" className="btn-sm w-100">
          <i className="ri-question-answer-line align-bottom me-1"></i>{" "}
          Ver Detalle
        </Button>
      </CardFooter>
    </UncontrolledCollapse>
  </Card>;
}

