import React, { forwardRef, useCallback, useImperativeHandle, useState } from 'react';
import './InputModal.css';
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap';

const InputModal = forwardRef(({children,submitEvent,title, nocontrol = false, size = "modal-lg"},ref) => {
    const [modal, setModal] = useState(false);
    const [isLoading, setisLoading] = useState(false);

    const toggleLoading = useCallback(() => {
        setisLoading(!isLoading)
    }, [isLoading, setisLoading]);

    const toggle = useCallback(() => {
        if (modal) {
            setModal(false);
        } else {
            setModal(true);
        }
    }, [modal]);


    useImperativeHandle(ref, () => ({
        toggle: toggle,
        toggleLoading: toggleLoading,
        setModal
    }));

    return (
        <>
            <Modal
                isOpen={modal}
                toggle={toggle}
                centered
                scrollable={true}
                size={size}
                className="border-0"
                modalClassName='modal fade zoomIn'>
                <ModalHeader className="p-3 bg-soft-info" toggle={toggle}>
                    {title}
                </ModalHeader>
                <ModalBody className="modal-body">
                    {children}
                </ModalBody>
                {
                    !nocontrol &&
                    <div className="modal-footer">
                        <div className="hstack gap-2 justify-content-end">
                            <Button
                                disabled={isLoading}
                                type="button"
                                onClick={() => {
                                    setModal(false);
                                }}
                                className="btn-light">
                                    Cancelar
                            </Button>
                            <Button
                                disabled={isLoading}
                                onClick={() => submitEvent()} 
                                type="button" 
                                className="btn btn-success btn-load">
                                    {isLoading ?                                
                                        <span className="d-flex align-items-center">
                                            <span className="spinner-border flex-shrink-0" role="status">
                                                <span className="visually-hidden">Cargando...</span>
                                            </span>
                                            <span className="flex-grow-1 ms-2">
                                                Cargando...
                                            </span>
                                        </span> :
                                        <span>
                                            Aceptar
                                        </span>                        
                                    }
                            </Button>
                        </div>
                    </div>
                }
            </Modal>           
        </>
    );
});


InputModal.displayName = 'InputModal';
export { InputModal }