import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Card, CardHeader, CardBody, Nav, NavItem, NavLink, TabContent, TabPane, Row, Col, Label, Input, Button, Spinner } from 'reactstrap';
import { InputSelect } from '../../Forms/Inputs/InputSelect/InputSelect';
import { InputText } from '../../Forms/Inputs/InputText/InputText';
import { DocumentsDropzone } from './components/DocumentsDropzone';
import { InputTextarea } from '../../Forms/Inputs/inputTextarea/inputTextarea';
import { useFormik } from 'formik';
import { APIClient, crearFormData, extractInitials, paginationModel } from '../../../helpers/api_helper';
import { statusChoicesReservas } from '../../../helpers/options';
import { PublicCardTemplate } from '../../../Components/PublicCardTemplate/PublicCardTemplate';
import { createApiMethods } from '../../../helpers/api_calls';
import { CLIENTES, FRANQUICIAS, RESERVAS } from '../../../helpers/api_urls';
import { toast } from 'react-toastify';
import { Link, useNavigate } from 'react-router-dom';
import SelectDropdown from '../../Forms/Inputs/SelectDropdown/SelectDropdown';
// import { SelectDropdown } from '../../Forms/Inputs/SelectDropdown/SelectDropdown';

const MultiStepForm = ({item,callback,...props}) => {
  const history = useNavigate();

  const [activeTab, setActiveTab] = useState(1);
  const [isLoading, setisLoading] = useState(false);
  const dropZoneRef = useRef(null);
  const [clientesList, setclientesList] = useState(paginationModel);
  const [franquiciasList, setfranquiciasList] = useState([]);
  const apiClientes = useMemo(() => createApiMethods(CLIENTES), []);
  const apiReservas = useMemo(() => createApiMethods(RESERVAS), []);
  const api = new APIClient();
  const refCompradorDropdown = useRef(null)
  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  useEffect(()=>{
    refCompradorDropdown.current?.setisLoading(true)
    Promise.all([
        apiClientes.getAll({page_size:1000}),
        api.get(`${FRANQUICIAS}list_all/`,{page_size:1000}),
    ]).then((res)=>{
        setclientesList(res[0])
        setfranquiciasList(res[1])
    }).catch(toast.error).finally(()=>{
      refCompradorDropdown.current.setisLoading(false)
    })
},[])

  const mapClientes = (prop,index) =>{
    return <>
      <div className="d-flex align-items-center">
        <div className="flex-shrink-0 me-3">
          <div className="avatar-sm rounded">
              <div className="avatar-title border bg-light text-primary rounded text-uppercase fs-16">
                {extractInitials(prop.nombre)}
              </div>
          </div>
        </div>
        <div className="flex-grow-1">
          <h5 className="fs-14 mb-1">
            <Link
              to="#"
              className="text-dark">
              {" "}
              {prop.nombre}
            </Link>
          </h5>
          <p className="text-muted mb-0">
            <span className="fw-medium">
              {prop?.franquicia?.nombre}
            </span>
          </p>
        </div>
      </div>
    </>
  }

  const handleClick = () => () => {
  };

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
        propiedad: item?.id,
        franquicia_captadora: item.franquicia_.id,
        franquicia_compradora: undefined,
        monto_venta: item?.precio,
        porcentaje_reserva: 20,
        monto_reserva: 0,
        documento_reserva: undefined,
        status_reserva: 1,
        cedula_catastral: undefined,
        condiciones: 'Condiciones del contrato, incluyendo tiempo'
    },

    onSubmit: (values) => {
        let data = crearFormData(values)
        setisLoading(true)
        apiReservas.create(data).then((res)=>{
          if(callback){
            callback()
            validation.resetForm()
          }
          toast.success('La reserva fue creada exitosamente!')
          history(`/reservas/${res.id}`);
        }).catch(console.error).finally(()=>{
          setisLoading(false)
        })
    }
  });

  const reservaporcentaje = validation.values.porcentaje_reserva;
  useEffect(()=>{
    if(reservaporcentaje){
        validation.setFieldValue('monto_reserva',(validation.values.monto_venta * reservaporcentaje) / 100)
    }
  },[reservaporcentaje])

  return (
    <Card className={`${isLoading && 'bg-overlay-pattern'} bg-soft-primary`}>
      <CardBody>
        
        {
          (isLoading) &&
          <div style={{position: 'absolute',height:'100%',left: '50%', top: 350, zIndex:1, display: 'flex', flexDirection:'column'}}>
            <span className="fs-5 text">
              Cargando
            </span>
            <div className="text-center w-100">
              <Spinner color="primary"></Spinner>
            </div>
          </div>
        }

        <form className="vertical-navs-step" onSubmit={validation.handleSubmit}>
          <Row className="gy-5">
            <Col lg={3}>
              <Nav className="flex-column">
                {[1, 2, 3, 4].map((step) => (
                  <NavItem key={step}>
                    <NavLink
                      href="#"
                      className={activeTab === step ? 'active' : ''}
                      onClick={() => toggleTab(step)}>
                      <span className="step-title me-2">
                        <i className="ri-checkbox-circle-fill step-icon me-2"></i>
                        {step}
                      </span>
                      {['Datos de Reserva', 'Punta Captadora', 'Punta Compradora', 'Final'][step - 1]}
                    </NavLink>
                  </NavItem>
                ))}
              </Nav>
            </Col>
            <Col lg={9}>
              <TabContent activeTab={activeTab}>
                <TabPane tabId={1}>
                    <Card>
                        <CardBody>
                            <Row>
                                <Col md={4}>
                                    <InputSelect
                                        name="status_reserva"
                                        label="Status de la Reserva"
                                        validation={validation}
                                        optionsProps={{
                                        value: 'id',
                                        label: 'nombre',
                                        items: statusChoicesReservas || [],
                                    }}
                                    />                 
                                </Col>
                                <Col md={8}>
                                    <InputText
                                        name="cedula_catastral"
                                        label="Cedula Catastral"
                                        validation={validation}
                                    />                 
                                </Col>

                                <Col md={4}>
                                    <Col md={12}>
                                        <InputText
                                            className='mt-2'
                                            postfix='USD'
                                            name="monto_venta"
                                            placeholder="Monto de Venta"
                                            validation={validation}
                                        />                 
                                    </Col>
                                    <Col md={12}>
                                        <InputText
                                            className='mt-2'
                                            name="porcentaje_reserva"
                                            postfix=' % '
                                            placeholder="Porcentaje de Reserva"
                                            validation={validation}
                                        />                 
                                    </Col>
                                    <Col md={12}>
                                        <InputText
                                            className='mt-2'
                                            name="monto_reserva"
                                            postfix='USD'
                                            disabled
                                            placeholder="Monto de Reserva"
                                            validation={validation}
                                        />                 
                                    </Col>
                                </Col>
                                <Col md={8}>
                                    <DocumentsDropzone
                                        ref={dropZoneRef}
                                        externalClick={handleClick}
                                        name="documento_reserva"
                                        label="Documento de Reserva"
                                        isMultiple={true}
                                        validation={validation}>
                                    </DocumentsDropzone>
                                </Col>

                                <Col md={12}>
                                    <InputTextarea
                                        name="condiciones"
                                        label="Condiciones"
                                        validation={validation}>
                                    </InputTextarea>
                                </Col>
                            </Row>
                        </CardBody>                        
                    </Card>
                </TabPane>
                <TabPane tabId={2}>
                    {/* Punta Captadora */}
                    <Row>
                        <Col md={6}>
                            <Card>
                                <CardHeader>
                                    <div className="d-flex align-items-center">
                                        <div className="flex-grow-1">
                                            <h6 className="card-title mb-0">Punta Captadora</h6>
                                        </div>
                                    </div>
                                </CardHeader>
                                <CardBody>
                                    <Card className="card-body">
                                        <div className="d-flex mb-4 align-items-center">
                                            <div className="flex-shrink-0">
                                                <img src={item?.franquicia_?.image} alt="" className="avatar-sm rounded-circle" />
                                            </div>
                                            <div className="flex-grow-1 ms-2">
                                                <h5 className="card-title mb-1">{item?.franquicia_?.nombre}</h5>
                                                <p className="text-muted mb-0">{item?.franquicia_?.usuario_asignado?.full_name}</p>
                                            </div>
                                        </div>
                                        <Link to={`/franquicia/${item?.franquicia_.id}/`} className="btn btn-primary btn-sm">Ver Detalles</Link>
                                    </Card>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col md={6}>
                            <PublicCardTemplate
                                item={item} 
                                gridStyle={true}>
                            </PublicCardTemplate>
                        </Col>
                    </Row>
                </TabPane>
                <TabPane tabId={3}>
                    {/* Punta Compradora */}
                    <Card>
                        <CardHeader>
                            <div className="d-flex align-items-center">
                                <div className="flex-grow-1">
                                    <h6 className="card-title mb-0">Punta Compradora</h6>
                                </div>
                            </div>
                        </CardHeader>
                        <CardBody>
                            <SelectDropdown
                                ref={refCompradorDropdown}
                                map={mapClientes}
                                name         = "franquicia_compradora"
                                className='mt-2'
                                selectedOptionMap={mapClientes}
                                searchTerm={'nombre'}
                                label        = "Franquicia Comprador"
                                validation   = {validation}
                                optionsProps = {
                                    {
                                        value:'id',
                                        label:'nombre',
                                        items:franquiciasList || []
                                    }}>
                            </SelectDropdown> 
                        </CardBody>
                    </Card>
                </TabPane>
                <TabPane tabId={4}>
                    <Card className="mt-4">
                      <CardHeader className="border-bottom-dashed p-4">
                          <h5 className="card-title mb-0">Resumen de la Información</h5>
                      </CardHeader>
                      <CardBody className="p-4">
                          <Row className="g-3">
                              <Col md={6}>
                                  <h6 className="text-muted text-uppercase fw-semibold mb-3">Datos de Reserva</h6>
                                  <p className="text-muted mb-1">Status de la Reserva: {validation.values.status_reserva}</p>
                                  <p className="text-muted mb-1">Cédula Catastral: {validation.values.cedula_catastral}</p>
                                  <p className="text-muted mb-1">Monto de Venta: {validation.values.monto_venta} USD</p>
                                  <p className="text-muted mb-1">Porcentaje de Reserva: {validation.values.porcentaje_reserva} %</p>
                                  <p className="text-muted mb-1">Monto de Reserva: {validation.values.monto_reserva} USD</p>
                              </Col>
                              <Col md={6}>
                                  <h6 className="text-muted text-uppercase fw-semibold mb-3">Punta Captadora</h6>
                                  <p className="text-muted mb-1">Franquicia Captadora: {item?.franquicia_?.nombre}</p>
                                  <p className="text-muted mb-1">Usuario Asignado: {item?.franquicia_?.usuario_asignado?.full_name}</p>
                              </Col>
                              <Col md={6} className="mt-4">
                                  <h6 className="text-muted text-uppercase fw-semibold mb-3">Punta Compradora</h6>
                                  <p className="text-muted mb-1">Franquicia Compradora: {validation.values.franquicia_compradora?.nombre}</p>
                              </Col>
                              <Col md={12} className="mt-4">
                                  <h6 className="text-muted text-uppercase fw-semibold mb-3">Condiciones</h6>
                                  <p className="text-muted mb-1">{validation.values.condiciones}</p>
                              </Col>
                              <Col md={12} className="mt-4">
                                  <h6 className="text-muted text-uppercase fw-semibold mb-3">Documentos de Reserva</h6>
                                  <p className="text-muted mb-1">{validation.values.documento_reserva?.map(doc => doc.name).join(', ')}</p>
                              </Col>
                          </Row>
                      </CardBody>
                    </Card>
                </TabPane>
              </TabContent>
              <div className="d-flex justify-content-between mt-4">
                <Button color="light" onClick={() => toggleTab(activeTab - 1)} disabled={activeTab === 1}>
                  Anterior
                </Button>
                {
                  activeTab === 4 ?
                  <Button color="primary" onClick={() => validation.submitForm()} >
                    Finalizar
                  </Button>:
                  <Button color="primary" onClick={() => toggleTab(activeTab + 1)} disabled={activeTab === 4}>
                    Siguiente
                  </Button>
                }
              </div>
            </Col>
          </Row>
        </form>
      </CardBody>
    </Card>
  );
};

export default MultiStepForm;
