import React, { forwardRef, useRef, useImperativeHandle } from 'react';
import { Button, DropdownItem, DropdownMenu, DropdownToggle, Spinner, UncontrolledDropdown } from "reactstrap";

const FranquiciaFilter = forwardRef(
  ({ loadingFranquicia, franquiciasLit, handleFranquiciaSelection, className }, ref) => {
  const dropdownToggleRef = useRef();

  // Expone el método de clic al padre
  useImperativeHandle(ref, () => ({
    clickToggle: () => {
      if (dropdownToggleRef.current) {
        dropdownToggleRef.current.click();
      }
    },
  }));

  return (
    <UncontrolledDropdown>
      <DropdownToggle
        tag="button"
        className={`btn btn-light btn-sm ${className}`}
        innerRef={dropdownToggleRef} // Asocia el ref interno
      >
        <Button
          color="light"
          className="btn-sm"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          title="Filtro por Franquicias"
        >
          <i className="ri-account-box-fill" /> Franquicias
        </Button>
      </DropdownToggle>
      {/* Add DropdownMenu and DropdownItem components here */}
    </UncontrolledDropdown>
  );
});

FranquiciaFilter.displayName = 'FranquiciaFilter';

export default FranquiciaFilter;