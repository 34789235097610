import React, { useContext, useRef } from "react";
import { Button, Card, CardBody } from "reactstrap";
import { str_dt, tConvert, getTime } from "../../helpers/api_helper";
import { InputModal } from "../Forms/Inputs/InputModal/InputModal";
import { InputRating } from "../Forms/Inputs/InputRating/InputRating";
import Rating from "react-rating";
import Can, { AbilityContext } from "../../helpers/acl/Can";
import { VISITAAGENDADA } from "../../helpers/entities";
import { Link } from "react-router-dom";

function UpcomingEvents({reload,handleclick,...props}) {
  const ability = useContext(AbilityContext)
  const event = { 
    obj_type: VISITAAGENDADA,
    ...props.event
  }
  const modalRef = useRef(null);
  let endDate = props.event.end   ? new Date(props.event.end) : null;
  let dateStart = props.event.start   ? new Date(props.event.start) : null;
  let formattedEndDate = endDate
    ? new Date(endDate.setDate(endDate.getDate() - 1))
        .toLocaleDateString("en-GB", {
          day: "numeric",
          month: "short",
          year: "numeric",
        })
        .split(" ")
        .join(" ")
    : null;

  const startDate = props.event.start ? str_dt(props.event.start) : null;
  const endDateString = formattedEndDate ? ` to ${formattedEndDate}` : "";

  let startTime = props.event.start ? tConvert(getTime(props.event.start)) : null;
  const eventDateHasPassed = dateStart && dateStart < new Date();

  const calificarEvento = () => {
    modalRef.current.toggle()
  }

  const toggleReload = () => {
    modalRef.current.toggle()
    reload()
  }

  const upcommingEventClick = () => {
    handleclick(event)
  }

  return (
    <Card className="mb-3 card-upcomming-event" onClick={() => upcommingEventClick()}>
      <CardBody>
        <div className="d-flex mb-3 flex-column-reverse text-end">
          <div className="flex-grow-1">
            <span className="fw-medium">
              {startDate} - {startTime}
            </span>
          </div>
          {
            (props.event.tipo.id === 1) && 
            <div className="flex-shrink-0">
              <small className="badge badge-soft-primary ms-auto">
                {props.event.tipo.nombre} - {props.event.asesor.franquicia.nombre}
              </small>
            </div>
          }
        </div>
        <h6 className="card-title fs-16">{props.event.titulo}</h6>
        <p className="text-muted text-truncate-two-lines mb-0">
          {props.event.descripcion}
        </p>
        
        {
          props.event.propiedad ?
          <Link
            to={`/inmueble/${props.event.propiedad.id}`}
            className="btn btn-link w-100 text-center">
            {" "}
            Ver propiedad {props.event.propiedad.id}
          </Link>:
          <a
            href={props.event.link}
            target="_blank"
            rel="noreferrer"
            className="btn btn-link text-center w-100">
            {" "}
            Visitar Link
          </a>            
        }
        {/* Conditionally render the button based on event date */}
        <Can I="update" this={event} ability={ability}>
          {(eventDateHasPassed && (!props.event.rating)) && (
            <div className="d-flex w-100">
              <Button onClick={() => calificarEvento()} size="sm" block={true} className="btn-light">
                Calificar
              </Button>
            </div>
          )}
        </Can>

        {(props.event.rating) && (
          <div className="d-flex w-100">
            <Rating
              initialRating={props.event.rating}
              emptySymbol="mdi mdi-star-outline text-muted "
              readonly={true}
              fullSymbol="mdi mdi-star text-warning "/>            
          </div>
        )}

      </CardBody>
      <InputModal
        nocontrol={true}
        title={'Califica esta visita'}
        submitEvent={() => console.log('submit')}
        ref={modalRef}>
          <InputRating 
            idrated={props.event.id} 
            toggle={toggleReload}>
          </InputRating>
      </InputModal>
    </Card>
  );
}

export default UpcomingEvents;
