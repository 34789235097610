import React from 'react';
import { Button, Card, CardBody, CardFooter, UncontrolledCollapse } from "reactstrap";
import { Link } from 'react-router-dom';
import { convertToAMPM, formatDateCustom, formatDateFromNow } from '../../../../helpers/api_helper';

const SolicitudesExternas = ({ columnsContactRequest }) => {
  return (
    <div style={{ minWidth: '300px', marginRight: '15px' }}>
      <Card>
        <Link
          to="#"
          className={"card-header bg-primary"}
          id={"leadDiscovereddsd55"}
        >
          <h5 className="card-title text-uppercase fw-semibold mb-1 fs-15 text-white">
            Solicitudes de Contacto
          </h5>
          <p className="text-muted mb-0">
            <span className="fw-medium text-white-50">{columnsContactRequest.length}</span>
          </p>
        </Link>
      </Card>
      <UncontrolledCollapse
        toggler={"#leadDiscovereddsd55"}
        defaultOpen={true}>
        {columnsContactRequest.map((client, subitemkey) => (
          <React.Fragment key={subitemkey}>
            {CardClienteExterno(subitemkey, client)}
          </React.Fragment>
        ))}
      </UncontrolledCollapse>
    </div>
  );
};

export default SolicitudesExternas;

export function CardClienteExterno(subitemkey, client) {
  return <Card className={"mb-1 bg-soft-warning ribbon-box ribbon-fill ribbon-sm "} key={subitemkey}>
    <CardBody>
      <Link
        to="#"
        className="d-flex align-items-center"
        id={`leadInnerDiscoveredRequestChildrenRoot${subitemkey}`}
      >
        <div className="flex-grow-1 ms-3">
          <h6 className="fs-14 mb-1">{client.nombre} {client.apellido}</h6>
          <p className="text-muted mb-0">{formatDateFromNow(client.creado_en)}</p>
        </div>
      </Link>
    </CardBody>
    <UncontrolledCollapse
      className="border-top border-top-dashed"
      toggler={`#leadInnerDiscoveredRequestChildrenRoot${subitemkey}`}
      defaultOpen={client.isOpen === true}
    >
      <CardBody>
        <div className='d-flex flex-column w-100'>
          <div className='bg-light popover p-2 rounded-3'>
            <h6 className="fs-14 mb-1">{`${client.mensaje}`}</h6>
            <small className="badge badge-soft-primary">{`${formatDateCustom(client.creado_en)} ${convertToAMPM(client.creado_en)}`}</small>
          </div>
          <div className="d-flex w-100 mt-3">
            <div className="flex-grow-1 w-100">
              <Link to={`propiedad/${client?.propiedad.id}/`}>
                <h6 className="mb-0">{`${client?.propiedad?.nombre}`}</h6>
              </Link>
              <Button className='w-100 btn-sm btn-link btn-light'>{`${client?.origen}`}</Button>
            </div>
          </div>
          <div className="d-flex w-100">
            <div className="flex-grow-1 w-100">
              <h6 className="mb-0">{`${client?.medio}`}</h6>
              <h6 className="mb-0">{`${client?.valor_contacto}`}</h6>
            </div>
          </div>
        </div>
      </CardBody>
      <CardFooter className="hstack gap-2 bg-soft-primary bg-light">
        <button disabled className="btn btn-success btn-sm w-100"><i
          className=" ri-check-double-line align-bottom me-1"></i> Crear Cliente</button>
        <button disabled className="btn btn-primary btn-sm w-100"><i
          className=" ri-close-fill align-bottom me-1"></i>
          Eliminar</button>
      </CardFooter>
    </UncontrolledCollapse>
  </Card>;
}
