import classnames from "classnames";
import { useFormik } from "formik";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Button, ButtonGroup, Card, CardBody, Col, Container, DropdownToggle, Nav, NavItem, NavLink, Row, TabContent, TabPane, UncontrolledDropdown } from "reactstrap";
import SimpleBar from "simplebar-react";
import { useAuth } from "../../AuthContext";
import { AbilityContext } from "../../helpers/acl/Can";
import { APIClient } from "../../helpers/api_helper";
import { FRANQUICIAS, INTEGRACIONES } from "../../helpers/api_urls";
import { InputModal } from "../Forms/Inputs/InputModal/InputModal";
import { InputText } from "../Forms/Inputs/InputText/InputText";
import DynamicParamInput from "../Forms/Inputs/DynamicParamInput/DynamicParamInput";
import { toast } from "react-toastify";
import { WordpressIntegration } from "./WordpressIntegration";
import { FRANQUICIA_ACL } from "../../helpers/entities";
import { useParams } from "react-router-dom";

export const Integraciones = () => {
    const ability = useContext(AbilityContext);
    const [isLoading, setIsLoading] = useState(false);
    const dynamicRef = useRef(null);
    const { user } = useAuth();
    const api = new APIClient();
    const [integracionesResponse, setintegracionesResponse] = useState(null);
    const modalRef = useRef(null);
    const { idfranquicia } = useParams();
    const [franquicia, setfranquicia] = useState({});
    const [customverticalTab, setcustomverticalTab] = useState("1");
    const customtoggleVertical = (tab) => {
        if (customverticalTab !== tab) {
            setcustomverticalTab(tab);
        }
    };

    useEffect(()=>{

        if(idfranquicia){
            api.get(`${FRANQUICIAS}/${idfranquicia}`).then((res)=>{
                res.obj_type = FRANQUICIA_ACL
                setfranquicia(res)
            }).catch((e)=>{
                console.error(e)
            })
        }

        api.get(`${INTEGRACIONES}`).then((res)=>{
            setintegracionesResponse(res.results)
            customtoggleVertical(res.results.length - 1)
        }).catch((e)=>{
            console.error(e)
        })
    },[])

    const handleAddIntegracion = () => {
        modalRef.current.toggle()
    }


    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: '',
            urlname: '',
            api_key: '',
            api_secret: '',
            config_params: {}
        },
    });
    
    const submit = () => {
        let values = validation.values
        values.franquicia = idfranquicia ? idfranquicia : user.user.franquicia.id
        api.create(`${INTEGRACIONES}`, values ).then((res)=>{
            toast.success(res)
        }).catch((e)=>{
            console.error(e)
        })
    }
    
    return (
        <React.Fragment>
            <InputModal
                submitEvent={submit}
                ref={modalRef}
                size='xl'
                title={`Crear nueva integracion`}>
                    <Row>
                        <Col md={4}>
                            <SimpleBar >
                                <DynamicParamInput
                                    franquicia={idfranquicia? franquicia : user.franquicia}
                                    validation={validation}
                                    name='config_params'
                                    />
                            </SimpleBar>                            
                        </Col>
                        <Col md={4}>
                            <InputText 
                                placeholder={'Nombre'} 
                                validation={validation} 
                                name={'name'}>
                            </InputText>
                            <InputText 
                                placeholder={'Url'} 
                                validation={validation} 
                                name={'urlname'}>
                            </InputText>
                        </Col>
                        <Col md={4}>
                            <InputText 
                                placeholder={'Api Key'} 
                                validation={validation} 
                                name={'api_key'}>
                            </InputText>
                            <InputText 
                                placeholder={'Api Secret'} 
                                validation={validation} 
                                name={'api_secret'}>
                            </InputText>
                        </Col>
                        
                    </Row>
            </InputModal>


            <div className="page-content">
                <Container fluid>
                    <Card>
                        <CardBody>
                            <Row className="g-3">
                                <Col md={3}>
                                    <div className="search-box">
                                        <i className="ri-search-line search-icon"></i>
                                    </div>
                                </Col>
                                <div className="col-md-auto ms-auto">
                                    <div className="d-flex hastck gap-2 flex-wrap">
                                        <ButtonGroup style={{marginLeft: '5px'}}>
                                            <UncontrolledDropdown>
                                                <DropdownToggle tag="button" className="btn btn-light btn-sm">
                                                    <Button 
                                                        color="light" 
                                                        className="btn-sm" 
                                                        data-bs-toggle="tooltip" 
                                                        data-bs-placement="top" 
                                                        onClick={handleAddIntegracion}
                                                        title="Filtro por Franquicias"> <i className="ri-account-box-fill" /> Crear Integracion </Button>
                                                </DropdownToggle>
                                            </UncontrolledDropdown>
                                        </ButtonGroup>
                                    </div>
                                </div>
                                {/* Rest of the existing code */}
                            </Row>
                        </CardBody>
                    </Card>
                    {
                        integracionesResponse &&
                        <Card>
                            <CardBody>
                                <Row>
                                    <Col lg={3}>
                                        <Nav pills className="nav nav-pills flex-column nav-pills-tab custom-verti-nav-pills text-center">
                                            {
                                                integracionesResponse.map((i)=>{
                                                    
                                                    return <NavItem key={`navnav-${i.id}`}>
                                                        <NavLink
                                                            style={{ cursor: "pointer" }}
                                                            className={classnames({
                                                                "mb-2": true,
                                                                active: customverticalTab === i.id,
                                                            })}
                                                            onClick={() => {
                                                                customtoggleVertical(i.id);
                                                            }}>
                                                            <i className="ri-home-4-line d-block fs-20 mb-1"></i>
                                                            {i.name}
                                                        </NavLink>
                                                    </NavItem>
                                                })
                                            }
                                        </Nav>
                                    </Col>
                                    <Col lg={9}>
                                        <TabContent
                                            activeTab={customverticalTab}
                                            className="text-muted mt-3 mt-lg-0">
                                            {
                                                integracionesResponse.map((i)=>{
                                                    return <TabPane key={`navtab-${i.id}`} tabId={i.id}>
                                                        <WordpressIntegration franquicia={idfranquicia ? idfranquicia : user.user.franquicia.id} integration={i} />
                                                    </TabPane>
                                                })
                                            }
                                        </TabContent>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    }
                </Container>
            </div>
        </React.Fragment>
    )
}