import React, { useEffect, useMemo, useState } from "react";

import {
  Col,
  Container,
  Row,
  Card,
  CardBody,
  Input,
  Button,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  Form,
  ModalBody,
  ModalFooter,
  Label,
  ModalHeader,
  UncontrolledCollapse,
  Collapse,
  ButtonGroup,
} from "reactstrap";
import * as Yup from "yup";
import { formatDistanceToNow } from 'date-fns';
import Flatpickr from "react-flatpickr";
import { es } from 'date-fns/locale';
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import Select from "react-select";
import { getciudades, getestados, gettiposinmuebles, getDeals as onGetDeals } from "../../../store/actions";
import { useSelector, useDispatch } from "react-redux";
import { createApiMethods } from "../../../helpers/api_calls";
import { BUSQUEDAS, CLIENTES, CONTACTOS, FRANQUICIAS, MEDIOS_DE_CONTACTO } from "../../../helpers/api_urls";
import { useFormik } from "formik";
import { InputSelect } from "../../Forms/Inputs/InputSelect/InputSelect";
import { InputText } from "../../Forms/Inputs/InputText/InputText";
import { InputTextarea } from "../../Forms/Inputs/inputTextarea/inputTextarea";
import { InputMoney } from "../../Forms/Inputs/InputMoney/InputMoney";
import { InputNumberSelect } from "../../Forms/Inputs/InputNumberSelect/InputNumberSelect";
import { toast } from "react-toastify";
import LeadDiscover from "./leadDiscover";
import { useAuth } from "../../../AuthContext";
import { useDataManager } from "../../../DataContext";
import { SkeletonCard } from "../../../Components/SkeletonCard/SkeletonCard";

const CrmDeals = () => {
  const [franquiciasLit, setfranquiciasLit] = useState([]);
  const [loadingFranquicia, setloadingFranquicia] = useState(false);
  const [activeFilters, setActiveFilters] = useState({});
  const dataManager = useDataManager();

  const dispatch = useDispatch();
  const [createNewCliente, setcreateNewCliente] = useState(false);
  const apiBusquedas = useMemo(() => createApiMethods(BUSQUEDAS), []);
  const [BusquedasList, setBusquedasList] = useState([]);
  const [clienteslist, setclienteslist] = useState([]);
  const [showSelect, setshowSelect] = useState(true);
  const [isLoading, setisLoading] = useState();
  const [sortBy, setsortBy] = useState("Owner");
  const [modal, setModal] = useState(false);
  const apiClientes = useMemo(() => createApiMethods(CLIENTES), []);
  const [tiposMediosContacto, settiposMediosContacto] = useState([]);
  const APImediosdecontacto = createApiMethods(MEDIOS_DE_CONTACTO)
  const [franquiciasAPIResults, setfranquiciasAPIResults] = useState();
  const { user } = useAuth()
  const APIfranquicias = createApiMethods(FRANQUICIAS)
  const [isUpdateBusqueda, setisUpdateBusqueda] = useState(false);
  const [busquedaupdate, setbusquedaupdate] = useState();

  const colXS = 12 //1
  const colMD = 6 //2
  const colLG = 3 //3
  const colXL = 3 //4

  const [XS, setXS] = useState(colXS);
  const [MD, setMD] = useState(colMD);
  const [LG, setLG] = useState(colLG);
  const [XL, setXL] = useState(colXL);

  const handleEditarBusqueda = (busqueda) =>{
    console.log(busqueda)
    validationBusqueda.setValues(busqueda)
    validationBusqueda.setFieldValue('cliente',busqueda.cliente.id)
    setisUpdateBusqueda(true)
    setbusquedaupdate(busqueda)
    setshowSelect(false)
    toggle()
  }
  

  useEffect(() => {
    const fetchData = async () => {
    try {
        setloadingFranquicia(true)
        const data = await dataManager.getList(FRANQUICIAS);
        setfranquiciasLit(data.results);
        setloadingFranquicia(false)
    } catch (error) {
        setloadingFranquicia(false)
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [dataManager]);

  const toggle = () => {
    if (modal) {
      setModal(false);
    } else {
      setModal(true);
    }
  };

  function callBusquedas(filt = activeFilters){
    setisLoading(true)
    apiBusquedas.getAll({...filt,page_size:1000}).then((res)=>{
      setisLoading(false)

      setBusquedasList(res.results)
    })
  }

  useEffect(() => {
    callBusquedas()

    APImediosdecontacto.getAll({page_size: 1000}).then((res)=>{
      settiposMediosContacto(res.results)
    }).catch(console.log)

    APIfranquicias.getAll({page_size: 1000}).then((res)=>{
      setfranquiciasAPIResults(res.results)
    }).catch(console.log)

    apiClientes.getAll({page_size:1000}).then((res)=>{
      let mapresults = res.results.map((e)=> {
          return {
              label:e.nombre,
              value:e.id
          }
      })
      mapresults.push({
        label:'Crear nuevo cliente',
        value: '*'
      })
      let clientesListOptions = [
        {
          label:'Crear nuevo cliente',
          value: '*'
        },
        ...mapresults
      ]
      setclienteslist(clientesListOptions)
    })

  }, [])

  const updateBusqueda = () => {
    apiBusquedas.getAll({page_size:1000}).then((res)=>{
      setBusquedasList(res.results)
    })
  }

  const validationBusqueda = useFormik({
    enableReinitialize: true,
    initialValues: {
        descripcion  : null,
        cliente       : null,
        franquicia: user.isAdmin() ? null : user.user.franquicia.id,
        precio       : 0,
        tipo_inmueble: null,
        habitaciones : null,
        ciudad       : null,
        estado       : null,
        numero_banios: null,

        nombre: null,
        tipo: null,
        valor: null
    },
    validationSchema: Yup.object({
      descripcion: Yup.string()
        .typeError("Debe agregar uns descripcion.")
        .required("Este campos es obligatorio"),
      }),
    onSubmit: async (values) => {
        const formData = new FormData();
      
        for (const [key, value] of Object.entries(values)) {
          if (value !== null && value !== undefined && value !== "") {
            if (key !== "imagenes") {
              formData.append(key, value);
            } else {
              value.forEach((image, index) => {
                formData.append(`imagenes[${index}]`, image);
              });
            }
          }
        }
      
        if(isUpdateBusqueda){
          setisLoading(true)
          values.id = busquedaupdate.id
          delete values.estatus
          apiBusquedas.update(values).then((res)=>{
            validationBusqueda.resetForm()
            setisUpdateBusqueda(false)
            setshowSelect(true)
            setisUpdateBusqueda(undefined)
            setModal(false)
            toast.success("La busqueda fue actualizada con éxito", { autoClose: 3000 });
            callBusquedas()
          }).catch((err)=>{
            toast.error("Algo ocurrió mientras se acutalizaba el inmueble", { autoClose: 3000 });
          }).finally(()=>{
            setisLoading(false)
          })
        } else {
          setisLoading(true)
          for (const clave in values) {
            if (values[clave] === "" || values[clave] === null) {
              delete values[clave];
            }
          }
          apiBusquedas.create(values).then((res)=>{
            toast.success("La busqueda fue creada con éxito", { autoClose: 3000 });
            callBusquedas()
          }).catch((err)=>{
            toast.error("Algo ocurrió mientras se creaba la busqueda", { autoClose: 3000 });
          }).finally(()=>{
            setisLoading(false)
            setModal(false);
            
          })
        }
        validationBusqueda.resetForm()

      }
  });

  const { propiedades } = useSelector((state) => ({
    propiedades: state.Propiedades,
  }));

  const handleFranquiciaSelection = (franquicia) =>{
    let filter = {...activeFilters,franquicia: franquicia.id}
    setActiveFilters({...activeFilters,franquicia: franquicia.id})
    callBusquedas(filter)
  }

  useEffect(() => {
    dispatch(getciudades({page_size: 1000}));
    dispatch(getestados({page_size: 1000}));
    dispatch(gettiposinmuebles({page_size: 1000}));
  }, [dispatch]);

  function formatDate(date) {
    const distance = formatDistanceToNow(new Date(date), { locale: es, addSuffix: true });
    return `${distance.charAt(0).toUpperCase()}${distance.slice(1)}`;
  }

  function handleSelectSingle(clienteSelect) {
    console.log(clienteSelect)
    validationBusqueda.setFieldValue('cliente',clienteSelect)
  }

  document.title = "Busquedas | Inmoapp";
  const extras = <UncontrolledCollapse
    className="border-top border-top-dashed"
    toggler={"#extrainputs"}
    defaultOpen={false}>
    <CardBody>
      <Row>
        <Col lg={6}>
          <InputNumberSelect
            name="numero_banios"
            label="Baños"
            validation={validationBusqueda}>
          </InputNumberSelect>
        </Col>
        <Col lg={6}>
          <InputNumberSelect
            name="habitaciones"
            label="Habitaciones"
            validation={validationBusqueda}>
          </InputNumberSelect>
        </Col>
        <Col lg={6}>
          <InputMoney
            label="Presupuesto"
            name="precio"
            validation={validationBusqueda}>
          </InputMoney>
        </Col>
        <Col lg={6}>
          <InputSelect
            name="tipo_inmueble"
            label="Tipo inmueble"
            validation={validationBusqueda}
            optionsProps={{
              value: 'id',
              label: 'nombre',
              items: propiedades.tiposinmuebles.results || []
            }}>
          </InputSelect>
        </Col>
        <Col lg={6}>
          <InputSelect
            name="estado"
            label="Estado"
            validation={validationBusqueda}
            optionsProps={{
              value: 'id',
              label: 'nombre',
              items: propiedades.estados.results || []
            }}>
          </InputSelect>
        </Col>
        <Col lg={6}>
          <InputSelect
            name="ciudad"
            label="Ciudad"
            validation={validationBusqueda}
            optionsProps={{
              value: 'id',
              label: 'nombre',
              items: propiedades.ciudades.results || []
            }}>
          </InputSelect>
        </Col>
      </Row>
    </CardBody>
  </UncontrolledCollapse>;

  const clientes = <Collapse
    isOpen={createNewCliente}>
    <CardBody>
      <Row>
        <Col lg={12}>
            <div className="bg-light p-2 modal-header mb-2">
                <h5 className="modal-title">Datos de cliente</h5>
                <button onClick={() => setcreateNewCliente(false)} type="button" className="btn-close" aria-label="Close"></button>
            </div>             
        </Col>        
        <Col lg={12}>
          <InputText
            name="nombre"
            label="Nombre"
            validation={validationBusqueda}>
          </InputText>       
          <InputSelect
            name         = "tipo"
            label        = "Tipo de contacto"
            validation   = {validationBusqueda}
            optionsProps = {
                {
                    value:'id',
                    label:'nombre',
                    items:tiposMediosContacto || []
                }}>
          </InputSelect>      
          <InputText
            name="valor"
            format='phone'
            label="Nro"
            validation={validationBusqueda}>
          </InputText>  
        </Col>
        <Col lg={12}>
          <InputSelect
              name         = "franquicia"
              label        = "Franquicia"
              validation   = {validationBusqueda}
              optionsProps = {
                  {
                      value:'id',
                      label:'nombre',
                      items:franquiciasAPIResults || []
                  }}>
          </InputSelect>
        </Col>        
      </Row>
    </CardBody>
  </Collapse>;

  const loadingSkeletons =  () => {
    const skeletons = [];

    for (let i = 0; i < 3; i++) {
      skeletons.push(
        <Col key={i} sm={XS} md={MD} lg={LG} xl={XL} xxl={XL}>
          <SkeletonCard gridStyle={true}></SkeletonCard>
        </Col>
      );
    }

    return skeletons;
  }


  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Busquedas" pageTitle="Busquedas de Clientes" />
          <Card>
            <CardBody>
              <Row className="g-3">
                <Col md={3}>
                  <div className="search-box">
                    <Input
                      type="text"
                      className="form-control search"
                      placeholder="Buscar"
                    />
                    <i className="ri-search-line search-icon"></i>
                  </div>
                </Col>
                <div className="col-md-auto ms-auto">
                  <div className="d-flex hastck gap-2 flex-wrap">
                    <ButtonGroup style={{marginLeft: '5px'}}>
                      <UncontrolledDropdown>
                          <DropdownToggle tag="button" className="btn btn-light btn-sm">
                              <Button 
                                  color="light" 
                                  className="btn-icon btn-sm" 
                                  data-bs-toggle="tooltip" 
                                  data-bs-placement="top" 
                                  title="Filtro por Franquicias"> <i className="ri-account-box-fill" /> </Button>
                          </DropdownToggle>
                          <DropdownMenu>
                              <div>
                                  {
                                      loadingFranquicia &&
                                      <div className="text-center">
                                        <div className="spinner-border text-primary" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                      </div>
                                  }
                                  {
                                      franquiciasLit && franquiciasLit.map((i)=>(
                                          <DropdownItem 
                                              key={i.id} 
                                              onClick={() => handleFranquiciaSelection(i)}>
                                                  <img src={i.first_image_url} alt="" className="avatar-xxs rounded-circle" />
                                                  {i.nombre}
                                          </DropdownItem>
                                      ))
                                  }
                              </div>
                          </DropdownMenu>
                      </UncontrolledDropdown>
                    </ButtonGroup>                    
                    <button className="btn btn-primary" onClick={toggle}>
                      <i className="ri-add-fill align-bottom me-1"></i> 
                      {
                        isUpdateBusqueda ?
                        'Actualizar busqueda' : 'Crear busqueda'
                      }
                      
                    </button>
                  </div>
                </div>
              </Row>
            </CardBody>
          </Card>

          <Row className="row-cols-md-2 row-cols-1">
            {
              isLoading ? 
              loadingSkeletons():
              <LeadDiscover handlerEditarBusqueda={handleEditarBusqueda} updateBusquedas={updateBusqueda} busquedas={BusquedasList} index={0} />
            }
          </Row>
        </Container>
      </div>


      <Modal id="adddeals" isOpen={modal} toggle={toggle} centered>
        <ModalHeader className="bg-light p-3" toggle={toggle}>
          Crear Busqueda
        </ModalHeader>
        <Form>
          {(validationBusqueda) &&
            <ModalBody className="modal-body">
              <Row>

                
                {
                  (clienteslist.length > 0 && createNewCliente === false) &&
                  <Col lg={12} md={12}>
                    <Label htmlFor="choices-single-default" className="form-label text-muted">Cliente</Label>       

                    { (isUpdateBusqueda && !showSelect) &&
                      <div className="d-flex w-100 justify-content-between">

                        <div>
                            <h5 
                              className="card-title mb-0 truncate" 
                              style={{lineHeight:'2rem'}}>
                                {
                                  busquedaupdate.cliente.nombre
                                }
                            </h5>
                        </div>
                        <div>
                          <a href="#" onClick={(event) => {
                            event.preventDefault();
                            setshowSelect(true)
                          }}>Cambiar</a>
                        </div>
                      </div>
                    }         
                    {
                      showSelect &&
                        <Select
                            className="mb-3"
                            name="cliente"
                            isClearable={false}
                            placeholder={'Seleccione un cliente'}
                            isSearchable
                            onChange={(e) => {
                                if(e?.value !== '*'){
                                  handleSelectSingle(e.value);
                                } else {
                                  setcreateNewCliente(true)
                                }
                            }}
                            options={clienteslist}/>
                    }                                        
                  </Col>
                }


                  {clientes}
                  <Col lg={12}>
                      <InputTextarea
                          name="descripcion"
                          label="Que busca este cliente?"
                          validation={validationBusqueda}>
                      </InputTextarea>   
                  </Col>
                  <Col lg={12}>
                    <h6 id="extrainputs" className="mb-1">Agregar campos <small className="text-muted">complementarios</small> a la busqueda</h6>
                  </Col>
              </Row>


              {extras}
            </ModalBody>
          }
          <ModalFooter>
            <Button
              type="button"
              color="light"
              onClick={() => {
                setModal(false);
              } }
            >
              Cerrar
            </Button>
            <Button
              onClick={validationBusqueda.submitForm}
              color="success">
              <i className="ri-save-line align-bottom me-1"></i> 
              {
                isUpdateBusqueda ?
                'Actualizar':'Guardar'
              }
              
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </React.Fragment>
  );
};

export default CrmDeals;
