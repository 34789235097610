import React, { useEffect, useRef, useState } from 'react';
import { Container, Spinner, Card, CardBody, Row, Col, Accordion, AccordionItem, Collapse } from "reactstrap";
import { APIClient } from '../../../helpers/api_helper';
import { useDataManager } from '../../../DataContext';
import { useFormik } from 'formik';

import KanbanBoardHeader from './components/KanbanBoardHeader';
import KanbanColumns from './components/KanbanColumns';
import SolicitudesExternas, { CardClienteExterno } from './components/SolicitudesExternas';
import DetalleClienteSidebar from '../../Clientes/DetalleClienteSidebar';
import ColumnSettingsModal from '../../../Components/ColumnSettingsModal';
import'./KanbanBoard.css'
import { mockKanban } from './mock';
import classnames from 'classnames'
import { clienteCard } from './KanbanClienteComp';

const KanbanBoard = () => {
  const [filterKanban, setfilterKanban] = useState({});
  const [columns, setColumns] = useState([]);
  const [filteredColumns, setFilteredColumns] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const api = new APIClient();
  const clienteCardRef = useRef(null);
  const [clienteSeleccion, setClienteSeleccion] = useState(null);
  const dataManager = useDataManager();
  const [franquiciasLit, setfranquiciasLit] = useState([]);
  const [loadingFranquicia, setloadingFranquicia] = useState(false);
  const [columnsContactRequest, setcolumnsContactRequest] = useState([]);

  const [visibleColumns, setVisibleColumns] = useState([2, 3, 4, 6, 7]);
  const [defaultColumns, setDefaultColumns] = useState([]);
  const [isColumnSettingsOpen, setIsColumnSettingsOpen] = useState(false);

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      date_range: undefined
    },
  });

  useEffect(() => {
    fetchClientes();
  }, []);

  useEffect(() => {
    filterClientes();
  }, [searchTerm, columns, visibleColumns]);

  const fetchClientes = async () => {
    setIsLoading(true);
    try {
      setColumns(mockKanban);
      if (visibleColumns.length === 0) {
        const columnIds = mockKanban.map(col => col.id);
        setVisibleColumns(columnIds);
        setDefaultColumns(columnIds);
      }
    } catch (error) {
      console.error('Error fetching clientes:', error);
    } finally {
      setIsLoading(false);
    }

    try {
      const responseSolicitudes = await api.get('/solicitudcontacto/?page_size=1000');
      setcolumnsContactRequest(responseSolicitudes.results);
    } catch (error) {
      console.error('solicitudcontacto', error);
    }
  };

  const filterClientes = () => {
    const filtered = columns
      .filter(column => visibleColumns.includes(column.id))
      .map(column => ({
        ...column,
        child: column.child.filter(client =>
          client?.nombre?.toLowerCase().includes(searchTerm.toLowerCase())
        ),
        count: column.child.filter(client =>
          client?.nombre?.toLowerCase().includes(searchTerm.toLowerCase())
        ).length
      }));
    setFilteredColumns(filtered);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleFranquiciaSelection = (franq) => {
    if (franq) {
      setfilterKanban({ franquicia: franq.id })
    } else {
      setfilterKanban({})
    }
  };

  const toggleColumnSettings = () => {
    setIsColumnSettingsOpen(!isColumnSettingsOpen);
  };

  const toggleDefaultColumn = (columnId) => {
    setDefaultColumns(prev =>
      prev.includes(columnId)
        ? prev.filter(id => id !== columnId)
        : [...prev, columnId]
    );
  };

  const applyColumnSettings = () => {
    setVisibleColumns(defaultColumns);
    setIsColumnSettingsOpen(false);
  };

  const onDragEnd = async (result) => {
    const { draggableId, source, destination } = result;

    if (!destination || source.droppableId === destination.droppableId) {
      return;
    }

    const sourceColumn = columns.find(col => col.id === parseInt(source.droppableId));
    const destinationColumn = columns.find(col => col.id === parseInt(destination.droppableId));
    const movedClient = sourceColumn.child.find(client => client.id === parseInt(draggableId));

    setColumns(prevColumns => {
      const updatedColumns = prevColumns.map(column => {
        if (column.id === sourceColumn.id) {
          return {
            ...column,
            child: column.child.filter(client => client.id !== movedClient.id),
            count: column.count - 1
          };
        }
        if (column.id === destinationColumn.id) {
          return {
            ...column,
            child: [...column.child, { ...movedClient, status: destinationColumn.id }],
            count: column.count + 1
          };
        }
        console.log(column)
        return column;
      });
      return updatedColumns;
    });

    try {
      await api.create(`/clientes/${movedClient.id}/change_status/`, { status: destinationColumn.id });
    } catch (error) {
      console.error('Error updating client status:', error);
      fetchClientes();
    }
  };

  const handleSelection = (client) => {
    setClienteSeleccion(client);
    clienteCardRef.current.toggle();
  };

  const [activeAccordion, setActiveAccordion] = useState(null); // Variable para controlar el acordeón activo

  const toggleAccordion = (index) => {
    setActiveAccordion(activeAccordion === index ? null : index); // Alterna entre abrir/cerrar
  };

  
  return (
    <React.Fragment>
      <div className="page-content" style={{ paddingTop: '80px' }}> {/* Ajuste de padding */}
        <Container fluid>
          <div className="">
            <Card>
              <CardBody>
                <KanbanBoardHeader
                  searchTerm={searchTerm}
                  onSearchChange={handleSearchChange}
                  toggleColumnSettings={toggleColumnSettings}
                  loadingFranquicia={loadingFranquicia}
                  franquiciasLit={franquiciasLit}
                  handleFranquiciaSelection={handleFranquiciaSelection}
                  validation={validation}
                />
              </CardBody>
            </Card>
          </div>

          {isLoading ? (
            <Row>
              {[...Array(4)].map((_, i) => (
                <Col key={i} md={3}>
                  <div className='w-100 mt-3 p-3 text-center'>
                    <Spinner />
                  </div>
                </Col>
              ))}
            </Row>
          ) : (
            <div>
              <div style={{ display: 'flex', overflowX: 'auto' }} className="desktop-only">
                <SolicitudesExternas columnsContactRequest={columnsContactRequest} />
                <KanbanColumns
                  columns={columns}
                  filteredColumns={filteredColumns}
                  onDragEnd={onDragEnd}
                  handleSelection={handleSelection}
                />
              </div>
            </div>
          )}
        </Container>

        {/* Componente vacío para dispositivos móviles */}
        <div className="mobile-only">
        <Card>
            <Accordion
              className="custom-accordionwithicon accordion-flush accordion-fill-secondary"
              id="accordionFill2"
            >
              <AccordionItem>
                <h2 className="accordion-header" id="accordionHeader-solicitudes-externas">
                  <button
                    className={classnames("accordion-button", {
                      collapsed: activeAccordion !== "solicitudes-externas", // Comparación con un ID único
                    })}
                    type="button"
                    onClick={() => toggleAccordion("solicitudes-externas")} // Alterna con un ID único
                    style={{ cursor: "pointer" }}
                  >
                    Solicitudes Externas
                  </button>
                </h2>
                <Collapse
                  isOpen={activeAccordion === "solicitudes-externas"} // Solo se abre si el ID coincide
                  className="accordion-collapse"
                  id="accordionCollapse-solicitudes-externas"
                >
                  <div className="accordion-body">
                    {columnsContactRequest.map((request, index) => (
                      CardClienteExterno(index,request)
                    ))}
                  </div>
                </Collapse>
              </AccordionItem>

              {filteredColumns.map((column, key) => (
                <AccordionItem key={key}>
                  <h2 className="accordion-header" id={`accordionHeader-${key}`}>
                    <button
                      className={classnames("accordion-button", {
                        collapsed: activeAccordion !== key,
                      })}
                      type="button"
                      onClick={() => toggleAccordion(key)} // Manejo del evento
                      style={{ cursor: "pointer" }}
                    >
                      {column.title}
                    </button>
                  </h2>
                  <Collapse
                    isOpen={activeAccordion === key} // Solo el acordeón activo estará abierto
                    className="accordion-collapse"
                    id={`accordionCollapse-${key}`}
                  >
                    <div className="accordion-body">
                      {/* Contenido dinámico del acordeón */}
                      {column.child.map((client, subitemkey) => (
                        clienteCard(subitemkey, client, handleSelection)

                      ))}
                    </div>
                  </Collapse>
                </AccordionItem>
              ))}
            </Accordion>
          </Card>

        </div>
        
        <DetalleClienteSidebar
          reload={fetchClientes}
          cliente={clienteSeleccion}
          ref={clienteCardRef}
        />
      </div>

      <ColumnSettingsModal
        isOpen={isColumnSettingsOpen}
        toggle={toggleColumnSettings}
        columns={columns}
        defaultColumns={defaultColumns}
        toggleDefaultColumn={toggleDefaultColumn}
        applyColumnSettings={applyColumnSettings}
      />


    </React.Fragment>
  );
};

export default KanbanBoard;
