import React, { useCallback, useEffect, useState } from 'react';
import './ModalAdjuntarFile.css';
import { Button, Col, FormFeedback, Input, Label, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import * as Yup from "yup";
import { InputText } from '../../../../../Forms/Inputs/InputText/InputText';
import { getLoggedinUser } from '../../../../../../helpers/api_helper';
import { createApiMethods } from '../../../../../../helpers/api_calls';
import { DOCUMENTS } from '../../../../../../helpers/api_urls';


// Import React FilePond
import { FilePond,  registerPlugin } from 'react-filepond';
// Import FilePond styles
import 'filepond/dist/filepond.min.css';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import { toast } from 'react-toastify';



export const ModalAdjuntarFile = ({modalState, toggleModal, editObject}) => {
    const userProfileSession = getLoggedinUser();
    const [userAvailable, setuserAvailable] = useState();
    const [userProfileLoaded, setUserProfileLoaded] = useState(false);
    const dispatch = useDispatch();
    const [isLoading, setisLoading] = useState();
    const apiDocuments = createApiMethods(DOCUMENTS)
    const [files, setFiles] = useState([]);


    useEffect(() => {
        if (userProfileSession && !userProfileLoaded) {
          setuserAvailable(userProfileSession);
          setUserProfileLoaded(true);
        }
    }, [userProfileSession, userProfileLoaded]);

    const onSubmitHandler = (values) => {
        const formData = new FormData();
        formData.append('user', values.user);
        formData.append('upload', values.upload);
        formData.append('descripcion', values.descripcion);
        setisLoading(true)
        apiDocuments.create(formData,{
            headers: {
                "Content-Type": "multipart/form-data"
            },
        }).then((res)=>{
            toast.success("El archivo se ha cargado con éxito", { autoClose: 3000 });
        }).catch((err)=> toast.error("Ha ocurrido un error intentando cargar el archivo.", { autoClose: 3000 }))
        .finally(()=>{
            setisLoading(false)
            toggleModal()
            validation.resetForm()
            setFiles([])
        })

    };
    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            user               : userAvailable ? userAvailable.id : null,
            upload              : null,
            descripcion              : null,
        },
        validationSchema: Yup.object({
            descripcion: Yup.string().required("Ingrese la descripcion del archivo"),
            upload: Yup.mixed().required('Archivo requerido'),
        }),
        onSubmit: onSubmitHandler,
    });


    
    const toggle = ()=> ()=>{
        toggleModal()
    }

    return (
        <>
            <Modal
                isOpen={modalState}
                toggle={toggleModal}
                centered
                size="sm"
                className="border-0"
                modalClassName='modal fade zoomIn'>
                <ModalHeader className="p-3 bg-soft-info" toggle={toggleModal}>
                    Adjuntar Archivos
                </ModalHeader>
                <ModalBody className="modal-body">
                    <Row>
                        <Col lg={12}>
                            <InputText
                                name="descripcion"
                                label="Descripcion de archivo"
                                validation={validation}
                            ></InputText>   
                        </Col>
                        <Col lg={12}>
                            <FilePond
                                files={files}
                                allowMultiple={false}
                                maxFiles={1}
                                onupdatefiles={fileItems => {
                                    if(fileItems && fileItems.length > 0){
                                        setFiles(fileItems.map(fileItem => fileItem.file));
                                        validation.setFieldValue("upload", fileItems[0].file);
                                    }
                                }}
                                name="files"
                                labelIdle="Arrastra tus archivos aquí o <span class='filepond--label-action'>Busca</span> en tu dispositivo"
                                className="filepond filepond-input-multiple"
                            />   
                        {validation.errors["upload"] ? (
                            <FormFeedback type="invalid" className="d-block"><div>{validation.errors["upload"]}</div></FormFeedback>
                        ) : null}                         
                        </Col>
                    </Row>
                <div className="modal-footer d-flex justify-content-between">
                    <Button disabled={isLoading} color="secondary" className="btn btn-light" onClick={toggle()}>
                        Cancelar
                    </Button>
                    <Button
                        color="primary"
                        onClick={() => validation.submitForm()}
                        className="btn btn-primary"
                        disabled={isLoading}>
                            {
                                ( isLoading) ? (
                                    <span className="d-flex align-items-center">
                                        <span className="flex-grow-1 me-2">
                                            Cargando...
                                        </span>
                                    </span>
                                ) : (
                                    <span className="d-flex align-items-center">
                                        <span className="flex-grow-1 me-2">
                                            Guardar
                                        </span>
                                    </span>
                                )
                            }
                    </Button>
                </div>
                </ModalBody>
            </Modal>
        </>
    );
};
