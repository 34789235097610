import React, { useContext, useMemo, useRef, useState } from "react";
import { Card, CardBody, CardFooter, Col, Container, Row, Button, UncontrolledDropdown, DropdownToggle, DropdownItem, DropdownMenu } from "reactstrap";
import { Link } from "react-router-dom";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import { TablaCrud } from "../../../Components/TablaCrud/TablaCrud";
import { createApiMethods } from "../../../helpers/api_calls";
import { RESERVAS } from "../../../helpers/api_urls";
import { ReservasColumns as OriginalReservasColumns } from "./ReservasColumns";
import './ReservasModule.css';
import { useFormik } from "formik";
import { RESERVA_ACL } from "../../../helpers/entities";
import Can, { AbilityContext } from "../../../helpers/acl/Can";

// Extend the existing columns with an additional column for actions
const ReservasModule = () => {
    const ability = useContext(AbilityContext)

    const ReservasColumns = [
      ...OriginalReservasColumns,
  
      {
          Header: " ",
          Cell: (cellProps) => {
            return (
              <UncontrolledDropdown>
                <DropdownToggle
                  className="btn btn-soft-secondary btn-sm"
                  tag="button">
                  <i className="ri-more-fill" />
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-end">
                  <DropdownItem
                    href={`/reservas/${cellProps.row.original.id}`}>
                    <i className="ri-eye-fill align-bottom me-2 text-muted"></i>{" "}
                    Ver Detalles
                  </DropdownItem>
                      <Can I="update" this={cellProps.row.original} ability={ability}>
                        <DropdownItem
                          href={`/reservas/${cellProps.row.original.id}/editar`}>
                          <i className="ri-pencil-fill align-bottom me-2 text-muted"></i>{" "}
                          Editar
                        </DropdownItem>
                      </Can>  
                </DropdownMenu>
              </UncontrolledDropdown>
            );
          },
        }
  ];


    const apiReservas = useMemo(() => createApiMethods(RESERVAS), []);
    const [recursoSeleccionado, setrecursoSeleccionado] = useState(null);
    const tablaCrudRef = useRef(null);

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {},
        onSubmit: (values) => {},
    });

    const formularioTabla = (
        <Card>
            <CardBody>
                <Row></Row>
            </CardBody>
            <CardFooter>
                <Row>
                    <Col lg={12}></Col>
                </Row>
            </CardFooter>
        </Card>
    );

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Lista de Reservas" pageTitle="Bandeja de Reservas" />
                    <TablaCrud 
                        nombreSingular='Reserva'
                        nombrePlural='Reservas'
                        validation={validation}
                        ref={tablaCrudRef}
                        add={false}
                        formularioModal={formularioTabla}
                        edit={false}
                        setResourceItem={(item) => setrecursoSeleccionado(item)}
                        columns={ReservasColumns} 
                        ACLPrefix={RESERVA_ACL}
                        apiLink={apiReservas} />
                </Container>
            </div>
        </React.Fragment>
    );
}

export default ReservasModule;
