import React, { useContext, useEffect, useMemo, useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Tooltip,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Badge,
} from "reactstrap";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import classnames from "classnames";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import SwiperCore, { FreeMode, Navigation, Thumbs } from "swiper";
import { Link, useParams } from "react-router-dom";
import { createApiMethods } from "../../../helpers/api_calls";
import { PROPIEDADESPUBLICAS } from "../../../helpers/api_urls";
import { toast } from "react-toastify";
import { useAuth } from "../../../AuthContext";
import { AbilityContext } from "../../../helpers/acl/Can";

SwiperCore.use([FreeMode, Navigation, Thumbs]);

function EcommerceProductDetail() {
  const { user } = useAuth();
  const { idinmueble } = useParams();
  const [inmueble, setInmueble] = useState({});
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [ttop, setttop] = useState(false);
  const [customActiveTab, setcustomActiveTab] = useState("1");
  const toggleCustom = (tab) => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab);
    }
  };
  const apiPropiedadesPublicas = useMemo(() => createApiMethods(PROPIEDADESPUBLICAS), []);

  useEffect(() => {
    apiPropiedadesPublicas
      .getDetails(idinmueble)
      .then((p) => {
        setInmueble(p);
      })
      .catch(toast.error);
  }, [idinmueble, apiPropiedadesPublicas]);

  document.title = "Detalles de Inmueble | Inmoapp";
  const ability = useContext(AbilityContext);

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb title="Detalles de Inmueble" pageTitle="Inmueble" />
        <Row>
          <Col lg={12}>
            <Card>
              <CardBody>
                <Row className="gx-lg-5">
                  <Col xl={4} md={8} className="mx-auto">
                    <div className="product-img-slider sticky-side-div">
                      <Swiper
                        navigation={true}
                        thumbs={{ swiper: thumbsSwiper }}
                        className="swiper product-thumbnail-slider p-2 rounded bg-light"
                      >
                        <div className="swiper-wrapper">
                          {inmueble.imagenes && inmueble.imagenes.length > 0 ? (
                            inmueble.imagenes.map((img, key) => (
                              <SwiperSlide key={key}>
                                <img src={img.image} alt="" className="img-fluid d-block" />
                              </SwiperSlide>
                            ))
                          ) : (
                            <SwiperSlide>
                              <img src={inmueble.first_image_url} alt="" className="img-fluid d-block" />
                            </SwiperSlide>
                          )}
                        </div>
                      </Swiper>

                      {inmueble.imagenes && inmueble.imagenes.length > 0 && (
                        <div className="product-nav-slider mt-2">
                          <Swiper
                            onSwiper={setThumbsSwiper}
                            slidesPerView={4}
                            freeMode={true}
                            watchSlidesProgress={true}
                            spaceBetween={10}
                            className="swiper product-nav-slider mt-2 overflow-hidden"
                          >
                            <div className="swiper-wrapper">
                              {inmueble.imagenes.map((img, key) => (
                                <SwiperSlide key={key} className="rounded">
                                  <div className="nav-slide-item">
                                    <img src={img.image} alt="" className="img-fluid d-block rounded" />
                                  </div>
                                </SwiperSlide>
                              ))}
                            </div>
                          </Swiper>
                        </div>
                      )}
                    </div>
                  </Col>

                  <Col xl={8}>
                    <div className="mt-xl-0 mt-5">
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <h4>{inmueble.nombre || "N/A"}</h4>
                          <div className="hstack gap-3 flex-wrap">
                            <div>
                              <Link to="#" className="text-primary d-block">
                                {inmueble.estado?.nombre} - {inmueble.ciudad?.nombre}
                              </Link>
                            </div>
                            <div className="vr"></div>
                            <div className="text-muted">
                              Franquicia :{" "}
                              <span className="text-body fw-medium">{inmueble.franquicia?.nombre}</span>
                            </div>
                            <div className="vr"></div>
                            <div className="text-muted">
                              <span className="text-body fw-medium">
                                <Badge color="primary">
                                  {inmueble?.status_inmueble?.nombre || "No disponible"}
                                </Badge>
                              </span>
                            </div>
                          </div>
                        </div>
                        {ability.can("update", inmueble) && (
                          <div className="flex-shrink-0">
                            <div>
                              <Tooltip
                                placement="top"
                                isOpen={ttop}
                                target="TooltipTop"
                                toggle={() => setttop(!ttop)}
                              >
                                Editar
                              </Tooltip>
                              <Link to={`/formulario-inmueble/${inmueble.id}`} id="TooltipTop" className="btn btn-light">
                                <i className="ri-pencil-fill align-bottom"></i>
                              </Link>
                            </div>
                          </div>
                        )}
                      </div>

                      <Row className="mt-4">
                        {inmueble.tipo_negociacion?.nombre === "Venta" ||
                        inmueble.tipo_negociacion?.nombre === "Venta y Alquiler" ? (
                          <Col lg={3} sm={6}>
                            <div className="p-2 border border-dashed rounded">
                              <div className="d-flex align-items-center">
                                <div className="avatar-sm me-2">
                                  <div className="avatar-title rounded bg-transparent text-primary fs-24">
                                    <i className="ri-money-dollar-circle-fill"></i>
                                  </div>
                                </div>
                                <div className="flex-grow-1">
                                  <p className="text-muted mb-1">Precio de Venta :</p>
                                  <h5 className="mb-0">{inmueble.precio || "N/A"}</h5>
                                </div>
                              </div>
                            </div>
                          </Col>
                        ) : null}

                        {inmueble.tipo_negociacion?.nombre === "Alquiler" ||
                        inmueble.tipo_negociacion?.nombre === "Venta y Alquiler" ? (
                          <Col lg={3} sm={6}>
                            <div className="p-2 border border-dashed rounded">
                              <div className="d-flex align-items-center">
                                <div className="avatar-sm me-2">
                                  <div className="avatar-title rounded bg-transparent text-primary fs-24">
                                    <i className="ri-money-dollar-circle-fill"></i>
                                  </div>
                                </div>
                                <div className="flex-grow-1">
                                  <p className="text-muted mb-1">Precio de Alquiler :</p>
                                  <h5 className="mb-0">{inmueble.precio_alquiler || "N/A"}</h5>
                                </div>
                              </div>
                            </div>
                          </Col>
                        ) : null}
                      </Row>

                      {inmueble.descripcion && (
                        <div className="mt-4 text-muted">
                          <h5 className="fs-14">Descripción :</h5>
                          <div dangerouslySetInnerHTML={{ __html: inmueble.descripcion }} />
                        </div>
                      )}

                      <div className="product-content mt-5">
                        <Nav tabs className="nav-tabs-custom nav-primary">
                          <NavItem>
                            <NavLink
                              style={{ cursor: "pointer" }}
                              className={classnames({
                                active: customActiveTab === "1",
                              })}
                              onClick={() => toggleCustom("1")}
                            >
                              Características
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink
                              style={{ cursor: "pointer" }}
                              className={classnames({
                                active: customActiveTab === "2",
                              })}
                              onClick={() => toggleCustom("2")}
                            >
                              Áreas comunes
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink
                              style={{ cursor: "pointer" }}
                              className={classnames({
                                active: customActiveTab === "3",
                              })}
                              onClick={() => toggleCustom("3")}
                            >
                              Detalles
                            </NavLink>
                          </NavItem>
                        </Nav>

                        <TabContent activeTab={customActiveTab} className="border border-top-0 p-4">
                          <TabPane tabId="1">
                            <div className="table-responsive">
                              <table className="table mb-0">
                                <tbody>
                                  <tr>
                                    <th scope="row">Baños</th>
                                    <td>{inmueble.numero_banios || "N/A"}</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">Habitaciones</th>
                                    <td>{inmueble.habitaciones || "N/A"}</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">Plantas</th>
                                    <td>{inmueble.numero_plantas || "N/A"}</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">Puestos de estacionamiento</th>
                                    <td>{inmueble.ptos_estacionamiento || "N/A"}</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">Metros cuadrados de terreno</th>
                                    <td>{inmueble.metros_cuadrados_terreno || "N/A"}</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">Metros cuadrados de construcción</th>
                                    <td>{inmueble.metros_cuadrados_construccion || "N/A"}</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">Family Room</th>
                                    <td>{inmueble.family_room ? "Sí" : "No"}</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">Cubículo</th>
                                    <td>{inmueble.cubiculo ? "Sí" : "No"}</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">Estudio</th>
                                    <td>{inmueble.estudio ? "Sí" : "No"}</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">Lavadero</th>
                                    <td>{inmueble.lavadero ? "Sí" : "No"}</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">Cocina empotrada</th>
                                    <td>{inmueble.cocina_empotrada ? "Sí" : "No"}</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">Amoblado</th>
                                    <td>{inmueble.amoblado ? "Sí" : "No"}</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">Jacuzzi</th>
                                    <td>{inmueble.jacuzzi ? "Sí" : "No"}</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </TabPane>
                          <TabPane tabId="2">
                            <div className="table-responsive">
                              <table className="table mb-0">
                                <tbody>
                                  <tr>
                                    <th scope="row">Ascensor</th>
                                    <td>{inmueble.ascensor ? "Sí" : "No"}</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">Piscina</th>
                                    <td>{inmueble.piscina ? "Sí" : "No"}</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">Gimnasio</th>
                                    <td>{inmueble.gym ? "Sí" : "No"}</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">Parrillera</th>
                                    <td>{inmueble.bbq ? "Sí" : "No"}</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">Canchas</th>
                                    <td>{inmueble.cancha_deportiva ? "Sí" : "No"}</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">Parque infantil</th>
                                    <td>{inmueble.parque_infantil ? "Sí" : "No"}</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">Vigilancia privada</th>
                                    <td>{inmueble.seguridad ? "Sí" : "No"}</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </TabPane>

                          <TabPane tabId="3">
                            <div className="table-responsive">
                              <table className="table mb-0">
                                <tbody>
                                  <tr>
                                    <th scope="row">Estado</th>
                                    <td>{inmueble.estado?.nombre || "N/A"}</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">Ciudad</th>
                                    <td>{inmueble.ciudad?.nombre || "N/A"}</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">Dirección</th>
                                    <td>{inmueble.direccion || "N/A"}</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">Tipo de Inmueble</th>
                                    <td>{inmueble.tipo_inmueble?.nombre || "N/A"}</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">Posee Hipoteca</th>
                                    <td>{inmueble.posee_hipoteca ? "Sí" : "No"}</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">Asesor</th>
                                    <td>{inmueble.asesor?.full_name || "N/A"}</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </TabPane>
                        </TabContent>
                      </div>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default EcommerceProductDetail;
