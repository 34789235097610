import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import './InputDateRange.css';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { date_r } from '../../../../helpers/api_helper';

const InputDateRange = forwardRef((props,ref) => {
    const [selectedRange, setSelectedRange] = useState([new Date(), new Date()]);
    const update = props.update

    useEffect(()=>{
        if(update){
            update(selectedRange)
        }
    },[selectedRange])


    const handleDateRangeSelect = (range) => {
        console.log(range)
        const { startDate, endDate } = calculateDateRange(range);
        setSelectedRange([startDate, endDate]);
    }


    function getStartOfMonth() {
        const currentDate = new Date(); // Obtén la fecha actual
        const startOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1); // Establece el día al 1
      
        return startOfMonth;
    }
      
    function getQuincena() {
        const currentDate = new Date(); // Obtén la fecha actual
        const currentDay = currentDate.getDate(); // Obtén el día del mes
        
        if (currentDay <= 15) {
            return 1; // Estamos en la quincena 1
        } else {
            return 2; // Estamos en la quincena 2
        }
    }    

    const calculateDateRange = (range) => {
        const currentDate = new Date();
        let startDate = new Date();
        let endDate = new Date();
      
        if (range === 'Hoy') {
          startDate = currentDate;
          endDate = currentDate;
        } else if (range === 'Semana') {
          const currentDay = currentDate.getDay();
          startDate.setDate(currentDate.getDate() - currentDay);
        } else if (range === 'Quincena') {
            const quincena = getQuincena();
            if (quincena === 1) {
              startDate = getStartOfMonth();
              endDate = currentDate;
            } else {
              startDate = currentDate;
              endDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 15); // Hasta el 15 del mes
            }
        } else if (range === 'Mes') {
          startDate.setDate(1);
        } else if (range === 'Trimestre') {
            const currentMonth = currentDate.getMonth();
            console.log(currentMonth)
            if (currentMonth < 3) {
                startDate.setMonth(0);  
                startDate.setDate(1);
            } else if (currentMonth < 6) {
                startDate.setMonth(3);  
                startDate.setDate(1);
            } else if (currentMonth < 9) {
                startDate.setMonth(6);  
                startDate.setDate(1);
            } else {
                startDate.setMonth(9);  
                startDate.setDate(1);
            }
        } else if (range === 'Semestre') {
            const currentMonth = currentDate.getMonth();
            if (currentMonth < 6) {
                startDate.setMonth(0);
            }
            else {
                startDate.setMonth(6);
            }
        } else if (range === 'Año') {
          startDate.setMonth(0);
        }
      
        return { startDate, endDate };
    };
      

    useImperativeHandle(ref, () => ({
        selectedRange
    }));

    return (
        <>
            <UncontrolledDropdown className="card-header-dropdown">
                <DropdownToggle tag="a" className="text-reset dropdown-btn" role="button">
                    <span className="text-muted">
                        {date_r(selectedRange[0])} - {date_r(selectedRange[1])}
                    <i className="mdi mdi-chevron-down ms-1"></i></span>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-end">
                    <DropdownItem onClick={() => handleDateRangeSelect('Hoy')}>Hoy</DropdownItem>
                    <DropdownItem onClick={() => handleDateRangeSelect('Semana')}>Semana</DropdownItem>
                    <DropdownItem onClick={() => handleDateRangeSelect('Quincena')}>Quincena</DropdownItem>
                    <DropdownItem onClick={() => handleDateRangeSelect('Mes')}>Mes</DropdownItem>
                    <DropdownItem onClick={() => handleDateRangeSelect('Trimestre')}>Trimestre</DropdownItem>
                    <DropdownItem onClick={() => handleDateRangeSelect('Semestre')}>Semestre</DropdownItem>
                    <DropdownItem onClick={() => handleDateRangeSelect('Año')}>Año</DropdownItem>
                </DropdownMenu>
            </UncontrolledDropdown>        
        </>
    );
})

InputDateRange.displayName = 'InputDateRange';
export { InputDateRange }