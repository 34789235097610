// ** React Imports
import { useEffect } from 'react'


// ** Component Import
import { useAuth } from '../../AuthContext'
import defineAbilityFor, { getHomeRoute } from '../acl'
import { Spinner } from 'reactstrap'
import { AbilityContext } from './Can'
import Cover404 from '../../pages/AuthenticationInner/Errors/Cover404'
import { useNavigate } from 'react-router-dom'
import { useLocation } from 'react-router-dom';


// ** Hooks

// ** Util Import
// import getHomeRoute from 'src/layouts/components/acl/getHomeRoute'

const AclGuard = props => {
  // ** Props
  const { aclAbilities, children, guestGuard = false, authGuard = true } = props

  // ** Hooks
  const auth = useAuth()
  const router = useNavigate()
  const location = useLocation();

  // ** Vars
  let ability
  useEffect(() => {
    const user = auth?.user?.user
    if (user && user.profile.slug_name && !guestGuard && location.pathname === '/') {
      const homeRoute = getHomeRoute(user.profile.slug_name)
      router(homeRoute)
    }
  }, [auth?.user, guestGuard, location.pathname])

  // User is logged in, build ability for the user based on his role
  if (auth.user && !ability) {
    ability = defineAbilityFor(auth.user.user, aclAbilities.subject)
  }

  // If guest guard or no guard is true or any error page
  
  if (guestGuard || location.pathname === '/404' || location.pathname === '/500' || !authGuard) {
    // If user is logged in and his ability is built
    if (auth.user && ability) {
      return <AbilityContext.Provider value={ability}>{children}</AbilityContext.Provider>
    } else {
      // If user is not logged in (render pages like login, register etc..)
      return <>{children}</>
    }
  }

  // Check the access of current user and render pages
  if (ability && auth.user && ability.can(aclAbilities.action, aclAbilities.subject)) {
    if (location.pathname === '/') {
      return <h1>hola2</h1>
      // return <Spinner />
    }

    return <AbilityContext.Provider value={ability}>{children}</AbilityContext.Provider>
  }

  // Render Not Authorized component if the current user has limited access
  return (
    // <BlankLayout>
      <Cover404 />
    // </BlankLayout>
  )
}

export default AclGuard
