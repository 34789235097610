import React, { useEffect, useState } from 'react';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';

//import images
import { ToastContainer, toast } from 'react-toastify';
import { useAuth } from '../../AuthContext';
import { APIClient } from '../../helpers/api_helper';
const api = new APIClient();

const ProfileDropdown = () => {
    const { user } = useAuth();
    const [userName, setUserName] = useState(" ");
    const [profilePicUrl, setprofilePicUrl] = useState(null);
    

    function socketBroadcast() {
        const channel = new BroadcastChannel('inmochannel');
        channel.addEventListener('message', (event) => {
            console.log('recimo mensaje')
            const messageData = event.data;
            toast.info(messageData?.description, { autoClose: 3000 });
        });
    }

    useEffect(() => {
        socketBroadcast();
    }, []);


    useEffect(() => {
        if (user) {
            const obj = JSON.parse(sessionStorage.getItem("authUser"));
            setUserName(`${user.user.first_name} ${user.user.last_name}`);
        }
        const ID_USER = user.user.id
        if(ID_USER){
            api.get(`/user/${ID_USER}/update_image/`).then((res)=>{
                setprofilePicUrl(res.image_url)
            })
        }
    }, []);


    const [isProfileDropdown, setIsProfileDropdown] = useState(false);
    const toggleProfileDropdown = () => {
        setIsProfileDropdown(!isProfileDropdown);
    };
    if(!user){
        return null
    }

    return (
        <React.Fragment>
            <ToastContainer></ToastContainer>
            <Dropdown isOpen={isProfileDropdown} toggle={toggleProfileDropdown} className="ms-sm-3 header-item topbar-user">
                <DropdownToggle tag="button" type="button" className="btn">
                    <span className="d-flex align-items-center">
                        <img className="rounded-circle header-profile-user" src={profilePicUrl}
                            alt="Header Avatar" />
                        <span className="text-start ms-xl-2">
                            <span className="d-none d-xl-inline-block ms-1 fw-medium user-name-text">{userName}</span>
                            <span className="d-none d-xl-block ms-1 fs-12 text-muted user-name-sub-text">{user.user?.profile ? user.user?.profile.nombre : ' '}</span>
                        </span>
                    </span>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-end">

                    <h6 className="dropdown-header">{userName}!</h6>
                    <DropdownItem 
                        href={process.env.PUBLIC_URL + "/perfil"}>
                            <i className="mdi mdi-account-circle text-muted fs-16 align-middle me-1"></i>
                            <span className="align-middle">Perfil</span>
                    </DropdownItem>
                    <DropdownItem 
                        href={process.env.PUBLIC_URL + "/perfil-config"}>
                            <i className="mdi mdi-account-circle text-muted fs-16 align-middle me-1"></i>
                            <span className="align-middle">Opciones</span>
                    </DropdownItem>
                    <DropdownItem href={process.env.PUBLIC_URL + "/apps-chat"}>
                            <i className="mdi mdi-message-text-outline text-muted fs-16 align-middle me-1"></i> <span
                            className="align-middle">Mensajes</span></DropdownItem>
                    <DropdownItem href={process.env.PUBLIC_URL + "#"}><i
                        className="mdi mdi-calendar-check-outline text-muted fs-16 align-middle me-1"></i> <span
                            className="align-middle">Calendario</span></DropdownItem>
                    <DropdownItem href={process.env.PUBLIC_URL + "/pages-faqs"}><i
                        className="mdi mdi-lifebuoy text-muted fs-16 align-middle me-1"></i> <span
                            className="align-middle">Soporte</span></DropdownItem>
                    <div className="dropdown-divider"></div>
                    <DropdownItem href={process.env.PUBLIC_URL + "/logout"}><i
                        className="mdi mdi-logout text-muted fs-16 align-middle me-1"></i> <span
                            className="align-middle" data-key="t-logout">Cerrar Sessión</span></DropdownItem>
                </DropdownMenu>
            </Dropdown>
        </React.Fragment>
    );
};

export default ProfileDropdown;