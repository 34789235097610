import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import './FilterComponent.css';
import { Card, CardBody, CardHeader, Col, Nav, NavItem, NavLink, Row, UncontrolledCollapse } from 'reactstrap';
import { Link } from 'react-router-dom';
import { InputSelect } from '../../../Forms/Inputs/InputSelect/InputSelect';
import { InputRangeSlider } from '../../../Forms/Inputs/InputRangeSlider/InputRangeSlider';
import classnames from "classnames";
import TableContainer from '../../../../Components/Common/TableContainer';
import { CheckxFilterComponent } from './components/CheckxFilterComponent/CheckxFilterComponent';
import { AlternativasComponent } from './components/AlternativasComponent/AlternativasComponent';
import { RadioButtons } from './components/RedioButtons/RadioButtons';
import { useFormik } from 'formik';

const FilterComponent = forwardRef(({notitle = false,franquicias,tiposinmuebles, estados = [],handler},ref) => {
    const filtroFranquicias = useRef(null);
    const filtroTiposInmuebles = useRef(null);
    const filtroEstados = useRef(null);
    const filtroBanios = useRef(null);
    const filtroHabitaciones = useRef(null);
    const filtroEstacionamiento = useRef(null);
    const filtroPrecio = useRef(null);
    const filtrosMtsCuadrados = useRef(null);
    const [filterSelections, setfilterSelections] = useState({});

    const baniosFilterList = [
        {
            nombre: '1',
            value: '1',
        },
        {
            nombre: '2',
            value: '2',
        },
        {
            nombre: '3',
            value: '3',
        },
        {
            nombre: '4',
            value: '4',
        },
        {
            nombre: '+5',
            value: '5',
        },
    ]

    const habitacionesFilterList = [
        {
            nombre: '1',
            value: '1',
        },
        {
            nombre: '2',
            value: '2',
        },
        {
            nombre: '3',
            value: '3',
        },
        {
            nombre: '4',
            value: '4',
        },
        {
            nombre: '+5',
            value: '5',
        },
    ]

    const estacionamientoFilterList = [
        {
            nombre: '+1',
            value: '1',
        },
        {
            nombre: '+2',
            value: '2',
        },
        {
            nombre: '+3',
            value: '3',
        }
    ]

    const handleClean = () =>{
        filtroBanios.current.reset()
        filtroHabitaciones.current.reset()
        filtroEstacionamiento.current.reset()
        filtroPrecio.current.reset()
        filtrosMtsCuadrados.current.reset()
        filtroEstados.current.reset()
        filtroFranquicias.current.reset()
        filtroTiposInmuebles.current.reset()
        setfilterSelections({})
        handler(filterSelections)
    }

    const cleanOptions = (options) => {
        // Filtrar las opciones para eliminar las claves con valores nulos o undefined
        return Object.fromEntries(
          Object.entries(options || {}).filter(([_, value]) => value !== null && value !== undefined && value !== '')
        );
    };

    useEffect(() => {
        let options = cleanOptions(filterSelections) 
        if(Object.keys(options).length > 0){
            handler(filterSelections)
        }
    }, [filterSelections]);

    const handleFranquiciasSelection = (selection) =>{
        setfilterSelections({...filterSelections,franquicia:selection})
    }

    const handleTipoInmuebleSelection = (selection) =>{
        setfilterSelections({...filterSelections,tipo_inmueble:selection})
    }

    const handleEstadoSelection = (selection) =>{
        setfilterSelections({...filterSelections,estado:selection})
    }

    const handleEstacionamientoSelection = (selection) =>{
        setfilterSelections({...filterSelections,ptos_estacionamiento:selection})
    }

    const handleBaniosSelection = (selection) =>{
        setfilterSelections({...filterSelections,banios:selection})
    }

    const handleHabitacionesSelection = (selection) =>{
        setfilterSelections({...filterSelections,habitaciones:Number(selection)})
    }

    const handlerPrecio = (selection) =>{
        let { minValue } = selection
        let { maxValue } = selection
        let filers ={
            ...filterSelections,
            min_precio:minValue !== null && minValue !== undefined ? Number(minValue) : 0,
            max_precio:maxValue !== null && maxValue !== undefined ? Number(maxValue) : 0,
        }
        setfilterSelections({...filterSelections,...filers})
    }

    const handlerMetsCuadrados = (selection) =>{
        let { minValue } = selection
        let { maxValue } = selection
        let filers ={
            ...filterSelections,
            min_mtscuadrados:minValue !== null && minValue !== undefined ? Number(minValue) : 0,
            max_mtscuadrados:maxValue !== null && maxValue !== undefined ? Number(maxValue) : 0,
        }
        setfilterSelections({...filterSelections,...filers})
    }

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: {
            estado       : null,
            ciudad       : null,
            banios       : null,
            franquicia   : null,
            min_precio   : null,
            max_precio   : null,
            tipo_inmueble: null,
            min_mtscuadrados: null,
            max_mtscuadrados: null,
            ptos_estacionamiento: null,
            habitaciones: null
        },
    });

    useImperativeHandle(ref, () => ({
        filterSelections: filterSelections,
    }));
    
    return (
        <Card>
            <CardHeader>
                <div className="d-flex mb-3">
                    {
                        !notitle && 
                        <div className="flex-grow-1">
                            <h5 className="fs-16">Filtros</h5>
                        </div>

                    }
                    <div className="flex-shrink-0">
                    <Link to="#" onClick={() => handleClean()} className="text-decoration-underline link-secondary">
                        Limpiar Todo
                    </Link>
                    </div>
                </div>
            </CardHeader>

            <div className="accordion accordion-flush">

                {/* Precio */}
                <div className="accordion-item">
                    <h2 className="accordion-header">
                        <button
                            className="accordion-button bg-transparent shadow-none"
                            id='precio'
                            type="button">
                            <span className="text-muted text-uppercase fs-12 fw-medium">
                                Precio
                            </span>
                        </button>
                    </h2>
                    <UncontrolledCollapse
                        toggler="#precio">
                        <div
                            className="accordion-collapse collapse show">
                            <div className="accordion-body text-body pt-0">
                                <InputRangeSlider
                                    placeHolderMin='Desde'
                                    placeHolderMax='Hasta'
                                    handler={handlerPrecio}
                                    minName="min_precio"
                                    maxName="max_precio"
                                    ref={filtroPrecio}
                                    validation={validation}
                                    label=" ">
                                </InputRangeSlider>
                            </div>
                        </div>
                    </UncontrolledCollapse>
                </div>

                {/* Habitaciones */}
                <div className="accordion-item">
                    <h2 className="accordion-header">
                        <button
                            className="accordion-button bg-transparent shadow-none"
                            type="button"
                            id="habitaciones">
                            <span className="text-muted text-uppercase fs-12 fw-medium">
                            Habitaciones
                            </span>{" "}
                        </button>
                    </h2>
                    <UncontrolledCollapse
                        toggler="#habitaciones">
                        <div
                            className="accordion-collapse collapse show">
                            <div className="accordion-body text-body pt-0">
                                <RadioButtons
                                    valueProp={'value'}
                                    handler={handleHabitacionesSelection}
                                    ref={filtroHabitaciones}
                                    nameProp={'nombre'}
                                    items={habitacionesFilterList}>
                                </RadioButtons>
                            </div>
                        </div>
                    </UncontrolledCollapse>
                </div>

                {/* Banios */}
                <div className="accordion-item">
                    <h2 className="accordion-header">
                        <button
                            className="accordion-button bg-transparent shadow-none"
                            type="button"
                            id="banios">
                            <span className="text-muted text-uppercase fs-12 fw-medium">
                            Baños
                            </span>{" "}
                        </button>
                    </h2>
                    <UncontrolledCollapse
                        toggler="#banios">
                        <div
                            className="accordion-collapse collapse show">
                            <div className="accordion-body text-body pt-0">
                                <RadioButtons
                                    valueProp={'value'}
                                    handler={handleBaniosSelection}
                                    ref={filtroBanios}
                                    nameProp={'nombre'}
                                    items={baniosFilterList}>
                                </RadioButtons>
                            </div>
                        </div>
                    </UncontrolledCollapse>
                </div>

                {/* Estacionamiento */}
                <div className="accordion-item">
                    <h2 className="accordion-header">
                        <button
                            className="accordion-button bg-transparent shadow-none"
                            type="button"
                            id="estacionamientos">
                            <span className="text-muted text-uppercase fs-12 fw-medium">
                            Estacionamientos
                            </span>{" "}
                        </button>
                    </h2>
                    <UncontrolledCollapse
                        toggler="#estacionamientos">
                        <div
                            className="accordion-collapse collapse show">
                            <div className="accordion-body text-body pt-0">
                                <RadioButtons
                                    handler={handleEstacionamientoSelection}
                                    valueProp={'value'}
                                    ref={filtroEstacionamiento}
                                    nameProp={'nombre'}
                                    items={estacionamientoFilterList}>
                                </RadioButtons>
                            </div>
                        </div>
                    </UncontrolledCollapse>
                </div>

                {/* Mets Cuadrados */}
                <div className="accordion-item">
                    <h2 className="accordion-header">
                        <button
                            className="accordion-button bg-transparent shadow-none"
                            id='mets'
                            type="button">
                            <span className="text-muted text-uppercase fs-12 fw-medium">
                                Mts Cuadrados
                            </span>
                        </button>
                    </h2>
                    <UncontrolledCollapse
                        toggler="#mets">
                        <div
                            className="accordion-collapse collapse show">
                            <div className="accordion-body text-body pt-0">
                            <InputRangeSlider
                                minValue={0}
                                maxValue={99999999}
                                minName="min_precio"
                                ref={filtrosMtsCuadrados}
                                handler={handlerMetsCuadrados}
                                maxName="max_precio"
                                placeHolderMin='Desde'
                                placeHolderMax='Hasta'
                                validation={validation}
                                label=" ">
                            </InputRangeSlider>
                            </div>
                        </div>
                    </UncontrolledCollapse>
                </div>

                {/* Estado */}
                <div className="accordion-item">
                    <h2 className="accordion-header">
                        <button
                            className="accordion-button bg-transparent shadow-none"
                            id='estados'
                            type="button">
                            <span className="text-muted text-uppercase fs-12 fw-medium">
                                Estado
                            </span>
                        </button>
                    </h2>
                    <UncontrolledCollapse
                        toggler="#estados">
                        <div
                            className="accordion-collapse collapse show">
                            <div className="accordion-body text-body pt-0">
                            <AlternativasComponent
                                ref={filtroEstados}
                                search={false}
                                handler={handleEstadoSelection}
                                nameProp={'nombre'}
                                items={estados}>
                            </AlternativasComponent>
                            </div>
                        </div>
                    </UncontrolledCollapse>
                </div>

                {/* Franquicias */}
                <div className="accordion-item">
                    <h2 className="accordion-header">
                        <button
                            className="accordion-button bg-transparent shadow-none"
                            type="button"
                            id="franquicias">
                            <span className="text-muted text-uppercase fs-12 fw-medium">
                            Franquicias
                            </span>{" "}
                        </button>
                    </h2>
                    <UncontrolledCollapse
                    toggler="#franquicias">
                    <div
                        className="accordion-collapse collapse show">
                        <div className="accordion-body text-body pt-0">
                            <CheckxFilterComponent
                                handler={handleFranquiciasSelection}
                                ref={filtroFranquicias}
                                search={true}
                                nameProp={'nombre'}
                                items={franquicias}>
                            </CheckxFilterComponent>
                        </div>
                    </div>
                    </UncontrolledCollapse>
                </div>

                {/* Tipos Inmuebles */}
                <div className="accordion-item">
                    <h2 className="accordion-header">
                    <button
                        className="accordion-button bg-transparent shadow-none"
                        type="button"
                        id="tiposinmuebles">
                        <span className="text-muted text-uppercase fs-12 fw-medium">
                            Tipos Inmuebles
                        </span>{" "}
                    </button>
                    </h2>
                    <UncontrolledCollapse
                    toggler="#tiposinmuebles">
                        <div
                            className="accordion-collapse collapse show">
                            <div className="accordion-body text-body pt-0">
                                <AlternativasComponent
                                    handler={handleTipoInmuebleSelection}
                                    ref={filtroTiposInmuebles}
                                    nameProp={'nombre'}
                                    items={tiposinmuebles}>
                                </AlternativasComponent>
                            </div>
                        </div>
                    </UncontrolledCollapse>
                </div>
            </div>
        </Card>
    )
});


FilterComponent.displayName = 'FilterComponent';
export { FilterComponent }