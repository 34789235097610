import React, { useMemo, useState } from 'react';
import './BusquedasCard.css';
import {
    Card,
    CardBody,
    UncontrolledCollapse,
    CardFooter,
    Button,
    OffcanvasBody,
    OffcanvasHeader,
    Row,
    Offcanvas,
    Col,
    Modal,
    ModalHeader,
    CardHeader,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem
  } from "reactstrap";
import { formatDistanceToNow } from 'date-fns';
import { Link } from 'react-router-dom';
import { es } from 'date-fns/locale';
import { InputTextarea } from '../../Forms/Inputs/inputTextarea/inputTextarea';
import { useFormik } from "formik";
import { createApiMethods } from '../../../helpers/api_calls';
import { SEGUIMIENTOS } from '../../../helpers/api_urls';
import { toast } from 'react-toastify';
import * as Yup from "yup";



export const BusquedasCard = ({editar,mute = false,busqueda,...props}) => {
    const [formularioNuevoSeguimiento, setformularioNuevoSeguimiento] = useState(false);
    const [SeguimientoItem, setSeguimientoItem] = useState(null);
    const [formularioNuevoMensaje, setformularioNuevoMensaje] = useState(false);
    const [isLoading, setisLoading] = useState(false);
    const apiSeguimientos = useMemo(() => createApiMethods(SEGUIMIENTOS), []);
    const [recording, setRecording] = useState(false);
    const [mediaRecorder, setMediaRecorder] = useState(null);
    const [audioURL, setAudioURL] = useState('');
    const [mediaStream, setMediaStream] = useState(null);
    const [modal_center, setmodal_center] = useState(false);
    function tog_center() {
        setmodal_center(!modal_center);
    }
  
    const toggleStartRecording = async () => {
      await startRecording()
      tog_center()
    }
  
    const startRecording = async () => {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      setMediaStream(stream);
      const recorder = new MediaRecorder(stream);
      setMediaRecorder(recorder);
  
      recorder.start();
      setRecording(true);
  
      const audioChunks = [];
      recorder.ondataavailable = (event) => {
        audioChunks.push(event.data);
      };
  
      recorder.onstop = () => {
        const audioBlob = new Blob(audioChunks);
        validationSeguimiento.setFieldValue('audio',audioBlob)
        const url = URL.createObjectURL(audioBlob);
        setAudioURL(url);
      };
    };
  
    const stopRecording = () => {
      if (mediaRecorder) {
        mediaRecorder.stop();
        setRecording(false);
      }
  
      if (mediaStream) {
        mediaStream.getTracks().forEach(track => track.stop());
        setMediaStream(null);
      }
      setmodal_center(false)
    };

    const handleSendMensaje = (mensaje) => {
        const phoneNumber = "+584143563118"; // Reemplaza esto con el número de teléfono al que deseas enviar el mensaje
    
    
        const url = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(mensaje)}`;
        window.open(url, '_blank');
    };
    

    const validationSeguimiento = useFormik({
        enableReinitialize: true,
        initialValues: {
            cliente: SeguimientoItem?.cliente.id,
            nota     : null,
            audio  : null,
            busqueda: SeguimientoItem?.id
        },
        validationSchema: Yup.object({
          // nota: Yup.string()
          //   .typeError("Debe agregar una nota.")
          //   .required("Este campos es obligatorio"),
          }),
        onSubmit: async (values) => {
            const formData = new FormData();
          
            for (const [key, value] of Object.entries(values)) {
              if (value !== null && value !== undefined && value !== "") {
                if (key !== "imagenes") {
                  formData.append(key, value);
                } else {
                  value.forEach((image, index) => {
                    formData.append(`imagenes[${index}]`, image);
                  });
                }
              }
            }
            // console.log(values)
            setisLoading(true)
            apiSeguimientos.create(formData,{
                headers: {
                    "Content-Type": "multipart/form-data"
                },
            }).then((res)=>{
              toast.success("El seguimiento se registro con éxito", { autoClose: 3000 });
              if(props.updateBusquedas){
                props.updateBusquedas()
              }
              setAudioURL(null);
              validationSeguimiento.resetForm()
            }).catch((err)=>{
              toast.error("Algo ocurrió mientras se creaba el seguimiento", { autoClose: 3000 });
            }).finally(()=>{
              setisLoading(false)
              toggleBottomCanvas()
            })
          }
    });

    const validationMensaje = useFormik({
        enableReinitialize: true,
        initialValues: {
            mensaje     : null,
        },
        validationSchema: Yup.object({
          mensaje: Yup.string()
            .typeError("Debe agregar un mensaje.")
            .required("Este campo es obligatorio"),
          }),
        onSubmit: async (values) => {
          console.log(values)
            const formData = new FormData();
          
            for (const [key, value] of Object.entries(values)) {
              if (value !== null && value !== undefined && value !== "") {
                if (key !== "imagenes") {
                  formData.append(key, value);
                } else {
                  value.forEach((image, index) => {
                    formData.append(`imagenes[${index}]`, image);
                  });
                }
              }
            }
    
              // setisLoading(true)
              // apiBusquedas.create(values).then((res)=>{
              //   toast.success("La busquedafue creada con éxito", { autoClose: 3000 });
              //   // history(`/inmueble/${editarinmueble.id}`);
              // }).catch((err)=>{
              //   toast.error("Algo ocurrió mientras se creaba la busqueda", { autoClose: 3000 });
              // }).finally(()=>{
              //   setisLoading(false)
              //   setModal(false);
              // })
    
              // toggleMensaje()
              handleSendMensaje(values.mensaje)
        }
    });
    

    function formatDate(date) {
        const distance = formatDistanceToNow(new Date(date), { locale: es, addSuffix: true });
        return `${distance.charAt(0).toUpperCase()}${distance.slice(1)}`;
    }

    const displaySeguimientoForm = (selectionSeguimiento) => () => {
        setSeguimientoItem(selectionSeguimiento)
        toggleBottomCanvas()
    }

    function formatearNumero(numero) {
        // Convierte el número a formato de moneda con separadores de miles y decimales
        const numeroFormateado = new Intl.NumberFormat('es-ES', { style: 'currency', currency: 'USD' }).format(numero);
      
        // Reemplaza el símbolo de moneda (€) con "$"
        return numeroFormateado.replace('€', '$');
    }

    const toggleMensaje = () => {
        setformularioNuevoMensaje(!formularioNuevoMensaje);
    };    

    const toggleBottomCanvas = (selectionSeguimiento) => {
        setformularioNuevoSeguimiento(!formularioNuevoSeguimiento);
    };

    return (
        <>
            <Card
                className={
                    busqueda.total_seguimientos
                    ? "mb-1 ribbon-box ribbon-fill ribbon-sm"
                    : "mb-1"
                }>
                <div className="ribbon ribbon-primary">
                    { busqueda.total_seguimientos }
                </div>
                <CardHeader className="cardheadercustom">
                    <div className="d-flex w-100" style={{justifyContent:'space-between'}}>
                        <div className='d-flex flex-column'>
                            <h5 className="card-title mb-0 truncate" style={{lineHeight:'2rem'}}>{busqueda.cliente.nombre}</h5>
                            <h6 className="text-muted mb-0 truncate" style={{lineHeight:'1rem'}}>{busqueda.cliente?.franquicia?.nombre}</h6>
                        </div>
                        {
                        (!mute) &&
                        <div className="float-end">
                            <div className={`d-flex align-items-center gap-1`}>
                                {/* <button type="button" className="btn btn-light btn-sm">Mensaje</button> */}
                                <UncontrolledDropdown>
                                    <DropdownToggle type="button" className="btn btn-icon btn-sm fs-16 text-muted dropdown" tag="button">
                                        <i className="ri-more-fill"></i>
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <li>
                                            <DropdownItem onClick={() => editar(busqueda)} >
                                                <i className="ri-eye-fill text-muted me-2 align-bottom"></i>
                                                Editar busqueda
                                            </DropdownItem>
                                        </li>
                                        {/* <li><DropdownItem><i className="ri-star-fill text-muted me-2 align-bottom"></i>Favourite</DropdownItem></li> */}
                                        {/* <li><DropdownItem><i className="ri-delete-bin-5-fill text-muted me-2 align-bottom"></i>Delete</DropdownItem></li> */}
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            </div>
                        </div>
                        }
                    </div>
                </CardHeader>
                <CardBody>
                    <Link
                    to="#"
                    className="d-flex align-items-center"
                    id={"leadInnerDiscovered" + busqueda.id}
                    >
                    <div className="flex-grow-1 ms-3">
                        <p className="text-muted mb-3">
                        {busqueda.descripcion}
                        </p>
                        {
                        (busqueda?.seguimientos && busqueda?.seguimientos.length > 0) &&
                        <p className="text-muted mb-0" style={{fontSize: '10px', fontWeight: 'bold'}}>
                            Última actualización: {formatDate(busqueda.seguimientos[busqueda.seguimientos.length -1].fecha_seguimiento)}
                        </p>
                        }
                    </div>
                    </Link>
                </CardBody>
                <UncontrolledCollapse
                    className="border-top border-top-dashed"
                    toggler={"#leadInnerDiscovered" + busqueda.id}
                    defaultOpen={busqueda.isOpen === true ? true : false}>
                    {
                        (busqueda?.seguimientos && busqueda?.seguimientos.length > 0) &&
                        <CardBody>
                            <ul className="list-unstyled vstack gap-2 mb-0">
                            {(busqueda.seguimientos || []).map((seguimiento, index) => (
                                <li key={index}>
                                <div className="d-flex">
                                    {
                                        (seguimiento.audio) ?
                                        <>
                                        <div className="flex-shrink-0 avatar-xxs text-muted">
                                            <i className="ri-volume-up-line"></i>
                                        </div>
                                        <div className="flex-grow-1 w-100 text-center">
                                            <audio src={seguimiento.audio} controls />:
                                        </div>                                
                                        </>: 
                                        <>
                                        <div className="flex-shrink-0 avatar-xxs text-muted">
                                            <i className="ri-chat-1-line"></i>
                                        </div>
                                        <div className="flex-grow-1">
                                            <h6 className="mb-0">{seguimiento.nota}</h6>
                                        </div>                                
                                        </>
                                    }
                                </div>
                                <div className="d-flex w-100 flex-row-reverse">
                                    <small className="text-muted">
                                    {formatDate(seguimiento.fecha_seguimiento)}
                                    </small>
                                </div>
                                </li>
                            ))}
                            </ul>
                        </CardBody>

                    }
                    {
                        (!mute) &&
                        <CardFooter className="hstack gap-2">
                            <Button 
                            onClick={displaySeguimientoForm(busqueda)}
                            color="success" 
                            className="btn-sm w-100">
                            <i className="ri-add-line align-bottom me-1"></i>Gestion
                            </Button>
                            <Button 
                            onClick={toggleMensaje}
                            color="primary" 
                            className="btn-sm w-100">
                            <i className="ri-question-answer-line align-bottom me-1"></i>{" "}
                            Mensaje
                            </Button>
                        </CardFooter>
                    }
                </UncontrolledCollapse>
                {
                    (busqueda.habitaciones ||
                    busqueda.ptos_estacionamiento ||
                    busqueda.numero_banios ||
                    (busqueda.precio && busqueda.precio !== '0.00')) &&
                    <CardFooter className="hstack gap-2">
                        <div className='d-flex flex-end footerdetails'>
                            {
                                busqueda.habitaciones &&
                                <div className='busqueda-footer-item d-flex'>
                                    <div>
                                        <i className="ri-hotel-bed-line"></i>
                                    </div>
                                    <span>
                                        {busqueda.habitaciones}
                                    </span>
                                </div>
                            }

                            {
                                busqueda.ptos_estacionamiento &&
                                <div className='busqueda-footer-item d-flex'>
                                    <div>
                                        <i className="ri-car-line"></i>
                                    </div>
                                    <span>
                                        {busqueda.ptos_estacionamiento}
                                    </span>
                                </div>
                            } 

                            {
                                busqueda.numero_banios &&
                                <div className='busqueda-footer-item d-flex'>
                                    <div>
                                        <i className="ri-door-fill"></i>
                                    </div>
                                    <span>
                                        {busqueda.numero_banios}
                                    </span>
                                </div>
                            }  

                            {
                                (busqueda.precio && busqueda.precio !== '0.00') &&
                                <div className='busqueda-footer-item d-flex'>
                                    <div>
                                        <i className="ri-money-dollar-box-line"></i>
                                    </div>
                                    <span>
                                        {formatearNumero(busqueda.precio)}
                                    </span>
                                </div>
                            }                                                                                              
                        </div>
                    </CardFooter>                
                }
            </Card>


            <Offcanvas
                fade={true}
                isOpen={formularioNuevoSeguimiento}
                direction="bottom"
                offcanvasTransition={{ timeout: 300 }}
                toggle={toggleBottomCanvas}
                style={{ minHeight: "46vh" }}>
                <OffcanvasHeader 
                toggle={toggleBottomCanvas} 
                className="border-bottom">
                    Nuevo Seguimiento
                </OffcanvasHeader>
                <OffcanvasBody>
                    <Row >
                    <Col lg={{ size: 6, offset: 3 }}>
                        <InputTextarea
                            name="nota"
                            label="Observaciones de gestión"
                            validation={validationSeguimiento}>
                        </InputTextarea>   
                    </Col>
                    <Col lg={{ size: 6, offset: 3 }}>
                        <div className="d-flex w-100 text-center mb-3" style={{justifyContent: 'center'}}>
                        {audioURL && <audio src={audioURL} controls />}
                        </div>
                    </Col>
                    <Col lg={{ size: 6, offset: 3 }} className="text-center">
                        <Button 
                        onClick={validationSeguimiento.submitForm}
                        color="success" 
                        className="btn-sm">
                        <i className="ri-send-plane-line align-bottom me-1"></i>Guardar nota
                        </Button>
                        {
                        (!recording) &&
                        <Button 
                            onClick={toggleStartRecording}
                            color="primary" 
                            className="btn-sm" 
                            style={{marginLeft: '10px'}}>
                            <i className=" ri-mic-line align-bottom me-1"></i>{" "}
                            Agregar Nota de Voz
                        </Button>
                        }
                        {/* { (recording) &&
                        <Button 
                            onClick={stopRecording}
                            color="primary" 
                            className="btn-sm" 
                            style={{marginLeft: '10px'}}>
                            <i className=" ri-mic-line align-bottom me-1"></i>{" "}
                            Detener Nota de Voz
                        </Button>
                        }                */}
                    </Col>
                    </Row>
                </OffcanvasBody>
            </Offcanvas>



            <Offcanvas
                fade={true}
                isOpen={formularioNuevoMensaje}
                direction="bottom"
                offcanvasTransition={{ timeout: 300 }}
                toggle={toggleMensaje}
                style={{ minHeight: "46vh" }}>
                <OffcanvasHeader 
                toggle={toggleMensaje} 
                className="border-bottom">
                    Enviar mensaje
                </OffcanvasHeader>
                <OffcanvasBody>
                    <Row >
                    <Col lg={{ size: 6, offset: 3 }}>
                        <InputTextarea
                            name="mensaje"
                            label="Mensaje"
                            validation={validationMensaje}>
                        </InputTextarea>   
                    </Col>
                    <Col lg={{ size: 6, offset: 3 }}>
                        <Button 
                        onClick={validationMensaje.submitForm}
                        color="success" 
                        className="btn-sm">
                        <i className="ri-send-plane-line align-bottom me-1"></i>Enviar mensaje
                        </Button>
                    </Col>
                    </Row>
                </OffcanvasBody>
            </Offcanvas>



            <Modal
                isOpen={modal_center}
                toggle={() => {
                    tog_center();
                }}
                centered>
                <div className="modal-body text-center p-5">                  
                    <lord-icon
                        src="https://cdn.lordicon.com/pneolqso.json"
                        trigger="loop"
                        colors="primary:#4bb3fd,secondary:#3a3347,tertiary:#646e78,quaternary:#ebe6ef"
                        style={{ width: "120px", height: "120px" }}>
                    </lord-icon>
                    <div className="mt-4">
                        <h4 className="mb-3">Grabando!</h4>
                        <div className="hstack gap-2 justify-content-center">
                            <Button color="light" onClick={() => stopRecording()}>Detener la grabacion</Button>
                        </div>
                    </div>
                </div>
            </Modal>
    
        </>
    );
};
