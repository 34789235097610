import React, { useEffect } from 'react';
import './InputText.css';
import { FormFeedback, Label, Input } from 'reactstrap';

export const InputText = ({ 
    validation, 
    className="", 
    id = "", 
    optionsProps, 
    type = 'text',
    nopadding = false, 
    error, 
    prefix = "", 
    postfix = "", 
    ...props 
}) => {
    const validationValue = validation.values[props?.name]

    useEffect(()=>{
        const formattedValue = formatValue(validationValue);
        validation.setFieldValue(`${props.name}_raw`, formattedValue); // Guardar el valor sin formato
    },[validationValue])

    const formatValue = (value) => {
        if(value !== null && value !== undefined){
            if (props.format === 'phone') {
                const phoneNumber = value.replace(/\D/g, ''); // Eliminar todos los caracteres no numéricos
                return `+${phoneNumber.slice(0, 2)} ${phoneNumber.slice(2, 5)} ${phoneNumber.slice(5, 8)} ${phoneNumber.slice(8)}`;
            } else if (props.format === 'instagram') {
                return `@${value}`;
            }
            return value; // Mantener el valor sin cambios si no hay formato específico
        }
        else{
            return value
        }
    };

    const handleChange = (event) => {
        const rawValue = event.target.value;
        const formattedValue = formatValue(rawValue);
        
        validation.setFieldValue(props.name, rawValue);
        validation.setFieldValue(`${props.name}_raw`, formattedValue); // Guardar el valor sin formato
    };

    return (
        <div className={!nopadding ? "mb-3": ""}>
            {(props?.label !== " " && props?.label !== "" && props.label) && (
                <Label htmlFor={props?.name || null} className="form-label">
                    {props?.label || ' '} 
                    {props.required ? <span className="text-danger">*</span> : ' '}    
                </Label>
            )}
            <div className={`input-group ${nopadding ? '' : 'mb-3'}`}>
                {prefix && (
                    <span className="input-group-text mt-2">{prefix}</span>
                )}
                <Input 
                    disabled={props.disabled}
                    type={type}
                    id={id}
                    name={props?.name || null}
                    className={` ${className} form-control`} 
                    onChange={handleChange}
                    onBlur={validation.handleBlur}
                    placeholder={props?.placeholder}
                    value={validation.values[`${props.name}_raw`] || ""} // Formatear el valor antes de mostrarlo
                    invalid={validation.touched[props?.name] && validation.errors[props?.name] ? true : false}
                />
                {postfix && (
                    <span className="input-group-text mt-2">{postfix}</span>
                )}
            </div>
            {validation.touched[props?.name] && validation.errors[props?.name] ? (
                <FormFeedback type="invalid" className="d-block">
                    <div>{validation.errors[props?.name]}</div>
                </FormFeedback>
            ) : null}
            {error && (
                <FormFeedback type="invalid" className="d-block">
                    <div>{error}</div>
                </FormFeedback>
            )}
        </div>
    );
};
