import { useFormik } from 'formik';
import React, { useState } from 'react';
import { Button, Card, CardBody, Col, Form, Row, Spinner } from 'reactstrap';
import * as Yup from 'yup';
import { APIClient } from '../../helpers/api_helper';
import { InputSelect } from '../Forms/Inputs/InputSelect/InputSelect';
import { InputText } from '../Forms/Inputs/InputText/InputText';

const ConfiguracionForm = ({name,setParams, param,modalToggle,idfranquicia,int}) => {
    const [ParamConfig, setParamConfig] = useState({});
    const api = new APIClient();
    const [isLoading, setisLoading] = useState(false);

    const tiposConfiguracion = [
        { id: 'url', nombre: 'URL' },
        { id: 'endpoint', nombre: 'Endpoint' },
        { id: 'parametro', nombre: 'Parámetro' },
        { id: 'otro', nombre: 'Otro' },
    ];

    const validation = useFormik({
        initialValues: {
            nombre: name,
            tipo: null,
            valor: '',
            descripcion: '',
            activo: true,
            ...param
        },
        validationSchema: Yup.object({
            nombre: int ? undefined : Yup.string().required("El nombre es requerido"),
            tipo: int ? undefined : Yup.string().required("El tipo es requerido"),
            valor: Yup.string().required("El valor es requerido"),
            descripcion: int ? undefined : Yup.string(),
            activo: Yup.boolean(),
        }),
        onSubmit: (values) => {
            setParamConfig(values)
            setisLoading(true)
            let request = null

            if(int){
                let { valor } = values
                request=api.create(`integraciones/${int.id}/set_config/`,{ key : name, value : valor })
            } else if(idfranquicia){
                request=api.create(`franquicias/${idfranquicia}/crear_o_actualizar_parametro/`,values)
            } else {
                request=api.create(`configuraciones/crear_o_actualizar/`,values)
            }

            request.then((res)=>{
                console.log(res[name])
                if(res[name]){
                    setParams({valor : res[name]})
                } else {
                    setParams(res)
                }
            }).catch((e)=>{
                console.error(e)
            }).finally(()=>{
                setisLoading(false)
                modalToggle(false)
            })
        },
    });

    return (
        <Card>
            { isLoading ?
                <div className='pt-3 text-center w-100'>
                    <Spinner color="primary"> Loading... </Spinner>:
                </div>:
                <CardBody>
                    <Form onSubmit={validation.handleSubmit}>
                        <Row>
                            <Col sm={6}>
                                <InputSelect
                                    validation={validation}
                                    name="tipo"
                                    label="Tipo"
                                    disabled={int ? true : false}
                                    optionsProps={{
                                        items: tiposConfiguracion,
                                        value: 'id',
                                        label: 'nombre'
                                    }}
                                    required
                                />
                            </Col>                        
                            <Col sm={6}>
                                <InputText
                                    validation={validation}
                                    name="nombre"
                                    disabled
                                    label="Nombre"
                                    placeholder="Ingrese el nombre de la configuración"
                                    required
                                />
                            </Col>
                            <Col sm={6} className='bg-soft-warning p-1 rounded-3'>
                                <InputText
                                    validation={validation}
                                    name="valor"
                                    label="Valor"
                                    placeholder="Ingrese el valor de la configuración"
                                    required
                                />
                            </Col>
                            <Col sm={6}>
                                <InputText
                                    validation={validation}
                                    disabled={int ? true : false}
                                    name="descripcion"
                                    label="Descripción"
                                    placeholder="Ingrese una descripción (opcional)"
                                />
                            </Col>
                            <Col sm={12}>
                                <Button className='w-100 ' onClick={validation.submitForm} color="primary" type="submit">
                                    Configurar parametro
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </CardBody>
            }

        </Card>
    );
};

export default ConfiguracionForm;