import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Container } from 'reactstrap';
import Section from './Section';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { getfranquiciasList, getfranquiciasdetail } from '../../../store/actions';
import { createApiMethods } from '../../../helpers/api_calls';
import { FRANQUICIAS } from '../../../helpers/api_urls';
import { useAuth } from '../../../AuthContext';
import { FRANQUICIA_ACL } from '../../../helpers/entities';
import { AbilityContext } from '../../../helpers/acl/Can';


const ProjectOverview = () => {
    const history = useNavigate();
    const dispatch = useDispatch();
    const { idfranquicia } = useParams();
    const apiFranquicias = useMemo(() => createApiMethods(FRANQUICIAS), []);
    const [franquiciaDetail, setfranquiciaDetail] = useState();
    const { user } = useAuth();
    

    useEffect(() => {
        if(idfranquicia !== undefined){
            apiFranquicias.getDetails(idfranquicia).then((res)=>{
                res.obj_type= FRANQUICIA_ACL
                setfranquiciaDetail(res)
            }).catch(console.error)
        } else {
            if(!user.isAdmin()){
                apiFranquicias.getDetails(user.user.franquicia.id).then((res)=>{
                    setfranquiciaDetail(res)
                }).catch(console.error)
            } 
        }


        dispatch(getfranquiciasList({page_size:30}));
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [dispatch,idfranquicia]);

    document.title="Franquicia | Inmoapp";
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>                    
                    <Section franq={franquiciaDetail} franquiciaStore={franquiciaDetail}/>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default ProjectOverview;