export const vistasChoices = [
    {'id': 1, 'nombre': 'Vista interna'},
    {'id': 2, 'nombre': 'Vista al mar'},
    {'id': 3, 'nombre': 'Vista al jardin'},
    {'id': 4, 'nombre': 'Vista al parque'},
    {'id': 5, 'nombre': 'Vista a la montaña'},
    {'id': 6, 'nombre': 'Vista a la calle'},
];

export const statusChoices = [
    {'id': 1, 'nombre': 'Activa'},
    // {'id': 2, 'nombre': 'En aprobación'},
    // {'id': 3, 'nombre': 'Pausada'},
    // {'id': 4, 'nombre': 'Pre captada'},
    {'id': 5, 'nombre': 'Inactiva'},
    // {'id': 6, 'nombre': 'Privada'},
];

export const statusChoicesReservas = [
    {'id': 1, 'nombre': 'Activa'},
    {'id': 2, 'nombre': 'Cerrada'},
    {'id': 3, 'nombre': 'Cancelada'}
];

export const antiguedadChoices = [
    {'id': 1, 'nombre': '0 a 9 años'},
    {'id': 2, 'nombre': '10 a 20 años'},
    {'id': 3, 'nombre': '21 a 30 años'},
    {'id': 4, 'nombre': '31 a 40 años'},
    {'id': 5, 'nombre': '41 a 50 años'},
    {'id': 6, 'nombre': 'más de 50 años'},
]

export  const aplicaAlternativasChoices = [
    {'id': 1, 'nombre': 'No aplica'},
    {'id': 2, 'nombre': 'Si'},
]

export const alternativasChoices = [
    {'id': 1, 'nombre': 'No'},
    {'id': 2, 'nombre': 'Si'},
]


export const amobladoChoices = [
    {'id': 1, 'nombre': 'No aplica'},
    {'id': 2, 'nombre': 'Si'},
    {'id': 3, 'nombre': 'Semi'},
    {'id': 4, 'nombre': 'Linea Blanca'},
]

export const estatusCalendarChoices = [
    {'id': 1, 'nombre': 'Pendiente'},
    {'id': 2, 'nombre': 'Concretada'},
    {'id': 3, 'nombre': 'Cancelada'},
]