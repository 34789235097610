// components/SearchBox.js
import React from 'react';

const SearchBox = ({ searchTerm, onChange,className }) => (
  <div className={`search-box ${className}`}>
    <input
      type="text"
      className="form-control search"
      placeholder="Buscar por nombre..."
      value={searchTerm}
      onChange={onChange}
    />
    <i className="ri-search-line search-icon"></i>
  </div>
);

export default SearchBox;
