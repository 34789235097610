import React, { useEffect, useMemo, useState } from 'react';
import './MisBusquedas.css';
import { createApiMethods } from '../../../helpers/api_calls';
import { BUSQUEDAS } from '../../../helpers/api_urls';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/pagination";
// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";

import { Autoplay, Mousewheel } from "swiper";
import { BusquedasCard } from '../BusquedasCard/BusquedasCard';
import { useNavigate } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { SkeletonCard } from '../../../Components/SkeletonCard/SkeletonCard';

export const MisBusquedas = () => {
    const colXS = 12 //1
    const colMD = 6 //2
    const colLG = 3 //3
    const colXL = 3 //4

    const [XS, setXS] = useState(colXS);
    const [MD, setMD] = useState(colMD);
    const [LG, setLG] = useState(colLG);
    const [XL, setXL] = useState(colXL);

    const apiBusquedas = useMemo(() => createApiMethods(BUSQUEDAS), []);
    const [BusquedasList, setBusquedasList] = useState([]);
    const [cantidadDePosts, setCantidadDePosts] = useState(1);
    const navigate = useNavigate();
    const [isLoading, setisLoading] = useState(false);
    const ajustarCantidadDePosts = () => {
      const anchoVentana = window.innerWidth;
  
      if (anchoVentana < 600) {
        setCantidadDePosts(1);
      } else if (anchoVentana < 900) {
        setCantidadDePosts(2);
      } else if (anchoVentana < 1200) {
        setCantidadDePosts(4);
      } else {
        setCantidadDePosts(4);
      }
    };

    useEffect(() => {
        // Llamar a la función inicialmente
        ajustarCantidadDePosts();
    
        // Agregar un listener para cambios en el tamaño de la ventana
        window.addEventListener('resize', ajustarCantidadDePosts);
    
        // Limpiar el listener cuando el componente se desmonta
        return () => {
          window.removeEventListener('resize', ajustarCantidadDePosts);
        };
    }, []);

    useEffect(()=>{
        setisLoading(true)
        apiBusquedas.getAll({page_size:1000}).then((res)=>{
            setBusquedasList(res.results)
        }).finally(()=>{
            setisLoading(false)
        })
    },[])

    const handleClick = () => {
        console.log('click')
        // navigate('/busquedas')
    }

    function loadingSkeletons() {
        const skeletons = [];
      
        for (let i = 0; i < 3; i++) {
          skeletons.push(
            <Col key={i} sm={XS} md={MD} lg={LG} xl={XL} xxl={XL}>
              <SkeletonCard gridStyle={true}></SkeletonCard>
            </Col>
          );
        }
      
        return skeletons;
    }    

    return (
        <>  
            <div className="d-flex align-items-center mb-3">
                <div className="flex-grow-1">
                    <h5 className="mb-0">Busquedas</h5>
                </div>
                <div className="flexshrink-0">
                    <button 
                        onClick={handleClick} 
                        className="btn btn-primary btn-sm">
                            <i className="ri-star-line align-bottom"></i> 
                            Crear busqueda
                    </button>
                </div>
            </div>
            <Swiper
                slidesPerView={cantidadDePosts}
                spaceBetween={24}
                mousewheel={true}
                modules={[Autoplay, Mousewheel]}
                className="cryptoSlider">

                    {
                        !isLoading ?
                        (BusquedasList || []).map((item, key) => (
                            <SwiperSlide key={key}>
                                <BusquedasCard mute={true} busqueda={item}></BusquedasCard>
                            </SwiperSlide>
                        )):<Row>
                            {loadingSkeletons()}
                        </Row>
                        
                    }

                
            </Swiper>        
        </>
    );
};
