import React, { useEffect } from 'react';
import './DatosAreasComunes.css';
import {
    Col,
    Input,
    FormFeedback,
  } from "reactstrap";
  import { useSelector, useDispatch } from "react-redux";
import { getciudades, getestados, getfranquicias, gettiposinmuebles, gettiposnegociacion } from "../../../../../store/actions";
import { InputSelect } from "../../../../Forms/Inputs/InputSelect/InputSelect";
import { InputNumberSelect } from "../../../../Forms/Inputs/InputNumberSelect/InputNumberSelect";
import { InputText } from "../../../../Forms/Inputs/InputText/InputText";
import { alternativasChoices, amobladoChoices, antiguedadChoices, statusChoices, vistasChoices } from "../../../../../helpers/options";
import { InputSwitch } from '../../../../Forms/Inputs/InputSwitch/InputSwitch';
  


export const DatosAreasComunes = ({validation}) => {
    const dispatch = useDispatch();

    const { propiedades } = useSelector((state) => ({
        propiedades: state.Propiedades,
    }));

    useEffect(() => {
        dispatch(gettiposnegociacion({page_size: 1000}));
        dispatch(getciudades({page_size: 1000}));
        dispatch(getestados({page_size: 1000}));
        dispatch(gettiposinmuebles({page_size: 1000}));
        dispatch(getfranquicias({page_size: 1000}));
      }, [dispatch]);
    return (
        <>
            <Col  lg={4}>
                <InputSwitch
                    name="tanque_agua_comun"
                    label="Tanque de agua en común"
                    validation={validation}
                />
            </Col>
            <Col  lg={4}>
                <InputSwitch
                    name="ascensor"
                    label="Ascensor"
                    validation={validation}
                />
            </Col>
            <Col  lg={4}>
                <InputSwitch
                    name="seguridad"
                    label="Seguridad Privada"
                    validation={validation}
                />
            </Col>
            <Col  lg={4}>
                <InputSwitch
                    name="parque_infantil"
                    label="Parque Infantil"
                    validation={validation}
                />
            </Col>
            <Col  lg={4}>
                <InputSwitch
                    name="family_room"
                    label="Sala de Estar"
                    validation={validation}
                />
            </Col>
            <Col  lg={4}>
                <InputSwitch
                    name="piscina"
                    label="Piscina"
                    validation={validation}
                />
            </Col>
            <Col  lg={4}>
                <InputSwitch
                    name="gym"
                    label="Gimnasio"
                    validation={validation}
                />
            </Col>
            <Col  lg={4}>
                <InputSwitch
                    name="bbq"
                    label="Parrilleras"
                    validation={validation}
                />
            </Col>
            <Col  lg={4}>
                <InputSwitch
                    name="salon_fiestas"
                    label="Salon de Fiestas"
                    validation={validation}
                />
            </Col>
            <Col  lg={4}>
                <InputSwitch
                    name="cancha_deportiva"
                    label="Cancha Deportiva"
                    validation={validation}
                />
            </Col>
            <Col  lg={4}>
                <InputSwitch
                    name="pozo_profundo"
                    label="Pozo Profundo"
                    validation={validation}
                    optionsProps={{
                    value: 'id',
                    label: 'nombre',
                    items: vistasChoices || [],
                }}
                />
            </Col>
            <Col  lg={4}>
                <InputSwitch
                    name="planta_electrica_comun"
                    label="Planta Electrica Común"
                    validation={validation}
                    optionsProps={{
                    value: 'id',
                    label: 'nombre',
                    items: amobladoChoices || [],
                }}
                />
            </Col>        
        </>
    );
};
