import React, { useContext, useState } from 'react';
import { Card, CardBody, Col, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import classnames from 'classnames';
import './index.css'
//import images
import slack from '../../../assets/images/brands/slack.png';
import OverviewTab from './OverviewTab';
import DocumentsTab from './DocumentsTab';
import ActivitiesTab from './ActivitiesTab';
import TeamTab from './TeamTab';
import Calender from '../../Calendar';
import { Swiper, SwiperSlide } from 'swiper/react';
import ParamInput from '../../Forms/Inputs/InputParam/InputParam';
import { AbilityContext } from '../../../helpers/acl/Can';

const Section = ({franquiciaStore,franq}) => {
    const ability = useContext(AbilityContext)

    const [configVars, setconfigVars] = useState({});
    //Tab 
    // const { franquicia_detail } = franquiciaStore
    const  franquicia_detail  = franquiciaStore
    const [activeTab, setActiveTab] = useState('1');
    const toggleTab = (tab) => {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    };
    
    if(!franquicia_detail){
        return null
    }
    return (
        <React.Fragment>
            <Row>
                <Col lg={12}>
                    <Card className="mt-n4 mx-n4 border-0">
                        <div className="bg-soft-primary">
                            <CardBody className="pb-0 px-4">
                                <Row className="mb-3">
                                    <div className="col-md">
                                        <Row className="align-items-center g-3">
                                            <div className="col-md-auto">
                                                <div className="avatar-md">
                                                    <div className="avatar-title bg-white rounded-circle rounded-circle_fix">
                                                        <img src={franquicia_detail.first_image_url} alt="" style={{width:'auto',height:'100%'}} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md">
                                                <div>
                                                    <h4 className="fw-bold">{franquicia_detail.nombre || ''}</h4>
                                                    <div className="hstack gap-3 flex-wrap">
                                                        <div>
                                                            <i className="ri-building-line align-bottom me-1"></i>
                                                            {franquicia_detail?.tipo_franquicia?.nombre || ''}
                                                        </div>
                                                        <div className="vr"></div>
                                                        <div>
                                                            <i className="ri-map-pin-line align-bottom me-1"></i>
                                                            {franquicia_detail?.ciudad?.nombre || ''} -{" "}
                                                            {franquicia_detail?.estado?.nombre || ''}
                                                        </div>
                                                        {/* <div className="vr"></div> */}
                                                        {/* <div className="badge rounded-pill bg-primary fs-12">New</div> */}
                                                        {/* <div className="badge rounded-pill bg-success fs-12">High</div> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </Row>
                                    </div>
                                    <div className="col-md-auto">
                                        {/* <div className="hstack gap-1 flex-wrap">
                                            <button type="button" className="btn py-0 fs-16 favourite-btn active">
                                                <i className="ri-star-fill"></i>
                                            </button>
                                            <button type="button" className="btn py-0 fs-16 text-body">
                                                <i className="ri-share-line"></i>
                                            </button>
                                            <button type="button" className="btn py-0 fs-16 text-body">
                                                <i className="ri-flag-line"></i>
                                            </button>
                                        </div> */}
                                    </div>
                                </Row>

                                <Nav className="nav-tabs-custom border-bottom-0" role="tablist">
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: activeTab === '1' }, "fw-semibold")}
                                            onClick={() => { toggleTab('1'); }}
                                            href="#">
                                            Principal
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: activeTab === '2' }, "fw-semibold")}
                                            onClick={() => { toggleTab('2'); }}
                                            href="#">
                                            Documentos
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: activeTab === '3' }, "fw-semibold")}
                                            onClick={() => { toggleTab('3'); }}
                                            href="#">
                                            Equipo
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: activeTab === '4' }, "fw-semibold")}
                                            href="/calendario">
                                            Calendario
                                        </NavLink>
                                    </NavItem>
                                    {
                                        ability.can('update',franq) &&
                                        <NavItem>
                                            <NavLink
                                                onClick={() => { toggleTab('5'); }}
                                                className={classnames({ active: activeTab === '5' }, "fw-semibold")}>
                                                Parametros Externos
                                            </NavLink>
                                        </NavItem>
                                    }
                                </Nav>
                            </CardBody>
                        </div>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col lg={12}>
                    <TabContent activeTab={activeTab} className="text-muted">
                        <TabPane tabId="1">
                            <OverviewTab detail={franq} franquiciaStore={franquiciaStore}/>
                        </TabPane>
                        <TabPane tabId="2">
                            <DocumentsTab />
                        </TabPane>
                        <TabPane tabId="3">
                            <TeamTab />
                        </TabPane>
                        <TabPane tabId="4">
                            <Calender />
                        </TabPane>
                        {
                            franq &&
                            <TabPane tabId="5">

                                <Swiper
                                    id='parametros'
                                    slidesPerView={4}
                                    spaceBetween={24}
                                    mousewheel={true}
                                    className="cryptoSlider">
                                        <SwiperSlide className='bg-primary p-3 rounded-3 text-white'>
                                            <ParamInput franquicia={franq} descripcion={'Este parametro es necesario para recibir informacion de los formularios de contacto generados por las propiedades en sitios de terceros. Es un campo obligatorio.'} name="contact_email" setter={setconfigVars}>
                                            </ParamInput> 
                                        </SwiperSlide>
                                        <SwiperSlide  className='bg-primary p-3 rounded-3 text-white'>
                                            <ParamInput franquicia={franq} descripcion={'El numero de telefono es usado para mostrar en la informacion de contacto de la franquicia en sitios de terceros.'} name="contact_nro_tlf" setter={setconfigVars}>
                                            </ParamInput>
                                        </SwiperSlide>
                                        <SwiperSlide  className='bg-primary p-3 rounded-3 text-white'>
                                            <ParamInput franquicia={franq} descripcion={'El numero de Whatsapp es utilizado para conectar los formularios de contacto de whatsapp con el asesor de la franquicia.'} name="contact_nro_whatsapp" setter={setconfigVars}>
                                            </ParamInput> 
                                        </SwiperSlide>
                                        <SwiperSlide  className='bg-primary p-3 rounded-3 text-white'>
                                            <ParamInput franquicia={franq} descripcion={'Utilizado para carga de RRSS de la franquicia en la pagina web donde se muestren las propiedades.'} name="contact_telegram_username" setter={setconfigVars}>
                                            </ParamInput> 
                                        </SwiperSlide>
                                        <SwiperSlide className='bg-primary p-3 rounded-3 text-white'>
                                            <ParamInput franquicia={franq}  descripcion={'Url de direccion de pagina web de la franquicia en caso de disponer de una.'} name="contact_website_url" setter={setconfigVars}>
                                            </ParamInput> 
                                        </SwiperSlide>
                                        <SwiperSlide  className='bg-primary p-3 rounded-3 text-white'>
                                            <ParamInput franquicia={franq} descripcion={'Direccion de Facebook en RRSS'} name="contact_facebook_url" setter={setconfigVars}>
                                            </ParamInput> 
                                        </SwiperSlide>
                                        <SwiperSlide  className='bg-primary p-3 rounded-3 text-white'>
                                            <ParamInput franquicia={franq} descripcion={'Direccion de LinkedIn en RRSS.'} name="contact_linkedin_url" setter={setconfigVars}>
                                            </ParamInput> 
                                        </SwiperSlide>
                                        <SwiperSlide  className='bg-primary p-3 rounded-3 text-white'>
                                            <ParamInput franquicia={franq} descripcion={'Direccion de Twitter/X en RRSS.'} name="contact_x_url" setter={setconfigVars}>
                                            </ParamInput> 
                                        </SwiperSlide>
                                        <SwiperSlide  className='bg-primary p-3 rounded-3 text-white'>
                                            <ParamInput franquicia={franq} descripcion={'Direccion de Youtube en RRSS.'} name="contact_youtube_url" setter={setconfigVars}>
                                            </ParamInput> 
                                        </SwiperSlide>
                                        <SwiperSlide className='bg-primary p-3 rounded-3 text-white'>
                                            <ParamInput franquicia={franq} descripcion={'Perfil de Instagram en RRSS.'}  name="contact_instagram_url" setter={setconfigVars}>
                                            </ParamInput> 
                                        </SwiperSlide>
                                        <SwiperSlide className='bg-primary p-3 rounded-3 text-white'>
                                            <ParamInput franquicia={franq} descripcion={'Perfil de Tik Tok en RRSS.'}  name="contact_tik_tok_url" setter={setconfigVars}>
                                            </ParamInput> 
                                        </SwiperSlide>
                                </Swiper>  

                            </TabPane>
                        }
                    </TabContent>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default Section;