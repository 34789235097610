import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Alert, Container, Row } from 'reactstrap';
import BreadCrumb from '../../Components/Common/BreadCrumb';
import BalanceOverview from './BalanceOverview';
import ClosingDeals from './ClosingDeals';
import DealsStatus from './DealsStatus';
import DealType from './DealType';
import MyTasks from './MyTasks';
import SalesForecast from './SalesForecast';
import UpcomingActivities from './UpcomingActivities';
import Widgets from './Widgets';
import { createApiMethods } from '../../helpers/api_calls';
import { BUSQUEDAS, CALENDAR, CLIENTES, CONTACTOS, FRANQUICIAS, PROPIEDADES } from '../../helpers/api_urls';
import UsersByDevice from '../DashboardAnalytics/UsersByDevice';
import PropiedadesStatus from './PropiedadesStatus';
import { AbilityContext } from '../../helpers/acl/Can';
import { MisBusquedas } from './MisBusquedas/MisBusquedas';
import { MisPropiedades } from './MisPropiedades/MisPropiedades';
import { Link } from 'react-router-dom';

const DashboardCrm = () => {
    document.title="CRM | Inmoapp";
    const apiClientes = useMemo(() => createApiMethods(CLIENTES), []);
    const apiPropiedades = useMemo(() => createApiMethods(PROPIEDADES), []);
    const apiCalendar = useMemo(() => createApiMethods(CALENDAR), []);
    const apiFranquicias = useMemo(() => createApiMethods(FRANQUICIAS), []);
    const [widgetData, setWidgetData] = useState(null);
    const ability = useContext(AbilityContext)
    const apiContactos = useMemo(() => createApiMethods(CONTACTOS), []);
    const [toggleContactoNotif, settoggleContactoNotif] = useState(false);

    
    useEffect(()=>{
        Promise.all([
            apiFranquicias.getCustom('franchise_stats'),
            apiClientes.getCustom('client_stats'),
            apiPropiedades.getCustom('property_stats'),
            apiCalendar.getCustom('visit_stats'),
            apiContactos.getAll({page_size:1000})
        ]).then((res)=>{
            setWidgetData({
                franchise_stats : res[0],
                client_stats : res[1],
                property_stats : res[2],
                visit_stats : res[3]
            })
            settoggleContactoNotif(!res[4].results.length > 0)
        })
    },[])    


    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>          
                    <BreadCrumb title="CRM" pageTitle="Dashboards" />
                    {
                        toggleContactoNotif &&
                        <Alert color="primary">
                            <strong> Agregue un medio de contacto! </strong>Recuerde agregar un medio de contacto para que sus clientes potenciales puedan contactarlo desde el portal de inmuebles.
                            <Link
                                type='a'
                                to="/perfil">
                                Haga click aqui para agregar un medio de contacto
                            </Link> 
                        </Alert>
                    }
                    <MisPropiedades></MisPropiedades>
                    <MisBusquedas></MisBusquedas>

                    {
                        widgetData &&
                        <>
                            <Row>
                                <Widgets data={widgetData} />
                            </Row>
                            <Row>
                                <DealsStatus />
                                <PropiedadesStatus />                                
                                <UsersByDevice stats={widgetData.visit_stats.estatus_stats}/>
                                <SalesForecast stats={widgetData.client_stats.status_stats} />
                            </Row>
                        </>
                    }
                    <Row>
                        <UpcomingActivities />
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default DashboardCrm;