import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import './Clientes.css';
import { createApiMethods } from '../../helpers/api_calls';
import { CLIENTES, CONTACTOCLIENTES, FRANQUICIAS, MEDIOS_DE_CONTACTO } from '../../helpers/api_urls';
import { useFormik } from 'formik';
import { ToastContainer, toast } from 'react-toastify';
import * as Yup from "yup";
import { Button, ButtonGroup, Card, CardBody, CardFooter, Col, Container, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledDropdown } from 'reactstrap';
import { InputText } from '../Forms/Inputs/InputText/InputText';
import BreadCrumb from '../../Components/Common/BreadCrumb';
import { TablaCrud } from '../../Components/TablaCrud/TablaCrud';
import { useAuth } from '../../AuthContext';
import { InputSelect } from '../Forms/Inputs/InputSelect/InputSelect';
import DetalleClienteSidebar from './DetalleClienteSidebar';
import { checkPermissions } from '../../helpers/api_helper';
import { subject } from '@casl/ability';
import Can, { AbilityContext } from '../../helpers/acl/Can';
import { CLIENTE } from '../../helpers/entities';
import { Link } from 'react-router-dom';
import avatar1 from "../../assets/images/users/user-dummy-img.jpg";
import DeleteModal from '../../Components/Common/DeleteModal';
import { useDataManager } from '../../DataContext';


export const Clientes = () => {
    const { user } = useAuth();
    const dataManager = useDataManager();
    const [franquiciasLit, setfranquiciasLit] = useState([]);
    const [loadingFranquicia, setloadingFranquicia] = useState(false);

    const [deleteModal, setdeleteModal] = useState(false);
    const [delteClienteObject, setdelteClienteObject] = useState(null);
    const [isLoading, setisLoading] = useState(false);
    const apiClientes = useMemo(() => createApiMethods(CLIENTES), []);
    const tablaCrudRef = useRef(null);
    const clienteCardRef = useRef(null);
    const [recursoSeleccionado, setrecursoSeleccionado] = useState(null);
    const [contactoSeleccionado, setcontactoSeleccionado] = useState(null);
    const [tiposMediosContacto, settiposMediosContacto] = useState([]);
    const APImediosdecontacto = createApiMethods(MEDIOS_DE_CONTACTO)
    const APIfranquicias = createApiMethods(FRANQUICIAS)
    const APIcontactoclientes = createApiMethods(CONTACTOCLIENTES)
    const [franquiciasAPIResults, setfranquiciasAPIResults] = useState();
    const [isEdition, setIsEdition] = useState(false);
    const [cliente, setcliente] = useState(null);
    const ability = useContext(AbilityContext)

    const seleccionItem = (item) => {
        setrecursoSeleccionado(item)
    }

    const callClientes = () => {
        tablaCrudRef.current.reloadResources();
    }

    useEffect(() => {
        APImediosdecontacto.getAll({page_size: 1000}).then((res)=>{
            settiposMediosContacto(res.results)
        }).catch(console.log)

        APIfranquicias.getAll({page_size: 1000}).then((res)=>{
            setfranquiciasAPIResults(res.results)
        }).catch(console.log)
    }, [])

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
          nombre: (recursoSeleccionado && recursoSeleccionado.nombre) || '',
          tipo:  null,
          valor:  null,
          franquicia: user && user.isAdmin() ? null : user.user.franquicia.id
        },
        validationSchema: Yup.object({
            nombre: Yup.string().required("Este campo es requerido"),
        }),
        onSubmit: (values) => {
            if (recursoSeleccionado) {
                if(recursoSeleccionado.email)
                    delete recursoSeleccionado.email
                
                    const updateResource = {
                    ...recursoSeleccionado,
                    nombre: values.nombre,
                    franquicia: values.franquicia,
                };

                tablaCrudRef.current.toggleLoading();
                delete updateResource.status
                delete updateResource.email
                apiClientes.update(updateResource).then((res)=>{
                    tablaCrudRef.current.reloadResources();
                    toast.success("El cliente fue actualizado con éxito.",{ autoClose: 3000 })
                    tablaCrudRef.current.toggle();
                    setIsEdition(true)
                }).catch( err =>{
                    toast.error("Algo ha fallado.",{ autoClose: 3000 })
                    toast.error(err,{ autoClose: 3000 })
                }).finally(()=>{
                    setrecursoSeleccionado(null)
                    tablaCrudRef.current.toggleLoading();
                })
            } else {
                tablaCrudRef.current.toggleLoading();
                delete values.email
                apiClientes.create(values).then((res)=>{
                    tablaCrudRef.current.reloadResources();
                    toast.success("El cliente fue creado con éxito.",{ autoClose: 3000 })
                    tablaCrudRef.current.toggle();
                }).catch( err =>{
                    toast.error("Algo ha fallado.",{ autoClose: 3000 })
                    toast.error(err,{ autoClose: 3000 })
                }).finally(()=>{
                    validation.resetForm();
                    tablaCrudRef.current.toggleLoading();
                    setIsEdition(false)
                })
            }
        },
    });

    const handleDetalles = (cliente) => {
        console.log(cliente)
        setcliente(cliente)
        clienteCardRef.current.toggle()
    }

    const handleEditar = (cliente) => {
        tablaCrudRef.current.handleClickEdit(cliente)
    }

    useEffect(() => {
        const fetchData = async () => {
        try {
            setloadingFranquicia(true)
            const data = await dataManager.getList(FRANQUICIAS);
            setfranquiciasLit(data.results);
            setloadingFranquicia(false)
        } catch (error) {
            setloadingFranquicia(false)
            console.error('Error fetching data:', error);
          }
        };
    
        fetchData();
      }, [dataManager]);
    
    
    const columns = [
        {
            Header: "Nombre",
            accessor: "nombre",
            filterable: false,
            Cell: (cellProps) => {
                let stringout = `${cellProps.row.original.id} |  ${cellProps.row.original.nombre}`
              return stringout
            },
        },
        {
            Header: "Propiedades",
            accessor: "propiedades",
            filterable: false,
            Cell: (cellProps) => {
              return cellProps.row.original.propiedades?.length
            },
        },
        {
            Header: "Visitas",
            accessor: "visitas",
            filterable: false,
            Cell: (cellProps) => {
              return cellProps.row.original.visitas.length
            },
        },
        {
            Header: "Busquedas",
            accessor: "busquedas",
            filterable: false,
            Cell: (cellProps) => {
              return cellProps.row.original.busquedas.length
            },
        },
        {
            Header: "Estatus",
            accessor: "status",
            filterable: false,
            Cell: (cellProps) => {
                return <span className="text-center badge rounded-pill border border-primary text-primary">
                    { cellProps.row.original.status.nombre }
                </span>
            },
        },
        {
            Header: "Contacto",
            Cell: (cellProps) => {
                let clienteItem = cellProps.row.original
                clienteItem.obj_type = CLIENTE
                return (
                    <>
                      <div className="d-flex align-items-center">
                        <div className="flex-grow-1">
                          {
                              ability.can('update',clienteItem) ?
                              <h5 className="fs-14 mb-1">
                                  {" "}
                                  {cellProps.row.original.contactos_clientes[0]?.valor}
                              </h5> :
                              <h5 className="fs-14 mb-1">
                                  {" ** *** ******* "}
                                  
                              </h5> 
                          }                    
                          <p className="text-muted mb-0">
                            <span className="fw-medium">
                              {" "}
                              {cellProps.row.original.contactos_clientes[0]?.tipo.nombre}
                            </span>
                          </p>
                        </div>
                      </div>
                    </>
                )
            },
          },
          {
            Header: "Franquicia Asignada",
            Cell: (cellProps) => (
              <>
                <div className="d-flex align-items-center">
                  <div className="flex-grow-1">
                    {/* <Can I="update" this={cliente}>
                        <DropdownItem onClick={() => handleEditar(cellProps.row.original)}>Editar </DropdownItem>
                    </Can>                     */}
                    <h5 className="fs-14 mb-1">
                        {" "}
                        {cellProps.row.original.franquicia?.nombre}
                    </h5>
                    <p className="text-muted mb-0">
                      <span className="fw-medium">
                        {" "}
                        {cellProps.row.original.franquicia?.usuario_asignado?.full_name}
                      </span>
                    </p>
                  </div>
                </div>
              </>
            ),
          },
          {
            Header: "Opciones",
            Cell: (cellProps) => {
                const cliente = cellProps.row.original
                cliente.obj_type = 'Cliente'
                if(ability.can('read',CLIENTE))
                    return <>
                        {
                            ability.can('read',cliente) &&
                            <ButtonGroup style={{marginLeft: '5px'}}>
                                <UncontrolledDropdown>
                                    <DropdownToggle tag="button" className="btn btn-light btn-sm">
                                        <i className="ri-more-2-fill"></i>
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <Can I="read" this={cliente} ability={ability}>
                                            <DropdownItem onClick={() => handleDetalles(cellProps.row.original)}>Detalles </DropdownItem>
                                        </Can>
                                        <Can I="update" this={cliente}>
                                            <DropdownItem onClick={() => handleEditar(cellProps.row.original)}>Editar </DropdownItem>
                                        </Can>
                                        <Can I="delete" this={cliente}>
                                            <DropdownItem onClick={() => {
                                                setdeleteModal(true)
                                                setdelteClienteObject(cellProps.row.original)
                                            }}>Eliminar </DropdownItem>
                                        </Can>
                                        <Can I="reasign" this={cliente}>
                                            <DropdownItem>Reasignar</DropdownItem>
                                        </Can>
                                        

                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            </ButtonGroup>            
                        }
                    </>
                else
                    return null
            },
          },
    ]

    const editarContacto = (item) => () =>{
        setIsEdition(true)
        setcontactoSeleccionado(item)
        validation.setFieldValue('tipo',item.tipo.id)
        validation.setFieldValue('valor',item.valor)
    }

    const updateCliente = () => () =>{
        const values = validation.values
        let newobj = {
            ...contactoSeleccionado,
            tipo: values.tipo,
            valor: values.valor,
        }
        tablaCrudRef.current.toggleLoading();
        APIcontactoclientes.update(newobj).then((res)=>{
            tablaCrudRef.current.reloadResources();
            toast.success("El contacto fue actualizado con éxito.",{ autoClose: 3000 })
        }).catch( err =>{
            toast.error("Algo ha fallado.",{ autoClose: 3000 })
            toast.error(err,{ autoClose: 3000 })
        }).finally(()=>{
            validation.setFieldValue('tipo',null)
            validation.setFieldValue('valor',null)
            setcontactoSeleccionado(null)
            setIsEdition(false)
            tablaCrudRef.current.toggleLoading();
        })
    }

    function cardDisplayRows(row) {
        const cliente = row
        cliente.obj_type = 'Cliente'

        return <Card className="card-body">
            <div className="d-flex mb-4 align-items-center">
                <div className="flex-shrink-0">
                    <img src={avatar1} alt="" className="avatar-sm rounded-circle" />
                </div>
                <div className="flex-grow-1 ms-2">
                    <h5 className="card-title mb-1">{row.nombre}</h5>
                    {/* <p className="text-muted mb-0">{row.franquicia?.nombre}</p> */}
                    {
                        ability.can('update',cliente) ?
                        <h5 className="fs-14 mb-1">
                            {" "}
                            {row.contactos_clientes[0]?.valor}
                        </h5> :
                        <h5 className="fs-14 mb-1">
                            {" ** *** ******* "}
                            
                        </h5> 
                    }                    
                    <p className="text-muted mb-0">{row.franquicia?.usuario_asignado?.full_name}</p>            
                    <span className="text-center badge rounded-pill border border-primary text-primary">
                            { row.status.nombre }
                    </span>
                </div>
            </div>
            <div className='d-flex w-100'>
                <Can I="read" this={cliente} ability={ability}>
                    <Link onClick={() => handleDetalles(row)} to="#" className="btn btn-primary btn-sm w-100 mr-1">Ver Detalles</Link>
                </Can>                
                {
                    ability.can('read',cliente) &&
                    <ButtonGroup style={{marginLeft: '5px'}}>
                        <UncontrolledDropdown>
                            <DropdownToggle tag="button" className="btn btn-light btn-sm">
                                <i className="ri-more-2-fill"></i>
                            </DropdownToggle>
                            <DropdownMenu>
                                <Can I="read" this={cliente} ability={ability}>
                                    <DropdownItem onClick={() => handleDetalles(row)}>Detalles </DropdownItem>
                                </Can>
                                <Can I="update" this={cliente}>
                                    <DropdownItem onClick={() => handleEditar(row)}>Editar </DropdownItem>
                                </Can>
                                <Can I="reasign" this={cliente}>
                                    <DropdownItem>Reasignar</DropdownItem>
                                </Can>
                                <Can I="delete" this={cliente}>
                                    <DropdownItem onClick={() => {
                                        setdeleteModal(true)
                                        setdelteClienteObject(row)
                                    }}>Eliminar </DropdownItem>
                                </Can>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </ButtonGroup>            
                }
            </div>
        </Card>;
    }
    
    const formularioTabla = <>
        <Card>
            <CardBody>
                <Row>
                    <Col lg={recursoSeleccionado && recursoSeleccionado.contactos_clientes.length > 0 ? 7 : 12}>
                        <InputText
                            name="nombre"
                            label="Nombre"
                            validation={validation}
                        ></InputText>       
                        {
                            !((contactoSeleccionado === null && recursoSeleccionado !== null) || (recursoSeleccionado === null && isEdition)) &&
                            <>
                                <InputSelect
                                    disabled={(contactoSeleccionado === null && recursoSeleccionado !== null) || (recursoSeleccionado === null && isEdition)}
                                    name         = "tipo"
                                    label        = "Tipo de contacto"
                                    validation   = {validation}
                                    optionsProps = {
                                        {
                                            value:'id',
                                            label:'nombre',
                                            items:tiposMediosContacto || []
                                        }}>
                                </InputSelect>      
                                <InputText
                                    disabled={(contactoSeleccionado === null && recursoSeleccionado !== null) || (recursoSeleccionado === null && isEdition)}
                                    name="valor"
                                    format='phone'
                                    label="Nro"
                                    validation={validation}
                                ></InputText>             
                                {
                                    isEdition &&
                                    <Button 
                                        disabled={(contactoSeleccionado === null && recursoSeleccionado !== null) || (recursoSeleccionado === null && isEdition)}
                                        size={'sm'} 
                                        onClick={updateCliente()}>
                                        Actualizar contacto
                                    </Button>                                                         
                                }
                            </>
                        }
                    </Col>
                    {
                        recursoSeleccionado && recursoSeleccionado.contactos_clientes.length > 0 && (
                            <Col lg={5} md={5} sm={5}>
                                <CardBody>
                                    <div className="table-card">
                                        <table className="table mb-0">
                                            <thead>
                                                <tr>
                                                    <td colSpan="3">
                                                        Contacto Cliente
                                                    </td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {recursoSeleccionado.contactos_clientes.map((item, key) => (
                                                    <tr key={key} className={`${contactoSeleccionado && contactoSeleccionado.id ? 'bg-soft-info': ''}`}>
                                                        <td className="fw-medium">
                                                            <span className="badge badge-soft-success">
                                                                {item.tipo.nombre}
                                                            </span>
                                                        </td>
                                                        <td>{item.valor}</td>
                                                        <td>
                                                            <div className="flex-shrink-0">
                                                                <div className={`d-flex align-items-center gap-1`}>
                                                                    {/* <button type="button" className="btn btn-light btn-sm">Mensaje</button> */}
                                                                    <UncontrolledDropdown>
                                                                        <DropdownToggle type="button" className="btn btn-icon btn-sm fs-16 text-muted dropdown" tag="button">
                                                                            <i className="ri-more-fill"></i>
                                                                        </DropdownToggle>
                                                                        <DropdownMenu>
                                                                            <li><DropdownItem onClick={editarContacto(item)}><i className="ri-eye-fill text-muted me-2 align-bottom"></i>Editar</DropdownItem></li>
                                                                            {/* <li><DropdownItem><i className="ri-star-fill text-muted me-2 align-bottom"></i>Favourite</DropdownItem></li> */}
                                                                            {/* <li><DropdownItem><i className="ri-delete-bin-5-fill text-muted me-2 align-bottom"></i>Delete</DropdownItem></li> */}
                                                                        </DropdownMenu>
                                                                    </UncontrolledDropdown>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>                        
                                </CardBody>
                            </Col>
                        )
                    }
                </Row>
            </CardBody>
            <CardFooter>
                <Row>
                    <Col lg={12}>
                        <InputSelect
                            name         = "franquicia"
                            label        = "Franquicia"
                            validation   = {validation}
                            optionsProps = {
                                {
                                    value:'id',
                                    label:'nombre',
                                    items:franquiciasAPIResults || []
                                }}>
                        </InputSelect>
                    </Col>
                </Row>                
            </CardFooter>
        </Card>
    </>

    const handleFranquiciaSelection = (franquicia) =>{
        if(tablaCrudRef.current){
            let { activeFilters } = tablaCrudRef.current
            tablaCrudRef.current.setActiveFilters({...activeFilters,franquicia: franquicia.id})
        }
    }

    const handleDeleteEvent = () => {
        tablaCrudRef.current.toggleLoading();

        apiClientes.delete(delteClienteObject).then((res)=>{
            tablaCrudRef.current.reloadResources();
            toast.success(res)
        }).catch(toast.error).finally(()=>{
            setdeleteModal(false)
            tablaCrudRef.current.toggleLoading(false);
        })
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <ToastContainer></ToastContainer>
                <DeleteModal
                    show={deleteModal}
                    onDeleteClick={handleDeleteEvent}
                    onCloseClick={() => setdeleteModal(false)}/>                
                <DetalleClienteSidebar 
                    reload={callClientes}
                    cliente={cliente}
                    ref={clienteCardRef}>
                </DetalleClienteSidebar>

                <Container fluid>
                    <BreadCrumb title="Lista de Clientes" pageTitle="Clientes" />
                    <TablaCrud 
                        nombreSingular='Cliente'
                        nombrePlural='Clientes'
                        validation={validation}
                        ref={tablaCrudRef}
                        add={true}
                        edit={false}
                        setResourceItem={seleccionItem}
                        formularioModal={formularioTabla}
                        columns={columns} 
                        cardDisplay={cardDisplayRows}
                        apiLink={apiClientes} >
                            <ButtonGroup style={{marginLeft: '5px'}}>
                                <UncontrolledDropdown>
                                    <DropdownToggle tag="button" className="btn btn-light btn-sm">
                                        <Button 
                                            color="light" 
                                            className="btn-icon btn-sm" 
                                            data-bs-toggle="tooltip" 
                                            data-bs-placement="top" 
                                            title="Filtro por Franquicias"> <i className="ri-account-box-fill" /> </Button>
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <div>
                                            {
                                                loadingFranquicia &&
                                                <div className="text-center">
                                                    <div className="spinner-border text-primary" role="status">
                                                        <span className="sr-only">Loading...</span>
                                                    </div>
                                                </div>
                                            }
                                            {
                                                franquiciasLit && franquiciasLit.map((i)=>(
                                                    <DropdownItem 
                                                        key={i.id} 
                                                        onClick={() => handleFranquiciaSelection(i)}>
                                                            <img src={i.first_image_url} alt="" className="avatar-xxs rounded-circle" />
                                                            {i.nombre}
                                                    </DropdownItem>
                                                ))
                                            }
                                        </div>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            </ButtonGroup>
                        </TablaCrud>
                </Container>
            </div>
        </React.Fragment>
    );
};

