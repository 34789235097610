import React, { useMemo } from "react";
import { Card, CardHeader, Nav, NavItem, NavLink, TabContent } from "reactstrap";
import classnames from "classnames";
import { ProductsTabPane } from "./ProductsTabPane";

export const ProductsTabs = ({
  setData,
  activeTab,
  onTabChange,
  propiedadesPublicas,
  propiedadesPropias,
  propiedadesInactivas,
  propiedadesReserva,
  apiMethods,
  filterOptions,
  user,
}) => {
  const tabs = useMemo(() => [
    { id: "1", title: "Todos", data: propiedadesPublicas },
    { id: "2", title: "Publicados", data: propiedadesPropias },
    { id: "3", title: "Inactivos", data: propiedadesInactivas },
    { id: "4", title: "En Reserva", data: propiedadesReserva },
  ], [propiedadesPublicas, propiedadesPropias, propiedadesInactivas]);

  return (
    <Card>
      <CardHeader className="border-0">
        <Nav className="nav-tabs-custom card-header-tabs border-bottom-0" role="tablist">
          {tabs.map((tab) => (
            <NavItem key={tab.id}>
              <NavLink
                className={classnames({ active: activeTab === tab.id }, "fw-semibold")}
                onClick={() => onTabChange(tab.id)}
                href={`#${tab.title.toLowerCase()}`}
              >
                {tab.title}{" "}
                <span className="badge bg-secondary align-middle rounded-pill ms-1">
                  {tab.data.count}
                </span>
              </NavLink>
            </NavItem>
          ))}
        </Nav>
      </CardHeader>
      <TabContent activeTab={activeTab} className="text-muted">
        {tabs.map((tab) => (
          <ProductsTabPane
            setData={setData}
            key={tab.id}
            tabId={tab.id}
            data={tab.data}
            apiMethods={apiMethods}
            filterOptions={filterOptions}
            user={user}
            isPublicados={tab.id === "2"}
          />
        ))}
      </TabContent>
    </Card>
  );
};