

import { call, put, takeEvery, all, fork } from "redux-saga/effects";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// propiedades Redux States
import {
    GET_PROPIEDADES,
    ADD_NEW_PROPIEDADES,
    DELETE_PROPIEDADES,
    UPDATE_PROPIEDADES,
    GET_CIUDADES,
    GET_ESTADOS,
    GET_FRANQUICIAS,
    GET_TIPOSINMUEBLES,
    GET_TIPOSNEGOCIACION,
    GET_PROPIEDADES_DETAIL
} from "./propiedadesActionTypes";

import {
    propiedadesApiResponseSuccess,
    propiedadesApiResponseError,
    addpropiedadesSuccess,
    addpropiedadesFail,
    updatepropiedadesSuccess,
    updatepropiedadesFail,
    deletepropiedadesSuccess,
    deletepropiedadesFail,
    isLoadingNow,
    isNotLoading
} from "./propiedadesActions";

import { 
    createInmueble, 
    deleteInmueble, 
    getInmuebles, 
    updateInmueble, 
    getEstados, 
    getCiudades, 
    getFranquicias, 
    getTiposInmuebles,
    getTiposNegociacion,
    getInmuebleDetails
} from "../../helpers/api_calls";


function* getpropiedaddetail({idInmueble}) {
    try {
        yield put(isLoadingNow());
        const response = yield call(getInmuebleDetails,idInmueble);
        yield put(propiedadesApiResponseSuccess(GET_PROPIEDADES_DETAIL, response));
    } catch (error) {
        yield put(propiedadesApiResponseError(GET_PROPIEDADES_DETAIL, error));
    } finally {
        yield put(isNotLoading());
    }
}

function* getpropiedades({urlParams}) {
    try {
        const response = yield call(getInmuebles,urlParams);
        yield put(propiedadesApiResponseSuccess(GET_PROPIEDADES, response));
    } catch (error) {
        yield put(propiedadesApiResponseError(GET_PROPIEDADES, error));
    }
}

function* gettiposnegociacion(urlParams) {
    try {
        const response = yield call(getTiposNegociacion,urlParams);
        yield put(propiedadesApiResponseSuccess(GET_TIPOSNEGOCIACION, response));
    } catch (error) {
        yield put(propiedadesApiResponseError(GET_TIPOSNEGOCIACION, error));
    }
}

function* getestados(urlParams) {
    try {
        const response = yield call(getEstados,urlParams);
        yield put(propiedadesApiResponseSuccess(GET_ESTADOS, response));
    } catch (error) {
        yield put(propiedadesApiResponseError(GET_ESTADOS, error));
    }
}

function* getciudades({urlParams}) {
    try {
        const response = yield call(getCiudades,urlParams);
        yield put(propiedadesApiResponseSuccess(GET_CIUDADES, response));
    } catch (error) {
        yield put(propiedadesApiResponseError(GET_CIUDADES, error));
    }
}

function* gettiposinmuebles(urlParams) {
    try {
        const response = yield call(getTiposInmuebles,urlParams);
        yield put(propiedadesApiResponseSuccess(GET_TIPOSINMUEBLES, response));
    } catch (error) {
        yield put(propiedadesApiResponseError(GET_TIPOSINMUEBLES, error));
    }
}

function* getfranquicias(urlParams) {
    try {
        const response = yield call(getFranquicias,urlParams);
        yield put(propiedadesApiResponseSuccess(GET_FRANQUICIAS, response));
    } catch (error) {
        yield put(propiedadesApiResponseError(GET_FRANQUICIAS, error));
    }
}

function* onAddNewpropiedades({ payload: propiedades }) {
    try {
        yield put(isLoadingNow());
        const response = yield call(createInmueble, propiedades);
        yield put(isNotLoading());

        yield put(addpropiedadesSuccess(response));
        toast.success("Se creo la propiedad con Exito!", { autoClose: 3000 });
    } catch (error) {
        yield put(isNotLoading());
        yield put(addpropiedadesFail(error));
        toast.error("Ocurrio un error al cargar la propiedad", { autoClose: 3000 });
    }
}

function* onUpdatepropiedades({ payload: propiedades }) {
    try {
        const response = yield call(updateInmueble, propiedades);
        yield put(updatepropiedadesSuccess(response));
        toast.success("El inmueble fue actualizado con éxito", { autoClose: 3000 });
    } catch (error) {
        yield put(updatepropiedadesFail(error));
        toast.error("Algo ocurrió mientras se acutalizaba el inmueble", { autoClose: 3000 });
    }
}

function* onDeletepropiedades({ payload: propiedades }) {
    try {
        const response = yield call(deleteInmueble, propiedades);
        yield put(deletepropiedadesSuccess({ propiedades, ...response }));
        toast.success("propiedades Delete Successfully", { autoClose: 3000 });
    } catch (error) {
        yield put(deletepropiedadesFail(error));
        toast.error("propiedades Delete Failed", { autoClose: 3000 });
    }
}

export function* watchGetpropiedadDetails() {
    yield takeEvery(GET_PROPIEDADES_DETAIL, getpropiedaddetail);
}

export function* watchGetpropiedades() {
    yield takeEvery(GET_PROPIEDADES, getpropiedades);
}

export function* watchUpdatepropiedades() {
    yield takeEvery(UPDATE_PROPIEDADES, onUpdatepropiedades);
}

export function* watchDeletepropiedades() {
    yield takeEvery(DELETE_PROPIEDADES, onDeletepropiedades);
}

export function* watchAddNewpropiedades() {
    yield takeEvery(ADD_NEW_PROPIEDADES, onAddNewpropiedades);
}


export function* watchGetEstados() {
    yield takeEvery(GET_ESTADOS, getestados);
}

export function* watchGetCiudades() {
    yield takeEvery(GET_CIUDADES, getciudades);
}

export function* watchGetTiposinmuebles() {
    yield takeEvery(GET_TIPOSINMUEBLES, gettiposinmuebles);
}

export function* watchGetFranquicias() {
    yield takeEvery(GET_FRANQUICIAS, getfranquicias);
}

export function* watchGetTiposNegociacion() {
    yield takeEvery(GET_TIPOSNEGOCIACION, gettiposnegociacion);
}


function* propiedadesSaga() {
yield all([
    fork(watchGetpropiedades),
    fork(watchAddNewpropiedades),
    fork(watchDeletepropiedades),
    fork(watchUpdatepropiedades),
    fork(watchGetEstados),
    fork(watchGetCiudades),
    fork(watchGetTiposinmuebles),
    fork(watchGetFranquicias),
    fork(watchGetTiposNegociacion),
    fork(watchGetpropiedadDetails),
]);
}

export default propiedadesSaga;
