import React, { useState } from "react";
import { Card, CardHeader, CardBody, Nav, NavItem, NavLink, TabContent, TabPane, Row } from "reactstrap";
import classnames from "classnames";
import { TabDatosBasicos } from "./tabs/TabDatosBasicos/TabDatosBasicos";
import { TabDatosNegociacion } from "./tabs/TabDatosNegociacion/TabDatosNegociacion";
import { TabContacto } from "./tabs/TabContacto/TabContacto";
import { DatosAreasComunes } from "./tabs/DatosAreasComunes/DatosAreasComunes";

export const PropertyTabsForm = ({ validation, isEdit }) => {
  const [customActiveTab, setcustomActiveTab] = useState("1");

  const toggleCustom = (tab) => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab);
    }
  };

  return (
    <Card>
      <CardHeader>
        <Nav className="nav-tabs-custom card-header-tabs border-bottom-0">
          <NavItem>
            <NavLink
              style={{ cursor: "pointer" }}
              className={classnames({ active: customActiveTab === "1" })}
              onClick={() => toggleCustom("1")}
            >
              Caracteristícas del Inmueble
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              style={{ cursor: "pointer" }}
              className={classnames({ active: customActiveTab === "2" })}
              onClick={() => toggleCustom("2")}
            >
              Áreas Comunes
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              style={{ cursor: "pointer" }}
              className={classnames({ active: customActiveTab === "3" })}
              onClick={() => toggleCustom("3")}
            >
              Datos de Negociacion
            </NavLink>
          </NavItem>
          {/* {!isEdit && ( */}
            <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classnames({ active: customActiveTab === "4" })}
                onClick={() => toggleCustom("4")}
              >
                Datos de Cliente
              </NavLink>
            </NavItem>
          {/* )} */}
        </Nav>
      </CardHeader>
      <CardBody>
        <TabContent activeTab={customActiveTab}>
          <TabPane tabId="1">
            <Row id="TabDatosBasicos">
              <TabDatosBasicos validation={validation} />
            </Row>
          </TabPane>
          <TabPane tabId="2">
            <Row id="DatosAreasComunes">
              <DatosAreasComunes validation={validation} />
            </Row>
          </TabPane>
          <TabPane tabId="3">
            <Row id="TabDatosNegociacion">
              <TabDatosNegociacion validation={validation} isEdit={isEdit} />
            </Row>
          </TabPane>
          {/* {!isEdit && ( */}
            <TabPane id="TabContacto" tabId="4">
              <Row>
                <TabContacto validation={validation} />
              </Row>
            </TabPane>
          {/* )} */}
        </TabContent>
      </CardBody>
    </Card>
  );
};
