import { APIClient, userValidators } from "./api_helper";

import * as url from "./api_urls";

const api = new APIClient();

// Gets the logged in user data from local session
export const getLoggedInUser = () => {
  const user = localStorage.getItem("authUser");
  if (user) return JSON.parse(user);
  return null;
};

export const getLoggedInUserValidations = () => {
  const userObjectString = localStorage.getItem("authUser");
  if (userObjectString) {
    let user = userValidators(JSON.parse(userObjectString));
    return user
  }
  return null;
};

export const isValidValueValidation = (value) => {
  const isValidValue = 
  (value !== undefined) && 
  (value !== null) && 
  (value !== ' ') && 
  (value !== 'Todos') && 
  (value !== 0)
  return isValidValue
}

export const setQueyParams = (urlParams) => {
  const defaultParams = {
    page_size: 10,
    page: 1,
  };

  const queryParams = urlParams ? { ...defaultParams, ...urlParams } : defaultParams;

  let queryStrings = [];

  for (const [key, value] of Object.entries(queryParams)) {
    if (isValidValueValidation(value)) {
      queryStrings.push(key + '=' + value);
    }
  }

  return queryStrings.length ? queryStrings.join('&') : '';
}


// //is user is logged in
export const isUserAuthenticated = () => {
  return getLoggedInUser() !== null;
};

export const postRegister = data => api.create(url.REGISTER_NEW_USER, data);

export const postLogin = data => api.create(url.POST_LOGIN, data);


// Inmuebles
export const getInmuebles = (urlParams) => {
  const queryString = setQueyParams(urlParams)
  return api.get(`${url.INMUEBLES}?${queryString}`);
};

export const createInmueble = (data) => {
  return api.create(`${url.INMUEBLES}`, data,{
    headers: {
      "Content-Type": "multipart/form-data"
    },
  });
}

export const getInmuebleDetails = (payload) => {
  return api.get(`${url.INMUEBLES}${payload}/`);
}

export const updateInmueble = (payload) => {
  if (payload instanceof FormData) {
    const id = payload.get("id");
    return api.update(`${url.INMUEBLES}${id}/`, payload);
  } else {
    return api.update(`${url.INMUEBLES}${payload.id}/`, payload);
  }
}

export const deleteInmueble = (id) => {
  return api.delete(`${url.INMUEBLES}${id}/`);
}


//Franquicias
export const getFranquicias = ({urlParams}) => {
  const queryString = setQueyParams(urlParams)
  return api.get(`${url.FRANQUICIAS}?${queryString}`);
}

export const createFranquicia = (data) => {
  return api.create(`${url.FRANQUICIAS}`, data);
}

export const getFranquiciaDetails = (payload) => {
  return api.get(`${url.FRANQUICIAS}${payload}/`);
}

export const updateFranquicia = (payload) => {
  if (payload instanceof FormData) {
    const id = payload.get("id");
    return api.update(`${url.FRANQUICIAS}${id}/`, payload);
  } else {
    return api.update(`${url.FRANQUICIAS}${payload.id}/`, payload);
  }
};

export const deleteFranquicia = (id) => {
  return api.delete(`${url.FRANQUICIAS}${id}/`);
}


//Estados
export const getEstados = ({urlParams}) => {
  const queryString = setQueyParams(urlParams)
  return api.get(`${url.ESTADOS}?${queryString}`);
}

export const createEstado = (data) => {
  return api.create(`${url.ESTADOS}`, data);
}

export const getEstadoDetails = (payload) => {
  return api.get(`${url.ESTADOS}${payload.id}/`);
}

export const updateEstado = (payload, data) => {
  return api.update(`${url.ESTADOS}${payload.id}/`, payload);
}

export const deleteEstado = (payload) => {
  return api.delete(`${url.ESTADOS}${payload.id}/`);
}

//Ciudades
export const getCiudades = (urlParams) => {
  return api.get(`${url.CIUDADES}`,urlParams);
}

export const createCiudad = (data) => {
  return api.create(`${url.CIUDADES}`, data);
}

export const getCiudadDetails = (payload) => {
  return api.get(`${url.CIUDADES}${payload.id}/`);
}

export const updateCiudad = (payload, data) => {
  return api.update(`${url.CIUDADES}${payload.id}/`, payload);
}

export const deleteCiudad = (payload) => {
  return api.delete(`${url.CIUDADES}${payload.id}/`);
}


//Tipos Inmuebles
export const getTiposInmuebles = ({urlParams}) => {
  const queryString = setQueyParams(urlParams)
  return api.get(`${url.TIPOSINMUEBLES}?${queryString}`);
}

export const createTipoInmueble = (data) => {
  return api.create(`${url.TIPOSINMUEBLES}`, data);
}

export const getTipoInmuebleDetails = (payload) => {
  return api.get(`${url.TIPOSINMUEBLES}${payload.id}/`);
}

export const updateTipoInmueble = (payload, data) => {
  return api.update(`${url.TIPOSINMUEBLES}${payload.id}/`, payload);
}

export const deleteTipoInmueble = (payload) => {
  return api.delete(`${url.TIPOSINMUEBLES}${payload.id}/`);
}


//Tipos Inmuebles
export const getTiposNegociacion = ({urlParams}) => {
  const queryString = setQueyParams(urlParams)
  return api.get(`${url.TIPOSNEGOCIACION}?${queryString}`);
}

export const createTipoNegociacion = (data) => {
  return api.create(`${url.TIPOSNEGOCIACION}`, data);
}

export const getTipoNegociacionDetails = (payload) => {
  return api.get(`${url.TIPOSNEGOCIACION}${payload.id}/`);
}

export const updateTipoNegociacion = (payload, data) => {
  return api.update(`${url.TIPOSNEGOCIACION}${payload.id}/`, payload);
}

export const deleteTipoNegociacion = (payload) => {
  return api.delete(`${url.TIPOSNEGOCIACION}${payload.id}/`);
}

export const userChangeForm = (idUser,data) => {
  return api.create(`/users/${idUser}/change_password/`,data);
};

export const updateUser = (user) => {
  return api.update(`${url.USERS}${user.id}/`, user);
};

export const getUserDetails = (userId) => {
  return api.get(`${url.USERS}${userId}/`);
};


export const createApiMethods = (resource) => {
  const resourceUrl = `${resource}`;

  return {
    getDetails: (id, options) => api.get(`${resourceUrl}${id}/`, null, { ...options }),

    create: (data, options) => api.create(resourceUrl, data, { ...options }),

    getAll: (urlParams, options) => api.get(resourceUrl, urlParams, { ...options }),

    getCustom: (customUrl,urlParams, options) => api.get(`${resourceUrl}${customUrl}/`, urlParams, { ...options }),

    update: (item, options) => {
      let id;
      if(item instanceof FormData) {
        id = item.get('id'); 
      } else {
        id = item.id;
      }
      return api.update(`${resourceUrl}${id}/`, item, {...options});
    },

    delete: (item, options) => api.delete(`${resourceUrl}${item.id}/`, { ...options })
  };
};

