

import { call, put, takeEvery, all, fork } from "redux-saga/effects";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// franquicias Redux States
import {
    GET_FRANQUICIAS,
    ADD_NEW_FRANQUICIAS,
    DELETE_FRANQUICIAS,
    UPDATE_FRANQUICIAS,
    GET_FRANQUICIAS_DETAIL
} from "./franquiciasActionTypes.js";

import {
    franquiciasApiResponseSuccess,
    franquiciasApiResponseError,
    addfranquiciasSuccess,
    addfranquiciasFail,
    updatefranquiciasSuccess,
    updatefranquiciasFail,
    deletefranquiciasSuccess,
    deletefranquiciasFail
} from "./franquiciasActions.js";


import { createFranquicia, deleteFranquicia, getFranquiciaDetails, getFranquicias, updateFranquicia } from "../../helpers/api_calls";
import { isLoadingNowFranquicia, isNotLoadingFranquicia } from "./franquiciasActions";


function* getfranquiciasdetail({idFranquicia}) {
    try {
        yield put(isLoadingNowFranquicia());
        const response = yield call(getFranquiciaDetails,idFranquicia);
        yield put(franquiciasApiResponseSuccess(GET_FRANQUICIAS_DETAIL, response));
    } catch (error) {
        yield put(franquiciasApiResponseError(GET_FRANQUICIAS_DETAIL, error));
    } finally {
        yield put(isNotLoadingFranquicia());
    }
}


function* getfranquicias() {
    try {
        const response = yield call(getFranquicias);
        yield put(franquiciasApiResponseSuccess(GET_FRANQUICIAS, response.data));
    } catch (error) {
        yield put(franquiciasApiResponseError(GET_FRANQUICIAS, error));
    }
}

function* onAddNewfranquicias({ payload: franquicias }) {
    try {
        const response = yield call(createFranquicia, franquicias);

        yield put(addfranquiciasSuccess(response));
        toast.success("franquicias Added Successfully", { autoClose: 3000 });
    } catch (error) {
        yield put(addfranquiciasFail(error));
        toast.error("franquicias Added Failed", { autoClose: 3000 });
    }
}

function* onUpdatefranquicias({ payload }) {
    try {
        const response = yield call(updateFranquicia, payload);
        yield put(updatefranquiciasSuccess(response));
        toast.success("franquicias Updated Successfully", { autoClose: 3000 });
    } catch (error) {
        yield put(updatefranquiciasFail(error));
        toast.error("franquicias Updated Failed", { autoClose: 3000 });
    }
}

function* onDeletefranquicias({ payload: franquicias }) {
    try {
        const response = yield call(deleteFranquicia, franquicias);
        yield put(deletefranquiciasSuccess({ franquicias, ...response }));
        toast.success("franquicias Delete Successfully", { autoClose: 3000 });
    } catch (error) {
        yield put(deletefranquiciasFail(error));
        toast.error("franquicias Delete Failed", { autoClose: 3000 });
    }
}


export function* watchGetfranquiciadetail() {
    yield takeEvery(GET_FRANQUICIAS_DETAIL, getfranquiciasdetail);
}

export function* watchGetfranquicias() {
    yield takeEvery(GET_FRANQUICIAS, getfranquicias);
}

export function* watchUpdatefranquicias() {
    yield takeEvery(UPDATE_FRANQUICIAS, onUpdatefranquicias);
}

export function* watchDeletefranquicias() {
    yield takeEvery(DELETE_FRANQUICIAS, onDeletefranquicias);
}

export function* watchAddNewfranquicias() {
    yield takeEvery(ADD_NEW_FRANQUICIAS, onAddNewfranquicias);
}

function* franquiciasSaga() {
yield all([
    fork(watchGetfranquicias),
    fork(watchAddNewfranquicias),
    fork(watchDeletefranquicias),
    fork(watchUpdatefranquicias),
    fork(watchGetfranquiciadetail),
]);
}

export default franquiciasSaga;
        