import React, { useState } from 'react';
import './InputNumberSelect.css';
import { FormFeedback, Input, Label } from 'reactstrap';

export const InputNumberSelect = ({ validation, ...props }) => {
    const [counter, setCounter] = useState(validation.values[props.name]);
    
    const handleChange = (e) => {
        const value = e.target.value;
        setCounter(value);
        console.log(value)
        validation.setFieldValue(props.name, value);
    }

    function countUP() {
        const newCounter = counter + 1;
        setCounter(newCounter);
        validation.setFieldValue(props.name, newCounter);
    }

    function countDown() {
        const newCounter = counter - 1;
        setCounter(newCounter);
        validation.setFieldValue(props.name, newCounter);
    }

    return (
        <>
            <div className="mb-3">
                <div>
                    <Label htmlFor={props?.name || null} className="form-label">
                        {props?.label || ' '} 
                        { props.required ? <span className="text-danger">*</span> : ' ' }    
                    </Label>
                </div>
                <div className="input-step full-width">
                    <button
                        type="button"
                        className="minus"
                        onClick={countDown}>
                        –
                    </button>
                    <Input
                        value={validation.values[props.name] || 0}
                        onChange={handleChange}/>
                    <button
                        type="button"
                        className="plus"
                        onClick={countUP}>
                        +
                    </button>
                </div>
                {validation.touched[props?.name] && validation.errors[props?.name] ? (
                 <FormFeedback type="invalid" className="d-block"><div>{validation.errors[props?.name]}</div></FormFeedback>
                ) : null}
            </div>        
        </>
    );
};