import {
  API_RESPONSE_SUCCESS, 
  API_RESPONSE_ERROR, 
  GET_FRANQUICIAS, 
  ADD_NEW_FRANQUICIAS, 
  ADD_FRANQUICIAS_SUCCESS, 
  ADD_FRANQUICIAS_FAIL, 
  UPDATE_FRANQUICIAS, 
  UPDATE_FRANQUICIAS_SUCCESS, 
  UPDATE_FRANQUICIAS_FAIL, 
  DELETE_FRANQUICIAS, 
  DELETE_FRANQUICIAS_SUCCESS, 
  DELETE_FRANQUICIAS_FAIL,
  GET_FRANQUICIAS_DETAIL,
  IS_LOADING,
  IS_NOT_LOADING,
  RESET_NEW
} from  "./franquiciasActionTypes.js";

export const clearResetNewFranquicia = () => ({
  type: RESET_NEW,
});

export const isLoadingNowFranquicia = () => ({
  type: IS_LOADING,
});

export const isNotLoadingFranquicia = () => ({
  type: IS_NOT_LOADING,
});

export const franquiciasApiResponseSuccess = (actionType, data) => ({
  type: API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});

export const franquiciasApiResponseError = (actionType, error) => ({
  type: API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const getfranquiciasList = (urlParams) => ({
  type: GET_FRANQUICIAS,
  urlParams
});

export const getfranquiciasdetail = (idFranquicia) =>  ({
  type: GET_FRANQUICIAS_DETAIL,
  idFranquicia
})

export const updatefranquicias = (franquicia) => ({
  type: UPDATE_FRANQUICIAS,
  payload: franquicia,
});

export const updatefranquiciasSuccess = franquicias => ({
  type: UPDATE_FRANQUICIAS_SUCCESS,
  payload: franquicias,
});

export const updatefranquiciasFail = error => ({
  type: UPDATE_FRANQUICIAS_FAIL,
  payload: error,
});

export const addNewfranquicias = franquicias => ({
  type: ADD_NEW_FRANQUICIAS,
  payload: franquicias,
});

export const addfranquiciasSuccess = franquicias => ({
  type: ADD_FRANQUICIAS_SUCCESS,
  payload: franquicias,
});

export const addfranquiciasFail = error => ({
  type: ADD_FRANQUICIAS_FAIL,
  payload: error,
});

export const deletefranquicias = franquicias => ({
  type: DELETE_FRANQUICIAS,
  payload: franquicias,
});

export const deletefranquiciasSuccess = franquicias => ({
  type: DELETE_FRANQUICIAS_SUCCESS,
  payload: franquicias,
});

export const deletefranquiciasFail = error => ({
  type: DELETE_FRANQUICIAS_FAIL,
  payload: error,
});

