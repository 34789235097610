import React, { forwardRef, useImperativeHandle, useMemo, useState } from 'react';
import './PublicDetailCardTemplate.css';
import { createApiMethods } from '../../helpers/api_calls';
import { PROPIEDADESPUBLICAS } from '../../helpers/api_urls';
import { Col, Container, Row } from 'reactstrap';
import { PublicCardTemplate } from '../PublicCardTemplate/PublicCardTemplate';
import { SkeletonCard } from '../SkeletonCard/SkeletonCard';

export const PublicDetailCardTemplate = forwardRef(({item = null},ref) => {
    const apiPropiedadesPublicas = useMemo(() => createApiMethods(PROPIEDADESPUBLICAS), []);
    const [propiedadesApiResponde, setpropiedadesApiResponde] = useState(null);
    const [isLoading, setisLoading] = useState(false);

    useImperativeHandle(ref, () => ({
        setisLoading,
    }));

    function loadingSkeletons() {
        const skeletons = [];
      
        for (let i = 0; i < 1; i++) {
          skeletons.push(
            <Col key={i} lg={12} >
              <SkeletonCard ></SkeletonCard>
            </Col>
          );
        }
      
        return skeletons;
    }
    
    
    return (
        <>
            <section className="section pb-0 hero-section" id="hero">
                <div style={{minHeight: '90vh'}} className="bg-overlay bg-overlay-pattern"></div>
                <Container style={{paddingBottom: '20rem'}}>
                    <Row className="justify-content-center">
                        <Col >
                            {
                                item &&
                                <PublicCardTemplate 
                                    detail={true}
                                    item={item} 
                                    gridStyle={false}>
                                </PublicCardTemplate>
                            }
                        </Col>

                        {
                            isLoading &&
                            loadingSkeletons()
                        }
                    </Row>
                </Container>
            </section>        
        </>
    );
});
PublicDetailCardTemplate.displayName = 'PublicDetailCardTemplate';

export default PublicDetailCardTemplate
