import React, { useEffect, useState, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";

import './TabDatosNegociacion.css';
import {
    Card,
    CardBody,
    Col,
    Container,
    CardHeader,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane,
    Input,
    Label,
    FormFeedback,
    Form,
  } from "reactstrap";
import { InputSelect } from '../../../../Forms/Inputs/InputSelect/InputSelect';
import { addContactFail, getfranquicias, gettiposinmuebles } from "../../../../../store/actions";
import { InputMoney } from "../../../../Forms/Inputs/InputMoney/InputMoney";
import { APIClient } from "../../../../../helpers/api_helper";
import { USERS } from "../../../../../helpers/api_urls";
import { InputSwitch } from "../../../../Forms/Inputs/InputSwitch/InputSwitch";
import { getLoggedInUserValidations } from "../../../../../helpers/api_calls";
import { useAuth } from "../../../../../AuthContext";

export const TabDatosNegociacion = ({isEdit, isCreate ,validation}) => {
    const dispatch = useDispatch();
    const { isAuthenticated, user } = useAuth();

    const { propiedades } = useSelector((state) => ({
        propiedades: state.Propiedades,
    }));

    useEffect(() => {
        const api = new APIClient();
        dispatch(gettiposinmuebles({page_size: 1000}));
        dispatch(getfranquicias({page_size: 1000}));
    }, [dispatch]);
    return (
        <>
            <Col  lg={6}>
                <InputSelect
                      name         = "tipo_inmueble"
                      label        = "Tipo Inmueble"
                      validation   = {validation}
                      optionsProps = {
                          {
                              value:'id',
                              label:'nombre',
                              items:propiedades.tiposinmuebles.results || []
                          }}>
                  </InputSelect>  
            </Col>      
            <Col  lg={6}>
                <InputSelect
                        disabled={!(user.isAdmin() || user.isSuperAdmin() || isCreate)}
                      name         = "franquicia"
                      label        = "Franquicia"
                      validation   = {validation}
                      optionsProps = {
                          {
                              value:'id',
                              label:'nombre',
                              items:propiedades.franquicias.results || []
                          }}>
                  </InputSelect>  
            </Col> 
            <Col lg={6}>
                <InputSelect
                    name         = "tipo_negociacion"
                    label        = "Tipo Negociacion"
                    validation   = {validation}
                    optionsProps = {
                        {
                            value:'id',
                            label:'nombre',
                            items:propiedades.tiposnegociacion.results || []
                        }}>
                </InputSelect>
            </Col>       
            <Col lg={6}>
                <InputMoney
                    label="Precio"
                    name         = "precio"
                    validation   = {validation}>
                </InputMoney>
            </Col>
            <Col  lg={4}>
                <InputSwitch
                    name="posee_hipoteca"
                    label="Posee Hipoteca"
                    validation={validation}
                />
            </Col>

        </>
    );
};
