import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

//Import Icons
import FeatherIcon from "feather-icons-react";
import { useAuth } from "../AuthContext";
import { AbilityContext } from "../helpers/acl/Can";

const Navdata = () => {
    const { user } = useAuth();
    // console.log(user.isAdmin())

    const ability = useContext(AbilityContext)
    
    const history = useNavigate();
    //state data
    const [isDashboard, setIsDashboard] = useState(false);
    const [isPropiedades, setIsPropiedades] = useState(false);
    const [isFranquicia, setIsFranquicia] = useState(false);
    const [isAdministracion, setisAdministracion] = useState(false);

    const [isPreferencias, setIsPreferencias] = useState(false);
    const [isApps, setIsApps] = useState(false);
    const [isAuth, setIsAuth] = useState(false);
    const [isPages, setIsPages] = useState(false);
    const [isBaseUi, setIsBaseUi] = useState(false);
    const [isAdvanceUi, setIsAdvanceUi] = useState(false);
    const [isForms, setIsForms] = useState(false);
    const [isTables, setIsTables] = useState(false);
    const [isCharts, setIsCharts] = useState(false);
    const [isIcons, setIsIcons] = useState(false);
    const [isMaps, setIsMaps] = useState(false);
    const [isMultiLevel, setIsMultiLevel] = useState(false);

    // Apps
    const [isEmail, setEmail] = useState(false);
    const [isSubEmail, setSubEmail] = useState(false);
    const [isEcommerce, setIsEcommerce] = useState(false);
    const [isProjects, setIsProjects] = useState(false);
    const [isTasks, setIsTasks] = useState(false);
    const [isCRM, setIsCRM] = useState(false);
    const [isCrypto, setIsCrypto] = useState(false);
    const [isInvoices, setIsInvoices] = useState(false);
    const [isSupportTickets, setIsSupportTickets] = useState(false);
    const [isNFTMarketplace, setIsNFTMarketplace] = useState(false);
    const [isJobs, setIsJobs] = useState(false);
    const [isJobList, setIsJobList] = useState(false);
    const [isCandidateList, setIsCandidateList] = useState(false);

    // Authentication
    const [isSignIn, setIsSignIn] = useState(false);
    const [isSignUp, setIsSignUp] = useState(false);
    const [isPasswordReset, setIsPasswordReset] = useState(false);
    const [isPasswordCreate, setIsPasswordCreate] = useState(false);
    const [isLockScreen, setIsLockScreen] = useState(false);
    const [isLogout, setIsLogout] = useState(false);
    const [isSuccessMessage, setIsSuccessMessage] = useState(false);
    const [isVerification, setIsVerification] = useState(false);
    const [isError, setIsError] = useState(false);
    // Pages
    const [isProfile, setIsProfile] = useState(false);
    const [isLanding, setIsLanding] = useState(false);

    // Charts
    const [isApex, setIsApex] = useState(false);

    // Multi Level
    const [isLevel1, setIsLevel1] = useState(false);
    const [isLevel2, setIsLevel2] = useState(false);
    const [iscurrentState, setIscurrentState] = useState('Dashboard');

    function updateIconSidebar(e) {
        if (e && e.target && e.target.getAttribute("subitems")) {
            const ul = document.getElementById("two-column-menu");
            const iconItems = ul.querySelectorAll(".nav-icon.active");
            let activeIconItems = [...iconItems];
            activeIconItems.forEach((item) => {
                item.classList.remove("active");
                var id = item.getAttribute("subitems");
                if (document.getElementById(id))
                    document.getElementById(id).classList.remove("show");
            });
        }
    }

    useEffect(() => {
        document.body.classList.remove('twocolumn-panel');
        if (iscurrentState !== 'Propiedades') {
            setIsPropiedades(false);
        }
        if (iscurrentState !== 'Preferencias') {
            setIsPreferencias(false);
        }
        if (iscurrentState !== 'Franquicias') {
            setIsFranquicia(false);
        }

        if (iscurrentState !== 'CRM') {
            setIsDashboard(false);
        }

    }, [
        history,
        iscurrentState,
    ]);

    const menuItems = [
        // ...extrasItems,
        {
            id: "widgets",
            label: "Dashboard",
            icon: <FeatherIcon icon="copy" className="icon-dual" />,
            link: "/dashboard",
            click: function (e) {
                e.preventDefault();
                setIscurrentState('Widgets');
            }
        },
        {
            label: "Menu",
            isHeader: true,
        },

        {
            id: "menu",
            label: "Propiedades",
            icon: <FeatherIcon icon="home" className="icon-dual" />,
            link: "/#",
            stateVariables: isPropiedades,
            click: function (e) {
                e.preventDefault();
                setIsPropiedades(!isPropiedades);
                setIscurrentState('Dashboard');
                updateIconSidebar(e);
            },
            subItems: [
                {
                    id: "propiedades",
                    label: "Propiedades",
                    link: "/inmuebles",
                    parentId: "menu",
                },
                {
                    id: "formulario_inmuebles",
                    label: "Crear Inmuebles",
                    link: "/formulario-inmueble",
                    parentId: "menu",
                },
            ],
        },


        {
            id: "franquicias",
            label: "Franquicias",
            icon: <FeatherIcon icon="book" className="icon-dual" />,
            link: "/#",
            stateVariables: isFranquicia,
            click: function (e) {
                e.preventDefault();
                setIsFranquicia(!isFranquicia);
                setIscurrentState('Franquicias');
                updateIconSidebar(e);
            },
            subItems: [
                {
                    id: "franquicias",
                    label: "Franquicias",
                    link: "/franquicias",
                    parentId: "franquicias",
                },
                (user !== null && (!user?.isAdmin()) ) ? {
                    id: "mi_franquicia",
                    label: "Mi Franquicia",
                    link: "/mi-franquicia",
                    parentId: "franquicias",
                } : null,
            ],
        },

        {
            id: "crm",
            label: "CRM",
            icon: <FeatherIcon icon="user-check" className="icon-dual" />,
            link: "/#",
            stateVariables: isCRM,
            click: function (e) {
                e.preventDefault();
                setIsCRM(!isCRM);
                setIscurrentState('CRM');
                updateIconSidebar(e);
            },
            subItems: [
                {
                    id: "busquedas",
                    label: "Busquedas",
                    link: "/busquedas",
                    parentId: "crm",
                },
                {
                    id: "clientes",
                    label: "Panel de Clientes",
                    link: "/clientes-board",
                    parentId: "crm",
                },
                {
                    id: "clientes",
                    label: "Lista de Clientes",
                    link: "/clientes",
                    parentId: "crm",
                },
                {
                    id: "calendar",
                    label: "Calendario",
                    link: "/calendario",
                    parentId: "crm",
                }              
            ],
        },

        {
            id: "administracion",
            label: "Administracion",
            icon: <FeatherIcon icon="briefcase" className="icon-dual" />,
            link: "/#",
            stateVariables: isAdministracion,
            click: function (e) {
                e.preventDefault();
                setisAdministracion(!isAdministracion);
                setIscurrentState('Administracion');
                updateIconSidebar(e);
            },
            subItems: [
                {
                    id: "reservas",
                    label: "Reservas",
                    link: "/reservas",
                    parentId: "administracion",
                }
            ],
        },

        {
            id: "preferencias",
            label: "Preferencias",
            icon: <FeatherIcon icon="tool" className="icon-dual" />,
            link: "/#",
            stateVariables: isPreferencias,
            click: function (e) {
                e.preventDefault();
                setIsPreferencias(!isPreferencias);
                setIscurrentState('Preferencias');
                updateIconSidebar(e);
            },
            subItems: [
                {
                    id: "integraciones",
                    label: "Integraciones",
                    link: "/integraciones",
                    parentId: "preferencias",
                },
                {
                    id: "profile",
                    label: "Perfil",
                    link: "/perfil",
                    parentId: "preferencias",
                }
            ],
        },

        {
            label: " ",
            isHeader: true,
        },
        {
            label: " ",
            isHeader: true,
        },


    ];
    return <React.Fragment>{menuItems}</React.Fragment>;
};
export default Navdata;