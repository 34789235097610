import React, { useState, useEffect } from 'react';
import { Button, Card, CardBody, Input, InputGroup, InputGroupText } from 'reactstrap';
import ParamInput from '../InputParam/InputParam';

const DynamicParamInput = ({ franquicia, validation, name }) => {
  const [params, setParams] = useState([]);
  const [newParamName, setNewParamName] = useState('');

  useEffect(() => {
    if (!validation.values[name]) {
      validation.setFieldValue(name, {});
    }
  }, []);

  const addParam = () => {
    if (newParamName && !params.some(p => p.name === newParamName)) {
      setParams([...params, { id: Date.now(), name: newParamName }]);
      setNewParamName('');
      validation.setFieldValue(`${name}.${newParamName}`, '');
    } else {
      alert('Por favor ingrese un parametro unico.');
    }
  };

  const removeParam = (id) => {
    const paramToRemove = params.find(p => p.id === id);
    setParams(params.filter(param => param.id !== id));
    
    const updatedValues = { ...validation.values[name] };
    delete updatedValues[paramToRemove.name];
    validation.setFieldValue(name, updatedValues);
  };

  const updateParamValue = (paramName, value) => {
    validation.setFieldValue(`${name}.${paramName}`, value);
  };

  return (
    <Card className='bg-light'>
      <CardBody className='p-2'>
        <InputGroup size='sm' className="mb-3">
          <Input
            type="text"
            bsSize="sm"
            placeholder="Nuevo parametro"
            value={newParamName}
            onChange={(e) => setNewParamName(e.target.value)}
          />
          <InputGroupText>
            <Button size='sm' color="primary" onClick={addParam} disabled={!newParamName}>
              Crear
            </Button>
          </InputGroupText>
        </InputGroup>

        {params.map((param) => (
          <Card key={param.id} className='bg-soft-info'>
            <CardBody className='p-2'>
              <div className="d-flex flex-row-reverse justify-content-between align-items-center">
                <Button color="danger" size="sm" onClick={() => removeParam(param.id)}>
                  Borrar
                </Button>
              </div>
              <ParamInput
                name={param.name}
                setter={(value) => updateParamValue(param.name, value)}
                descripcion={``}
                franquicia={franquicia()}
                value={''}
              />
            </CardBody>
          </Card>
        ))}
      </CardBody>
    </Card>
  );
};

export default DynamicParamInput;