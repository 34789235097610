import React, { useState, useEffect, useMemo, useRef } from 'react';
import { Card, CardHeader, Col, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { SalesForecastCharts } from './DashboardCrmCharts';
import ReactApexChart from "react-apexcharts";
import { useSelector, useDispatch } from "react-redux";
import { getSalesForecastChartsData } from '../../store/dashboardCRM/action';
import { createApiMethods } from '../../helpers/api_calls';
import { CLIENTES } from '../../helpers/api_urls';
import { APIClient, formatDateToYYYYMMDD } from '../../helpers/api_helper';
import { InputDateRange } from '../Forms/Inputs/InputDateRange/InputDateRange';
import getChartColorsArray from '../../Components/Common/ChartsDynamicColor';
const api = new APIClient();


const SalesForecast = () => {
    const apiClientes = useMemo(() => createApiMethods(CLIENTES), []);
    const dateRangeRef = useRef({});
    const [chartData, setchartData] = useState([]);
    const [x, setx] = useState([]);
    const [y, sety] = useState([]);

    useEffect(()=>{
        callClientes()
    },[])

    const callClientes = (range) => {
        let obj= {}
        if(range){
            const [created_at_after, created_at_before] = range
            obj = {
                created_at_after : formatDateToYYYYMMDD(created_at_after),
                created_at_before : formatDateToYYYYMMDD(created_at_before),
            }
        }
        apiClientes.getCustom('status_cantidad',obj).then((res)=>{
            let data = Object.keys(res).map((key)=>{
                return res[key]
            })
            setx(Object.keys(res))
            sety(data)
            let series = [
                {
                    data: data
                }
            ]
            setchartData(series)
        }).catch(console.log)

    }

    var options = {
        chart: {
            type: 'bar',
            height: 341,
            toolbar: {
                show: false,
            },
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '65%',
            },
        },
        stroke: {
            show: true,
            width: 5,
            colors: ['transparent']
        },
        xaxis: {
            categories: x,
            axisTicks: {
                show: false,
                borderType: 'solid',
                color: '#78909C',
                height: 6,
                offsetX: 0,
                offsetY: 0
            },
            title: {
                text: 'Total Forecasted Value',
                offsetX: 0,
                offsetY: -30,
                style: {
                    color: '#78909C',
                    fontSize: '12px',   
                    fontWeight: 400,
                },
            },
        },
        yaxis: {
            // labels: {
            //     formatter: function (value) {
            //         return "$" + value + "k";
            //     }
            // },
            tickAmount: 4,
            min: 0
        },
        fill: {
            opacity: 1
        },
        legend: {
            show: true,
            position: 'bottom',
            horizontalAlign: 'center',
            fontWeight: 500,
            offsetX: 0,
            offsetY: -14,
            itemMargin: {
                horizontal: 8,
                vertical: 0
            },
            markers: {
                width: 10,
                height: 10,
            }
        },
        // colors: areachartSalesColors
    };

    return (
        <React.Fragment>
            <Col xxl={3} md={6}>
                <Card>
                    <CardHeader className="align-items-center d-flex">
                        <h4 className="card-title mb-0 flex-grow-1">Status Clientes</h4>
                        <div className="flex-shrink-0">
                            <InputDateRange update={callClientes} ref={dateRangeRef}></InputDateRange>
                        </div>
                    </CardHeader>
                    <div className="card-body pb-0">
                        <div id="sales-forecast-chart" className="apex-charts" dir="ltr">
                        <ReactApexChart
                                options={options}
                                series={chartData}
                                type="bar"
                                height="341"
                                className="apex-charts"
                            />
                            {/* <SalesForecastCharts series={chartData} dataColors='["--vz-primary-rgb, 0.75", "--vz-primary", "--vz-primary-rgb, 0.55"]' /> */}
                        </div>
                    </div>
                </Card>
            </Col>
        </React.Fragment>
    );
};

export default SalesForecast;