import React, { useContext, useEffect, useRef, useState } from "react";
import { Button, Spinner, Form, FormGroup, Label } from "reactstrap";
import ConfiguracionForm from "../../../WebSync.js/ConfiguracionForm";
import { InputModal } from "../InputModal/InputModal";
import { InputText } from "../InputText/InputText";
import { APIClient } from "../../../../helpers/api_helper";
import { useFormik } from "formik";
import { AbilityContext } from "../../../../helpers/acl/Can";

const ParamInput = ({ name, setter, descripcion, franquicia, int }) => {
  const modalRef = useRef(null);
  const api = new APIClient();
  const [paramValue, setParamValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const ability = useContext(AbilityContext);

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: { [name]: paramValue }
  });

  useEffect(() => {
    const fetchParamValue = async () => {
      setIsLoading(true);
      try {
        if (int) {
          const value = int.config_params[name];
          setParamValue(value);
          updateConfigVars(value);
        } else if (franquicia) {
          const res = await api.get(`franquicias/${franquicia.id}/obtener_parametro/?nombre=${name}`);
          setParamValue(res.valor);
          updateConfigVars(res.valor);
        } else {
          const res = await api.get(`configuraciones/obtener_parametro/?nombre=${name}`);
          setParamValue(res.valor);
          updateConfigVars(res.valor);
        }
      } catch (e) {
        console.error(e);
      } finally {
        setIsLoading(false);
      }
    };

    fetchParamValue();
  }, [name, franquicia, int]);

  const updateConfigVars = (value) => {
    if (setter && value !== undefined) {
      setter(prevState => ({
        ...prevState,
        [name]: value
      }));
    }
  };

  const handleCreateParam = () => {
    modalRef.current.toggle();
  };

  const handleUpdateParam = () => {
    modalRef.current.toggle();
  };

  const handleSetParams = (value) => {
    setParamValue(value.valor);
    updateConfigVars(value.valor);
  };

  return (
    <Form>
      <FormGroup noMargin>
        {isLoading ? (
          <>
            <Label>
              <strong>{name}</strong>
            </Label>
            <div className="w-100 text-center mt-3">
              <Spinner color="primary">Loading...</Spinner>
            </div>
          </>
        ) : (
          <>
            <InputText
              type="text"
              name={name}
              nopadding={true}
              error={paramValue ? undefined : `!Parametro no configurado`}
              validation={validation}
              label={paramValue ? name : `${name}`}
              disabled={true}
            />
            {ability.can('update', franquicia) && (
              <div className="text-center mt-2">
                {paramValue ? (
                  <Button
                    onClick={handleUpdateParam}
                    className="btn-dark w-100 btn-sm"
                  >
                    Actualizar <i className="ri-arrow-right-s-line align-middle lh-1"></i>
                  </Button>
                ) : (
                  <Button
                    onClick={handleCreateParam}
                    className="btn-danger w-100 btn-sm"
                  >
                    Crear <i className="ri-arrow-right-s-line align-middle lh-1"></i>
                  </Button>
                )}
              </div>
            )}
            <small>{descripcion}</small>
          </>
        )}
      </FormGroup>

      <InputModal
        nocontrol={true}
        ref={modalRef}
        size="modal-lg"
        title={`Configurar parametro`}
        submitEvent={() => console.log('submit')}>
        <ConfiguracionForm
          idfranquicia={franquicia?.id}
          modalToggle={modalRef.current?.setModal}
          param={{ valor: paramValue }}
          int={int}
          setParams={handleSetParams}
          name={name}>
        </ConfiguracionForm>
      </InputModal>
    </Form>
  );
};

export default ParamInput;