import React from "react";
import { Card, CardHeader, CardBody, Alert, Col, Row } from "reactstrap";
import { InputSelect } from "../Forms/Inputs/InputSelect/InputSelect";
import { statusChoices } from "../../helpers/options";

export const PropertyStatusForm = ({ validation }) => {
  return (
    <Card>
      <CardHeader>
        <h5 className="card-title mb-0">Publicar</h5>
      </CardHeader>
      <CardBody>
        <Row>
          <Col lg={12}>
            <InputSelect
              name="status_inmueble"
              label="Status del inmueble"
              validation={validation}
              optionsProps={{
                value: "id",
                label: "nombre",
                items: statusChoices || [],
              }}
            />
          </Col>
        </Row>
      </CardBody>
      <CardBody>
        {validation.errors && (
          Object.entries(validation.errors).map(([key, value], index) => (
            <Alert key={index} color="danger">
              <div>{value}</div>
            </Alert>
          ))
        )}
      </CardBody>
    </Card>
  );
};
