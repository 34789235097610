import React, { useEffect, useMemo, useRef, useState } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
//Import Icons
import FeatherIcon from "feather-icons-react";

import {
  Card,
  CardBody,
  Container,
  Row,
  Col
} from "reactstrap";

import FullCalendar from "@fullcalendar/react";
import esLocale from '@fullcalendar/core/locales/es';
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
import BootstrapTheme from "@fullcalendar/bootstrap";
import Flatpickr from "react-flatpickr";
import listPlugin from '@fullcalendar/list';

import BreadCrumb from "../../Components/Common/BreadCrumb";
import SimpleBar from "simplebar-react";
import UpcommingEvents from './UpcommingEvents';

import { InputModalVisita } from "../Forms/Inputs/InputModalVisita/InputModalVisita";
import { createApiMethods } from "../../helpers/api_calls";
import { CALENDAR, FRANQUICIAS } from "../../helpers/api_urls";
import { ToastContainer, toast } from "react-toastify";
import { formatDateToYYYYMMDD, paginationModel } from "../../helpers/api_helper";

const Calender = () => {
  const modalEventoCalendar = useRef(null);
  const apiCalendar = useMemo(() => createApiMethods(CALENDAR), []);
  const [calendarResponse, setcalendarResponse] = useState(paginationModel);
  const [isLoading, setisLoading] = useState(false);
  const [events, setevents] = useState();

  function filtrarFechasPosteriores(arr) {
    const hoy = new Date(); // Obtener la fecha actual
    const fechaLimite = new Date('2024-03-10'); // Convertir la fecha límite a un objeto Date
  
    if (!arr)
      return []

    return arr.filter(objeto => {
      // Convertir la propiedad 'start' del objeto a un objeto Date
      const fechaObjeto = new Date(objeto.start);
  
      // Comparar si la fecha del objeto es posterior al día de hoy
      return fechaObjeto > fechaLimite || !objeto.rating;
    });
  }

  useEffect(() => {
    setisLoading(true)
    let fecha = new Date()
    apiCalendar.getAll({page_size:1000}).then((res)=>{
      let filtered = filtrarFechasPosteriores(res?.results)
      res.results = filtered
      setcalendarResponse(res)
      let eventstocalendar = res.results.map((item)=>{
        return {
          title:item.titulo,
          ...item,
          obj_type: 'VisitaAgendada'
        }
      })
      setevents(eventstocalendar)
    }).catch(toast.error).finally(()=>{
      setisLoading(false)
    })
  }, []);

  const toggleNuevoEvento = () => {
    modalEventoCalendar.current.toggle()
    modalEventoCalendar.current.setEvent(null)
    modalEventoCalendar.current.setIsEdit(false)
    modalEventoCalendar.current.setshowForm(true)
  };

  const handleDateClick = (arg) => {
    modalEventoCalendar.current.handleDateClick(arg)
    modalEventoCalendar.current.toggle()
  };

  const reloadResults = () => () => {
    setisLoading(true)
    apiCalendar.getAll({page_size:1000}).then((res)=>{
      setcalendarResponse(res)
      let eventstocalendar = res.results.map((item)=>{
        return {
          title:item.titulo,
          ...item
        }
      })
      setevents(eventstocalendar)
    }).catch(toast.error).finally(()=>{
      setisLoading(false)
    })
  }

  const reloadResultsChild = () => {
    setisLoading(true)
    apiCalendar.getAll({page_size:1000}).then((res)=>{
      setcalendarResponse(res)
      let eventstocalendar = res.results.map((item)=>{
        return {
          title:item.titulo,
          ...item
        }
      })
      setevents(eventstocalendar)
    }).catch(toast.error).finally(()=>{
      setisLoading(false)
    })
  }

  const eventClick = (event) =>{
    modalEventoCalendar.current.handleEventClick(event)
    modalEventoCalendar.current.toggle()
  }

  const handleEventMenu = (event) =>{
    event._def = {extendedProps : event}
    eventClick({event: event})
  }

  return (
    <React.Fragment>
      <ToastContainer></ToastContainer>
      <BreadCrumb title="Calendario" pageTitle="Calendario" />

        
      <InputModalVisita
        reloader={reloadResults()}
        ref={modalEventoCalendar}>
      </InputModalVisita>

      <div className="page-content">
        <Container fluid>
          <Row>
            <Col xs={12}>
              <Row>
                <Col xl={3}>
                  <Card className="card-h-100">
                    <CardBody>
                      <button
                        className="btn btn-primary w-100"
                        id="btn-new-event"
                        onClick={() => toggleNuevoEvento()}
                      >
                        <i className="mdi mdi-plus"></i> Nuevo Evento
                      </button>
                    </CardBody>
                  </Card>
                  <div>
                    <h5 className="mb-1">Proximos Eventos</h5>
                    <p className="text-muted">No te pierdas los eventos pautados</p>
                    <SimpleBar
                      className="pe-2 me-n1 mb-3"
                      style={{ height: "400px" }}>
                      <div id="upcoming-event-list">
                        {
                          calendarResponse.results.map((event, key) => (
                            <div key={key}>
                              <UpcommingEvents handleclick={handleEventMenu} reload={reloadResultsChild} event={event}  />
                            </div>
                          ))
                        }
                      </div>
                    </SimpleBar>
                  </div>

                  <Card>
                    <CardBody className="bg-soft-info">
                      <div className="d-flex">
                        <div className="flex-shrink-0">
                          <FeatherIcon
                            icon="calendar"
                            className="text-info icon-dual-info"
                          />
                        </div>
                        <div className="flex-grow-1 ms-3">
                          <h6 className="fs-15">Bienvenido al calendario!</h6>
                          <p className="text-muted mb-0">
                            Los eventos que tengas pautados apareceran aqui para ayudarte a gestionar tus tiempos.
                          </p>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>

                <Col xl={9}>
                  <Card className="card-h-100">
                    <CardBody>
                      <FullCalendar
                        locale={esLocale}
                        plugins={[
                          BootstrapTheme,
                          dayGridPlugin,
                          interactionPlugin,
                          listPlugin
                        ]}
                        initialView="dayGridMonth"
                        slotDuration={"00:15:00"}
                        handleWindowResize={true}
                        themeSystem="bootstrap"
                        headerToolbar={{
                          left: "prev,next today",
                          center: "title",
                          right: "dayGridMonth,dayGridWeek,dayGridDay,listWeek",
                        }}
                        events={events}
                        // editable={true}
                        // droppable={true}
                        selectable={true}
                        dateClick={handleDateClick}
                        eventClick={eventClick}
                      />
                    </CardBody>
                  </Card>
                </Col>
              </Row>

              <div style={{ clear: "both" }}></div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

Calender.propTypes = {
  events: PropTypes.any,
  categories: PropTypes.array,
  className: PropTypes.string,
  onGetEvents: PropTypes.func,
  onAddNewEvent: PropTypes.func,
  onUpdateEvent: PropTypes.func,
  onDeleteEvent: PropTypes.func,
  onGetCategories: PropTypes.func,
};

export default Calender;
