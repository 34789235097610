import React, { useEffect, useMemo, useState } from 'react';
import Select from "react-select";
import { Alert, Col, Label } from 'reactstrap';
import { createApiMethods } from '../../../../helpers/api_calls';
import { CLIENTES, MEDIOS_DE_CONTACTO } from '../../../../helpers/api_urls';
import { InputSelect } from '../../../Forms/Inputs/InputSelect/InputSelect';
import { InputText } from '../../../Forms/Inputs/InputText/InputText';
import './TabContacto.css';
import Loader from '../../../../Components/Common/Loader';


export const TabContacto = ({validation,...props}) => {
    const [tiposMediosContacto, settiposMediosContacto] = useState([]);
    const APImediosdecontacto = createApiMethods(MEDIOS_DE_CONTACTO)
    const [clienteslist, setclienteslist] = useState([]);
    const apiClientes = useMemo(() => createApiMethods(CLIENTES), []);
    const [isLoading, setisLoading] = useState(false);

    const handleSelection = (item) => {
        console.log(item)
    }

    useEffect(() => {
        setisLoading(true)
        APImediosdecontacto.getAll({page_size: 1000}).then((res)=>{
            settiposMediosContacto(res.results)
        }).catch(console.log)

        apiClientes.getAll({page_size:1000}).then((res)=>{
            let mapresults = res.results.map((e)=> {
                return {
                    label:e.nombre,
                    value:e.id
                }
            })
            mapresults.push({
              label:'Crear nuevo cliente',
              value: '*'
            })
            let clientesListOptions = [
              {
                label:'Crear nuevo cliente',
                value: '*'
              },
              ...mapresults
            ]
            setclienteslist(clientesListOptions)
        }).finally(()=>{
              setisLoading(false)
        })
    }, [])


    function handleSelectSingle(clienteSelect) {
        validation.setFieldValue('cliente',clienteSelect)
    }

    return (
        <>
                {
                    isLoading &&
                    <Loader error={'Cargando'}></Loader>        
                }
                {
                  (clienteslist.length > 0 && (validation.values?.createNewCliente === false)) &&
                  <Col lg={12} md={12}>
                    <Label htmlFor="choices-single-default" className="form-label text-muted">Cliente</Label>                                                        
                    <Select
                        className="mb-3"
                        name="cliente"
                        isClearable={false}
                        placeholder={'Seleccione un cliente'}
                        isSearchable
                        onChange={(e) => {
                            console.log(e.value)
                            if(e?.value !== '*'){
                              handleSelectSingle(e.value);
                            } else {
                                handleSelectSingle(undefined);
                                validation.setFieldValue('createNewCliente',true)

                            }
                        }}
                        options={clienteslist}/>
                  </Col>
                }

                {
                    validation.values?.createNewCliente &&
                    <>
                        <Col lg={12}>
                            <div className="bg-light p-2 modal-header mb-2">
                                <h5 className="modal-title">Crear cliente</h5>
                                <button onClick={() => {
                                    validation.setFieldValue('createNewCliente',false)
                                    validation.setFieldValue('cliente',undefined)
                                    validation.setFieldValue('cliente_nombre',undefined)
                                    validation.setFieldValue('cliente_tipo_contacto',undefined)
                                    validation.setFieldValue('cliente_contacto_valor',undefined)
                                }} type="button" className="btn-close" aria-label="Close"></button>
                            </div>             
                        </Col>
                        <Col lg={6}>
                            <InputText
                                name="cliente_nombre"
                                label="Nombre Cliente"
                                validation={validation}>
                            </InputText>       
                        </Col>
                        <Col lg={6}>
                            <InputSelect
                                disabled={false}
                                name         = "cliente_tipo_contacto"
                                label        = "Tipo de contacto"
                                validation   = {validation}
                                optionsProps = {
                                    {
                                        value:'id',
                                        label:'nombre',
                                        items:tiposMediosContacto || []
                                    }}>
                            </InputSelect>                                
                            <InputText
                                disabled={false}
                                name="cliente_contacto_valor"
                                type='number'
                                label="Nro"
                                validation={validation}>
                            </InputText>     
                            <Alert color="info">
                                <div>
                                    Agregue un numero de telefono sin signos e incluyendo el numero de pais. Ejemplo: 584143339988
                                </div>
                            </Alert>                                                      
                        </Col>
                    </>
                }
        </>
    );
};
