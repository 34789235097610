import React, { useEffect, useState } from "react";
import "./InputMoney.css";
import { FormFeedback, Label, Input } from "reactstrap";

export const InputMoney = ({
  prefix,
  disabled,
  classes,
  validation,
  ...props
}) => {
  const [prepend, setPrepend] = useState(false);
  const [formattedValue, setFormattedValue] = useState("");

  useEffect(() => {
    if (prefix != null) {
      setPrepend(true);
    }
  }, [prefix]);

  useEffect(() => {
    setFormattedValue(formatNumber(validation.values[props?.name] || ""));
  }, [validation.values[props?.name]]);

  const removeCommas = (value) => {
    if (typeof value === "string") {
      return value.replace(/,/g, "");
    }
    return value;
  };

  const formatNumber = (value) => {
    // Remove commas and convert to number
    const cleanedValue = removeCommas(value);
    const numberValue = parseFloat(cleanedValue);

    // Check if the value is a valid number
    if (!isNaN(numberValue)) {
      // Format the number with commas
      return numberValue.toLocaleString("en-US");
    }

    return "";
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const cleanedValue = removeCommas(value);
    validation.handleChange(e);
    validation.setFieldValue(name, cleanedValue);
    setFormattedValue(formatNumber(cleanedValue));
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;
    const cleanedValue = removeCommas(value);
    validation.handleBlur(e);
    validation.setFieldValue(name, cleanedValue);
    setFormattedValue(formatNumber(cleanedValue));
  };

  return (
    <div className="mb-3">
      <Label htmlFor={props?.name || null} className="form-label">
        {props?.label || " "}
        {props.required ? <span className="text-danger">*</span> : " "}
      </Label>
      <div className="input-group mb-3">
        {prepend && <span className="input-group-text">{prefix}</span>}
        <Input
          type="text"
          placeholder={props?.placeholder}
          disabled={disabled}
          value={formattedValue}
          name={props?.name || null}
          onBlur={handleBlur}
          onChange={handleChange}
          className={`form-control ${classes}`}
        />
      </div>
      {validation.touched[props?.name] && validation.errors[props?.name] ? (
        <FormFeedback className="d-block" type="invalid">
          <div>{validation.errors[props?.name]}</div>
        </FormFeedback>
      ) : null}
    </div>
  );
};
