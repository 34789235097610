import React from "react";
import { Card, CardBody } from "reactstrap";
import { InputText } from "../Forms/Inputs/InputText/InputText";
import { InputTextEditor } from "../Forms/Inputs/InputTextEditor/InputTextEditor";

export const PropertyDetailsForm = ({ validation }) => {
  return (
    <Card>
      <CardBody>
        <div className="mb-3">
          <InputText label="Nombre del Inmueble" name="nombre" validation={validation} />
        </div>
        <div>
          <InputTextEditor label="Descripcion del Inmueble" name="descripcion" validation={validation} />
        </div>
      </CardBody>
    </Card>
  );
};
