import React, { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody, Col, DropdownItem, DropdownMenu, DropdownToggle, Input, Modal, Row, UncontrolledDropdown, ModalBody, ModalHeader } from 'reactstrap';

//SimpleBar
import SimpleBar from "simplebar-react";

//import images
import avatar2 from "../../../assets/images/users/avatar-2.jpg";
import avatar3 from "../../../assets/images/users/avatar-3.jpg";
import avatar4 from "../../../assets/images/users/avatar-4.jpg";
import avatar8 from "../../../assets/images/users/avatar-8.jpg";

import avatar7 from "../../../assets/images/users/avatar-7.jpg";
import avatar5 from "../../../assets/images/users/avatar-5.jpg";
import { createApiMethods } from '../../../helpers/api_calls';
import { FRANQUICIAS, USERS } from '../../../helpers/api_urls';
import { toast } from 'react-toastify';
import { paginationModel } from '../../../helpers/api_helper';

const TeamTab = () => {
    const [modal, setModal] = useState(false);
    const openModal = () => setModal(!modal);
    const [usersList, setusersList] = useState(paginationModel);
    const apiUserList = useMemo(() => createApiMethods(USERS), []);

    useEffect(()=>{

        apiUserList.getAll().then((res)=>{
            setusersList(res)
        }).catch(toast.error).finally(()=>{

        })
    },[])

    return (
        <React.Fragment>
            <Row className="g-4 mb-3">
                <div className="col-sm">
                    <div className="d-flex">
                        <div className="search-box me-2">
                            <input type="text" className="form-control" placeholder="Buscar..." />
                            <i className="ri-search-line search-icon"></i>
                        </div>
                    </div>
                </div>
                <div className="col-sm-auto">
                    <div>
                        {/* <button type="button" className="btn btn-danger" onClick={openModal}><i className="ri-share-line me-1 align-bottom"></i> Invite Member</button> */}
                    </div>
                </div>
            </Row>

            <div className="team-list list-view-filter">
                {
                    (usersList.results || []).map((i,x) => { 
                        
                        let { franquicia, visitas } = i
                        if(!franquicia)
                            return null
                        let { franquicias_personales, franquicias_comerciales, membresias, propiedades, busquedas } = franquicia
                        let asociados = franquicias_personales.length + franquicias_comerciales.length + membresias.length
                        
                        return <Card key={x} className="team-box">
                                <CardBody className="px-4">
                                    <Row className="align-items-center team-row">
                                        <div className="col team-settings">
                                            <Row className="align-items-center">
                                                {/* <div className="col">
                                                    <div className="flex-shrink-0 me-2">
                                                        <button type="button" className="btn fs-16 p-0 favourite-btn">
                                                            <i className="ri-star-fill"></i>
                                                        </button>
                                                    </div>
                                                </div> */}
                                                <UncontrolledDropdown className="col text-end">
                                                    <DropdownToggle tag="a" role="button">
                                                        <i className="ri-more-fill fs-17"></i>
                                                    </DropdownToggle>
                                                    <DropdownMenu className="dropdown-menu-end">
                                                        <li><DropdownItem><i className="ri-eye-fill text-muted me-2 align-bottom"></i>Detalles</DropdownItem></li>
                                                        {/* <li><DropdownItem><i className="ri-star-fill text-muted me-2 align-bottom"></i>Favourite</DropdownItem></li> */}
                                                        {/* <li><DropdownItem><i className="ri-delete-bin-5-fill text-muted me-2 align-bottom"></i>Delete</DropdownItem></li> */}
                                                    </DropdownMenu>
                                                </UncontrolledDropdown>
                                            </Row>
                                        </div>
                                        <Col lg={3}>
                                            <div className="team-profile-img">
                                                <div className="avatar-lg rounded-circle text-center rounded-circle_fix">
                                                    <img src={i.franquicia.first_image_url} alt="" style={{width: 'auto',height: '100%'}} />
                                                </div>
                                                <div className="team-content">
                                                    <Link to="#" className="d-block"><h5 className="fs-16 mb-1">{i.full_name}</h5></Link>
                                                    <p className="text-muted mb-0">{i.franquicia.nombre}</p>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col lg={5}>
                                            <Row className="text-muted text-center">
                                                <Col xs={3} className="border-end border-end-dashed">
                                                    <h5 className="mb-1">{asociados}</h5>
                                                    <p className="text-muted mb-0">Asociados</p>
                                                </Col>
                                                <Col xs={3} className="border-end border-end-dashed">
                                                    <h5 className="mb-1">{busquedas.length}</h5>
                                                    <p className="text-muted mb-0">Busquedas</p>
                                                </Col>
                                                <Col xs={3}>
                                                    <h5 className="mb-1">{propiedades.length}</h5>
                                                    <p className="text-muted mb-0">Captaciones</p>
                                                </Col>
                                                <Col xs={3}>
                                                    <h5 className="mb-1">{visitas.length}</h5>
                                                    <p className="text-muted mb-0">Visitas</p>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col lg={2} className="col">
                                            <div className="text-end">
                                                <Link to="/pages-profile" className="btn btn-light view-btn">Ver Perfil</Link>
                                            </div>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                    })
                }
            </div>
        </React.Fragment>
    );
};

export default TeamTab;