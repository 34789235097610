// AuthProvider.js
import React, { createContext, useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { generateUniqueId, setAuthorization } from './helpers/api_helper';
import { BROADCAST_LOGIN, BROADCAST_LOGOUT } from './helpers/entities';


export const AuthContext = createContext();

export const registerUserWebsocket = (userId,token) => {
  if (userId) {
    const websocketUrl = `${process.env.REACT_APP_API_WEBSOCKET_URL}notif/${userId}/`;
    let msg = {
      type: 'websocket-url',
      url: websocketUrl,
      hostUrl: `${window.location.protocol}//${window.location.hostname}`
    }
    sendToSW(msg)
    subcribePushServerService(userId,token)
  }
}

export const logoutWebsocket = () => {
    let msg = {
      type: 'websocket-close'
    }
    sendToSW(msg)
}

export const subcribePushServerService = (user,token) => {
  let msg = {
    type: 'push-suscription',
    pathUrl: process.env.REACT_APP_API_URL,
    user,
    token
  }

  Notification.requestPermission().then((permission)=>{
    if (permission === 'granted') {
      sendToSW(msg)
    }
  })  
}

export const sendToSW = (message) => {
  const waitForActivation = (registration) => {
    return new Promise((resolve, reject) => {
      if (registration.active) {
        resolve(registration.active);
      } else {
        registration.addEventListener('updatefound', () => {
          const newWorker = registration.installing;
          if (newWorker) {
            newWorker.addEventListener('statechange', () => {
              if (newWorker.state === 'activated') {
                resolve(newWorker);
              }
            });
          } else {
            reject('No se pudo obtener el nuevo Service Worker');
          }
        });
      }
    });
  };

  if ('serviceWorker' in navigator && 'MessageChannel' in window) {
    if (navigator.serviceWorker.controller) {
      navigator.serviceWorker.controller.postMessage(message);
    } else {
      navigator.serviceWorker.register('./serviceWorker.js')
        .then(waitForActivation)
        .then((activeWorker) => {
          activeWorker.postMessage(message);
        })
        .catch((error) => {
          console.error('Error al registrar el Service Worker:', error);
        });
    }
  }
};

export const userValidators = (user) => {
  return {
    isSuperAdmin: () => (user.profile.slug_name === "superusuario" || user.profile.slug_name === "superusuario") ,
    isAdmin: () => (user.profile.slug_name === "administrador") ,
    isGerente: () => user.profile.slug_name === "gerente" ,
    isAsesor: () => user.profile.slug_name === "asesor" ,
    isAuditor: () => user.profile.slug_name === "auditor" ,
    isAutor: (id) => user.id === Number(id),
    user : user,
    id : user.id,
    franquicia: () => user.franquicia ? user.franquicia :false,
    isFranquiciaGeneral: () => user.franquicia ? user.franquicia.tipo_franquicia.slug_name === 'franquicia_general' : false,
    isFranquiciaComercial: () => user.franquicia ? user.franquicia.tipo_franquicia.slug_name === 'franquicia_comercial' : false,
    isFranquiciaPersonal: () => user.franquicia ? user.franquicia.tipo_franquicia.slug_name === 'franquicia_personal' : false,
    isMembresia: () => user.franquicia ? user.franquicia.tipo_franquicia.slug_name === 'membresia' : false,
  };
};

export const getLoggedInUserValidations = () => {
  const userObjectString = localStorage.getItem("authUser");
  if (userObjectString) {
    let user = userValidators(JSON.parse(userObjectString));
    return user
  }
  return null;
};


export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [broadCastChannel, setbroadCastChannel] = useState(new BroadcastChannel('inmobapp-broadcast-channel'));

  const [
    isAuthenticated, 
    setIsAuthenticated] 
    = useState(
      false)
    ;
  const [isCheckingAuth, setIsCheckingAuth] = useState(true);
  const navigate = useNavigate();

  const isAccessTokenExpired = (accessTokenExpiresTimestamp) => {
    const currentTime = Date.now();
    return currentTime >= accessTokenExpiresTimestamp * 1000;
  };

  useEffect(() => {
    const fetchUser = async () => {
      const user = await getLoggedInUserValidations();
      if (user) {
        const isExpired = isAccessTokenExpired(user.access_token_expires);
        if (isExpired) {
          handleLogout()
          // navigate('/login');
          // window.location.reload();
        } else {
          setUser(user);
          registerUserWebsocket(user.id,user.user.token)
          setIsAuthenticated(true);
          setAuthorization(user.user.token);
        }
      }
      setIsCheckingAuth(false);
    };

    fetchUser();

    // En la pestaña de login

  }, []);

  useEffect(()=>{
    broadCastChannel.onmessage = ((message)=>{
      const { data } = message
      switch(data.type){
        case BROADCAST_LOGIN:
          window.location.reload(true);
        break;
        case BROADCAST_LOGOUT:
          window.location.reload(true);
          break;
        default:
          console.warn('Se disparo un evento desconocido')
      }
    })
  },[])

  const handleLogout = () => {
    logoutWebsocket()
    setIsAuthenticated(false)
    setAuthorization(null)
    setUser(null)

    
  }

  return (
    <AuthContext.Provider
      value={{ 
        isAuthenticated, 
        user, 
        setUser, 
        setIsAuthenticated,
        handleLogout,
        broadCastChannel
      }}>
      {!isCheckingAuth && children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
