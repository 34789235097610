import React, { useRef, useCallback } from "react";
import { Card, CardBody, CardHeader } from "reactstrap";
import { GaleriaDropzone } from "../Ecommerce/EcommerceProducts/components/GaleriaDropzone/GaleriaDropzone";
import { ImprovedFileInput } from "../Forms/Inputs/FileInputV2";

export const PropertyImageUploadForm = ({ validation, dropZoneRef }) => {
  const fileInputRef = useRef(null);

  const handleFilesChange = (files) => {
    dropZoneRef.current?.setselectedFiles(files);
  };

  const handleClick = useCallback(() => {
    console.log("handleClick called");
    fileInputRef.current?.openFileDialog();
  }, []);

  return (
    <Card>
      <CardHeader>
        <h5 className="card-title mb-0">Imagenes del Inmueble</h5>
      </CardHeader>
      <CardBody>
        <ImprovedFileInput ref={fileInputRef} onFilesChange={handleFilesChange} silentMode />
        <GaleriaDropzone ref={dropZoneRef} externalClick={handleClick} validation={validation} name="imagenes"/>
      </CardBody>
    </Card>
  );
};