import React from 'react';
import './InputTextEditor.css';
import {
    Card,
    CardBody,
    Col,
    Container,
    CardHeader,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane,
    Input,
    Label,
    FormFeedback,
    Form,
  } from "reactstrap";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
  
export const InputTextEditor = ({ validation, ...props }) => {
    return (
        <>
            <Label htmlFor="direccion_origen" className="form-label">
                {props?.label || ' '} 
                { props.required ? <span className="text-danger">*</span> : ' ' }    
            </Label>
            <CKEditor
                className = "form-control mb-3" 
                editor={ClassicEditor}
                data       = {validation.values[props?.name]}
                onChange={ ( event, editor ) => {
                    const data = editor.getData();
                    if(data){
                        validation.setFieldValue(props?.name,data)
                    }
                }}/> 
            {validation.touched[props?.name] && validation.errors[props?.name] ? (
                <FormFeedback type="invalid" className="d-block"><span>{validation.errors[props?.name]}</span></FormFeedback>
            ) : null}       

        </>
    );
};
