import React, { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { PublicCardTemplate } from '../../../Components/PublicCardTemplate/PublicCardTemplate';
import { InputModal } from '../../Forms/Inputs/InputModal/InputModal';
import MultiStepForm from './MultiStepForm';


const ReservaModal = forwardRef(({inmueble,...props},ref) => {
    const modalRef = useRef(null);

    useEffect(()=>{
        if(inmueble){
            console.log(inmueble)
        }
    },[inmueble])

    const openForm = () => {
        modalRef.current.toggle()
    }

    useImperativeHandle(ref, () => ({
        openForm
    }));


    const closeCallback = () => {
        modalRef.current.setModal(false)
    }


    return(
        <InputModal
            nocontrol={true}
            ref={modalRef}
            size="xl"
            title={`Crear Reserva`}
            submitEvent={() => console.log('submit')}>
                <Container>
                    <Row>
                        <Col md={12}>
                            <MultiStepForm callback={closeCallback} item={inmueble}></MultiStepForm>
                        </Col>
                    </Row>
                </Container>
        </InputModal>
    )
})

ReservaModal.displayName = 'ReservaModal';
export { ReservaModal };
