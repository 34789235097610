
import React, { useState } from 'react';
import './InputFile.css';
import { FormFeedback, Label, Input } from 'reactstrap';
import { FilePond,  registerPlugin } from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import 'filepond/dist/filepond.min.css';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginImageCrop from 'filepond-plugin-image-crop';
import FilePondPluginImageResize from 'filepond-plugin-image-resize';
import FilePondPluginImageTransform from 'filepond-plugin-image-transform';
import FilePondPluginImageEdit from 'filepond-plugin-image-edit';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';

registerPlugin(
    FilePondPluginImagePreview,
    FilePondPluginImageExifOrientation,
    FilePondPluginImageEdit,
    FilePondPluginImageExifOrientation,
    FilePondPluginImagePreview,
    FilePondPluginFileValidateType,
    FilePondPluginImageCrop,
    FilePondPluginImageResize,
    FilePondPluginImageTransform,
    FilePondPluginImageEdit
);


export const InputFile = ({ validation, ...props }) => {
    const [files, setFiles] = useState([]);
    return (
        <div className="mb-3">
            <Label htmlFor={props?.name || null} className="form-label">
                {props?.label || ' '} 
                { props.required ? <span className="text-danger">*</span> : ' ' }    
            </Label>
            <FilePond
                files={files}
                allowMultiple={false}
                maxFiles={1}
                onupdatefiles={fileItems => {
                    if(fileItems && fileItems.length > 0){
                        setFiles(fileItems.map(fileItem => fileItem.file));
                        validation.setFieldValue(props?.name, fileItems[0].file);
                    }
                }}
                name="files"
                labelIdle={props.placeholder || "Arrastra tus archivos aquí o <span class='filepond--label-action'>Busca</span> en tu dispositivo" }
                imagePreviewHeight={170}
                imageCropAspectRatio="1:1"
                imageResizeTargetWidth={200}
                imageResizeTargetHeight={200}
                stylePanelLayout="compact circle"
                styleLoadIndicatorPosition="center bottom"
                styleProgressIndicatorPosition="right bottom"
                styleButtonRemoveItemPosition="left bottom"
                styleButtonProcessItemPosition="right bottom"
                className="filepond filepond-input-multiple"
            />
            {validation.touched[props?.name] && validation.errors[props?.name] ? (
                <FormFeedback type="invalid" className="d-block"><div>{validation.errors[props?.name]}</div></FormFeedback>
            ) : null}
        </div>
    );
};
