import React, { useEffect, useState } from 'react';
import './SelectDropdownCustom.css';
import styled from "styled-components";
import { ButtonGroup, DropdownItem, DropdownMenu, DropdownToggle, ListGroup, ListGroupItem, UncontrolledDropdown } from 'reactstrap';
import { InputSearch } from '../InputSearch/InputSearch';
import SimpleBar from "simplebar-react";

export const SelectDropdownCustom = ({ 
    map, 
    disabled,
    preselection, 
    validation, 
    optionsProps,
    search=false,
    tag = 'button',
    classNameSelected='btn btn-primary w-100', 
    ...props 
  }) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  
  useEffect(() => {
      setSelectedOption(preselection);
  }, [preselection]);

  const onOptionClicked = (value) => {
    setSelectedOption(value);
    validation.setFieldValue(props?.name, value[optionsProps?.value]);
  };

  useEffect(() => {
    let valor = validation.values[props?.name];
    if (valor && optionsProps && optionsProps.items.length > 0) {
      let itemPreselected = optionsProps.items.find((i) => i.id === valor);
      onOptionClicked(itemPreselected);
    }
  }, []);

  const filteredOptions = optionsProps.items.filter(item => {
    const lowerCaseTerm = searchTerm.toLowerCase();
    return item[props?.searchTerm].toLowerCase().includes(lowerCaseTerm);
  });

  return (
    <div className='w-100'>
      <ButtonGroup className='w-100 custom-dropdown-custom'>
        <UncontrolledDropdown className='w-100'>
          <DropdownToggle tag={tag} type="menu" className={classNameSelected}>
            {selectedOption ? selectedOption[props?.searchTerm] : props?.label}
            <i className="mdi mdi-chevron-down"></i>
          </DropdownToggle>
          {
            (!disabled) && 
            <DropdownMenu className='w-100'>
              {
                (search) &&
                <div className="dropdown-header noti-title">
                  <div className="d-flex justify-content-sm-end">
                    <div className="search-box ms-2 w-100 pb-1">
                      <input
                        type="text"
                        className="form-control form-control-sm pb-1"
                        placeholder="Buscar"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                      />
                      <i className="ri-search-line search-icon"></i>
                    </div>
                  </div>
                </div>
              }
              <SimpleBar data-simplebar className="mx-n3 px-3">
                <ListGroup>
                  {filteredOptions.map((x, y) => {
                    return (
                      <DropdownItem 
                        onClick={() => onOptionClicked(x)} 
                        key={y}>
                        <ListGroupItem className='listgroupitem_'> 
                          {map(x)}
                        </ListGroupItem>
                      </DropdownItem>
                    )
                  })}
                </ListGroup>
              </SimpleBar>
            </DropdownMenu>
          }
        </UncontrolledDropdown>
      </ButtonGroup>
    </div>
  );
};
