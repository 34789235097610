import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Card, CardBody, CardHeader, Col, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { Link } from 'react-router-dom';
import { createApiMethods } from '../../helpers/api_calls';
import { CLIENTES } from '../../helpers/api_urls';
import { toast } from 'react-toastify';
import { date_r, formatDateCustom, formatDateToYYYYMMDD } from '../../helpers/api_helper';
import { InputDateRange } from '../Forms/Inputs/InputDateRange/InputDateRange';

const DealsStatus = () => {
    const apiClientes = useMemo(() => createApiMethods(CLIENTES), []);
    const [clientes, setclientes] = useState([]);
    const dateRangeRef = useRef({});

    useEffect(()=>{
        callClientes()
    },[])

    const callClientes = (range) => {
        let obj= {}
        if(range){
            const [created_at_after, created_at_before] = range
            obj = {
                created_at_after : formatDateToYYYYMMDD(created_at_after),
                created_at_before : formatDateToYYYYMMDD(created_at_before),
            }
        }
        apiClientes.getAll(obj).then((res)=>{
            setclientes(res.results)
        }).catch(toast.error)
    }

    return (
        <React.Fragment>
            <Col xl={6}>
                <Card>
                    <CardHeader className="align-items-center d-flex">
                        <h4 className="card-title mb-0 flex-grow-1">Resumen Clientes</h4>
                        <div className="flex-shrink-0">
                            <InputDateRange update={callClientes} ref={dateRangeRef}></InputDateRange>
                        </div>
                    </CardHeader>

                    <CardBody>
                        <div className="table-responsive table-card">
                            <table className="table table-borderless table-hover table-nowrap align-middle mb-0">
                                <thead className="table-light">
                                    <tr className="text-muted">
                                        <th scope="col">Nombre</th>
                                        <th scope="col" style={{ width: "16%" }}>Status</th>
                                        <th scope="col" >Asesor</th>
                                        <th scope="col" style={{ width: "20%" }}>Creado</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {(clientes || []).map((item, index) => (
                                        <tr key={index}>
                                            <td>{item.nombre}</td>
                                            <td><span className={"badge badge-soft-primary p-2"}>{item.status.nombre}</span></td>
                                            <td>
                                                <img src={item?.franquicia?.usuario_asignado?.first_image_url} alt="" className="avatar-xs rounded-circle me-2" />
                                                <Link to="#" className="text-body fw-medium">{item?.franquicia?.usuario_asignado?.full_name}</Link>
                                            </td>
                                            <td>{date_r(item.created_at)}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </CardBody>
                </Card>
            </Col>
        </React.Fragment>
    );
};

export default DealsStatus;