import React from 'react';
import { DropdownItem, DropdownMenu, DropdownToggle, Pagination, PaginationItem, PaginationLink, UncontrolledDropdown } from 'reactstrap';

const PaginationComponent = ({ activePage, totalPages, onPageChange, pageSizeChange }) => {
  const renderPaginationItems = () => {
    const items = [];
    for (let i = 1; i <= totalPages; i++) {
      items.push(
        <PaginationItem key={i} active={i === activePage}>
          <PaginationLink onClick={() => onPageChange(i)}>{i}</PaginationLink>
        </PaginationItem>
      );
    }
    return items;
  };

  const sizeUpdate = (page_size) =>{
    onPageChange(activePage,page_size)
  }

  return (
    <div className='d-flex flex-row justify-content-between'>
      <Pagination listClassName="justify-content-center" className="pagination-separated mb-0">
        <PaginationItem disabled={activePage === 1 || !activePage}>
          <PaginationLink onClick={() => onPageChange(activePage - 1)}>
            <i className="mdi mdi-chevron-left" />
          </PaginationLink>
        </PaginationItem>
        {renderPaginationItems()}
        <PaginationItem disabled={activePage === totalPages || !activePage}>
          <PaginationLink onClick={() => onPageChange(activePage + 1)}>
            <i className="mdi mdi-chevron-right" />
          </PaginationLink>
        </PaginationItem>
      </Pagination>
      <UncontrolledDropdown direction='start'>
          <DropdownToggle tag="button" className="btn btn-link text-muted p-1 mt-n2 py-0 text-decoration-none fs-15">
              <i className="ri-more-2-fill"></i>
          </DropdownToggle>

          <DropdownMenu className="dropdown-menu-end">
              <DropdownItem onClick={() =>sizeUpdate(10)}>
                <i className="ri-add-box-line align-bottom me-2 text-muted"></i> 
                Ver 10 por página
              </DropdownItem>
              <DropdownItem onClick={() =>sizeUpdate(20)}>
                <i className="ri-add-box-line align-bottom me-2 text-muted"></i>
                Ver 20 por página
              </DropdownItem>
              <DropdownItem onClick={() =>sizeUpdate(50)}>
                <i className="ri-add-box-line align-bottom me-2 text-muted"></i>
                Ver 50 por página
              </DropdownItem>
              <DropdownItem onClick={() =>sizeUpdate(100)}>
                <i className="ri-add-box-line align-bottom me-2 text-muted"></i>
                Ver 100 por página
              </DropdownItem>
          </DropdownMenu>
      </UncontrolledDropdown>
    </div>
  );
};

export default PaginationComponent;