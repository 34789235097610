import { call, put, takeEvery, takeLatest } from "redux-saga/effects";

// Login Redux States
import { LOGIN_USER, LOGOUT_USER, SOCIAL_LOGIN } from "./actionTypes";
import { apiError, loginSuccess, logoutUserSuccess } from "./actions";

//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper";
import {
  postFakeLogin,
  postJwtLogin,
  postSocialLogin,
} from "../../../helpers/fakebackend_helper";
import { postLogin } from "../../../helpers/api_calls";
import { setAuthorization } from "../../../helpers/api_helper";


const fireBaseBackend = getFirebaseBackend();

function* loginUser({ payload: { user, history } }) {
  try {
      if (process.env.REACT_APP_API_URL) {
        const response = yield call(postLogin, {
          email: user.email,
          password: user.password,
        });
        if (response.access_token) {
          let userLoginIdentity = response.user
          userLoginIdentity.token = response.access_token
          userLoginIdentity.access_token_expires = response.access_token_expires
          localStorage.setItem("authUser", JSON.stringify(response.user));
          yield put(loginSuccess(response.user));
          setAuthorization(response.access_token);

          // // Actualizar el estado del usuario en AuthProvider
          // setUser(userLoginIdentity);
          // setIsAuthenticated(true);


          history('/dashboard');
        } else {
          console.warn('api error')
          yield put(apiError(response));
        }
      }
  } catch (error) {
    yield put(apiError(error));
  }
}



function* logoutUser() {
  try {
    localStorage.removeItem("authUser");
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const response = yield call(fireBaseBackend.logout);
      yield put(logoutUserSuccess(LOGOUT_USER, response));
    } else {
      yield put(logoutUserSuccess(LOGOUT_USER, true));
    }
  } catch (error) {
    yield put(apiError(LOGOUT_USER, error));
  }
}

function* socialLogin({ payload: { data, history, type } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const fireBaseBackend = getFirebaseBackend();
      const response = yield call(
        fireBaseBackend.socialLoginUser,
        data,
        type,
      );
      localStorage.setItem("authUser", JSON.stringify(response));
      yield put(loginSuccess(response));
    } else {
      const response = yield call(postSocialLogin, data);
      localStorage.setItem("authUser", JSON.stringify(response));
      yield put(loginSuccess(response));
    }
    history('/dashboard');
  } catch (error) {
    yield put(apiError(error));
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser);
  yield takeLatest(SOCIAL_LOGIN, socialLogin);
  yield takeEvery(LOGOUT_USER, logoutUser);
}

export default authSaga;
