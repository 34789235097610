import React, { useMemo, useState } from 'react';
import './InputRating.css';
import { Badge, Button, Card, CardBody, Col, Row } from 'reactstrap';
import { useFormik } from 'formik';
import * as Yup from "yup";
import Rating from 'react-rating';
import { toast } from 'react-toastify';
import { createApiMethods } from '../../../../helpers/api_calls';
import { RATINGVISITAS } from '../../../../helpers/api_urls';
import { InputTextarea } from '../inputTextarea/inputTextarea';
import { useAuth } from '../../../../AuthContext';


export const InputRating = ({titulo, submit, idrated=undefined,toggle}) => {
    const { user } = useAuth()
    const [customize, setcustomize] = useState("");
    const [reset, setreset] = useState("");
    const [ratingValue, setratingValue] = useState(0);
    const apiRatingVisitas = useMemo(() => createApiMethods(RATINGVISITAS), []);


    const calificationValidation = useFormik({
        enableReinitialize: true,
        initialValues: {
            user:  user?.id,
            event:  idrated,
            rating: null,
            comentarios: null,
        },
        validationSchema: Yup.object({
            comentarios: Yup.string().required("Este campo es requerido"),
        }),
        onSubmit: submit,
    });

    const handleRatingChange = (val) =>{
        setcustomize(val)
        setratingValue(val)
        calificationValidation.setFieldValue('rating',val)
    }
      
    const submitEvent = () =>{
        apiRatingVisitas.create(calificationValidation.values).then((res)=>{
            toast.success('Gracias por su calificación!')
            toggle()
        }).catch(console.error)
    }

    const handleCancel = () => {
        toggle()
    }

    return (
        <>
            <Card>

                <CardBody>
                    <Row>
                        <Col xl={12} style={{height: 'auto', margin: 'auto'}}>
                        </Col>
                        <Col xl={12}>
                            <div className='d-flex flex-column'>
                                {titulo}
                                <div className="d-flex flex-wrap gap-2 flex-column" style={{fontSize: '1.5rem'}}>
                                    <Rating
                                    emptySymbol="mdi mdi-star-outline text-muted"
                                    fullSymbol={reset}
                                    onClick={handleRatingChange}
                                    initialRating={ratingValue}
                                    onHover={() => setreset("mdi mdi-star text-warning")}/>
                                </div>

                            </div>
                        </Col>
                        <Col xl={12} style={{height: 'auto', margin: 'auto'}}>
                            <InputTextarea
                                label="Comentarios"
                                name         = "comentarios"
                                validation   = {calificationValidation}>
                            </InputTextarea>
                        </Col>
                        <Col xl={12}>
                            <Button
                                onClick={handleCancel} 
                                type="button" 
                                size='sm'
                                style={{marginRight: '5px'}}
                                className="btn btn-danger btn-load mr-3">
                                    Cancelar
                            </Button>   
                            <Button
                                onClick={() => submitEvent()} 
                                size='sm'
                                type="button" 
                                className="btn btn-success btn-load">
                                    Enviar
                            </Button>                            
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </>
    );
};
