import {
    GET_PROPIEDADES,
    API_RESPONSE_SUCCESS,
    API_RESPONSE_ERROR,

    ADD_PROPIEDADES_SUCCESS,
    ADD_PROPIEDADES_FAIL,
    UPDATE_PROPIEDADES_SUCCESS,
    UPDATE_PROPIEDADES_FAIL,
    DELETE_PROPIEDADES_SUCCESS,
    DELETE_PROPIEDADES_FAIL,
    GET_CIUDADES,
    GET_ESTADOS,
    GET_FRANQUICIAS,
    GET_TIPOSINMUEBLES,
    IS_LOADING,
    IS_NOT_LOADING,
    GET_TIPOSNEGOCIACION,
    GET_PROPIEDADES_DETAIL,
    RESET_NEW
} from "./propiedadesActionTypes";

const INIT_STATE = {
    propiedad_detail: {},
    propiedades: [],
    estados: [],
    ciudades: [],
    tiposinmuebles: [],
    franquicias: [],
    tiposnegociacion: [],
    error: {},
    isLoading: false,
    isPropiedadesCreated: false,
    newPropiedad:null,
};

const Propiedades = (state = INIT_STATE, action) => {
switch (action.type) {
    case IS_LOADING:
        return {
            ...state,
            isLoading: true,
        };

    case IS_NOT_LOADING:
        return {
            ...state,
            isLoading: false,
        };

    case RESET_NEW:
        return {
            ...state,
            isPropiedadesCreated: false,
        };


    case API_RESPONSE_SUCCESS:
        switch (action.payload.actionType) {
            case GET_PROPIEDADES_DETAIL:
                return {
                    ...state,
                    propiedad_detail: action.payload.data,
                };
            case GET_TIPOSNEGOCIACION:
                return {
                    ...state,
                    tiposnegociacion: action.payload.data,
                };
            case GET_PROPIEDADES:
                return {
                    ...state,
                    propiedades: action.payload.data,
                };
            case GET_ESTADOS:
                return {
                    ...state,
                    estados: action.payload.data,
                };
            case GET_CIUDADES:
                return {
                    ...state,
                    ciudades: action.payload.data,
                };
            case GET_FRANQUICIAS:
                return {
                    ...state,
                    franquicias: action.payload.data,
                };
            case GET_TIPOSINMUEBLES:
                return {
                    ...state,
                    tiposinmuebles: action.payload.data,
                };
            default:
                return { ...state };
        }
    case API_RESPONSE_ERROR:
        switch (action.payload.actionType) {
            case GET_PROPIEDADES:
                return {
                    ...state,
                    error: action.payload,
                    isPropiedadesCreated: false,
                    isPropiedadesSuccess: false
                };
                default:
            return { ...state };
        }

    case ADD_PROPIEDADES_SUCCESS:
        return {
            ...state,
            isPropiedadesCreated: true,
            error: null,
            newPropiedad:action.payload,
            propiedades: [...state.propiedades ],
        };

    case ADD_PROPIEDADES_FAIL:
        return {
            ...state,
            isPropiedadesCreated: false,
            newPropiedad:null,
            error: action.payload,
        };

    case UPDATE_PROPIEDADES_SUCCESS:
        return {
            ...state,
            propiedades: state.propiedades.map(propiedades =>
            propiedades.id.toString() === action.payload.data.id.toString()
                ? { ...propiedades, ...action.payload.data }
                : propiedades
            ),
        };

    case UPDATE_PROPIEDADES_FAIL:
        return {
            ...state,
            error: action.payload,
        };

    case DELETE_PROPIEDADES_SUCCESS:
        return {
            ...state,
            propiedades: state.propiedades.filter(
            propiedades => propiedades.id.toString() !== action.payload.propiedades.id.toString()
            ),
            isPropiedadesDelete: true,
            isPropiedadesDeleteFail: false,
        };

    case DELETE_PROPIEDADES_FAIL:
        return {
            ...state,
            error: action.payload,
            isPropiedadesDelete: false,
            isPropiedadesDeleteFail: true,
        };

    default:
        return { ...state };
}
};

export default Propiedades;