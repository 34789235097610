import React from "react";
import { PublicDetailCard } from "../Components/PublicDetailCard/PublicDetailCard";
import ForgetPasswordPage from "../pages/Authentication/ForgetPassword";
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import Alt404 from '../pages/AuthenticationInner/Errors/Alt404';
import Basic404 from '../pages/AuthenticationInner/Errors/Basic404';
import Cover404 from '../pages/AuthenticationInner/Errors/Cover404';
import Error500 from '../pages/AuthenticationInner/Errors/Error500';
import Offlinepage from "../pages/AuthenticationInner/Errors/Offlinepage";
import BasicPasswCreate from "../pages/AuthenticationInner/PasswordCreate/BasicPasswCreate";
import CoverPasswCreate from "../pages/AuthenticationInner/PasswordCreate/CoverPasswCreate";
import Calendar from "../pages/Calendar";
import { Clientes } from "../pages/Clientes/Clientes";
import CrmDeals from "../pages/Crm/CrmDeals/index";
import DashboardCrm from "../pages/DashboardCrm";
import EcommerceAddProduct from "../pages/Ecommerce/EcommerceProducts/EcommerceAddProduct";
import EcommerceProductDetail from "../pages/Ecommerce/EcommerceProducts/EcommerceProductDetail";
// import EcommerceProducts from "../pages/Ecommerce/EcommerceProducts/index";
import { FranquiciasList } from "../pages/Franquicias/FranquiciasList/FranquiciasList";
import JobsLanding from "../pages/Job_Landing";
import NFTLanding from "../pages/Landing/NFTLanding";
import OnePage from "../pages/Landing/OnePage";
import ComingSoon from '../pages/Pages/ComingSoon/ComingSoon';
import Maintenance from '../pages/Pages/Maintenance/Maintenance';
import Settings from '../pages/Pages/Profile/Settings/Settings';
import SimplePage from '../pages/Pages/Profile/SimplePage/SimplePage';
import ProjectOverview from "../pages/Projects/ProjectOverview";
import Chat from "../pages/Chat"
import { Integraciones } from "../pages/Integraciones/Integraciones.js";
import ReservasModule from "../pages/Reservas/ReservasModule/ReservasModule.js";
import { DetalleReservaView } from "../pages/Reservas/DetalleReservaView/DetalleReservaView.js";
import { DetalleReservaForm } from "../pages/Reservas/DetalleReservaForm/DetalleReservaForm.js";
import EcommerceAddProperty from "../pages/Propiedades/EcommerceAddProperty.js";
import KanbanBoard from "../pages/Crm/ClientesKanban/index.js";
import EcommerceProducts from "../pages/ListaPropiedades/EcommerceProducts.js";

const authProtectedRoutes = [
  { path: "/dashboard", component: <DashboardCrm /> },
  { path: "/inmuebles", component: <EcommerceProducts /> },
  { path: "/inmueble/:idinmueble", component: <EcommerceProductDetail /> },
  { path: "/formulario-inmueble", component: <EcommerceAddProperty /> },
  { path: "/mi-franquicia", component: <ProjectOverview /> },
  { path: "/formulario-inmueble/:idinmueble", component: <EcommerceAddProperty /> },
  { path: "/franquicia/:idfranquicia", component: <ProjectOverview /> },
  { path: "/franquicias", component: <FranquiciasList /> },
  { path: "/perfil-config", component: <Settings /> },
  { path: "/perfil", component: <SimplePage /> },
  { path: "/busquedas", component: <CrmDeals /> },
  { path: "/clientes", component: <Clientes /> },
  { path: "/clientes-board", component: <KanbanBoard /> },
  { path: "/chat", component: <Chat /> },
  { path: "/calendario", component: <Calendar /> },
  // { path: "/solicitudes-contacto", component: <SolicitudesContacto /> },
  { path: "/reservas", component: <ReservasModule /> },
  { path: "/reservas/:idreserva", component: <DetalleReservaView /> },
  { path: "/reservas/:idreserva/editar", component: <DetalleReservaForm /> },
  { path: "/integraciones", component: <Integraciones /> }
];

const publicRoutes = [
  { path: "/",exact: true, component: <OnePage /> },
  { path: "/propiedad/:idinmueble", component: <PublicDetailCard /> },
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
  { path: "/forgot-password", component: <ForgetPasswordPage /> },
  { path: "/register", component: <Register /> },
  { path: "*", component: <Basic404 /> },
  { path: "/auth-404-cover", component: <Cover404 /> },
  { path: "/auth-404-alt", component: <Alt404 /> },
  { path: "/auth-500", component: <Error500 /> },
  { path: "/pages-maintenance", component: <Maintenance /> },
  { path: "/pages-coming-soon", component: <ComingSoon /> },
  { path: "/landing", component: <OnePage /> },
  { path: "/nft-landing", component: <NFTLanding /> },
  { path: "/jobs-landing", component: <JobsLanding /> },
  { path: "/cambiar-contrasena", component: <BasicPasswCreate /> },
  { path: "/auth-pass-change-cover", component: <CoverPasswCreate /> },
  { path: "/auth-offline", component: <Offlinepage /> },

];

export { authProtectedRoutes, publicRoutes };
