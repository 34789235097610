import PropTypes from "prop-types";
import React, { Fragment } from "react";
import {
  useExpanded,
  useFilters,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable
} from "react-table";
import { Col, Row, Table, Button, Input } from "reactstrap";
import { DefaultColumnFilter } from "./filters";
import { DropdownItem, DropdownMenu, DropdownToggle, Pagination, PaginationItem, PaginationLink, UncontrolledDropdown } from 'reactstrap';


const TableContainer = ({
  columns,
  data,
  isGlobalSearch,
  customPageSize,
  tableClass,
  theadClass,
  trClass,
  thClass,
  divClass,
  handleHeaderRowClick = () => {},
  handleBodyRowClick = () => {},
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    gotoPage,
    setPageSize,
    state: { pageIndex, pageSize },
    pageCount,
    canPreviousPage,
    canNextPage,
  } = useTable(
    {
      columns,
      data,
      defaultColumn: { Filter: DefaultColumnFilter },
      initialState: {
        pageIndex: 0, pageSize: customPageSize, selectedRowIds: 0, sortBy: [
          {
            desc: true,
          },
        ],
      },
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect,
    
  );

  const generateSortingIndicator = (column) => {
    return column.isSorted ? (column.isSortedDesc ? " " : "") : "";
  };

  const onChangeInSelect = (event) => {
    setPageSize(Number(event.target.value));
  };
  const onChangeInInput = (event) => {
    const page = event.target.value ? Number(event.target.value) - 1 : 0;
    gotoPage(page);
  };

  return (
    <Fragment>
      <div className={divClass}>
        <Table hover {...getTableProps()} className={tableClass}>
          <thead className={theadClass}>
            {headerGroups.map((headerGroup) => (
              <tr onClick={handleHeaderRowClick} className={trClass} key={headerGroup.id}  {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th key={column.id} className={thClass} {...column.getSortByToggleProps()}>
                    {column.render("Header")}
                    {generateSortingIndicator(column)}
                    {/* <Filter column={column} /> */}
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <Fragment key={row.getRowProps().key}>
                  <tr>
                    {row.cells.map((cell) => {
                      return (
                        <td key={cell.id} {...cell.getCellProps()}>
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                </Fragment>
              );
            })}
          </tbody>
        </Table>
      </div>

      <Row className="mt-3">
        <div className='d-flex flex-row justify-content-between'>
          <div className='d-flex flex-row justify-content-between'>
            <Pagination listClassName="justify-content-center" className="pagination-separated mb-0">
              <PaginationItem disabled={!canPreviousPage}>
                <PaginationLink onClick={() => gotoPage(0)}>
                  <i className="mdi mdi-chevron-left" /><i className="mdi mdi-chevron-left" />
                </PaginationLink>
              </PaginationItem>
              <PaginationItem disabled={!canPreviousPage}>
                <PaginationLink onClick={() => gotoPage(pageIndex - 1)}>
                  <i className="mdi mdi-chevron-left" />
                </PaginationLink>
              </PaginationItem>
              <PaginationItem disabled={true}>
                <PaginationLink>
                  {pageIndex + 1} de {pageCount}
                </PaginationLink>
              </PaginationItem>
              <PaginationItem disabled={!canNextPage}>
                <PaginationLink onClick={() => gotoPage(pageIndex + 1)}>
                  <i className="mdi mdi-chevron-right" />
                </PaginationLink>
              </PaginationItem>
              <PaginationItem disabled={!canNextPage}>
                <PaginationLink onClick={() => gotoPage(pageCount - 1)}>
                  <i className="mdi mdi-chevron-right" /><i className="mdi mdi-chevron-right" />
                </PaginationLink>
              </PaginationItem>
              
            </Pagination>
            <span className="ms-2">
              Ir a la pagina:{" "}
              <Input
                type="number"
                defaultValue={pageIndex + 1}
                onChange={onChangeInInput}
                style={{ width: "100px", display: "inline" }}
              />
            </span>
          </div>
          
          <UncontrolledDropdown direction='start'>
              <DropdownToggle tag="button" className="btn btn-link text-muted p-1 mt-n2 py-0 text-decoration-none fs-15">
                  <i className="ri-more-2-fill"></i>
              </DropdownToggle>

              <DropdownMenu className="dropdown-menu-end">
                  <DropdownItem onClick={() =>setPageSize(10)}>
                    <i className="ri-add-box-line align-bottom me-2 text-muted"></i> 
                    Ver 10 por página
                  </DropdownItem>
                  <DropdownItem onClick={() =>setPageSize(20)}>
                    <i className="ri-add-box-line align-bottom me-2 text-muted"></i>
                    Ver 20 por página
                  </DropdownItem>
                  <DropdownItem onClick={() =>setPageSize(50)}>
                    <i className="ri-add-box-line align-bottom me-2 text-muted"></i>
                    Ver 50 por página
                  </DropdownItem>
                  <DropdownItem onClick={() =>setPageSize(100)}>
                    <i className="ri-add-box-line align-bottom me-2 text-muted"></i>
                    Ver 100 por página
                  </DropdownItem>
              </DropdownMenu>
          </UncontrolledDropdown>
        </div>
      </Row>
    </Fragment>
  );
};

TableContainer.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default TableContainer;