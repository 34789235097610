import React, { useEffect, useState } from 'react';
import { Card, CardBody, Col, Container, Input, Label, Row, Button, Form, FormFeedback, Alert, Spinner } from 'reactstrap';
import ParticlesAuth from "../AuthenticationInner/ParticlesAuth";

//redux
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import { GoogleLogin } from "react-google-login";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { loginUser, socialLogin, resetLoginFlag } from "../../store/actions";
import logoLight from "../../assets/images/logo-light.png";
import withRouter from "../../Components/Common/withRouter";
import { facebook, google } from "../../config";
import { inmoAppLogoSinFondo } from "../../assets/images";
import { registerUserWebsocket, subcribePushServerService, useAuth } from '../../AuthContext';
import { postLogin } from '../../helpers/api_calls';
import { setAuthorization, userValidators } from '../../helpers/api_helper';
import { BROADCAST_LOGIN, FRANQUICIA_ACL } from '../../helpers/entities';

const Login = (props) => {
    const { setUser,setIsAuthenticated, isAuthenticated,currentTabId,broadCastChannel } = useAuth();
    const navigate = useNavigate();

    const dispatch = useDispatch();
    const { user, errorMsg, loading, error } = useSelector(state => ({
        user: state.Account.user,
        errorMsg: state.Login.errorMsg,
        loading: state.Login.loading,
        error: state.Login.error,
    }));


    const [userLogin, setUserLogin] = useState([]);
    const [passwordShow, setPasswordShow] = useState(false);

    useEffect(()=>{
        if(isAuthenticated)
            navigate('/')
    },[isAuthenticated])

    useEffect(() => {
        if (user && user) {
            setUserLogin({
                email: user.user.email,
                password: user.user.confirm_password
            });
        }
    }, [user]);

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            email: userLogin.email || '',
            password: userLogin.password || '',
        },
        validationSchema: Yup.object({
            email: Yup.string().required("Ingresa tu Email"),
            password: Yup.string().required("Ingresa tu contrseña"),
        }),
        onSubmit: ({email,password}) => {
            postLogin({email,password}).then((response) => {
                
                let userLoginIdentity                   =  response.user
                userLoginIdentity.token                 =  response.access_token
                userLoginIdentity.access_token_expires  =  response.access_token_expires
                console.log(response.user)
                if(response.user.franquicia){
                    response.user.franquicia.obj_type = FRANQUICIA_ACL
                }
                localStorage.setItem("authUser", JSON.stringify(response.user));
                setUser(userValidators(userLoginIdentity));
                setIsAuthenticated(true)
                setAuthorization(response.access_token);
                registerUserWebsocket(userLoginIdentity.id,response.access_token)
                
                if(broadCastChannel){
                    broadCastChannel.postMessage({
                        type: BROADCAST_LOGIN,
                        currentTabId
                    })
                }
                if(response.user.reset_password){
                    props.router.navigate('/cambiar-contrasena');
                } else {
                    props.router.navigate('/dashboard');
                }
            }).catch(console.error).finally(()=>{
                
            })
        }
    });


    useEffect(() => {
        if (error) {
            setTimeout(() => {
                dispatch(resetLoginFlag());
            }, 3000);
        }
    }, [dispatch, error]);

    document.title = "Inmoapp";
    return (
        <React.Fragment>
            <ParticlesAuth>
                <div className="auth-page-content">
                    <Container>
                        <Row className="justify-content-center">
                            <Col md={8} lg={6} xl={5}>
                                <Card className="mt-4">
                                    <CardBody className="p-4">
                                        <div className="text-center mt-sm-5 text-white-50">
                                            <div>
                                                <Link to="/" className="d-inline-block auth-logo">
                                                    <img src={inmoAppLogoSinFondo} alt="" height="120" />
                                                </Link>
                                            </div>
                                            {/* <p className="mt-3 fs-15 fw-medium">Premium Admin & Dashboard Template</p> */}
                                        </div>
                                        <div className="text-center mt-2">
                                            <h5 className="text-primary">Bienvenido !</h5>
                                            {/* <p className="text-muted">Inicia Sesión en Inmoapp.</p> */}
                                        </div>
                                        {errorMsg && errorMsg ? (<Alert color="danger"> {errorMsg} </Alert>) : null}
                                        <div className="p-2 mt-4">
                                            <Form
                                                onSubmit={(e) => {
                                                    e.preventDefault();
                                                    validation.handleSubmit();
                                                    return false;
                                                }}
                                                action="#">

                                                <div className="mb-3">
                                                    <Label htmlFor="email" className="form-label">Email</Label>
                                                    <Input
                                                        name="email"
                                                        className="form-control"
                                                        placeholder="Escribe tu email"
                                                        type="email"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.email || ""}
                                                        invalid={
                                                            validation.touched.email && validation.errors.email ? true : false
                                                        }
                                                    />
                                                    {validation.touched.email && validation.errors.email ? (
                                                        <FormFeedback type="invalid" className="d-block">{validation.errors.email}</FormFeedback>
                                                    ) : null}
                                                </div>

                                                <div className="mb-3">
                                                    <div className="float-end">
                                                        <Link to="/forgot-password" className="text-muted">¿Olvidaste la contraseña?</Link>
                                                    </div>
                                                    <Label className="form-label" htmlFor="password-input">Contraseña</Label>
                                                    <div className="position-relative auth-pass-inputgroup mb-3">
                                                        <Input
                                                            name="password"
                                                            value={validation.values.password || ""}
                                                            type={passwordShow ? "text" : "password"}
                                                            className="form-control pe-5"
                                                            placeholder="Escribe tu contraseña"
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            invalid={
                                                                validation.touched.password && validation.errors.password ? true : false
                                                            }
                                                        />
                                                        {validation.touched.password && validation.errors.password ? (
                                                            <FormFeedback type="invalid" className="d-block">{validation.errors.password}</FormFeedback>
                                                        ) : null}
                                                        <button className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted" type="button" id="password-addon" onClick={() => setPasswordShow(!passwordShow)}><i className="ri-eye-fill align-middle"></i></button>
                                                    </div>
                                                </div>

                                                <div className="form-check">
                                                    <Input className="form-check-input" type="checkbox" value="" id="auth-remember-check" />
                                                    <Label className="form-check-label" htmlFor="auth-remember-check">Recordarme</Label>
                                                </div>

                                                <div className="mt-4">
                                                    <Button color="success" disabled={error ? null : loading ? true : false} className="btn btn-success w-100" type="submit">
                                                        {error ? null : loading ? <Spinner size="sm" className='me-2'> Loading... </Spinner> : null}
                                                        Iniciar Sesión
                                                    </Button>
                                                </div>

                                                {/* <div className="mt-4 text-center">
                                                    <div className="signin-other-title">
                                                        <h5 className="fs-13 mb-4 title">Sign In with</h5>
                                                    </div>
                                                    <div>
                                                        <FacebookLogin
                                                            appId={facebook.APP_ID}
                                                            autoLoad={false}
                                                            callback={facebookResponse}
                                                            render={renderProps => (
                                                                <Button color="primary"
                                                                    className="btn-icon me-1"
                                                                    onClick={renderProps.onClick}
                                                                >
                                                                    <i className="ri-facebook-fill fs-16" />
                                                                </Button>
                                                            )}
                                                        />
                                                        <GoogleLogin
                                                            clientId={
                                                                google.CLIENT_ID ? google.CLIENT_ID : ""
                                                            }
                                                            render={renderProps => (
                                                                <Button color="danger"
                                                                    to="#"
                                                                    className="btn-icon me-1"
                                                                    onClick={renderProps.onClick}
                                                                >
                                                                    <i className="ri-google-fill fs-16" />
                                                                </Button>
                                                            )}
                                                            onSuccess={googleResponse}
                                                            onFailure={() => {

                                                            }}
                                                        />
                                                        <Button color="dark" className="btn-icon"><i className="ri-github-fill fs-16"></i></Button>{" "}
                                                        <Button color="info" className="btn-icon"><i className="ri-twitter-fill fs-16"></i></Button>
                                                    </div>
                                                </div> */}
                                            </Form>
                                        </div>
                                    </CardBody>
                                </Card>
{/* 
                                <div className="mt-4 text-center">
                                    <p className="mb-0"> <Link to="/register" className="fw-semibold text-primary text-decoration-underline"> Registrarse </Link> </p>
                                </div> */}

                            </Col>
                        </Row>
                    </Container>
                </div>
            </ParticlesAuth>
        </React.Fragment>
    );
};

export default withRouter(Login);