import React, { useEffect, useState, useMemo } from "react";
import './TabDatosBasicos.js.css';
import {
    Col,
    Input,
    FormFeedback,
  } from "reactstrap";
  import { useSelector, useDispatch } from "react-redux";
import { amobladoChoices, vistasChoices,antiguedadChoices } from '../../../../helpers/options';

import { InputTextarea } from "../../../Forms/Inputs/inputTextarea/inputTextarea";
import { InputText } from "../../../Forms/Inputs/InputText/InputText";
import { InputNumberSelect } from "../../../Forms/Inputs/InputNumberSelect/InputNumberSelect";
import { getciudades, getestados, getfranquicias, gettiposinmuebles, gettiposnegociacion } from "../../../../store/actions";
import { InputSwitch } from "../../../Forms/Inputs/InputSwitch/InputSwitch";
import { InputSelect } from "../../../Forms/Inputs/InputSelect/InputSelect";
  
export const TabDatosBasicos = ({validation}) => {
    const dispatch = useDispatch();
    const [ciudadesList, setciudadesList] = useState([]);
    const estadoValue = Number(validation.values.estado)

    const { propiedades } = useSelector((state) => ({
        propiedades: state.Propiedades,
    }));

    useEffect(() => {
        dispatch(gettiposnegociacion({page_size: 1000}));
        dispatch(getciudades({page_size: 1000}));
        dispatch(getestados({page_size: 1000}));
        dispatch(gettiposinmuebles({page_size: 1000}));
        dispatch(getfranquicias({page_size: 1000}));
      }, [dispatch]);

    useEffect(() => {
        if (propiedades.ciudades && propiedades.ciudades.results) {
            if (estadoValue !== null || estadoValue!== undefined || estadoValue!==0){
                let filters = propiedades.ciudades.results.filter((e) => e.estado.id === estadoValue)
                setciudadesList(filters)
            }
        }
    }, [estadoValue, propiedades.ciudades]);
    return (
        <>
            <Col  lg={3}>
                <InputSelect
                      name         = "estado"
                      label        = "Estado"
                      validation   = {validation}
                      optionsProps = {
                          {
                              value:'id',
                              label:'nombre',
                              items:propiedades.estados.results || []
                          }}>
                  </InputSelect>  
            </Col>        
            <Col lg={3}>
                <InputSelect
                    disabled={!!!validation.values.estado}
                    name         = "ciudad"
                    label        = "Ciudades"
                    validation   = {validation}
                    optionsProps = {
                        {
                            value:'id',
                            label:'nombre',
                            items:ciudadesList || []
                        }}>
                </InputSelect>  
            </Col>       
            <Col  lg={6}>
                <InputTextarea
                    label="Direccion"
                    name         = "direccion"
                    validation   = {validation}>
                </InputTextarea>
            </Col>
            <Col  lg={4}>
                <InputText
                    label="Mts. Cuadrados"
                    name         = "metros_cuadrados_terreno"
                    validation   = {validation}>
                </InputText>
            </Col>

            <Col  lg={4}>
                <InputText
                    label="Mts. Cuadrados Construccion"
                    name         = "metros_cuadrados_construccion"
                    validation   = {validation}>
                </InputText>
            </Col>

            
            <Col  lg={4}>
                <InputNumberSelect
                    name         = "numero_plantas"
                    label        = "Plantas"
                    validation   = {validation}>
                </InputNumberSelect>
            </Col>     

            <Col  lg={4}>
                <InputNumberSelect
                    name         = "numero_banios"
                    label        = "Baños"
                    validation   = {validation}>
                </InputNumberSelect>
            </Col>

            <Col  lg={4}>
                <InputNumberSelect
                    name         = "banio_servicio"
                    label        = "Baños de Servicio"
                    validation   = {validation}>
                </InputNumberSelect>
            </Col>   

            <Col  lg={4}>
                <InputNumberSelect
                    name         = "habitaciones"
                    label        = "Habitaciones"
                    validation   = {validation}>
                </InputNumberSelect>
            </Col>     

            <Col  lg={4}>
                <InputNumberSelect
                    name         = "hab_servicio"
                    label        = "Habitaciones de Servicio"
                    validation   = {validation}>
                </InputNumberSelect>
            </Col>     

            <Col  lg={4}>
                <InputNumberSelect
                    name         = "ptos_estacionamiento"
                    label        = "Ptos de Estacionamiento"
                    validation   = {validation}>
                </InputNumberSelect>
            </Col>

            <Col  lg={4}>
                <InputSelect
                    name="antiguedad"
                    label="Antiguedad del inmueble"
                    validation={validation}
                    optionsProps={{
                    value: 'id',
                    label: 'nombre',
                    items: antiguedadChoices || [],
                }}
                />
            </Col>
            <Col  lg={4}>
                <InputSelect
                    name="vista"
                    label="Vista"
                    validation={validation}
                    optionsProps={{
                    value: 'id',
                    label: 'nombre',
                    items: vistasChoices || [],
                }}
                />
            </Col>
            <Col  lg={4}>
                <InputSelect
                    name="amoblado"
                    label="Amoblado"
                    validation={validation}
                    optionsProps={{
                    value: 'id',
                    label: 'nombre',
                    items: amobladoChoices || [],
                }}
                />
            </Col>

            <Col  lg={4}>
                <InputSwitch
                    name="family_room"
                    label="Sala de Estar"
                    validation={validation}/>                
            </Col>
            <Col  lg={4}>
                <InputSwitch
                    name="estudio"
                    label="Estudio"
                    validation={validation}/>
            </Col>
            <Col  lg={4}>
                <InputSwitch
                    name="lavadero"
                    label="Lavadero"
                    validation={validation}/>
            </Col>
            <Col  lg={4}>
                <InputSwitch
                    name="terraza_jardin"
                    label="Terraza / Jardin"
                    validation={validation}/>
            </Col>
            <Col  lg={4}>
                <InputSwitch
                    name="meletero_bodega"
                    label="Maletero / Bodega"
                    validation={validation}/>
            </Col>
            <Col  lg={4}>
                <InputSwitch
                    name="tanque_agua"
                    label="Tanque de Agua"
                    validation={validation}/>                
            </Col>
            <Col  lg={4}>
                <InputSwitch
                    name="planta_electrica"
                    label="Planta Electrica"
                    validation={validation}/>                     
            </Col>            
        </>
    );
};
