import React from 'react';
import './InputSelect.scss';
import { FormFeedback, Label } from 'reactstrap';

export const InputSelect = ({ disabled,validation, optionsProps, ...props }) => {
    return (
        <div className="mb-3">
            {
                ((props.label !== undefined) && (props.label !== ' ')) && (
                    <Label htmlFor={props?.name || null} className="form-label">
                        {props?.label} 
                        { props.required ? <span className="text-danger">*</span> : ' ' }
                    </Label>
                )
            }
            <select 
                disabled={disabled}
                name      = {props?.name || null}
                className = "form-select mb-3" 
                onChange  = {validation.handleChange}
                onBlur    = {validation.handleBlur}
                value     = {validation.values[props?.name] || ""}>
                <option> 
                    Todos
                </option>
                {
                  (optionsProps.items || []).map((x,y)=>{
                      return(
                          <option value={x[optionsProps?.value]} key={y}>{x[optionsProps?.label]}</option>
                      ) 
                  })
                }
            </select>
            {validation.touched[props?.name] && validation.errors[props?.name] ? (
                <FormFeedback type="invalid" className="d-block"><div>{validation.errors[props?.name]}</div></FormFeedback>
            ) : null}
        </div>
    );
};
