import { useFormik } from 'formik';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Card, CardBody, CardHeader, Col, Container, Row, Table } from 'reactstrap';
import * as Yup from 'yup';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import { inmoAppLogoSinFondo } from "../../../assets/images"; // Importa el logo
import { createApiMethods } from '../../../helpers/api_calls';
import { crearFormData } from '../../../helpers/api_helper';
import { GASTOSRESERVAS, RESERVAS } from '../../../helpers/api_urls';
import { DocumentsUploader } from '../../Ecommerce/EcommerceProducts/components/DocumentsUploader';
import './DetalleReservaForm.css';
import { SelectInput, TextAreaInput, TextInput } from './Inputs';
import { usdPortfolioData } from '../../../common/data';
import { AbilityContext } from '../../../helpers/acl/Can';
import { RESERVA_ACL } from '../../../helpers/entities';

export const DetalleReservaForm = () => {
  const { idreserva } = useParams();
  const [reserva, setReserva] = useState(null);
  const [documentos, setDocumentos] = useState([]);
  const [gastos, setGastos] = useState([{ concepto: "", monto: "" }]);
  const apiReservas = createApiMethods(RESERVAS);
  const apiGastosReservas = createApiMethods(GASTOSRESERVAS);
  const navigate = useNavigate();
  const [precioPropiedad, setprecioPropiedad] = useState(0);
  const dropZoneRef = useRef(null);
  const gastosUploaderRef = useRef(null);
  const [tmpGasto, settmpGasto] = useState({});
  const [isLoadingGastos, setisLoadingGastos] = useState(false);
  const ability = useContext(AbilityContext)
  // ability.can('update',clienteItem)

  useEffect(() => {
    apiReservas.getDetails(idreserva).then((response) => {
      response.obj_type = RESERVA_ACL
      setReserva(response);
      setDocumentos(response.documentos || []);
      setprecioPropiedad(response.propiedad?.precio || 0);
      let gastos = response.gastos.map((_,index) => { return {..._,obj_type : GASTOSRESERVAS}})
      setGastos(gastos);
      console.log(response.documentos)
      dropZoneRef.current.setUploadedFiles(response.documentos)
    });
  }, [idreserva]);

  const calculateMonto = (porcentaje) => {
    return (porcentaje / 100) * precioPropiedad;
  };

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      status_reserva: reserva?.status_reserva.id || '',
      monto_reserva: reserva?.monto_reserva || '',
      porcentaje_reserva: reserva?.porcentaje_reserva || '',
      fecha_reserva: reserva?.fecha_reserva || '',
      condiciones: reserva?.condiciones || '',
      documento_reserva: [],
    },
    validationSchema: Yup.object({
      status_reserva: Yup.string().required('El estado es obligatorio'),
      monto_reserva: Yup.number().required('El monto de reserva es obligatorio'),
      porcentaje_reserva: Yup.number().required('El porcentaje de reserva es obligatorio'),
      fecha_reserva: Yup.date().required('La fecha de reserva es obligatoria'),
      condiciones: Yup.string().required('Las condiciones son obligatorias'),
    }),
    onSubmit: (values) => {
      let data = crearFormData(values)
      
      data.append('id',reserva.id)
      apiReservas.update(data).then((res)=>{
        console.log(res)
      })

      // navigate(`/reservas/${idreserva}`);
    },
  });

  useEffect(() => {
    if (validation.values.porcentaje_reserva) {
      const newMonto = calculateMonto(parseFloat(validation.values.porcentaje_reserva));
      validation.setFieldValue('monto_reserva', newMonto.toFixed(2));
    }
  }, [validation.values.porcentaje_reserva, precioPropiedad]);

  // Función para agregar un gasto
  const handleAddGasto = async () => {
    try {
      setisLoadingGastos(true)
      let { id } = reserva
      const data =  {
        ...tmpGasto,
        reserva: id
      }
      const response = await apiGastosReservas.create(data); // Subir gasto al backend
      setGastos([...gastos,tmpGasto])
      settmpGasto({})
      setisLoadingGastos(false)
      console.log("Gasto agregado con éxito", response);
    } catch (error) {
      console.error("Error al agregar el gasto", error);
    }
  };

  // Función para eliminar un gasto
  const handleRemoveGasto = async (gasto) => {
    try {
      await apiGastosReservas.delete({ id: gasto.id }); // Eliminar el gasto en el backend
      setGastos(gastos.filter((_, i) => _.id != gasto.id )); // Remover del estado local
      console.log("Gasto eliminado con éxito");
    } catch (error) {
      console.error("Error al eliminar el gasto", error);
    }
  };

  const optionsStatus = [
    { value: 1, label: 'En Proceso' },
    { value: 2, label: 'Suspendido' },
    { value: 3, label: 'Pagado' },
  ];
  
  const handleAdd = (name,value) => {
    settmpGasto({...tmpGasto, [name]: value})
  }

  const totalGastos = () => {
    return gastos.reduce((acc,item,index,arr)=>{
      return acc= acc + Number(item.monto || 0)
    },0)
  }

  const isAddGastoDisabled = (!tmpGasto.nombre || !tmpGasto.monto) || isLoadingGastos;

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb title="Editar Reserva" pageTitle="Reservas" />
        <Row className="justify-content-center">
          <Col xxl={9}>
            <Card>


              <CardHeader className="border-bottom-dashed p-4">
                <div className="d-sm-flex">
                  <div className="flex-grow-1">
                    <img src={inmoAppLogoSinFondo} alt="logo" height="55" />
                    <div className="mt-sm-5 mt-4">
                      <h6 className="text-muted text-uppercase fw-semibold">Editar Reserva</h6>
                    </div>
                  </div>

                    <div className="flex-shrink-0 mt-sm-0 mt-3 text-end">
                        <h6>
                            <span className="text-muted fw-normal">ID de Reserva:</span> {idreserva}
                        </h6>

                    </div>
                </div>
              </CardHeader>


                <CardBody className="border-bottom border-bottom-dashed p-4">
                  <Row>
                    <Col lg={4} className='flex-column-reverse d-flex'>
                      <SelectInput
                        validation={validation}
                        label='Estatus'
                        name="status_reserva"
                        options={optionsStatus}
                        placeholder="Estado de la Reserva"
                      />
                    </Col>
                    <Col lg={4}>
                      <TextInput
                        validation={validation}
                        label='Monto de Reserva'
                        disabled={true}
                        name="monto_reserva"
                        placeholder="Monto de la Reserva"
                      />
                    </Col>
                    <Col lg={4}>
                      <TextInput
                        validation={validation}
                        label='Porcentaje de Reserva'
                        name="porcentaje_reserva"
                        placeholder="Porcentaje de la Reserva"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={12}>
                      <TextAreaInput
                        validation={validation}
                        name="condiciones"
                        placeholder="Condiciones de la Reserva"
                      />
                    </Col>
                  </Row>
                </CardBody>

                <CardBody>
                  <DocumentsUploader
                    ref={dropZoneRef}
                    additionalData={
                      {
                        reserva:idreserva,
                        tipo_documento:'Contrato'
                      }
                    }
                    name="archivo"  // Nombre del parámetro que se enviará al servidor en el FormData
                    endpoint={`/reservas/${idreserva}/documentos/`}  // Endpoint del backend
                    endpointdelete={`/documentos`}  // Endpoint del backend
                    isMultiple={true}  // Permitir la carga de múltiples archivos
                    validation={{}}  // Si tienes validaciones adicionales, puedes pasarlas aquí
                  />

                </CardBody>

                <CardBody className="p-4 border-top border-top-dashed">
                  <h6>Gastos Extra</h6>






                  <div className="table-responsive">
                  <Table className="table-borderless text-center table-nowrap align-middle mb-0">
                    <thead>
                      <tr className="table-active">
                        <th scope="col" style={{ width: "50px" }}>
                          #
                        </th>
                        <th scope="col">Concepto</th>
                        <th scope="col"></th>
                        <th scope="col"></th>
                        <th scope="col"></th>
                        <th scope="col" className="text-end">
                          Monto
                        </th>

                      </tr>
                    </thead>
                    <tbody>
                      {
                        gastos.length > 0 &&
                        (gastos || []).map((gasto,index)=>(
                          <tr key={index}>
                            <th scope="row">{index + 1}</th>
                            <td className="text-start">
                              <span className="fw-medium">
                                {gasto.nombre}
                              </span>
                              <p className="text-muted mb-0">
                                
                              </p>
                            </td>

                            <td>
                              <Button className='btn-sm' color="danger" onClick={() => handleRemoveGasto(gasto)}>
                                Eliminar
                              </Button>                              
                            </td>
                            <td></td>
                            <td></td>
                            <td className="text-end">
                              $ {gasto.monto}
                            </td>
                          </tr>
                        ))
                      }

                      <tr className="border-top border-top-dashed mt-2">
                        <td colSpan="3"></td>
                        <td colSpan="2" className="fw-medium p-0">
                          <Table className="table-borderless text-start table-nowrap align-middle mb-0">
                            <tbody>
                              <tr className="border-top border-top-dashed">
                                <th scope="row">Total Gastos</th>
                                <td className="text-end">$ { totalGastos() }</td>
                              </tr>
                            </tbody>
                          </Table>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>


                      {/* { */}
                   
                        {/* ability.can('update',reserva) && */}
                        <Row className="align-items-center mb-2 mt-3">
                          <Col lg={5}>
                            <TextInput
                              validation={{
                                values: { nombre: tmpGasto?.nombre }, // Mostrar "Sin nombre" si está vacío
                                handleChange: (e) => {
                                  handleAdd('nombre',e.target.value)
                                },
                                handleBlur: () => {},
                                errors: {},
                                touched: {},
                              }}
                              name={`nombre`}
                              placeholder="Concepto del Gasto"
                            />
                          </Col>
                          <Col lg={5}>
                            <TextInput
                              validation={{
                                values: { monto: tmpGasto?.monto}, // Asegúrate de que el monto está disponible
                                handleChange: (e) => {
                                  handleAdd('monto',e.target.value)
                                },
                                handleBlur: () => {},
                                errors: {},
                                touched: {},
                              }}
                              name={`monto`}
                              placeholder="Monto del Gasto"
                            />
                          </Col>
                          <Col lg={2}>

                            <Button disabled={isAddGastoDisabled} color="primary" onClick={handleAddGasto}>
                              Añadir Gasto
                            </Button>
                          </Col>
                        </Row>

                </CardBody>

                <CardBody className="p-4">
                  <div className="hstack gap-2 justify-content-end d-print-none mt-4">
                    <Button onClick={() => validation.handleSubmit()} color="success">
                      Guardar
                    </Button>
                    <Button color="danger" onClick={() => navigate(-1)}>
                      Cancelar
                    </Button>
                  </div>
                </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

