export const mockKanban = [
    {
        "id": 1,
        "title": "Captacion",
        "count": 7,
        "child": [
            {
                "id": 57,
                "email": "",
                "franquicia": {
                    "nombre": "Ervi Solorzano Deluxe",
                    "descripcion": null,
                    "id": 103,
                    "tipo_franquicia": {
                        "id": 3,
                        "nombre": "Franquicia Personal",
                        "descripcion": "Franquicia Personal",
                        "slug_name": "franquicia_personal"
                    },
                    "usuario_asignado": {
                        "username": null,
                        "email": "ervii.deluxe@gmail.com",
                        "profile": {
                            "id": 3,
                            "descripcion": "Gerente",
                            "nombre": "Gerente",
                            "codigo": "5625acf1-f0f6-4ce1-b206-c0ab0faf79ca",
                            "slug_name": "gerente",
                            "keys": []
                        },
                        "first_name": "Ervi",
                        "last_name": "Solorzano",
                        "full_name": "Ervi Solorzano",
                        "id": 81,
                        "first_image_url": "https://inmobapp-public-bucket.s3.us-east-2.amazonaws.com/static/media/default-user.jpg",
                        "contactos": []
                    },
                    "image": "https://inmobapp-public-bucket.s3.us-east-2.amazonaws.com/static/media/default-user.jpg",
                    "franquicia_comercial": 43,
                    "franquicia_personal": null,
                    "medios_contacto": [],
                    "solicitudes": {
                        "username": null,
                        "email": "ervii.deluxe@gmail.com",
                        "profile": {
                            "id": 3,
                            "descripcion": "Gerente",
                            "nombre": "Gerente",
                            "codigo": "5625acf1-f0f6-4ce1-b206-c0ab0faf79ca",
                            "slug_name": "gerente",
                            "keys": []
                        },
                        "first_name": "Ervi",
                        "last_name": "Solorzano",
                        "full_name": "Ervi Solorzano",
                        "id": 81,
                        "first_image_url": "https://inmobapp-public-bucket.s3.us-east-2.amazonaws.com/static/media/default-user.jpg",
                        "contactos": []
                    },
                    "configuraciones": {}
                },
                "contactos_clientes": [
                    {
                        "id": 57,
                        "tipo": {
                            "id": 1,
                            "descripcion": "Whatsapp",
                            "nombre": "Whatsapp"
                        },
                        "valor": "+58 424 144 5498"
                    }
                ],
                "visitas": [],
                "busquedas": [],
                "status": {
                    "id": 1,
                    "nombre": "Captacion"
                },
                "nombre": "Juan Lorenzo",
                "created_at": "2024-05-21T16:03:11.928698-04:00",
                "autor": 27,
                "editores": []
            },
            {
                "id": 56,
                "email": "",
                "franquicia": {
                    "nombre": "Ervi Solorzano Deluxe",
                    "descripcion": null,
                    "id": 103,
                    "tipo_franquicia": {
                        "id": 3,
                        "nombre": "Franquicia Personal",
                        "descripcion": "Franquicia Personal",
                        "slug_name": "franquicia_personal"
                    },
                    "usuario_asignado": {
                        "username": null,
                        "email": "ervii.deluxe@gmail.com",
                        "profile": {
                            "id": 3,
                            "descripcion": "Gerente",
                            "nombre": "Gerente",
                            "codigo": "5625acf1-f0f6-4ce1-b206-c0ab0faf79ca",
                            "slug_name": "gerente",
                            "keys": []
                        },
                        "first_name": "Ervi",
                        "last_name": "Solorzano",
                        "full_name": "Ervi Solorzano",
                        "id": 81,
                        "first_image_url": "https://inmobapp-public-bucket.s3.us-east-2.amazonaws.com/static/media/default-user.jpg",
                        "contactos": []
                    },
                    "image": "https://inmobapp-public-bucket.s3.us-east-2.amazonaws.com/static/media/default-user.jpg",
                    "franquicia_comercial": 43,
                    "franquicia_personal": null,
                    "medios_contacto": [],
                    "solicitudes": {
                        "username": null,
                        "email": "ervii.deluxe@gmail.com",
                        "profile": {
                            "id": 3,
                            "descripcion": "Gerente",
                            "nombre": "Gerente",
                            "codigo": "5625acf1-f0f6-4ce1-b206-c0ab0faf79ca",
                            "slug_name": "gerente",
                            "keys": []
                        },
                        "first_name": "Ervi",
                        "last_name": "Solorzano",
                        "full_name": "Ervi Solorzano",
                        "id": 81,
                        "first_image_url": "https://inmobapp-public-bucket.s3.us-east-2.amazonaws.com/static/media/default-user.jpg",
                        "contactos": []
                    },
                    "configuraciones": {}
                },
                "contactos_clientes": [
                    {
                        "id": 56,
                        "tipo": {
                            "id": 1,
                            "descripcion": "Whatsapp",
                            "nombre": "Whatsapp"
                        },
                        "valor": "+58 424 144 5498"
                    }
                ],
                "visitas": [],
                "busquedas": [],
                "status": {
                    "id": 1,
                    "nombre": "Captacion"
                },
                "nombre": "Juan Lorenzo",
                "created_at": "2024-05-21T15:49:15.920677-04:00",
                "autor": 27,
                "editores": []
            },
            {
                "id": 55,
                "email": "",
                "franquicia": {
                    "nombre": "Inmobiliaria Deluxe",
                    "descripcion": null,
                    "id": 43,
                    "tipo_franquicia": {
                        "id": 2,
                        "nombre": "Franquicia Comercial",
                        "descripcion": "Franquicia Comercial",
                        "slug_name": "franquicia_comercial"
                    },
                    "usuario_asignado": {
                        "username": null,
                        "email": "tuenlacedeluxe@gmail.com",
                        "profile": {
                            "id": 3,
                            "descripcion": "Gerente",
                            "nombre": "Gerente",
                            "codigo": "5625acf1-f0f6-4ce1-b206-c0ab0faf79ca",
                            "slug_name": "gerente",
                            "keys": []
                        },
                        "first_name": "Deluxe",
                        "last_name": "Las mercedes",
                        "full_name": "Deluxe Las mercedes",
                        "id": 27,
                        "first_image_url": "https://inmobapp-public-bucket.s3.amazonaws.com/media/user_image/Logo_Color_Original.png",
                        "contactos": [
                            {
                                "tipo": 1,
                                "valor": "+584241445498"
                            },
                            {
                                "tipo": 1,
                                "valor": "+584242396556"
                            }
                        ]
                    },
                    "image": "https://inmobapp-public-bucket.s3.amazonaws.com/media/franquicia_image/Logo_Color_Original.png",
                    "franquicia_comercial": null,
                    "franquicia_personal": null,
                    "medios_contacto": [],
                    "solicitudes": {
                        "username": null,
                        "email": "tuenlacedeluxe@gmail.com",
                        "profile": {
                            "id": 3,
                            "descripcion": "Gerente",
                            "nombre": "Gerente",
                            "codigo": "5625acf1-f0f6-4ce1-b206-c0ab0faf79ca",
                            "slug_name": "gerente",
                            "keys": []
                        },
                        "first_name": "Deluxe",
                        "last_name": "Las mercedes",
                        "full_name": "Deluxe Las mercedes",
                        "id": 27,
                        "first_image_url": "https://inmobapp-public-bucket.s3.amazonaws.com/media/user_image/Logo_Color_Original.png",
                        "contactos": [
                            {
                                "tipo": 1,
                                "valor": "+584241445498"
                            },
                            {
                                "tipo": 1,
                                "valor": "+584242396556"
                            }
                        ]
                    },
                    "configuraciones": {
                        "contact_email": "tuenlacedeluxe@gmail.com",
                        "franquicias_enlaces": "44,45,46,47",
                        "prop_list_endpoint": "wp-json/inmobapp/v1/properties/",
                        "prop_upload_endpoint": "wp-json/inmobapp/v1/inmobapp-upload/",
                        "web_url": "https://preview.tuenlaceinmobiliario.com/",
                        "wordpress_auth_key": "e5cee474d75f3ea64ad9aade5b15817d"
                    }
                },
                "contactos_clientes": [
                    {
                        "id": 55,
                        "tipo": {
                            "id": 1,
                            "descripcion": "Whatsapp",
                            "nombre": "Whatsapp"
                        },
                        "valor": "+58 414 555 6666"
                    }
                ],
                "visitas": [],
                "busquedas": [],
                "status": {
                    "id": 1,
                    "nombre": "Captacion"
                },
                "nombre": "Juan Lorenso",
                "created_at": "2024-05-21T15:46:41.106103-04:00",
                "autor": 27,
                "editores": []
            },
            {
                "id": 47,
                "email": "",
                "franquicia": {
                    "nombre": "Barbara Deluxe",
                    "descripcion": null,
                    "id": 44,
                    "tipo_franquicia": {
                        "id": 4,
                        "nombre": "Membresía",
                        "descripcion": "Membresía",
                        "slug_name": "membresia"
                    },
                    "usuario_asignado": {
                        "username": null,
                        "email": "enlacedeluxebn@gmail.com",
                        "profile": {
                            "id": 3,
                            "descripcion": "Gerente",
                            "nombre": "Gerente",
                            "codigo": "5625acf1-f0f6-4ce1-b206-c0ab0faf79ca",
                            "slug_name": "gerente",
                            "keys": []
                        },
                        "first_name": "Barbara",
                        "last_name": "Novoa",
                        "full_name": "Barbara Novoa",
                        "id": 31,
                        "first_image_url": "https://inmobapp-public-bucket.s3.us-east-2.amazonaws.com/static/media/default-user.jpg",
                        "contactos": []
                    },
                    "image": "https://inmobapp-public-bucket.s3.amazonaws.com/media/franquicia_image/WhatsApp_Image_2024-07-09_at_12.20.13.jpeg",
                    "franquicia_comercial": 43,
                    "franquicia_personal": null,
                    "medios_contacto": [],
                    "solicitudes": {
                        "username": null,
                        "email": "enlacedeluxebn@gmail.com",
                        "profile": {
                            "id": 3,
                            "descripcion": "Gerente",
                            "nombre": "Gerente",
                            "codigo": "5625acf1-f0f6-4ce1-b206-c0ab0faf79ca",
                            "slug_name": "gerente",
                            "keys": []
                        },
                        "first_name": "Barbara",
                        "last_name": "Novoa",
                        "full_name": "Barbara Novoa",
                        "id": 31,
                        "first_image_url": "https://inmobapp-public-bucket.s3.us-east-2.amazonaws.com/static/media/default-user.jpg",
                        "contactos": []
                    },
                    "configuraciones": {
                        "contact_email": "maria@mail.com"
                    }
                },
                "contactos_clientes": [
                    {
                        "id": 47,
                        "tipo": {
                            "id": 1,
                            "descripcion": "Whatsapp",
                            "nombre": "Whatsapp"
                        },
                        "valor": "+34 635 101 850"
                    }
                ],
                "visitas": [],
                "busquedas": [],
                "status": {
                    "id": 1,
                    "nombre": "Captacion"
                },
                "nombre": "Fabi",
                "created_at": "2024-01-17T12:16:35.427672-04:00",
                "autor": 31,
                "editores": []
            },
            {
                "id": 45,
                "email": "",
                "franquicia": {
                    "nombre": "Barbara Deluxe",
                    "descripcion": null,
                    "id": 44,
                    "tipo_franquicia": {
                        "id": 4,
                        "nombre": "Membresía",
                        "descripcion": "Membresía",
                        "slug_name": "membresia"
                    },
                    "usuario_asignado": {
                        "username": null,
                        "email": "enlacedeluxebn@gmail.com",
                        "profile": {
                            "id": 3,
                            "descripcion": "Gerente",
                            "nombre": "Gerente",
                            "codigo": "5625acf1-f0f6-4ce1-b206-c0ab0faf79ca",
                            "slug_name": "gerente",
                            "keys": []
                        },
                        "first_name": "Barbara",
                        "last_name": "Novoa",
                        "full_name": "Barbara Novoa",
                        "id": 31,
                        "first_image_url": "https://inmobapp-public-bucket.s3.us-east-2.amazonaws.com/static/media/default-user.jpg",
                        "contactos": []
                    },
                    "image": "https://inmobapp-public-bucket.s3.amazonaws.com/media/franquicia_image/WhatsApp_Image_2024-07-09_at_12.20.13.jpeg",
                    "franquicia_comercial": 43,
                    "franquicia_personal": null,
                    "medios_contacto": [],
                    "solicitudes": {
                        "username": null,
                        "email": "enlacedeluxebn@gmail.com",
                        "profile": {
                            "id": 3,
                            "descripcion": "Gerente",
                            "nombre": "Gerente",
                            "codigo": "5625acf1-f0f6-4ce1-b206-c0ab0faf79ca",
                            "slug_name": "gerente",
                            "keys": []
                        },
                        "first_name": "Barbara",
                        "last_name": "Novoa",
                        "full_name": "Barbara Novoa",
                        "id": 31,
                        "first_image_url": "https://inmobapp-public-bucket.s3.us-east-2.amazonaws.com/static/media/default-user.jpg",
                        "contactos": []
                    },
                    "configuraciones": {
                        "contact_email": "maria@mail.com"
                    }
                },
                "contactos_clientes": [
                    {
                        "id": 45,
                        "tipo": {
                            "id": 1,
                            "descripcion": "Whatsapp",
                            "nombre": "Whatsapp"
                        },
                        "valor": "+58 414 431 4120"
                    }
                ],
                "visitas": [],
                "busquedas": [],
                "status": {
                    "id": 1,
                    "nombre": "Captacion"
                },
                "nombre": "MIKE",
                "created_at": "2024-01-17T11:52:41.560505-04:00",
                "autor": 31,
                "editores": []
            },
            {
                "id": 43,
                "email": "",
                "franquicia": {
                    "nombre": "Barbara Deluxe",
                    "descripcion": null,
                    "id": 44,
                    "tipo_franquicia": {
                        "id": 4,
                        "nombre": "Membresía",
                        "descripcion": "Membresía",
                        "slug_name": "membresia"
                    },
                    "usuario_asignado": {
                        "username": null,
                        "email": "enlacedeluxebn@gmail.com",
                        "profile": {
                            "id": 3,
                            "descripcion": "Gerente",
                            "nombre": "Gerente",
                            "codigo": "5625acf1-f0f6-4ce1-b206-c0ab0faf79ca",
                            "slug_name": "gerente",
                            "keys": []
                        },
                        "first_name": "Barbara",
                        "last_name": "Novoa",
                        "full_name": "Barbara Novoa",
                        "id": 31,
                        "first_image_url": "https://inmobapp-public-bucket.s3.us-east-2.amazonaws.com/static/media/default-user.jpg",
                        "contactos": []
                    },
                    "image": "https://inmobapp-public-bucket.s3.amazonaws.com/media/franquicia_image/WhatsApp_Image_2024-07-09_at_12.20.13.jpeg",
                    "franquicia_comercial": 43,
                    "franquicia_personal": null,
                    "medios_contacto": [],
                    "solicitudes": {
                        "username": null,
                        "email": "enlacedeluxebn@gmail.com",
                        "profile": {
                            "id": 3,
                            "descripcion": "Gerente",
                            "nombre": "Gerente",
                            "codigo": "5625acf1-f0f6-4ce1-b206-c0ab0faf79ca",
                            "slug_name": "gerente",
                            "keys": []
                        },
                        "first_name": "Barbara",
                        "last_name": "Novoa",
                        "full_name": "Barbara Novoa",
                        "id": 31,
                        "first_image_url": "https://inmobapp-public-bucket.s3.us-east-2.amazonaws.com/static/media/default-user.jpg",
                        "contactos": []
                    },
                    "configuraciones": {
                        "contact_email": "maria@mail.com"
                    }
                },
                "contactos_clientes": [
                    {
                        "id": 43,
                        "tipo": {
                            "id": 1,
                            "descripcion": "Whatsapp",
                            "nombre": "Whatsapp"
                        },
                        "valor": "+58 414 326 0995"
                    }
                ],
                "visitas": [],
                "busquedas": [],
                "status": {
                    "id": 1,
                    "nombre": "Captacion"
                },
                "nombre": "Rosa Rodil",
                "created_at": "2024-01-17T09:31:49.753820-04:00",
                "autor": 31,
                "editores": []
            },
            {
                "id": 33,
                "email": "",
                "franquicia": {
                    "nombre": "Barbara Deluxe",
                    "descripcion": null,
                    "id": 44,
                    "tipo_franquicia": {
                        "id": 4,
                        "nombre": "Membresía",
                        "descripcion": "Membresía",
                        "slug_name": "membresia"
                    },
                    "usuario_asignado": {
                        "username": null,
                        "email": "enlacedeluxebn@gmail.com",
                        "profile": {
                            "id": 3,
                            "descripcion": "Gerente",
                            "nombre": "Gerente",
                            "codigo": "5625acf1-f0f6-4ce1-b206-c0ab0faf79ca",
                            "slug_name": "gerente",
                            "keys": []
                        },
                        "first_name": "Barbara",
                        "last_name": "Novoa",
                        "full_name": "Barbara Novoa",
                        "id": 31,
                        "first_image_url": "https://inmobapp-public-bucket.s3.us-east-2.amazonaws.com/static/media/default-user.jpg",
                        "contactos": []
                    },
                    "image": "https://inmobapp-public-bucket.s3.amazonaws.com/media/franquicia_image/WhatsApp_Image_2024-07-09_at_12.20.13.jpeg",
                    "franquicia_comercial": 43,
                    "franquicia_personal": null,
                    "medios_contacto": [],
                    "solicitudes": {
                        "username": null,
                        "email": "enlacedeluxebn@gmail.com",
                        "profile": {
                            "id": 3,
                            "descripcion": "Gerente",
                            "nombre": "Gerente",
                            "codigo": "5625acf1-f0f6-4ce1-b206-c0ab0faf79ca",
                            "slug_name": "gerente",
                            "keys": []
                        },
                        "first_name": "Barbara",
                        "last_name": "Novoa",
                        "full_name": "Barbara Novoa",
                        "id": 31,
                        "first_image_url": "https://inmobapp-public-bucket.s3.us-east-2.amazonaws.com/static/media/default-user.jpg",
                        "contactos": []
                    },
                    "configuraciones": {
                        "contact_email": "maria@mail.com"
                    }
                },
                "contactos_clientes": [
                    {
                        "id": 33,
                        "tipo": {
                            "id": 1,
                            "descripcion": "Whatsapp",
                            "nombre": "Whatsapp"
                        },
                        "valor": "+58 412 645 3258"
                    }
                ],
                "visitas": [],
                "busquedas": [
                    {
                        "id": 20,
                        "total_seguimientos": 4,
                        "estatus": 1,
                        "descripcion": "Apartamento en la tahona",
                        "habitaciones": 2,
                        "numero_banios": 2,
                        "precio": "65000.00",
                        "ptos_estacionamiento": null,
                        "created_at": "2024-03-09T16:11:15.778959-04:00",
                        "cliente": 33,
                        "tipo_inmueble": 1,
                        "ciudad": 1,
                        "estado": 24,
                        "seguimientos": [
                            {
                                "id": 29,
                                "cliente": {
                                    "id": 33,
                                    "email": "",
                                    "franquicia": 44,
                                    "nombre": "Orly",
                                    "status": 1,
                                    "created_at": "2024-01-16T19:06:33.873414-04:00",
                                    "autor": 31,
                                    "editores": []
                                },
                                "fecha_seguimiento": "2024-03-09T16:13:49.504091-04:00",
                                "nota": "",
                                "audio": "https://inmobapp-public-bucket.s3.amazonaws.com/media/voicenotes/blob_O9FyF3h",
                                "busqueda": 20
                            },
                            {
                                "id": 28,
                                "cliente": {
                                    "id": 33,
                                    "email": "",
                                    "franquicia": 44,
                                    "nombre": "Orly",
                                    "status": 1,
                                    "created_at": "2024-01-16T19:06:33.873414-04:00",
                                    "autor": 31,
                                    "editores": []
                                },
                                "fecha_seguimiento": "2024-03-09T16:13:48.854968-04:00",
                                "nota": "",
                                "audio": "https://inmobapp-public-bucket.s3.amazonaws.com/media/voicenotes/blob_f4tbbLr",
                                "busqueda": 20
                            },
                            {
                                "id": 27,
                                "cliente": {
                                    "id": 33,
                                    "email": "",
                                    "franquicia": 44,
                                    "nombre": "Orly",
                                    "status": 1,
                                    "created_at": "2024-01-16T19:06:33.873414-04:00",
                                    "autor": 31,
                                    "editores": []
                                },
                                "fecha_seguimiento": "2024-03-09T16:13:09.232111-04:00",
                                "nota": "Le consegui un apartamento en candelaria",
                                "audio": null,
                                "busqueda": 20
                            },
                            {
                                "id": 26,
                                "cliente": {
                                    "id": 33,
                                    "email": "",
                                    "franquicia": 44,
                                    "nombre": "Orly",
                                    "status": 1,
                                    "created_at": "2024-01-16T19:06:33.873414-04:00",
                                    "autor": 31,
                                    "editores": []
                                },
                                "fecha_seguimiento": "2024-03-09T16:11:15.780320-04:00",
                                "nota": "Se crea seguimiento para busqueda de cliente.",
                                "audio": null,
                                "busqueda": 20
                            }
                        ]
                    }
                ],
                "status": {
                    "id": 1,
                    "nombre": "Captacion"
                },
                "nombre": "Orly",
                "created_at": "2024-01-16T19:06:33.873414-04:00",
                "autor": 31,
                "editores": []
            }
        ]
    },
    {
        "id": 2,
        "title": "En busqueda",
        "count": 34,
        "child": [
            {
                "id": 110,
                "email": "",
                "franquicia": {
                    "nombre": "Rosveli Bruzco Deluxe",
                    "descripcion": null,
                    "id": 97,
                    "tipo_franquicia": {
                        "id": 4,
                        "nombre": "Membresía",
                        "descripcion": "Membresía",
                        "slug_name": "membresia"
                    },
                    "usuario_asignado": {
                        "username": null,
                        "email": "rosveli.deluxe@gmail.com",
                        "profile": {
                            "id": 3,
                            "descripcion": "Gerente",
                            "nombre": "Gerente",
                            "codigo": "5625acf1-f0f6-4ce1-b206-c0ab0faf79ca",
                            "slug_name": "gerente",
                            "keys": []
                        },
                        "first_name": "Rosveli",
                        "last_name": "Bruzco",
                        "full_name": "Rosveli Bruzco",
                        "id": 75,
                        "first_image_url": "https://inmobapp-public-bucket.s3.us-east-2.amazonaws.com/static/media/default-user.jpg",
                        "contactos": []
                    },
                    "image": "https://inmobapp-public-bucket.s3.us-east-2.amazonaws.com/static/media/default-user.jpg",
                    "franquicia_comercial": 43,
                    "franquicia_personal": null,
                    "medios_contacto": [],
                    "solicitudes": {
                        "username": null,
                        "email": "rosveli.deluxe@gmail.com",
                        "profile": {
                            "id": 3,
                            "descripcion": "Gerente",
                            "nombre": "Gerente",
                            "codigo": "5625acf1-f0f6-4ce1-b206-c0ab0faf79ca",
                            "slug_name": "gerente",
                            "keys": []
                        },
                        "first_name": "Rosveli",
                        "last_name": "Bruzco",
                        "full_name": "Rosveli Bruzco",
                        "id": 75,
                        "first_image_url": "https://inmobapp-public-bucket.s3.us-east-2.amazonaws.com/static/media/default-user.jpg",
                        "contactos": []
                    },
                    "configuraciones": {}
                },
                "contactos_clientes": [
                    {
                        "id": 109,
                        "tipo": {
                            "id": 1,
                            "descripcion": "Whatsapp",
                            "nombre": "Whatsapp"
                        },
                        "valor": "+58 416 615 7378"
                    }
                ],
                "visitas": [],
                "busquedas": [
                    {
                        "id": 50,
                        "total_seguimientos": 1,
                        "estatus": 1,
                        "descripcion": "cliente interesado en proyecto blueloft",
                        "habitaciones": null,
                        "numero_banios": null,
                        "precio": "0.00",
                        "ptos_estacionamiento": null,
                        "created_at": "2024-07-09T20:09:55.062245-04:00",
                        "cliente": 110,
                        "tipo_inmueble": null,
                        "ciudad": null,
                        "estado": null,
                        "seguimientos": [
                            {
                                "id": 59,
                                "cliente": {
                                    "id": 110,
                                    "email": "",
                                    "franquicia": 97,
                                    "nombre": "Yaritza",
                                    "status": 2,
                                    "created_at": "2024-07-09T20:09:55.025449-04:00",
                                    "autor": null,
                                    "editores": []
                                },
                                "fecha_seguimiento": "2024-07-09T20:09:55.063704-04:00",
                                "nota": "Se crea seguimiento para busqueda de cliente.",
                                "audio": null,
                                "busqueda": 50
                            }
                        ]
                    }
                ],
                "status": {
                    "id": 2,
                    "nombre": "En busqueda"
                },
                "nombre": "Yaritza",
                "created_at": "2024-07-09T20:09:55.025449-04:00",
                "autor": null,
                "editores": []
            },
            {
                "id": 108,
                "email": "",
                "franquicia": {
                    "nombre": "Barbara Deluxe",
                    "descripcion": null,
                    "id": 44,
                    "tipo_franquicia": {
                        "id": 4,
                        "nombre": "Membresía",
                        "descripcion": "Membresía",
                        "slug_name": "membresia"
                    },
                    "usuario_asignado": {
                        "username": null,
                        "email": "enlacedeluxebn@gmail.com",
                        "profile": {
                            "id": 3,
                            "descripcion": "Gerente",
                            "nombre": "Gerente",
                            "codigo": "5625acf1-f0f6-4ce1-b206-c0ab0faf79ca",
                            "slug_name": "gerente",
                            "keys": []
                        },
                        "first_name": "Barbara",
                        "last_name": "Novoa",
                        "full_name": "Barbara Novoa",
                        "id": 31,
                        "first_image_url": "https://inmobapp-public-bucket.s3.us-east-2.amazonaws.com/static/media/default-user.jpg",
                        "contactos": []
                    },
                    "image": "https://inmobapp-public-bucket.s3.amazonaws.com/media/franquicia_image/WhatsApp_Image_2024-07-09_at_12.20.13.jpeg",
                    "franquicia_comercial": 43,
                    "franquicia_personal": null,
                    "medios_contacto": [],
                    "solicitudes": {
                        "username": null,
                        "email": "enlacedeluxebn@gmail.com",
                        "profile": {
                            "id": 3,
                            "descripcion": "Gerente",
                            "nombre": "Gerente",
                            "codigo": "5625acf1-f0f6-4ce1-b206-c0ab0faf79ca",
                            "slug_name": "gerente",
                            "keys": []
                        },
                        "first_name": "Barbara",
                        "last_name": "Novoa",
                        "full_name": "Barbara Novoa",
                        "id": 31,
                        "first_image_url": "https://inmobapp-public-bucket.s3.us-east-2.amazonaws.com/static/media/default-user.jpg",
                        "contactos": []
                    },
                    "configuraciones": {
                        "contact_email": "maria@mail.com"
                    }
                },
                "contactos_clientes": [
                    {
                        "id": 107,
                        "tipo": {
                            "id": 1,
                            "descripcion": "Whatsapp",
                            "nombre": "Whatsapp"
                        },
                        "valor": "+58 412 710 2951"
                    }
                ],
                "visitas": [],
                "busquedas": [
                    {
                        "id": 48,
                        "total_seguimientos": 1,
                        "estatus": 1,
                        "descripcion": "cliente interesado en apartamento en alianza en el marquez, busca por la california, solicitar información para filtrar la busqueda. filtrar presupuesto",
                        "habitaciones": null,
                        "numero_banios": null,
                        "precio": "0.00",
                        "ptos_estacionamiento": null,
                        "created_at": "2024-07-09T19:53:30.240838-04:00",
                        "cliente": 108,
                        "tipo_inmueble": null,
                        "ciudad": null,
                        "estado": null,
                        "seguimientos": [
                            {
                                "id": 57,
                                "cliente": {
                                    "id": 108,
                                    "email": "",
                                    "franquicia": 44,
                                    "nombre": "Yileny Hernandez",
                                    "status": 2,
                                    "created_at": "2024-07-09T19:53:30.049573-04:00",
                                    "autor": null,
                                    "editores": []
                                },
                                "fecha_seguimiento": "2024-07-09T19:53:30.253776-04:00",
                                "nota": "Se crea seguimiento para busqueda de cliente.",
                                "audio": null,
                                "busqueda": 48
                            }
                        ]
                    }
                ],
                "status": {
                    "id": 2,
                    "nombre": "En busqueda"
                },
                "nombre": "Yileny Hernandez",
                "created_at": "2024-07-09T19:53:30.049573-04:00",
                "autor": null,
                "editores": []
            },
            {
                "id": 107,
                "email": "",
                "franquicia": {
                    "nombre": "Inmobiliaria Deluxe",
                    "descripcion": null,
                    "id": 43,
                    "tipo_franquicia": {
                        "id": 2,
                        "nombre": "Franquicia Comercial",
                        "descripcion": "Franquicia Comercial",
                        "slug_name": "franquicia_comercial"
                    },
                    "usuario_asignado": {
                        "username": null,
                        "email": "tuenlacedeluxe@gmail.com",
                        "profile": {
                            "id": 3,
                            "descripcion": "Gerente",
                            "nombre": "Gerente",
                            "codigo": "5625acf1-f0f6-4ce1-b206-c0ab0faf79ca",
                            "slug_name": "gerente",
                            "keys": []
                        },
                        "first_name": "Deluxe",
                        "last_name": "Las mercedes",
                        "full_name": "Deluxe Las mercedes",
                        "id": 27,
                        "first_image_url": "https://inmobapp-public-bucket.s3.amazonaws.com/media/user_image/Logo_Color_Original.png",
                        "contactos": [
                            {
                                "tipo": 1,
                                "valor": "+584241445498"
                            },
                            {
                                "tipo": 1,
                                "valor": "+584242396556"
                            }
                        ]
                    },
                    "image": "https://inmobapp-public-bucket.s3.amazonaws.com/media/franquicia_image/Logo_Color_Original.png",
                    "franquicia_comercial": null,
                    "franquicia_personal": null,
                    "medios_contacto": [],
                    "solicitudes": {
                        "username": null,
                        "email": "tuenlacedeluxe@gmail.com",
                        "profile": {
                            "id": 3,
                            "descripcion": "Gerente",
                            "nombre": "Gerente",
                            "codigo": "5625acf1-f0f6-4ce1-b206-c0ab0faf79ca",
                            "slug_name": "gerente",
                            "keys": []
                        },
                        "first_name": "Deluxe",
                        "last_name": "Las mercedes",
                        "full_name": "Deluxe Las mercedes",
                        "id": 27,
                        "first_image_url": "https://inmobapp-public-bucket.s3.amazonaws.com/media/user_image/Logo_Color_Original.png",
                        "contactos": [
                            {
                                "tipo": 1,
                                "valor": "+584241445498"
                            },
                            {
                                "tipo": 1,
                                "valor": "+584242396556"
                            }
                        ]
                    },
                    "configuraciones": {
                        "contact_email": "tuenlacedeluxe@gmail.com",
                        "franquicias_enlaces": "44,45,46,47",
                        "prop_list_endpoint": "wp-json/inmobapp/v1/properties/",
                        "prop_upload_endpoint": "wp-json/inmobapp/v1/inmobapp-upload/",
                        "web_url": "https://preview.tuenlaceinmobiliario.com/",
                        "wordpress_auth_key": "e5cee474d75f3ea64ad9aade5b15817d"
                    }
                },
                "contactos_clientes": [
                    {
                        "id": 106,
                        "tipo": {
                            "id": 1,
                            "descripcion": "Whatsapp",
                            "nombre": "Whatsapp"
                        },
                        "valor": "+584242396556"
                    }
                ],
                "visitas": [],
                "busquedas": [],
                "status": {
                    "id": 2,
                    "nombre": "En busqueda"
                },
                "nombre": "Luis Gatos",
                "created_at": "2024-07-02T17:31:23.897111-04:00",
                "autor": 27,
                "editores": []
            },
            {
                "id": 84,
                "email": "",
                "franquicia": {
                    "nombre": "Ervi Solorzano Deluxe",
                    "descripcion": null,
                    "id": 103,
                    "tipo_franquicia": {
                        "id": 3,
                        "nombre": "Franquicia Personal",
                        "descripcion": "Franquicia Personal",
                        "slug_name": "franquicia_personal"
                    },
                    "usuario_asignado": {
                        "username": null,
                        "email": "ervii.deluxe@gmail.com",
                        "profile": {
                            "id": 3,
                            "descripcion": "Gerente",
                            "nombre": "Gerente",
                            "codigo": "5625acf1-f0f6-4ce1-b206-c0ab0faf79ca",
                            "slug_name": "gerente",
                            "keys": []
                        },
                        "first_name": "Ervi",
                        "last_name": "Solorzano",
                        "full_name": "Ervi Solorzano",
                        "id": 81,
                        "first_image_url": "https://inmobapp-public-bucket.s3.us-east-2.amazonaws.com/static/media/default-user.jpg",
                        "contactos": []
                    },
                    "image": "https://inmobapp-public-bucket.s3.us-east-2.amazonaws.com/static/media/default-user.jpg",
                    "franquicia_comercial": 43,
                    "franquicia_personal": null,
                    "medios_contacto": [],
                    "solicitudes": {
                        "username": null,
                        "email": "ervii.deluxe@gmail.com",
                        "profile": {
                            "id": 3,
                            "descripcion": "Gerente",
                            "nombre": "Gerente",
                            "codigo": "5625acf1-f0f6-4ce1-b206-c0ab0faf79ca",
                            "slug_name": "gerente",
                            "keys": []
                        },
                        "first_name": "Ervi",
                        "last_name": "Solorzano",
                        "full_name": "Ervi Solorzano",
                        "id": 81,
                        "first_image_url": "https://inmobapp-public-bucket.s3.us-east-2.amazonaws.com/static/media/default-user.jpg",
                        "contactos": []
                    },
                    "configuraciones": {}
                },
                "contactos_clientes": [
                    {
                        "id": 83,
                        "tipo": {
                            "id": 1,
                            "descripcion": "Whatsapp",
                            "nombre": "Whatsapp"
                        },
                        "valor": "+58 424 185 5395"
                    }
                ],
                "visitas": [],
                "busquedas": [
                    {
                        "id": 47,
                        "total_seguimientos": 1,
                        "estatus": 1,
                        "descripcion": "Cliente interesado en Blue Loft",
                        "habitaciones": null,
                        "numero_banios": null,
                        "precio": "0.00",
                        "ptos_estacionamiento": null,
                        "created_at": "2024-06-07T14:23:28.518630-04:00",
                        "cliente": 84,
                        "tipo_inmueble": null,
                        "ciudad": null,
                        "estado": null,
                        "seguimientos": [
                            {
                                "id": 56,
                                "cliente": {
                                    "id": 84,
                                    "email": "",
                                    "franquicia": 103,
                                    "nombre": "Juan Rugeles",
                                    "status": 2,
                                    "created_at": "2024-06-07T14:23:28.502321-04:00",
                                    "autor": null,
                                    "editores": []
                                },
                                "fecha_seguimiento": "2024-06-07T14:23:28.519932-04:00",
                                "nota": "Se crea seguimiento para busqueda de cliente.",
                                "audio": null,
                                "busqueda": 47
                            }
                        ]
                    }
                ],
                "status": {
                    "id": 2,
                    "nombre": "En busqueda"
                },
                "nombre": "Juan Rugeles",
                "created_at": "2024-06-07T14:23:28.502321-04:00",
                "autor": null,
                "editores": []
            },
            {
                "id": 83,
                "email": "",
                "franquicia": {
                    "nombre": "Ervi Solorzano Deluxe",
                    "descripcion": null,
                    "id": 103,
                    "tipo_franquicia": {
                        "id": 3,
                        "nombre": "Franquicia Personal",
                        "descripcion": "Franquicia Personal",
                        "slug_name": "franquicia_personal"
                    },
                    "usuario_asignado": {
                        "username": null,
                        "email": "ervii.deluxe@gmail.com",
                        "profile": {
                            "id": 3,
                            "descripcion": "Gerente",
                            "nombre": "Gerente",
                            "codigo": "5625acf1-f0f6-4ce1-b206-c0ab0faf79ca",
                            "slug_name": "gerente",
                            "keys": []
                        },
                        "first_name": "Ervi",
                        "last_name": "Solorzano",
                        "full_name": "Ervi Solorzano",
                        "id": 81,
                        "first_image_url": "https://inmobapp-public-bucket.s3.us-east-2.amazonaws.com/static/media/default-user.jpg",
                        "contactos": []
                    },
                    "image": "https://inmobapp-public-bucket.s3.us-east-2.amazonaws.com/static/media/default-user.jpg",
                    "franquicia_comercial": 43,
                    "franquicia_personal": null,
                    "medios_contacto": [],
                    "solicitudes": {
                        "username": null,
                        "email": "ervii.deluxe@gmail.com",
                        "profile": {
                            "id": 3,
                            "descripcion": "Gerente",
                            "nombre": "Gerente",
                            "codigo": "5625acf1-f0f6-4ce1-b206-c0ab0faf79ca",
                            "slug_name": "gerente",
                            "keys": []
                        },
                        "first_name": "Ervi",
                        "last_name": "Solorzano",
                        "full_name": "Ervi Solorzano",
                        "id": 81,
                        "first_image_url": "https://inmobapp-public-bucket.s3.us-east-2.amazonaws.com/static/media/default-user.jpg",
                        "contactos": []
                    },
                    "configuraciones": {}
                },
                "contactos_clientes": [
                    {
                        "id": 82,
                        "tipo": {
                            "id": 1,
                            "descripcion": "Whatsapp",
                            "nombre": "Whatsapp"
                        },
                        "valor": "+58 424 178 2345"
                    }
                ],
                "visitas": [],
                "busquedas": [
                    {
                        "id": 46,
                        "total_seguimientos": 1,
                        "estatus": 1,
                        "descripcion": "Cliente interesado en Blue Loft",
                        "habitaciones": null,
                        "numero_banios": null,
                        "precio": "0.00",
                        "ptos_estacionamiento": null,
                        "created_at": "2024-06-07T14:21:36.678932-04:00",
                        "cliente": 83,
                        "tipo_inmueble": null,
                        "ciudad": null,
                        "estado": null,
                        "seguimientos": [
                            {
                                "id": 55,
                                "cliente": {
                                    "id": 83,
                                    "email": "",
                                    "franquicia": 103,
                                    "nombre": "Marisabel Filigueira",
                                    "status": 2,
                                    "created_at": "2024-06-07T14:21:36.662720-04:00",
                                    "autor": null,
                                    "editores": []
                                },
                                "fecha_seguimiento": "2024-06-07T14:21:36.680246-04:00",
                                "nota": "Se crea seguimiento para busqueda de cliente.",
                                "audio": null,
                                "busqueda": 46
                            }
                        ]
                    }
                ],
                "status": {
                    "id": 2,
                    "nombre": "En busqueda"
                },
                "nombre": "Marisabel Filigueira",
                "created_at": "2024-06-07T14:21:36.662720-04:00",
                "autor": null,
                "editores": []
            },
            {
                "id": 82,
                "email": "",
                "franquicia": {
                    "nombre": "Ervi Solorzano Deluxe",
                    "descripcion": null,
                    "id": 103,
                    "tipo_franquicia": {
                        "id": 3,
                        "nombre": "Franquicia Personal",
                        "descripcion": "Franquicia Personal",
                        "slug_name": "franquicia_personal"
                    },
                    "usuario_asignado": {
                        "username": null,
                        "email": "ervii.deluxe@gmail.com",
                        "profile": {
                            "id": 3,
                            "descripcion": "Gerente",
                            "nombre": "Gerente",
                            "codigo": "5625acf1-f0f6-4ce1-b206-c0ab0faf79ca",
                            "slug_name": "gerente",
                            "keys": []
                        },
                        "first_name": "Ervi",
                        "last_name": "Solorzano",
                        "full_name": "Ervi Solorzano",
                        "id": 81,
                        "first_image_url": "https://inmobapp-public-bucket.s3.us-east-2.amazonaws.com/static/media/default-user.jpg",
                        "contactos": []
                    },
                    "image": "https://inmobapp-public-bucket.s3.us-east-2.amazonaws.com/static/media/default-user.jpg",
                    "franquicia_comercial": 43,
                    "franquicia_personal": null,
                    "medios_contacto": [],
                    "solicitudes": {
                        "username": null,
                        "email": "ervii.deluxe@gmail.com",
                        "profile": {
                            "id": 3,
                            "descripcion": "Gerente",
                            "nombre": "Gerente",
                            "codigo": "5625acf1-f0f6-4ce1-b206-c0ab0faf79ca",
                            "slug_name": "gerente",
                            "keys": []
                        },
                        "first_name": "Ervi",
                        "last_name": "Solorzano",
                        "full_name": "Ervi Solorzano",
                        "id": 81,
                        "first_image_url": "https://inmobapp-public-bucket.s3.us-east-2.amazonaws.com/static/media/default-user.jpg",
                        "contactos": []
                    },
                    "configuraciones": {}
                },
                "contactos_clientes": [
                    {
                        "id": 81,
                        "tipo": {
                            "id": 1,
                            "descripcion": "Whatsapp",
                            "nombre": "Whatsapp"
                        },
                        "valor": "+58 414 116 9885"
                    }
                ],
                "visitas": [],
                "busquedas": [
                    {
                        "id": 45,
                        "total_seguimientos": 1,
                        "estatus": 1,
                        "descripcion": "Cliente interesado en Blue Loft",
                        "habitaciones": null,
                        "numero_banios": null,
                        "precio": "0.00",
                        "ptos_estacionamiento": null,
                        "created_at": "2024-06-07T14:20:18.705513-04:00",
                        "cliente": 82,
                        "tipo_inmueble": null,
                        "ciudad": null,
                        "estado": null,
                        "seguimientos": [
                            {
                                "id": 54,
                                "cliente": {
                                    "id": 82,
                                    "email": "",
                                    "franquicia": 103,
                                    "nombre": "Sin nombre",
                                    "status": 2,
                                    "created_at": "2024-06-07T14:20:18.688613-04:00",
                                    "autor": null,
                                    "editores": []
                                },
                                "fecha_seguimiento": "2024-06-07T14:20:18.706964-04:00",
                                "nota": "Se crea seguimiento para busqueda de cliente.",
                                "audio": null,
                                "busqueda": 45
                            }
                        ]
                    }
                ],
                "status": {
                    "id": 2,
                    "nombre": "En busqueda"
                },
                "nombre": "Sin nombre",
                "created_at": "2024-06-07T14:20:18.688613-04:00",
                "autor": null,
                "editores": []
            },
            {
                "id": 81,
                "email": "",
                "franquicia": {
                    "nombre": "Ervi Solorzano Deluxe",
                    "descripcion": null,
                    "id": 103,
                    "tipo_franquicia": {
                        "id": 3,
                        "nombre": "Franquicia Personal",
                        "descripcion": "Franquicia Personal",
                        "slug_name": "franquicia_personal"
                    },
                    "usuario_asignado": {
                        "username": null,
                        "email": "ervii.deluxe@gmail.com",
                        "profile": {
                            "id": 3,
                            "descripcion": "Gerente",
                            "nombre": "Gerente",
                            "codigo": "5625acf1-f0f6-4ce1-b206-c0ab0faf79ca",
                            "slug_name": "gerente",
                            "keys": []
                        },
                        "first_name": "Ervi",
                        "last_name": "Solorzano",
                        "full_name": "Ervi Solorzano",
                        "id": 81,
                        "first_image_url": "https://inmobapp-public-bucket.s3.us-east-2.amazonaws.com/static/media/default-user.jpg",
                        "contactos": []
                    },
                    "image": "https://inmobapp-public-bucket.s3.us-east-2.amazonaws.com/static/media/default-user.jpg",
                    "franquicia_comercial": 43,
                    "franquicia_personal": null,
                    "medios_contacto": [],
                    "solicitudes": {
                        "username": null,
                        "email": "ervii.deluxe@gmail.com",
                        "profile": {
                            "id": 3,
                            "descripcion": "Gerente",
                            "nombre": "Gerente",
                            "codigo": "5625acf1-f0f6-4ce1-b206-c0ab0faf79ca",
                            "slug_name": "gerente",
                            "keys": []
                        },
                        "first_name": "Ervi",
                        "last_name": "Solorzano",
                        "full_name": "Ervi Solorzano",
                        "id": 81,
                        "first_image_url": "https://inmobapp-public-bucket.s3.us-east-2.amazonaws.com/static/media/default-user.jpg",
                        "contactos": []
                    },
                    "configuraciones": {}
                },
                "contactos_clientes": [
                    {
                        "id": 80,
                        "tipo": {
                            "id": 1,
                            "descripcion": "Whatsapp",
                            "nombre": "Whatsapp"
                        },
                        "valor": "+58 414 288 5861"
                    }
                ],
                "visitas": [],
                "busquedas": [
                    {
                        "id": 44,
                        "total_seguimientos": 1,
                        "estatus": 1,
                        "descripcion": "Cliente interesado en Blue Loft",
                        "habitaciones": null,
                        "numero_banios": null,
                        "precio": "0.00",
                        "ptos_estacionamiento": null,
                        "created_at": "2024-06-07T14:19:20.706061-04:00",
                        "cliente": 81,
                        "tipo_inmueble": null,
                        "ciudad": null,
                        "estado": null,
                        "seguimientos": [
                            {
                                "id": 53,
                                "cliente": {
                                    "id": 81,
                                    "email": "",
                                    "franquicia": 103,
                                    "nombre": "Sin nombre",
                                    "status": 2,
                                    "created_at": "2024-06-07T14:19:20.687993-04:00",
                                    "autor": null,
                                    "editores": []
                                },
                                "fecha_seguimiento": "2024-06-07T14:19:20.707387-04:00",
                                "nota": "Se crea seguimiento para busqueda de cliente.",
                                "audio": null,
                                "busqueda": 44
                            }
                        ]
                    }
                ],
                "status": {
                    "id": 2,
                    "nombre": "En busqueda"
                },
                "nombre": "Sin nombre",
                "created_at": "2024-06-07T14:19:20.687993-04:00",
                "autor": null,
                "editores": []
            },
            {
                "id": 80,
                "email": "",
                "franquicia": {
                    "nombre": "Rosveli Bruzco Deluxe",
                    "descripcion": null,
                    "id": 97,
                    "tipo_franquicia": {
                        "id": 4,
                        "nombre": "Membresía",
                        "descripcion": "Membresía",
                        "slug_name": "membresia"
                    },
                    "usuario_asignado": {
                        "username": null,
                        "email": "rosveli.deluxe@gmail.com",
                        "profile": {
                            "id": 3,
                            "descripcion": "Gerente",
                            "nombre": "Gerente",
                            "codigo": "5625acf1-f0f6-4ce1-b206-c0ab0faf79ca",
                            "slug_name": "gerente",
                            "keys": []
                        },
                        "first_name": "Rosveli",
                        "last_name": "Bruzco",
                        "full_name": "Rosveli Bruzco",
                        "id": 75,
                        "first_image_url": "https://inmobapp-public-bucket.s3.us-east-2.amazonaws.com/static/media/default-user.jpg",
                        "contactos": []
                    },
                    "image": "https://inmobapp-public-bucket.s3.us-east-2.amazonaws.com/static/media/default-user.jpg",
                    "franquicia_comercial": 43,
                    "franquicia_personal": null,
                    "medios_contacto": [],
                    "solicitudes": {
                        "username": null,
                        "email": "rosveli.deluxe@gmail.com",
                        "profile": {
                            "id": 3,
                            "descripcion": "Gerente",
                            "nombre": "Gerente",
                            "codigo": "5625acf1-f0f6-4ce1-b206-c0ab0faf79ca",
                            "slug_name": "gerente",
                            "keys": []
                        },
                        "first_name": "Rosveli",
                        "last_name": "Bruzco",
                        "full_name": "Rosveli Bruzco",
                        "id": 75,
                        "first_image_url": "https://inmobapp-public-bucket.s3.us-east-2.amazonaws.com/static/media/default-user.jpg",
                        "contactos": []
                    },
                    "configuraciones": {}
                },
                "contactos_clientes": [
                    {
                        "id": 79,
                        "tipo": {
                            "id": 1,
                            "descripcion": "Whatsapp",
                            "nombre": "Whatsapp"
                        },
                        "valor": "+58 424 168 8448"
                    }
                ],
                "visitas": [],
                "busquedas": [
                    {
                        "id": 43,
                        "total_seguimientos": 1,
                        "estatus": 1,
                        "descripcion": "Cliente interesado en Blue Loft",
                        "habitaciones": null,
                        "numero_banios": null,
                        "precio": "0.00",
                        "ptos_estacionamiento": null,
                        "created_at": "2024-06-07T14:15:55.388581-04:00",
                        "cliente": 80,
                        "tipo_inmueble": null,
                        "ciudad": null,
                        "estado": null,
                        "seguimientos": [
                            {
                                "id": 52,
                                "cliente": {
                                    "id": 80,
                                    "email": "",
                                    "franquicia": 97,
                                    "nombre": "Vicky",
                                    "status": 2,
                                    "created_at": "2024-06-07T14:15:55.371994-04:00",
                                    "autor": null,
                                    "editores": []
                                },
                                "fecha_seguimiento": "2024-06-07T14:15:55.389912-04:00",
                                "nota": "Se crea seguimiento para busqueda de cliente.",
                                "audio": null,
                                "busqueda": 43
                            }
                        ]
                    }
                ],
                "status": {
                    "id": 2,
                    "nombre": "En busqueda"
                },
                "nombre": "Vicky",
                "created_at": "2024-06-07T14:15:55.371994-04:00",
                "autor": null,
                "editores": []
            },
            {
                "id": 79,
                "email": "",
                "franquicia": {
                    "nombre": "Rosveli Bruzco Deluxe",
                    "descripcion": null,
                    "id": 97,
                    "tipo_franquicia": {
                        "id": 4,
                        "nombre": "Membresía",
                        "descripcion": "Membresía",
                        "slug_name": "membresia"
                    },
                    "usuario_asignado": {
                        "username": null,
                        "email": "rosveli.deluxe@gmail.com",
                        "profile": {
                            "id": 3,
                            "descripcion": "Gerente",
                            "nombre": "Gerente",
                            "codigo": "5625acf1-f0f6-4ce1-b206-c0ab0faf79ca",
                            "slug_name": "gerente",
                            "keys": []
                        },
                        "first_name": "Rosveli",
                        "last_name": "Bruzco",
                        "full_name": "Rosveli Bruzco",
                        "id": 75,
                        "first_image_url": "https://inmobapp-public-bucket.s3.us-east-2.amazonaws.com/static/media/default-user.jpg",
                        "contactos": []
                    },
                    "image": "https://inmobapp-public-bucket.s3.us-east-2.amazonaws.com/static/media/default-user.jpg",
                    "franquicia_comercial": 43,
                    "franquicia_personal": null,
                    "medios_contacto": [],
                    "solicitudes": {
                        "username": null,
                        "email": "rosveli.deluxe@gmail.com",
                        "profile": {
                            "id": 3,
                            "descripcion": "Gerente",
                            "nombre": "Gerente",
                            "codigo": "5625acf1-f0f6-4ce1-b206-c0ab0faf79ca",
                            "slug_name": "gerente",
                            "keys": []
                        },
                        "first_name": "Rosveli",
                        "last_name": "Bruzco",
                        "full_name": "Rosveli Bruzco",
                        "id": 75,
                        "first_image_url": "https://inmobapp-public-bucket.s3.us-east-2.amazonaws.com/static/media/default-user.jpg",
                        "contactos": []
                    },
                    "configuraciones": {}
                },
                "contactos_clientes": [
                    {
                        "id": 78,
                        "tipo": {
                            "id": 1,
                            "descripcion": "Whatsapp",
                            "nombre": "Whatsapp"
                        },
                        "valor": "+58 414 914 7638"
                    }
                ],
                "visitas": [],
                "busquedas": [
                    {
                        "id": 42,
                        "total_seguimientos": 1,
                        "estatus": 1,
                        "descripcion": "Cliente interesado en Blue Loft",
                        "habitaciones": null,
                        "numero_banios": null,
                        "precio": "0.00",
                        "ptos_estacionamiento": null,
                        "created_at": "2024-06-07T14:14:58.302934-04:00",
                        "cliente": 79,
                        "tipo_inmueble": null,
                        "ciudad": null,
                        "estado": null,
                        "seguimientos": [
                            {
                                "id": 51,
                                "cliente": {
                                    "id": 79,
                                    "email": "",
                                    "franquicia": 97,
                                    "nombre": "Jessika",
                                    "status": 2,
                                    "created_at": "2024-06-07T14:14:58.286176-04:00",
                                    "autor": null,
                                    "editores": []
                                },
                                "fecha_seguimiento": "2024-06-07T14:14:58.304405-04:00",
                                "nota": "Se crea seguimiento para busqueda de cliente.",
                                "audio": null,
                                "busqueda": 42
                            }
                        ]
                    }
                ],
                "status": {
                    "id": 2,
                    "nombre": "En busqueda"
                },
                "nombre": "Jessika",
                "created_at": "2024-06-07T14:14:58.286176-04:00",
                "autor": null,
                "editores": []
            },
            {
                "id": 78,
                "email": "",
                "franquicia": {
                    "nombre": "Rosveli Bruzco Deluxe",
                    "descripcion": null,
                    "id": 97,
                    "tipo_franquicia": {
                        "id": 4,
                        "nombre": "Membresía",
                        "descripcion": "Membresía",
                        "slug_name": "membresia"
                    },
                    "usuario_asignado": {
                        "username": null,
                        "email": "rosveli.deluxe@gmail.com",
                        "profile": {
                            "id": 3,
                            "descripcion": "Gerente",
                            "nombre": "Gerente",
                            "codigo": "5625acf1-f0f6-4ce1-b206-c0ab0faf79ca",
                            "slug_name": "gerente",
                            "keys": []
                        },
                        "first_name": "Rosveli",
                        "last_name": "Bruzco",
                        "full_name": "Rosveli Bruzco",
                        "id": 75,
                        "first_image_url": "https://inmobapp-public-bucket.s3.us-east-2.amazonaws.com/static/media/default-user.jpg",
                        "contactos": []
                    },
                    "image": "https://inmobapp-public-bucket.s3.us-east-2.amazonaws.com/static/media/default-user.jpg",
                    "franquicia_comercial": 43,
                    "franquicia_personal": null,
                    "medios_contacto": [],
                    "solicitudes": {
                        "username": null,
                        "email": "rosveli.deluxe@gmail.com",
                        "profile": {
                            "id": 3,
                            "descripcion": "Gerente",
                            "nombre": "Gerente",
                            "codigo": "5625acf1-f0f6-4ce1-b206-c0ab0faf79ca",
                            "slug_name": "gerente",
                            "keys": []
                        },
                        "first_name": "Rosveli",
                        "last_name": "Bruzco",
                        "full_name": "Rosveli Bruzco",
                        "id": 75,
                        "first_image_url": "https://inmobapp-public-bucket.s3.us-east-2.amazonaws.com/static/media/default-user.jpg",
                        "contactos": []
                    },
                    "configuraciones": {}
                },
                "contactos_clientes": [
                    {
                        "id": 77,
                        "tipo": {
                            "id": 1,
                            "descripcion": "Whatsapp",
                            "nombre": "Whatsapp"
                        },
                        "valor": "+58 412 719 7487"
                    }
                ],
                "visitas": [],
                "busquedas": [
                    {
                        "id": 41,
                        "total_seguimientos": 1,
                        "estatus": 1,
                        "descripcion": "Cliente interesado en Blue Loft",
                        "habitaciones": null,
                        "numero_banios": null,
                        "precio": "0.00",
                        "ptos_estacionamiento": null,
                        "created_at": "2024-06-07T14:14:04.103976-04:00",
                        "cliente": 78,
                        "tipo_inmueble": null,
                        "ciudad": null,
                        "estado": null,
                        "seguimientos": [
                            {
                                "id": 50,
                                "cliente": {
                                    "id": 78,
                                    "email": "",
                                    "franquicia": 97,
                                    "nombre": "Daniuska Rodríguez",
                                    "status": 2,
                                    "created_at": "2024-06-07T14:14:04.087046-04:00",
                                    "autor": null,
                                    "editores": []
                                },
                                "fecha_seguimiento": "2024-06-07T14:14:04.105375-04:00",
                                "nota": "Se crea seguimiento para busqueda de cliente.",
                                "audio": null,
                                "busqueda": 41
                            }
                        ]
                    }
                ],
                "status": {
                    "id": 2,
                    "nombre": "En busqueda"
                },
                "nombre": "Daniuska Rodríguez",
                "created_at": "2024-06-07T14:14:04.087046-04:00",
                "autor": null,
                "editores": []
            },
            {
                "id": 77,
                "email": "",
                "franquicia": {
                    "nombre": "Inmobiliaria Deluxe",
                    "descripcion": null,
                    "id": 43,
                    "tipo_franquicia": {
                        "id": 2,
                        "nombre": "Franquicia Comercial",
                        "descripcion": "Franquicia Comercial",
                        "slug_name": "franquicia_comercial"
                    },
                    "usuario_asignado": {
                        "username": null,
                        "email": "tuenlacedeluxe@gmail.com",
                        "profile": {
                            "id": 3,
                            "descripcion": "Gerente",
                            "nombre": "Gerente",
                            "codigo": "5625acf1-f0f6-4ce1-b206-c0ab0faf79ca",
                            "slug_name": "gerente",
                            "keys": []
                        },
                        "first_name": "Deluxe",
                        "last_name": "Las mercedes",
                        "full_name": "Deluxe Las mercedes",
                        "id": 27,
                        "first_image_url": "https://inmobapp-public-bucket.s3.amazonaws.com/media/user_image/Logo_Color_Original.png",
                        "contactos": [
                            {
                                "tipo": 1,
                                "valor": "+584241445498"
                            },
                            {
                                "tipo": 1,
                                "valor": "+584242396556"
                            }
                        ]
                    },
                    "image": "https://inmobapp-public-bucket.s3.amazonaws.com/media/franquicia_image/Logo_Color_Original.png",
                    "franquicia_comercial": null,
                    "franquicia_personal": null,
                    "medios_contacto": [],
                    "solicitudes": {
                        "username": null,
                        "email": "tuenlacedeluxe@gmail.com",
                        "profile": {
                            "id": 3,
                            "descripcion": "Gerente",
                            "nombre": "Gerente",
                            "codigo": "5625acf1-f0f6-4ce1-b206-c0ab0faf79ca",
                            "slug_name": "gerente",
                            "keys": []
                        },
                        "first_name": "Deluxe",
                        "last_name": "Las mercedes",
                        "full_name": "Deluxe Las mercedes",
                        "id": 27,
                        "first_image_url": "https://inmobapp-public-bucket.s3.amazonaws.com/media/user_image/Logo_Color_Original.png",
                        "contactos": [
                            {
                                "tipo": 1,
                                "valor": "+584241445498"
                            },
                            {
                                "tipo": 1,
                                "valor": "+584242396556"
                            }
                        ]
                    },
                    "configuraciones": {
                        "contact_email": "tuenlacedeluxe@gmail.com",
                        "franquicias_enlaces": "44,45,46,47",
                        "prop_list_endpoint": "wp-json/inmobapp/v1/properties/",
                        "prop_upload_endpoint": "wp-json/inmobapp/v1/inmobapp-upload/",
                        "web_url": "https://preview.tuenlaceinmobiliario.com/",
                        "wordpress_auth_key": "e5cee474d75f3ea64ad9aade5b15817d"
                    }
                },
                "contactos_clientes": [
                    {
                        "id": 76,
                        "tipo": {
                            "id": 1,
                            "descripcion": "Whatsapp",
                            "nombre": "Whatsapp"
                        },
                        "valor": "+52 553 071 0145"
                    }
                ],
                "visitas": [],
                "busquedas": [
                    {
                        "id": 40,
                        "total_seguimientos": 1,
                        "estatus": 1,
                        "descripcion": "Cliente interesado en Blue Loft",
                        "habitaciones": null,
                        "numero_banios": null,
                        "precio": "0.00",
                        "ptos_estacionamiento": null,
                        "created_at": "2024-06-07T14:12:43.331051-04:00",
                        "cliente": 77,
                        "tipo_inmueble": null,
                        "ciudad": null,
                        "estado": null,
                        "seguimientos": [
                            {
                                "id": 49,
                                "cliente": {
                                    "id": 77,
                                    "email": "",
                                    "franquicia": 43,
                                    "nombre": "Brigitte",
                                    "status": 2,
                                    "created_at": "2024-06-07T14:12:43.310996-04:00",
                                    "autor": null,
                                    "editores": []
                                },
                                "fecha_seguimiento": "2024-06-07T14:12:43.332446-04:00",
                                "nota": "Se crea seguimiento para busqueda de cliente.",
                                "audio": null,
                                "busqueda": 40
                            }
                        ]
                    }
                ],
                "status": {
                    "id": 2,
                    "nombre": "En busqueda"
                },
                "nombre": "Brigitte",
                "created_at": "2024-06-07T14:12:43.310996-04:00",
                "autor": null,
                "editores": []
            },
            {
                "id": 76,
                "email": "",
                "franquicia": {
                    "nombre": "Rosveli Bruzco Deluxe",
                    "descripcion": null,
                    "id": 97,
                    "tipo_franquicia": {
                        "id": 4,
                        "nombre": "Membresía",
                        "descripcion": "Membresía",
                        "slug_name": "membresia"
                    },
                    "usuario_asignado": {
                        "username": null,
                        "email": "rosveli.deluxe@gmail.com",
                        "profile": {
                            "id": 3,
                            "descripcion": "Gerente",
                            "nombre": "Gerente",
                            "codigo": "5625acf1-f0f6-4ce1-b206-c0ab0faf79ca",
                            "slug_name": "gerente",
                            "keys": []
                        },
                        "first_name": "Rosveli",
                        "last_name": "Bruzco",
                        "full_name": "Rosveli Bruzco",
                        "id": 75,
                        "first_image_url": "https://inmobapp-public-bucket.s3.us-east-2.amazonaws.com/static/media/default-user.jpg",
                        "contactos": []
                    },
                    "image": "https://inmobapp-public-bucket.s3.us-east-2.amazonaws.com/static/media/default-user.jpg",
                    "franquicia_comercial": 43,
                    "franquicia_personal": null,
                    "medios_contacto": [],
                    "solicitudes": {
                        "username": null,
                        "email": "rosveli.deluxe@gmail.com",
                        "profile": {
                            "id": 3,
                            "descripcion": "Gerente",
                            "nombre": "Gerente",
                            "codigo": "5625acf1-f0f6-4ce1-b206-c0ab0faf79ca",
                            "slug_name": "gerente",
                            "keys": []
                        },
                        "first_name": "Rosveli",
                        "last_name": "Bruzco",
                        "full_name": "Rosveli Bruzco",
                        "id": 75,
                        "first_image_url": "https://inmobapp-public-bucket.s3.us-east-2.amazonaws.com/static/media/default-user.jpg",
                        "contactos": []
                    },
                    "configuraciones": {}
                },
                "contactos_clientes": [
                    {
                        "id": 75,
                        "tipo": {
                            "id": 1,
                            "descripcion": "Whatsapp",
                            "nombre": "Whatsapp"
                        },
                        "valor": "+58 412 335 2126"
                    }
                ],
                "visitas": [],
                "busquedas": [
                    {
                        "id": 39,
                        "total_seguimientos": 1,
                        "estatus": 1,
                        "descripcion": "Cliente interesado en Blue Loft",
                        "habitaciones": null,
                        "numero_banios": null,
                        "precio": "0.00",
                        "ptos_estacionamiento": null,
                        "created_at": "2024-06-07T14:11:38.581642-04:00",
                        "cliente": 76,
                        "tipo_inmueble": null,
                        "ciudad": null,
                        "estado": null,
                        "seguimientos": [
                            {
                                "id": 48,
                                "cliente": {
                                    "id": 76,
                                    "email": "",
                                    "franquicia": 97,
                                    "nombre": "Jorge Ayach",
                                    "status": 2,
                                    "created_at": "2024-06-07T14:11:38.566071-04:00",
                                    "autor": null,
                                    "editores": []
                                },
                                "fecha_seguimiento": "2024-06-07T14:11:38.582956-04:00",
                                "nota": "Se crea seguimiento para busqueda de cliente.",
                                "audio": null,
                                "busqueda": 39
                            }
                        ]
                    }
                ],
                "status": {
                    "id": 2,
                    "nombre": "En busqueda"
                },
                "nombre": "Jorge Ayach",
                "created_at": "2024-06-07T14:11:38.566071-04:00",
                "autor": null,
                "editores": []
            },
            {
                "id": 75,
                "email": "",
                "franquicia": {
                    "nombre": "Barbara Deluxe",
                    "descripcion": null,
                    "id": 44,
                    "tipo_franquicia": {
                        "id": 4,
                        "nombre": "Membresía",
                        "descripcion": "Membresía",
                        "slug_name": "membresia"
                    },
                    "usuario_asignado": {
                        "username": null,
                        "email": "enlacedeluxebn@gmail.com",
                        "profile": {
                            "id": 3,
                            "descripcion": "Gerente",
                            "nombre": "Gerente",
                            "codigo": "5625acf1-f0f6-4ce1-b206-c0ab0faf79ca",
                            "slug_name": "gerente",
                            "keys": []
                        },
                        "first_name": "Barbara",
                        "last_name": "Novoa",
                        "full_name": "Barbara Novoa",
                        "id": 31,
                        "first_image_url": "https://inmobapp-public-bucket.s3.us-east-2.amazonaws.com/static/media/default-user.jpg",
                        "contactos": []
                    },
                    "image": "https://inmobapp-public-bucket.s3.amazonaws.com/media/franquicia_image/WhatsApp_Image_2024-07-09_at_12.20.13.jpeg",
                    "franquicia_comercial": 43,
                    "franquicia_personal": null,
                    "medios_contacto": [],
                    "solicitudes": {
                        "username": null,
                        "email": "enlacedeluxebn@gmail.com",
                        "profile": {
                            "id": 3,
                            "descripcion": "Gerente",
                            "nombre": "Gerente",
                            "codigo": "5625acf1-f0f6-4ce1-b206-c0ab0faf79ca",
                            "slug_name": "gerente",
                            "keys": []
                        },
                        "first_name": "Barbara",
                        "last_name": "Novoa",
                        "full_name": "Barbara Novoa",
                        "id": 31,
                        "first_image_url": "https://inmobapp-public-bucket.s3.us-east-2.amazonaws.com/static/media/default-user.jpg",
                        "contactos": []
                    },
                    "configuraciones": {
                        "contact_email": "maria@mail.com"
                    }
                },
                "contactos_clientes": [
                    {
                        "id": 74,
                        "tipo": {
                            "id": 1,
                            "descripcion": "Whatsapp",
                            "nombre": "Whatsapp"
                        },
                        "valor": "+58 412 017 5671"
                    }
                ],
                "visitas": [],
                "busquedas": [
                    {
                        "id": 38,
                        "total_seguimientos": 1,
                        "estatus": 1,
                        "descripcion": "Cliente interesado en Blue Loft",
                        "habitaciones": null,
                        "numero_banios": null,
                        "precio": "0.00",
                        "ptos_estacionamiento": null,
                        "created_at": "2024-06-07T14:10:19.614270-04:00",
                        "cliente": 75,
                        "tipo_inmueble": null,
                        "ciudad": null,
                        "estado": null,
                        "seguimientos": [
                            {
                                "id": 47,
                                "cliente": {
                                    "id": 75,
                                    "email": "",
                                    "franquicia": 44,
                                    "nombre": "Jose Luis Parra",
                                    "status": 2,
                                    "created_at": "2024-06-07T14:10:19.572740-04:00",
                                    "autor": null,
                                    "editores": []
                                },
                                "fecha_seguimiento": "2024-06-07T14:10:19.615696-04:00",
                                "nota": "Se crea seguimiento para busqueda de cliente.",
                                "audio": null,
                                "busqueda": 38
                            }
                        ]
                    }
                ],
                "status": {
                    "id": 2,
                    "nombre": "En busqueda"
                },
                "nombre": "Jose Luis Parra",
                "created_at": "2024-06-07T14:10:19.572740-04:00",
                "autor": null,
                "editores": []
            },
            {
                "id": 74,
                "email": "",
                "franquicia": {
                    "nombre": "Barbara Deluxe",
                    "descripcion": null,
                    "id": 44,
                    "tipo_franquicia": {
                        "id": 4,
                        "nombre": "Membresía",
                        "descripcion": "Membresía",
                        "slug_name": "membresia"
                    },
                    "usuario_asignado": {
                        "username": null,
                        "email": "enlacedeluxebn@gmail.com",
                        "profile": {
                            "id": 3,
                            "descripcion": "Gerente",
                            "nombre": "Gerente",
                            "codigo": "5625acf1-f0f6-4ce1-b206-c0ab0faf79ca",
                            "slug_name": "gerente",
                            "keys": []
                        },
                        "first_name": "Barbara",
                        "last_name": "Novoa",
                        "full_name": "Barbara Novoa",
                        "id": 31,
                        "first_image_url": "https://inmobapp-public-bucket.s3.us-east-2.amazonaws.com/static/media/default-user.jpg",
                        "contactos": []
                    },
                    "image": "https://inmobapp-public-bucket.s3.amazonaws.com/media/franquicia_image/WhatsApp_Image_2024-07-09_at_12.20.13.jpeg",
                    "franquicia_comercial": 43,
                    "franquicia_personal": null,
                    "medios_contacto": [],
                    "solicitudes": {
                        "username": null,
                        "email": "enlacedeluxebn@gmail.com",
                        "profile": {
                            "id": 3,
                            "descripcion": "Gerente",
                            "nombre": "Gerente",
                            "codigo": "5625acf1-f0f6-4ce1-b206-c0ab0faf79ca",
                            "slug_name": "gerente",
                            "keys": []
                        },
                        "first_name": "Barbara",
                        "last_name": "Novoa",
                        "full_name": "Barbara Novoa",
                        "id": 31,
                        "first_image_url": "https://inmobapp-public-bucket.s3.us-east-2.amazonaws.com/static/media/default-user.jpg",
                        "contactos": []
                    },
                    "configuraciones": {
                        "contact_email": "maria@mail.com"
                    }
                },
                "contactos_clientes": [
                    {
                        "id": 73,
                        "tipo": {
                            "id": 1,
                            "descripcion": "Whatsapp",
                            "nombre": "Whatsapp"
                        },
                        "valor": "+58 414 302 5276"
                    }
                ],
                "visitas": [],
                "busquedas": [
                    {
                        "id": 37,
                        "total_seguimientos": 1,
                        "estatus": 1,
                        "descripcion": "Cliente interesado en Blue Loft",
                        "habitaciones": null,
                        "numero_banios": null,
                        "precio": "0.00",
                        "ptos_estacionamiento": null,
                        "created_at": "2024-06-07T14:09:11.858602-04:00",
                        "cliente": 74,
                        "tipo_inmueble": null,
                        "ciudad": null,
                        "estado": null,
                        "seguimientos": [
                            {
                                "id": 46,
                                "cliente": {
                                    "id": 74,
                                    "email": "",
                                    "franquicia": 44,
                                    "nombre": "DomingoTexeira",
                                    "status": 2,
                                    "created_at": "2024-06-07T14:09:11.842970-04:00",
                                    "autor": null,
                                    "editores": []
                                },
                                "fecha_seguimiento": "2024-06-07T14:09:11.859952-04:00",
                                "nota": "Se crea seguimiento para busqueda de cliente.",
                                "audio": null,
                                "busqueda": 37
                            }
                        ]
                    }
                ],
                "status": {
                    "id": 2,
                    "nombre": "En busqueda"
                },
                "nombre": "DomingoTexeira",
                "created_at": "2024-06-07T14:09:11.842970-04:00",
                "autor": null,
                "editores": []
            },
            {
                "id": 73,
                "email": "",
                "franquicia": {
                    "nombre": "Yorgelis Arellano Deluxe",
                    "descripcion": null,
                    "id": 102,
                    "tipo_franquicia": {
                        "id": 4,
                        "nombre": "Membresía",
                        "descripcion": "Membresía",
                        "slug_name": "membresia"
                    },
                    "usuario_asignado": {
                        "username": null,
                        "email": "yorgelis.deluxe@gmail.com",
                        "profile": {
                            "id": 3,
                            "descripcion": "Gerente",
                            "nombre": "Gerente",
                            "codigo": "5625acf1-f0f6-4ce1-b206-c0ab0faf79ca",
                            "slug_name": "gerente",
                            "keys": []
                        },
                        "first_name": "Yorgelis",
                        "last_name": "Arellano",
                        "full_name": "Yorgelis Arellano",
                        "id": 80,
                        "first_image_url": "https://inmobapp-public-bucket.s3.us-east-2.amazonaws.com/static/media/default-user.jpg",
                        "contactos": []
                    },
                    "image": "https://inmobapp-public-bucket.s3.amazonaws.com/media/franquicia_image/WhatsApp_Image_2024-07-09_at_11.34.12.jpeg",
                    "franquicia_comercial": 43,
                    "franquicia_personal": null,
                    "medios_contacto": [],
                    "solicitudes": {
                        "username": null,
                        "email": "yorgelis.deluxe@gmail.com",
                        "profile": {
                            "id": 3,
                            "descripcion": "Gerente",
                            "nombre": "Gerente",
                            "codigo": "5625acf1-f0f6-4ce1-b206-c0ab0faf79ca",
                            "slug_name": "gerente",
                            "keys": []
                        },
                        "first_name": "Yorgelis",
                        "last_name": "Arellano",
                        "full_name": "Yorgelis Arellano",
                        "id": 80,
                        "first_image_url": "https://inmobapp-public-bucket.s3.us-east-2.amazonaws.com/static/media/default-user.jpg",
                        "contactos": []
                    },
                    "configuraciones": {}
                },
                "contactos_clientes": [
                    {
                        "id": 72,
                        "tipo": {
                            "id": 1,
                            "descripcion": "Whatsapp",
                            "nombre": "Whatsapp"
                        },
                        "valor": "+58 426 520 5862"
                    }
                ],
                "visitas": [],
                "busquedas": [
                    {
                        "id": 36,
                        "total_seguimientos": 1,
                        "estatus": 1,
                        "descripcion": "Cliente interesado en Bue Loft",
                        "habitaciones": null,
                        "numero_banios": null,
                        "precio": "0.00",
                        "ptos_estacionamiento": null,
                        "created_at": "2024-06-07T14:07:18.108554-04:00",
                        "cliente": 73,
                        "tipo_inmueble": null,
                        "ciudad": null,
                        "estado": null,
                        "seguimientos": [
                            {
                                "id": 45,
                                "cliente": {
                                    "id": 73,
                                    "email": "",
                                    "franquicia": 102,
                                    "nombre": "Armando Segovia",
                                    "status": 2,
                                    "created_at": "2024-06-07T14:07:18.090396-04:00",
                                    "autor": null,
                                    "editores": []
                                },
                                "fecha_seguimiento": "2024-06-07T14:07:18.110085-04:00",
                                "nota": "Se crea seguimiento para busqueda de cliente.",
                                "audio": null,
                                "busqueda": 36
                            }
                        ]
                    }
                ],
                "status": {
                    "id": 2,
                    "nombre": "En busqueda"
                },
                "nombre": "Armando Segovia",
                "created_at": "2024-06-07T14:07:18.090396-04:00",
                "autor": null,
                "editores": []
            },
            {
                "id": 72,
                "email": "",
                "franquicia": {
                    "nombre": "Yorgelis Arellano Deluxe",
                    "descripcion": null,
                    "id": 102,
                    "tipo_franquicia": {
                        "id": 4,
                        "nombre": "Membresía",
                        "descripcion": "Membresía",
                        "slug_name": "membresia"
                    },
                    "usuario_asignado": {
                        "username": null,
                        "email": "yorgelis.deluxe@gmail.com",
                        "profile": {
                            "id": 3,
                            "descripcion": "Gerente",
                            "nombre": "Gerente",
                            "codigo": "5625acf1-f0f6-4ce1-b206-c0ab0faf79ca",
                            "slug_name": "gerente",
                            "keys": []
                        },
                        "first_name": "Yorgelis",
                        "last_name": "Arellano",
                        "full_name": "Yorgelis Arellano",
                        "id": 80,
                        "first_image_url": "https://inmobapp-public-bucket.s3.us-east-2.amazonaws.com/static/media/default-user.jpg",
                        "contactos": []
                    },
                    "image": "https://inmobapp-public-bucket.s3.amazonaws.com/media/franquicia_image/WhatsApp_Image_2024-07-09_at_11.34.12.jpeg",
                    "franquicia_comercial": 43,
                    "franquicia_personal": null,
                    "medios_contacto": [],
                    "solicitudes": {
                        "username": null,
                        "email": "yorgelis.deluxe@gmail.com",
                        "profile": {
                            "id": 3,
                            "descripcion": "Gerente",
                            "nombre": "Gerente",
                            "codigo": "5625acf1-f0f6-4ce1-b206-c0ab0faf79ca",
                            "slug_name": "gerente",
                            "keys": []
                        },
                        "first_name": "Yorgelis",
                        "last_name": "Arellano",
                        "full_name": "Yorgelis Arellano",
                        "id": 80,
                        "first_image_url": "https://inmobapp-public-bucket.s3.us-east-2.amazonaws.com/static/media/default-user.jpg",
                        "contactos": []
                    },
                    "configuraciones": {}
                },
                "contactos_clientes": [
                    {
                        "id": 71,
                        "tipo": {
                            "id": 1,
                            "descripcion": "Whatsapp",
                            "nombre": "Whatsapp"
                        },
                        "valor": "+58 424 198 2474"
                    }
                ],
                "visitas": [],
                "busquedas": [
                    {
                        "id": 35,
                        "total_seguimientos": 1,
                        "estatus": 1,
                        "descripcion": "Cliente interesado en Blue Loft",
                        "habitaciones": null,
                        "numero_banios": null,
                        "precio": "0.00",
                        "ptos_estacionamiento": null,
                        "created_at": "2024-06-07T14:06:03.320753-04:00",
                        "cliente": 72,
                        "tipo_inmueble": null,
                        "ciudad": null,
                        "estado": null,
                        "seguimientos": [
                            {
                                "id": 44,
                                "cliente": {
                                    "id": 72,
                                    "email": "",
                                    "franquicia": 102,
                                    "nombre": "Manuel Alvarez",
                                    "status": 2,
                                    "created_at": "2024-06-07T14:06:03.304005-04:00",
                                    "autor": null,
                                    "editores": []
                                },
                                "fecha_seguimiento": "2024-06-07T14:06:03.322508-04:00",
                                "nota": "Se crea seguimiento para busqueda de cliente.",
                                "audio": null,
                                "busqueda": 35
                            }
                        ]
                    }
                ],
                "status": {
                    "id": 2,
                    "nombre": "En busqueda"
                },
                "nombre": "Manuel Alvarez",
                "created_at": "2024-06-07T14:06:03.304005-04:00",
                "autor": null,
                "editores": []
            },
            {
                "id": 71,
                "email": "",
                "franquicia": {
                    "nombre": "Yorgelis Arellano Deluxe",
                    "descripcion": null,
                    "id": 102,
                    "tipo_franquicia": {
                        "id": 4,
                        "nombre": "Membresía",
                        "descripcion": "Membresía",
                        "slug_name": "membresia"
                    },
                    "usuario_asignado": {
                        "username": null,
                        "email": "yorgelis.deluxe@gmail.com",
                        "profile": {
                            "id": 3,
                            "descripcion": "Gerente",
                            "nombre": "Gerente",
                            "codigo": "5625acf1-f0f6-4ce1-b206-c0ab0faf79ca",
                            "slug_name": "gerente",
                            "keys": []
                        },
                        "first_name": "Yorgelis",
                        "last_name": "Arellano",
                        "full_name": "Yorgelis Arellano",
                        "id": 80,
                        "first_image_url": "https://inmobapp-public-bucket.s3.us-east-2.amazonaws.com/static/media/default-user.jpg",
                        "contactos": []
                    },
                    "image": "https://inmobapp-public-bucket.s3.amazonaws.com/media/franquicia_image/WhatsApp_Image_2024-07-09_at_11.34.12.jpeg",
                    "franquicia_comercial": 43,
                    "franquicia_personal": null,
                    "medios_contacto": [],
                    "solicitudes": {
                        "username": null,
                        "email": "yorgelis.deluxe@gmail.com",
                        "profile": {
                            "id": 3,
                            "descripcion": "Gerente",
                            "nombre": "Gerente",
                            "codigo": "5625acf1-f0f6-4ce1-b206-c0ab0faf79ca",
                            "slug_name": "gerente",
                            "keys": []
                        },
                        "first_name": "Yorgelis",
                        "last_name": "Arellano",
                        "full_name": "Yorgelis Arellano",
                        "id": 80,
                        "first_image_url": "https://inmobapp-public-bucket.s3.us-east-2.amazonaws.com/static/media/default-user.jpg",
                        "contactos": []
                    },
                    "configuraciones": {}
                },
                "contactos_clientes": [
                    {
                        "id": 70,
                        "tipo": {
                            "id": 1,
                            "descripcion": "Whatsapp",
                            "nombre": "Whatsapp"
                        },
                        "valor": "+58 412 025 0200"
                    }
                ],
                "visitas": [],
                "busquedas": [
                    {
                        "id": 34,
                        "total_seguimientos": 1,
                        "estatus": 1,
                        "descripcion": "Cliente interesado en Blue Loft",
                        "habitaciones": null,
                        "numero_banios": null,
                        "precio": "0.00",
                        "ptos_estacionamiento": null,
                        "created_at": "2024-06-07T14:04:54.311098-04:00",
                        "cliente": 71,
                        "tipo_inmueble": null,
                        "ciudad": null,
                        "estado": null,
                        "seguimientos": [
                            {
                                "id": 43,
                                "cliente": {
                                    "id": 71,
                                    "email": "",
                                    "franquicia": 102,
                                    "nombre": "Dr cesar Rojas",
                                    "status": 2,
                                    "created_at": "2024-06-07T14:04:54.294584-04:00",
                                    "autor": null,
                                    "editores": []
                                },
                                "fecha_seguimiento": "2024-06-07T14:04:54.312538-04:00",
                                "nota": "Se crea seguimiento para busqueda de cliente.",
                                "audio": null,
                                "busqueda": 34
                            }
                        ]
                    }
                ],
                "status": {
                    "id": 2,
                    "nombre": "En busqueda"
                },
                "nombre": "Dr cesar Rojas",
                "created_at": "2024-06-07T14:04:54.294584-04:00",
                "autor": null,
                "editores": []
            },
            {
                "id": 69,
                "email": "",
                "franquicia": {
                    "nombre": "Yorgelis Arellano Deluxe",
                    "descripcion": null,
                    "id": 102,
                    "tipo_franquicia": {
                        "id": 4,
                        "nombre": "Membresía",
                        "descripcion": "Membresía",
                        "slug_name": "membresia"
                    },
                    "usuario_asignado": {
                        "username": null,
                        "email": "yorgelis.deluxe@gmail.com",
                        "profile": {
                            "id": 3,
                            "descripcion": "Gerente",
                            "nombre": "Gerente",
                            "codigo": "5625acf1-f0f6-4ce1-b206-c0ab0faf79ca",
                            "slug_name": "gerente",
                            "keys": []
                        },
                        "first_name": "Yorgelis",
                        "last_name": "Arellano",
                        "full_name": "Yorgelis Arellano",
                        "id": 80,
                        "first_image_url": "https://inmobapp-public-bucket.s3.us-east-2.amazonaws.com/static/media/default-user.jpg",
                        "contactos": []
                    },
                    "image": "https://inmobapp-public-bucket.s3.amazonaws.com/media/franquicia_image/WhatsApp_Image_2024-07-09_at_11.34.12.jpeg",
                    "franquicia_comercial": 43,
                    "franquicia_personal": null,
                    "medios_contacto": [],
                    "solicitudes": {
                        "username": null,
                        "email": "yorgelis.deluxe@gmail.com",
                        "profile": {
                            "id": 3,
                            "descripcion": "Gerente",
                            "nombre": "Gerente",
                            "codigo": "5625acf1-f0f6-4ce1-b206-c0ab0faf79ca",
                            "slug_name": "gerente",
                            "keys": []
                        },
                        "first_name": "Yorgelis",
                        "last_name": "Arellano",
                        "full_name": "Yorgelis Arellano",
                        "id": 80,
                        "first_image_url": "https://inmobapp-public-bucket.s3.us-east-2.amazonaws.com/static/media/default-user.jpg",
                        "contactos": []
                    },
                    "configuraciones": {}
                },
                "contactos_clientes": [
                    {
                        "id": 68,
                        "tipo": {
                            "id": 1,
                            "descripcion": "Whatsapp",
                            "nombre": "Whatsapp"
                        },
                        "valor": "+58 414 323 2560"
                    }
                ],
                "visitas": [],
                "busquedas": [
                    {
                        "id": 33,
                        "total_seguimientos": 1,
                        "estatus": 1,
                        "descripcion": "Cliente interesado en Blue Loft",
                        "habitaciones": null,
                        "numero_banios": null,
                        "precio": "0.00",
                        "ptos_estacionamiento": null,
                        "created_at": "2024-06-07T14:02:28.534587-04:00",
                        "cliente": 69,
                        "tipo_inmueble": null,
                        "ciudad": null,
                        "estado": null,
                        "seguimientos": [
                            {
                                "id": 42,
                                "cliente": {
                                    "id": 69,
                                    "email": "",
                                    "franquicia": 102,
                                    "nombre": "Joxander Delgado",
                                    "status": 2,
                                    "created_at": "2024-06-07T14:02:28.513949-04:00",
                                    "autor": null,
                                    "editores": []
                                },
                                "fecha_seguimiento": "2024-06-07T14:02:28.536051-04:00",
                                "nota": "Se crea seguimiento para busqueda de cliente.",
                                "audio": null,
                                "busqueda": 33
                            }
                        ]
                    }
                ],
                "status": {
                    "id": 2,
                    "nombre": "En busqueda"
                },
                "nombre": "Joxander Delgado",
                "created_at": "2024-06-07T14:02:28.513949-04:00",
                "autor": null,
                "editores": []
            },
            {
                "id": 68,
                "email": "",
                "franquicia": {
                    "nombre": "Natalia Deluxe",
                    "descripcion": null,
                    "id": 88,
                    "tipo_franquicia": {
                        "id": 4,
                        "nombre": "Membresía",
                        "descripcion": "Membresía",
                        "slug_name": "membresia"
                    },
                    "usuario_asignado": {
                        "username": null,
                        "email": "nataliac.tei@gmail.com",
                        "profile": {
                            "id": 3,
                            "descripcion": "Gerente",
                            "nombre": "Gerente",
                            "codigo": "5625acf1-f0f6-4ce1-b206-c0ab0faf79ca",
                            "slug_name": "gerente",
                            "keys": []
                        },
                        "first_name": "Natalia",
                        "last_name": "Campos",
                        "full_name": "Natalia Campos",
                        "id": 66,
                        "first_image_url": "https://inmobapp-public-bucket.s3.us-east-2.amazonaws.com/static/media/default-user.jpg",
                        "contactos": []
                    },
                    "image": "https://inmobapp-public-bucket.s3.amazonaws.com/media/franquicia_image/WhatsApp_Image_2024-07-09_at_12.29.46.jpeg",
                    "franquicia_comercial": 43,
                    "franquicia_personal": null,
                    "medios_contacto": [],
                    "solicitudes": {
                        "username": null,
                        "email": "nataliac.tei@gmail.com",
                        "profile": {
                            "id": 3,
                            "descripcion": "Gerente",
                            "nombre": "Gerente",
                            "codigo": "5625acf1-f0f6-4ce1-b206-c0ab0faf79ca",
                            "slug_name": "gerente",
                            "keys": []
                        },
                        "first_name": "Natalia",
                        "last_name": "Campos",
                        "full_name": "Natalia Campos",
                        "id": 66,
                        "first_image_url": "https://inmobapp-public-bucket.s3.us-east-2.amazonaws.com/static/media/default-user.jpg",
                        "contactos": []
                    },
                    "configuraciones": {}
                },
                "contactos_clientes": [
                    {
                        "id": 67,
                        "tipo": {
                            "id": 1,
                            "descripcion": "Whatsapp",
                            "nombre": "Whatsapp"
                        },
                        "valor": "+58 416 634 0929"
                    }
                ],
                "visitas": [],
                "busquedas": [
                    {
                        "id": 32,
                        "total_seguimientos": 1,
                        "estatus": 1,
                        "descripcion": "Cliente interesado en Blue Loft",
                        "habitaciones": null,
                        "numero_banios": null,
                        "precio": "0.00",
                        "ptos_estacionamiento": null,
                        "created_at": "2024-06-07T14:00:27.959912-04:00",
                        "cliente": 68,
                        "tipo_inmueble": null,
                        "ciudad": null,
                        "estado": null,
                        "seguimientos": [
                            {
                                "id": 41,
                                "cliente": {
                                    "id": 68,
                                    "email": "",
                                    "franquicia": 88,
                                    "nombre": "Javier Antonio",
                                    "status": 2,
                                    "created_at": "2024-06-07T14:00:27.944434-04:00",
                                    "autor": null,
                                    "editores": []
                                },
                                "fecha_seguimiento": "2024-06-07T14:00:27.961212-04:00",
                                "nota": "Se crea seguimiento para busqueda de cliente.",
                                "audio": null,
                                "busqueda": 32
                            }
                        ]
                    }
                ],
                "status": {
                    "id": 2,
                    "nombre": "En busqueda"
                },
                "nombre": "Javier Antonio",
                "created_at": "2024-06-07T14:00:27.944434-04:00",
                "autor": null,
                "editores": []
            },
            {
                "id": 67,
                "email": "",
                "franquicia": {
                    "nombre": "Dionnys Valentina Deluxe",
                    "descripcion": null,
                    "id": 105,
                    "tipo_franquicia": {
                        "id": 4,
                        "nombre": "Membresía",
                        "descripcion": "Membresía",
                        "slug_name": "membresia"
                    },
                    "usuario_asignado": {
                        "username": null,
                        "email": "dionnys.deluxe@gmail.com",
                        "profile": {
                            "id": 3,
                            "descripcion": "Gerente",
                            "nombre": "Gerente",
                            "codigo": "5625acf1-f0f6-4ce1-b206-c0ab0faf79ca",
                            "slug_name": "gerente",
                            "keys": []
                        },
                        "first_name": "Dionnys Valentina",
                        "last_name": "Gómez",
                        "full_name": "Dionnys Valentina Gómez",
                        "id": 83,
                        "first_image_url": "https://inmobapp-public-bucket.s3.us-east-2.amazonaws.com/static/media/default-user.jpg",
                        "contactos": []
                    },
                    "image": "https://inmobapp-public-bucket.s3.amazonaws.com/media/franquicia_image/WhatsApp_Image_2024-07-09_at_12.20.14.jpeg",
                    "franquicia_comercial": 43,
                    "franquicia_personal": null,
                    "medios_contacto": [],
                    "solicitudes": {
                        "username": null,
                        "email": "dionnys.deluxe@gmail.com",
                        "profile": {
                            "id": 3,
                            "descripcion": "Gerente",
                            "nombre": "Gerente",
                            "codigo": "5625acf1-f0f6-4ce1-b206-c0ab0faf79ca",
                            "slug_name": "gerente",
                            "keys": []
                        },
                        "first_name": "Dionnys Valentina",
                        "last_name": "Gómez",
                        "full_name": "Dionnys Valentina Gómez",
                        "id": 83,
                        "first_image_url": "https://inmobapp-public-bucket.s3.us-east-2.amazonaws.com/static/media/default-user.jpg",
                        "contactos": []
                    },
                    "configuraciones": {}
                },
                "contactos_clientes": [
                    {
                        "id": 66,
                        "tipo": {
                            "id": 1,
                            "descripcion": "Whatsapp",
                            "nombre": "Whatsapp"
                        },
                        "valor": "+58 412 597 1110"
                    }
                ],
                "visitas": [],
                "busquedas": [
                    {
                        "id": 31,
                        "total_seguimientos": 1,
                        "estatus": 1,
                        "descripcion": "Cliente interesado en Blue Loft",
                        "habitaciones": null,
                        "numero_banios": null,
                        "precio": "0.00",
                        "ptos_estacionamiento": null,
                        "created_at": "2024-06-07T13:57:22.713757-04:00",
                        "cliente": 67,
                        "tipo_inmueble": null,
                        "ciudad": null,
                        "estado": null,
                        "seguimientos": [
                            {
                                "id": 40,
                                "cliente": {
                                    "id": 67,
                                    "email": "",
                                    "franquicia": 105,
                                    "nombre": "Dagny Campuzano",
                                    "status": 2,
                                    "created_at": "2024-06-07T13:57:22.697728-04:00",
                                    "autor": null,
                                    "editores": []
                                },
                                "fecha_seguimiento": "2024-06-07T13:57:22.715020-04:00",
                                "nota": "Se crea seguimiento para busqueda de cliente.",
                                "audio": null,
                                "busqueda": 31
                            }
                        ]
                    }
                ],
                "status": {
                    "id": 2,
                    "nombre": "En busqueda"
                },
                "nombre": "Dagny Campuzano",
                "created_at": "2024-06-07T13:57:22.697728-04:00",
                "autor": null,
                "editores": []
            },
            {
                "id": 66,
                "email": "",
                "franquicia": {
                    "nombre": "Dionnys Valentina Deluxe",
                    "descripcion": null,
                    "id": 105,
                    "tipo_franquicia": {
                        "id": 4,
                        "nombre": "Membresía",
                        "descripcion": "Membresía",
                        "slug_name": "membresia"
                    },
                    "usuario_asignado": {
                        "username": null,
                        "email": "dionnys.deluxe@gmail.com",
                        "profile": {
                            "id": 3,
                            "descripcion": "Gerente",
                            "nombre": "Gerente",
                            "codigo": "5625acf1-f0f6-4ce1-b206-c0ab0faf79ca",
                            "slug_name": "gerente",
                            "keys": []
                        },
                        "first_name": "Dionnys Valentina",
                        "last_name": "Gómez",
                        "full_name": "Dionnys Valentina Gómez",
                        "id": 83,
                        "first_image_url": "https://inmobapp-public-bucket.s3.us-east-2.amazonaws.com/static/media/default-user.jpg",
                        "contactos": []
                    },
                    "image": "https://inmobapp-public-bucket.s3.amazonaws.com/media/franquicia_image/WhatsApp_Image_2024-07-09_at_12.20.14.jpeg",
                    "franquicia_comercial": 43,
                    "franquicia_personal": null,
                    "medios_contacto": [],
                    "solicitudes": {
                        "username": null,
                        "email": "dionnys.deluxe@gmail.com",
                        "profile": {
                            "id": 3,
                            "descripcion": "Gerente",
                            "nombre": "Gerente",
                            "codigo": "5625acf1-f0f6-4ce1-b206-c0ab0faf79ca",
                            "slug_name": "gerente",
                            "keys": []
                        },
                        "first_name": "Dionnys Valentina",
                        "last_name": "Gómez",
                        "full_name": "Dionnys Valentina Gómez",
                        "id": 83,
                        "first_image_url": "https://inmobapp-public-bucket.s3.us-east-2.amazonaws.com/static/media/default-user.jpg",
                        "contactos": []
                    },
                    "configuraciones": {}
                },
                "contactos_clientes": [
                    {
                        "id": 65,
                        "tipo": {
                            "id": 1,
                            "descripcion": "Whatsapp",
                            "nombre": "Whatsapp"
                        },
                        "valor": "+58 424 146 6630"
                    }
                ],
                "visitas": [],
                "busquedas": [
                    {
                        "id": 30,
                        "total_seguimientos": 1,
                        "estatus": 1,
                        "descripcion": "Cliente interesado  en Blue Loft",
                        "habitaciones": null,
                        "numero_banios": null,
                        "precio": "0.00",
                        "ptos_estacionamiento": null,
                        "created_at": "2024-06-07T13:56:10.691078-04:00",
                        "cliente": 66,
                        "tipo_inmueble": null,
                        "ciudad": null,
                        "estado": null,
                        "seguimientos": [
                            {
                                "id": 39,
                                "cliente": {
                                    "id": 66,
                                    "email": "",
                                    "franquicia": 105,
                                    "nombre": "Yvette Quintero",
                                    "status": 2,
                                    "created_at": "2024-06-07T13:56:10.673398-04:00",
                                    "autor": null,
                                    "editores": []
                                },
                                "fecha_seguimiento": "2024-06-07T13:56:10.692574-04:00",
                                "nota": "Se crea seguimiento para busqueda de cliente.",
                                "audio": null,
                                "busqueda": 30
                            }
                        ]
                    }
                ],
                "status": {
                    "id": 2,
                    "nombre": "En busqueda"
                },
                "nombre": "Yvette Quintero",
                "created_at": "2024-06-07T13:56:10.673398-04:00",
                "autor": null,
                "editores": []
            },
            {
                "id": 65,
                "email": "",
                "franquicia": {
                    "nombre": "Dionnys Valentina Deluxe",
                    "descripcion": null,
                    "id": 105,
                    "tipo_franquicia": {
                        "id": 4,
                        "nombre": "Membresía",
                        "descripcion": "Membresía",
                        "slug_name": "membresia"
                    },
                    "usuario_asignado": {
                        "username": null,
                        "email": "dionnys.deluxe@gmail.com",
                        "profile": {
                            "id": 3,
                            "descripcion": "Gerente",
                            "nombre": "Gerente",
                            "codigo": "5625acf1-f0f6-4ce1-b206-c0ab0faf79ca",
                            "slug_name": "gerente",
                            "keys": []
                        },
                        "first_name": "Dionnys Valentina",
                        "last_name": "Gómez",
                        "full_name": "Dionnys Valentina Gómez",
                        "id": 83,
                        "first_image_url": "https://inmobapp-public-bucket.s3.us-east-2.amazonaws.com/static/media/default-user.jpg",
                        "contactos": []
                    },
                    "image": "https://inmobapp-public-bucket.s3.amazonaws.com/media/franquicia_image/WhatsApp_Image_2024-07-09_at_12.20.14.jpeg",
                    "franquicia_comercial": 43,
                    "franquicia_personal": null,
                    "medios_contacto": [],
                    "solicitudes": {
                        "username": null,
                        "email": "dionnys.deluxe@gmail.com",
                        "profile": {
                            "id": 3,
                            "descripcion": "Gerente",
                            "nombre": "Gerente",
                            "codigo": "5625acf1-f0f6-4ce1-b206-c0ab0faf79ca",
                            "slug_name": "gerente",
                            "keys": []
                        },
                        "first_name": "Dionnys Valentina",
                        "last_name": "Gómez",
                        "full_name": "Dionnys Valentina Gómez",
                        "id": 83,
                        "first_image_url": "https://inmobapp-public-bucket.s3.us-east-2.amazonaws.com/static/media/default-user.jpg",
                        "contactos": []
                    },
                    "configuraciones": {}
                },
                "contactos_clientes": [
                    {
                        "id": 64,
                        "tipo": {
                            "id": 1,
                            "descripcion": "Whatsapp",
                            "nombre": "Whatsapp"
                        },
                        "valor": "+58 412 338 3150"
                    }
                ],
                "visitas": [],
                "busquedas": [
                    {
                        "id": 29,
                        "total_seguimientos": 1,
                        "estatus": 1,
                        "descripcion": "Cliente interesado en Blue Loft",
                        "habitaciones": null,
                        "numero_banios": null,
                        "precio": "0.00",
                        "ptos_estacionamiento": null,
                        "created_at": "2024-06-07T13:54:16.520754-04:00",
                        "cliente": 65,
                        "tipo_inmueble": null,
                        "ciudad": null,
                        "estado": null,
                        "seguimientos": [
                            {
                                "id": 38,
                                "cliente": {
                                    "id": 65,
                                    "email": "",
                                    "franquicia": 105,
                                    "nombre": "David De Aveiro",
                                    "status": 2,
                                    "created_at": "2024-06-07T13:54:16.504121-04:00",
                                    "autor": null,
                                    "editores": []
                                },
                                "fecha_seguimiento": "2024-06-07T13:54:16.522025-04:00",
                                "nota": "Se crea seguimiento para busqueda de cliente.",
                                "audio": null,
                                "busqueda": 29
                            }
                        ]
                    }
                ],
                "status": {
                    "id": 2,
                    "nombre": "En busqueda"
                },
                "nombre": "David De Aveiro",
                "created_at": "2024-06-07T13:54:16.504121-04:00",
                "autor": null,
                "editores": []
            },
            {
                "id": 64,
                "email": "",
                "franquicia": {
                    "nombre": "Dionnys Valentina Deluxe",
                    "descripcion": null,
                    "id": 105,
                    "tipo_franquicia": {
                        "id": 4,
                        "nombre": "Membresía",
                        "descripcion": "Membresía",
                        "slug_name": "membresia"
                    },
                    "usuario_asignado": {
                        "username": null,
                        "email": "dionnys.deluxe@gmail.com",
                        "profile": {
                            "id": 3,
                            "descripcion": "Gerente",
                            "nombre": "Gerente",
                            "codigo": "5625acf1-f0f6-4ce1-b206-c0ab0faf79ca",
                            "slug_name": "gerente",
                            "keys": []
                        },
                        "first_name": "Dionnys Valentina",
                        "last_name": "Gómez",
                        "full_name": "Dionnys Valentina Gómez",
                        "id": 83,
                        "first_image_url": "https://inmobapp-public-bucket.s3.us-east-2.amazonaws.com/static/media/default-user.jpg",
                        "contactos": []
                    },
                    "image": "https://inmobapp-public-bucket.s3.amazonaws.com/media/franquicia_image/WhatsApp_Image_2024-07-09_at_12.20.14.jpeg",
                    "franquicia_comercial": 43,
                    "franquicia_personal": null,
                    "medios_contacto": [],
                    "solicitudes": {
                        "username": null,
                        "email": "dionnys.deluxe@gmail.com",
                        "profile": {
                            "id": 3,
                            "descripcion": "Gerente",
                            "nombre": "Gerente",
                            "codigo": "5625acf1-f0f6-4ce1-b206-c0ab0faf79ca",
                            "slug_name": "gerente",
                            "keys": []
                        },
                        "first_name": "Dionnys Valentina",
                        "last_name": "Gómez",
                        "full_name": "Dionnys Valentina Gómez",
                        "id": 83,
                        "first_image_url": "https://inmobapp-public-bucket.s3.us-east-2.amazonaws.com/static/media/default-user.jpg",
                        "contactos": []
                    },
                    "configuraciones": {}
                },
                "contactos_clientes": [
                    {
                        "id": 63,
                        "tipo": {
                            "id": 1,
                            "descripcion": "Whatsapp",
                            "nombre": "Whatsapp"
                        },
                        "valor": "+58 424 249 3312"
                    }
                ],
                "visitas": [],
                "busquedas": [
                    {
                        "id": 28,
                        "total_seguimientos": 1,
                        "estatus": 1,
                        "descripcion": "Cliente interesado en Blue Loft",
                        "habitaciones": null,
                        "numero_banios": null,
                        "precio": "0.00",
                        "ptos_estacionamiento": null,
                        "created_at": "2024-06-07T13:52:42.350086-04:00",
                        "cliente": 64,
                        "tipo_inmueble": null,
                        "ciudad": null,
                        "estado": null,
                        "seguimientos": [
                            {
                                "id": 37,
                                "cliente": {
                                    "id": 64,
                                    "email": "",
                                    "franquicia": 105,
                                    "nombre": "Greisy Cantillo",
                                    "status": 2,
                                    "created_at": "2024-06-07T13:52:42.334485-04:00",
                                    "autor": null,
                                    "editores": []
                                },
                                "fecha_seguimiento": "2024-06-07T13:52:42.351668-04:00",
                                "nota": "Se crea seguimiento para busqueda de cliente.",
                                "audio": null,
                                "busqueda": 28
                            }
                        ]
                    }
                ],
                "status": {
                    "id": 2,
                    "nombre": "En busqueda"
                },
                "nombre": "Greisy Cantillo",
                "created_at": "2024-06-07T13:52:42.334485-04:00",
                "autor": null,
                "editores": []
            },
            {
                "id": 62,
                "email": "",
                "franquicia": {
                    "nombre": "Rosveli Bruzco Deluxe",
                    "descripcion": null,
                    "id": 97,
                    "tipo_franquicia": {
                        "id": 4,
                        "nombre": "Membresía",
                        "descripcion": "Membresía",
                        "slug_name": "membresia"
                    },
                    "usuario_asignado": {
                        "username": null,
                        "email": "rosveli.deluxe@gmail.com",
                        "profile": {
                            "id": 3,
                            "descripcion": "Gerente",
                            "nombre": "Gerente",
                            "codigo": "5625acf1-f0f6-4ce1-b206-c0ab0faf79ca",
                            "slug_name": "gerente",
                            "keys": []
                        },
                        "first_name": "Rosveli",
                        "last_name": "Bruzco",
                        "full_name": "Rosveli Bruzco",
                        "id": 75,
                        "first_image_url": "https://inmobapp-public-bucket.s3.us-east-2.amazonaws.com/static/media/default-user.jpg",
                        "contactos": []
                    },
                    "image": "https://inmobapp-public-bucket.s3.us-east-2.amazonaws.com/static/media/default-user.jpg",
                    "franquicia_comercial": 43,
                    "franquicia_personal": null,
                    "medios_contacto": [],
                    "solicitudes": {
                        "username": null,
                        "email": "rosveli.deluxe@gmail.com",
                        "profile": {
                            "id": 3,
                            "descripcion": "Gerente",
                            "nombre": "Gerente",
                            "codigo": "5625acf1-f0f6-4ce1-b206-c0ab0faf79ca",
                            "slug_name": "gerente",
                            "keys": []
                        },
                        "first_name": "Rosveli",
                        "last_name": "Bruzco",
                        "full_name": "Rosveli Bruzco",
                        "id": 75,
                        "first_image_url": "https://inmobapp-public-bucket.s3.us-east-2.amazonaws.com/static/media/default-user.jpg",
                        "contactos": []
                    },
                    "configuraciones": {}
                },
                "contactos_clientes": [
                    {
                        "id": 61,
                        "tipo": {
                            "id": 1,
                            "descripcion": "Whatsapp",
                            "nombre": "Whatsapp"
                        },
                        "valor": "+58 424 244 4679"
                    }
                ],
                "visitas": [],
                "busquedas": [
                    {
                        "id": 27,
                        "total_seguimientos": 1,
                        "estatus": 1,
                        "descripcion": "Cliente interesado en Blue Loft",
                        "habitaciones": null,
                        "numero_banios": null,
                        "precio": "0.00",
                        "ptos_estacionamiento": null,
                        "created_at": "2024-06-07T13:49:59.147713-04:00",
                        "cliente": 62,
                        "tipo_inmueble": null,
                        "ciudad": null,
                        "estado": null,
                        "seguimientos": [
                            {
                                "id": 36,
                                "cliente": {
                                    "id": 62,
                                    "email": "",
                                    "franquicia": 97,
                                    "nombre": "Jóse Vidal",
                                    "status": 2,
                                    "created_at": "2024-06-07T13:49:59.131822-04:00",
                                    "autor": null,
                                    "editores": []
                                },
                                "fecha_seguimiento": "2024-06-07T13:49:59.148974-04:00",
                                "nota": "Se crea seguimiento para busqueda de cliente.",
                                "audio": null,
                                "busqueda": 27
                            }
                        ]
                    }
                ],
                "status": {
                    "id": 2,
                    "nombre": "En busqueda"
                },
                "nombre": "Jóse Vidal",
                "created_at": "2024-06-07T13:49:59.131822-04:00",
                "autor": null,
                "editores": []
            },
            {
                "id": 61,
                "email": "",
                "franquicia": {
                    "nombre": "Rosveli Bruzco Deluxe",
                    "descripcion": null,
                    "id": 97,
                    "tipo_franquicia": {
                        "id": 4,
                        "nombre": "Membresía",
                        "descripcion": "Membresía",
                        "slug_name": "membresia"
                    },
                    "usuario_asignado": {
                        "username": null,
                        "email": "rosveli.deluxe@gmail.com",
                        "profile": {
                            "id": 3,
                            "descripcion": "Gerente",
                            "nombre": "Gerente",
                            "codigo": "5625acf1-f0f6-4ce1-b206-c0ab0faf79ca",
                            "slug_name": "gerente",
                            "keys": []
                        },
                        "first_name": "Rosveli",
                        "last_name": "Bruzco",
                        "full_name": "Rosveli Bruzco",
                        "id": 75,
                        "first_image_url": "https://inmobapp-public-bucket.s3.us-east-2.amazonaws.com/static/media/default-user.jpg",
                        "contactos": []
                    },
                    "image": "https://inmobapp-public-bucket.s3.us-east-2.amazonaws.com/static/media/default-user.jpg",
                    "franquicia_comercial": 43,
                    "franquicia_personal": null,
                    "medios_contacto": [],
                    "solicitudes": {
                        "username": null,
                        "email": "rosveli.deluxe@gmail.com",
                        "profile": {
                            "id": 3,
                            "descripcion": "Gerente",
                            "nombre": "Gerente",
                            "codigo": "5625acf1-f0f6-4ce1-b206-c0ab0faf79ca",
                            "slug_name": "gerente",
                            "keys": []
                        },
                        "first_name": "Rosveli",
                        "last_name": "Bruzco",
                        "full_name": "Rosveli Bruzco",
                        "id": 75,
                        "first_image_url": "https://inmobapp-public-bucket.s3.us-east-2.amazonaws.com/static/media/default-user.jpg",
                        "contactos": []
                    },
                    "configuraciones": {}
                },
                "contactos_clientes": [
                    {
                        "id": 60,
                        "tipo": {
                            "id": 1,
                            "descripcion": "Whatsapp",
                            "nombre": "Whatsapp"
                        },
                        "valor": "+58 414 902 282"
                    }
                ],
                "visitas": [],
                "busquedas": [
                    {
                        "id": 26,
                        "total_seguimientos": 1,
                        "estatus": 1,
                        "descripcion": "Cliente interesado en Blue Loft",
                        "habitaciones": null,
                        "numero_banios": null,
                        "precio": "0.00",
                        "ptos_estacionamiento": null,
                        "created_at": "2024-06-07T13:48:16.841993-04:00",
                        "cliente": 61,
                        "tipo_inmueble": null,
                        "ciudad": null,
                        "estado": null,
                        "seguimientos": [
                            {
                                "id": 35,
                                "cliente": {
                                    "id": 61,
                                    "email": "",
                                    "franquicia": 97,
                                    "nombre": "Victor Herrera",
                                    "status": 2,
                                    "created_at": "2024-06-07T13:48:16.803149-04:00",
                                    "autor": null,
                                    "editores": []
                                },
                                "fecha_seguimiento": "2024-06-07T13:48:16.843328-04:00",
                                "nota": "Se crea seguimiento para busqueda de cliente.",
                                "audio": null,
                                "busqueda": 26
                            }
                        ]
                    }
                ],
                "status": {
                    "id": 2,
                    "nombre": "En busqueda"
                },
                "nombre": "Victor Herrera",
                "created_at": "2024-06-07T13:48:16.803149-04:00",
                "autor": null,
                "editores": []
            },
            {
                "id": 60,
                "email": "",
                "franquicia": {
                    "nombre": "Rosveli Bruzco Deluxe",
                    "descripcion": null,
                    "id": 97,
                    "tipo_franquicia": {
                        "id": 4,
                        "nombre": "Membresía",
                        "descripcion": "Membresía",
                        "slug_name": "membresia"
                    },
                    "usuario_asignado": {
                        "username": null,
                        "email": "rosveli.deluxe@gmail.com",
                        "profile": {
                            "id": 3,
                            "descripcion": "Gerente",
                            "nombre": "Gerente",
                            "codigo": "5625acf1-f0f6-4ce1-b206-c0ab0faf79ca",
                            "slug_name": "gerente",
                            "keys": []
                        },
                        "first_name": "Rosveli",
                        "last_name": "Bruzco",
                        "full_name": "Rosveli Bruzco",
                        "id": 75,
                        "first_image_url": "https://inmobapp-public-bucket.s3.us-east-2.amazonaws.com/static/media/default-user.jpg",
                        "contactos": []
                    },
                    "image": "https://inmobapp-public-bucket.s3.us-east-2.amazonaws.com/static/media/default-user.jpg",
                    "franquicia_comercial": 43,
                    "franquicia_personal": null,
                    "medios_contacto": [],
                    "solicitudes": {
                        "username": null,
                        "email": "rosveli.deluxe@gmail.com",
                        "profile": {
                            "id": 3,
                            "descripcion": "Gerente",
                            "nombre": "Gerente",
                            "codigo": "5625acf1-f0f6-4ce1-b206-c0ab0faf79ca",
                            "slug_name": "gerente",
                            "keys": []
                        },
                        "first_name": "Rosveli",
                        "last_name": "Bruzco",
                        "full_name": "Rosveli Bruzco",
                        "id": 75,
                        "first_image_url": "https://inmobapp-public-bucket.s3.us-east-2.amazonaws.com/static/media/default-user.jpg",
                        "contactos": []
                    },
                    "configuraciones": {}
                },
                "contactos_clientes": [
                    {
                        "id": 59,
                        "tipo": {
                            "id": 1,
                            "descripcion": "Whatsapp",
                            "nombre": "Whatsapp"
                        },
                        "valor": "+58 42 421 54378"
                    }
                ],
                "visitas": [],
                "busquedas": [
                    {
                        "id": 25,
                        "total_seguimientos": 1,
                        "estatus": 1,
                        "descripcion": "Cliente interesado en Blue Loft",
                        "habitaciones": null,
                        "numero_banios": null,
                        "precio": "0.00",
                        "ptos_estacionamiento": null,
                        "created_at": "2024-06-07T13:46:59.219872-04:00",
                        "cliente": 60,
                        "tipo_inmueble": null,
                        "ciudad": null,
                        "estado": null,
                        "seguimientos": [
                            {
                                "id": 34,
                                "cliente": {
                                    "id": 60,
                                    "email": "",
                                    "franquicia": 97,
                                    "nombre": "Fedra",
                                    "status": 2,
                                    "created_at": "2024-06-07T13:46:59.184449-04:00",
                                    "autor": null,
                                    "editores": []
                                },
                                "fecha_seguimiento": "2024-06-07T13:46:59.221326-04:00",
                                "nota": "Se crea seguimiento para busqueda de cliente.",
                                "audio": null,
                                "busqueda": 25
                            }
                        ]
                    }
                ],
                "status": {
                    "id": 2,
                    "nombre": "En busqueda"
                },
                "nombre": "Fedra",
                "created_at": "2024-06-07T13:46:59.184449-04:00",
                "autor": null,
                "editores": []
            },
            {
                "id": 59,
                "email": "",
                "franquicia": {
                    "nombre": "Rosveli Bruzco Deluxe",
                    "descripcion": null,
                    "id": 97,
                    "tipo_franquicia": {
                        "id": 4,
                        "nombre": "Membresía",
                        "descripcion": "Membresía",
                        "slug_name": "membresia"
                    },
                    "usuario_asignado": {
                        "username": null,
                        "email": "rosveli.deluxe@gmail.com",
                        "profile": {
                            "id": 3,
                            "descripcion": "Gerente",
                            "nombre": "Gerente",
                            "codigo": "5625acf1-f0f6-4ce1-b206-c0ab0faf79ca",
                            "slug_name": "gerente",
                            "keys": []
                        },
                        "first_name": "Rosveli",
                        "last_name": "Bruzco",
                        "full_name": "Rosveli Bruzco",
                        "id": 75,
                        "first_image_url": "https://inmobapp-public-bucket.s3.us-east-2.amazonaws.com/static/media/default-user.jpg",
                        "contactos": []
                    },
                    "image": "https://inmobapp-public-bucket.s3.us-east-2.amazonaws.com/static/media/default-user.jpg",
                    "franquicia_comercial": 43,
                    "franquicia_personal": null,
                    "medios_contacto": [],
                    "solicitudes": {
                        "username": null,
                        "email": "rosveli.deluxe@gmail.com",
                        "profile": {
                            "id": 3,
                            "descripcion": "Gerente",
                            "nombre": "Gerente",
                            "codigo": "5625acf1-f0f6-4ce1-b206-c0ab0faf79ca",
                            "slug_name": "gerente",
                            "keys": []
                        },
                        "first_name": "Rosveli",
                        "last_name": "Bruzco",
                        "full_name": "Rosveli Bruzco",
                        "id": 75,
                        "first_image_url": "https://inmobapp-public-bucket.s3.us-east-2.amazonaws.com/static/media/default-user.jpg",
                        "contactos": []
                    },
                    "configuraciones": {}
                },
                "contactos_clientes": [
                    {
                        "id": 58,
                        "tipo": {
                            "id": 1,
                            "descripcion": "Whatsapp",
                            "nombre": "Whatsapp"
                        },
                        "valor": "+58 414 297 43261"
                    }
                ],
                "visitas": [],
                "busquedas": [
                    {
                        "id": 24,
                        "total_seguimientos": 1,
                        "estatus": 1,
                        "descripcion": "Cliente interesado en Blue Loft",
                        "habitaciones": null,
                        "numero_banios": null,
                        "precio": "0.00",
                        "ptos_estacionamiento": null,
                        "created_at": "2024-06-07T13:44:17.566562-04:00",
                        "cliente": 59,
                        "tipo_inmueble": null,
                        "ciudad": null,
                        "estado": null,
                        "seguimientos": [
                            {
                                "id": 33,
                                "cliente": {
                                    "id": 59,
                                    "email": "",
                                    "franquicia": 97,
                                    "nombre": "Henry Manuel",
                                    "status": 2,
                                    "created_at": "2024-06-07T13:44:17.493338-04:00",
                                    "autor": null,
                                    "editores": []
                                },
                                "fecha_seguimiento": "2024-06-07T13:44:17.574051-04:00",
                                "nota": "Se crea seguimiento para busqueda de cliente.",
                                "audio": null,
                                "busqueda": 24
                            }
                        ]
                    }
                ],
                "status": {
                    "id": 2,
                    "nombre": "En busqueda"
                },
                "nombre": "Henry Manuel",
                "created_at": "2024-06-07T13:44:17.493338-04:00",
                "autor": null,
                "editores": []
            },
            {
                "id": 52,
                "email": "",
                "franquicia": {
                    "nombre": "Rosveli Bruzco Deluxe",
                    "descripcion": null,
                    "id": 97,
                    "tipo_franquicia": {
                        "id": 4,
                        "nombre": "Membresía",
                        "descripcion": "Membresía",
                        "slug_name": "membresia"
                    },
                    "usuario_asignado": {
                        "username": null,
                        "email": "rosveli.deluxe@gmail.com",
                        "profile": {
                            "id": 3,
                            "descripcion": "Gerente",
                            "nombre": "Gerente",
                            "codigo": "5625acf1-f0f6-4ce1-b206-c0ab0faf79ca",
                            "slug_name": "gerente",
                            "keys": []
                        },
                        "first_name": "Rosveli",
                        "last_name": "Bruzco",
                        "full_name": "Rosveli Bruzco",
                        "id": 75,
                        "first_image_url": "https://inmobapp-public-bucket.s3.us-east-2.amazonaws.com/static/media/default-user.jpg",
                        "contactos": []
                    },
                    "image": "https://inmobapp-public-bucket.s3.us-east-2.amazonaws.com/static/media/default-user.jpg",
                    "franquicia_comercial": 43,
                    "franquicia_personal": null,
                    "medios_contacto": [],
                    "solicitudes": {
                        "username": null,
                        "email": "rosveli.deluxe@gmail.com",
                        "profile": {
                            "id": 3,
                            "descripcion": "Gerente",
                            "nombre": "Gerente",
                            "codigo": "5625acf1-f0f6-4ce1-b206-c0ab0faf79ca",
                            "slug_name": "gerente",
                            "keys": []
                        },
                        "first_name": "Rosveli",
                        "last_name": "Bruzco",
                        "full_name": "Rosveli Bruzco",
                        "id": 75,
                        "first_image_url": "https://inmobapp-public-bucket.s3.us-east-2.amazonaws.com/static/media/default-user.jpg",
                        "contactos": []
                    },
                    "configuraciones": {}
                },
                "contactos_clientes": [
                    {
                        "id": 52,
                        "tipo": {
                            "id": 1,
                            "descripcion": "Whatsapp",
                            "nombre": "Whatsapp"
                        },
                        "valor": "+58 414 324 2699"
                    }
                ],
                "visitas": [],
                "busquedas": [
                    {
                        "id": 23,
                        "total_seguimientos": 1,
                        "estatus": 1,
                        "descripcion": "Inmueble similar al de La Florida de Ervi en 90.000$",
                        "habitaciones": null,
                        "numero_banios": null,
                        "precio": "0.00",
                        "ptos_estacionamiento": null,
                        "created_at": "2024-03-11T14:44:23.527328-04:00",
                        "cliente": 52,
                        "tipo_inmueble": null,
                        "ciudad": null,
                        "estado": null,
                        "seguimientos": [
                            {
                                "id": 32,
                                "cliente": {
                                    "id": 52,
                                    "email": "",
                                    "franquicia": 97,
                                    "nombre": "Jesus Morgado",
                                    "status": 2,
                                    "created_at": "2024-03-11T14:44:23.514500-04:00",
                                    "autor": null,
                                    "editores": []
                                },
                                "fecha_seguimiento": "2024-03-11T14:44:23.528460-04:00",
                                "nota": "Se crea seguimiento para busqueda de cliente.",
                                "audio": null,
                                "busqueda": 23
                            }
                        ]
                    }
                ],
                "status": {
                    "id": 2,
                    "nombre": "En busqueda"
                },
                "nombre": "Jesus Morgado",
                "created_at": "2024-03-11T14:44:23.514500-04:00",
                "autor": null,
                "editores": []
            },
            {
                "id": 51,
                "email": "",
                "franquicia": {
                    "nombre": "Rosveli Bruzco Deluxe",
                    "descripcion": null,
                    "id": 97,
                    "tipo_franquicia": {
                        "id": 4,
                        "nombre": "Membresía",
                        "descripcion": "Membresía",
                        "slug_name": "membresia"
                    },
                    "usuario_asignado": {
                        "username": null,
                        "email": "rosveli.deluxe@gmail.com",
                        "profile": {
                            "id": 3,
                            "descripcion": "Gerente",
                            "nombre": "Gerente",
                            "codigo": "5625acf1-f0f6-4ce1-b206-c0ab0faf79ca",
                            "slug_name": "gerente",
                            "keys": []
                        },
                        "first_name": "Rosveli",
                        "last_name": "Bruzco",
                        "full_name": "Rosveli Bruzco",
                        "id": 75,
                        "first_image_url": "https://inmobapp-public-bucket.s3.us-east-2.amazonaws.com/static/media/default-user.jpg",
                        "contactos": []
                    },
                    "image": "https://inmobapp-public-bucket.s3.us-east-2.amazonaws.com/static/media/default-user.jpg",
                    "franquicia_comercial": 43,
                    "franquicia_personal": null,
                    "medios_contacto": [],
                    "solicitudes": {
                        "username": null,
                        "email": "rosveli.deluxe@gmail.com",
                        "profile": {
                            "id": 3,
                            "descripcion": "Gerente",
                            "nombre": "Gerente",
                            "codigo": "5625acf1-f0f6-4ce1-b206-c0ab0faf79ca",
                            "slug_name": "gerente",
                            "keys": []
                        },
                        "first_name": "Rosveli",
                        "last_name": "Bruzco",
                        "full_name": "Rosveli Bruzco",
                        "id": 75,
                        "first_image_url": "https://inmobapp-public-bucket.s3.us-east-2.amazonaws.com/static/media/default-user.jpg",
                        "contactos": []
                    },
                    "configuraciones": {}
                },
                "contactos_clientes": [
                    {
                        "id": 51,
                        "tipo": {
                            "id": 1,
                            "descripcion": "Whatsapp",
                            "nombre": "Whatsapp"
                        },
                        "valor": "+58 416 604 5952"
                    }
                ],
                "visitas": [],
                "busquedas": [
                    {
                        "id": 22,
                        "total_seguimientos": 1,
                        "estatus": 1,
                        "descripcion": "Inmueble similar a La Florida de Ervi de 67.000$",
                        "habitaciones": null,
                        "numero_banios": null,
                        "precio": "0.00",
                        "ptos_estacionamiento": null,
                        "created_at": "2024-03-11T14:43:01.577020-04:00",
                        "cliente": 51,
                        "tipo_inmueble": null,
                        "ciudad": null,
                        "estado": null,
                        "seguimientos": [
                            {
                                "id": 31,
                                "cliente": {
                                    "id": 51,
                                    "email": "",
                                    "franquicia": 97,
                                    "nombre": "Salem Nasaneh",
                                    "status": 2,
                                    "created_at": "2024-03-11T14:43:01.544678-04:00",
                                    "autor": null,
                                    "editores": []
                                },
                                "fecha_seguimiento": "2024-03-11T14:43:01.578137-04:00",
                                "nota": "Se crea seguimiento para busqueda de cliente.",
                                "audio": null,
                                "busqueda": 22
                            }
                        ]
                    }
                ],
                "status": {
                    "id": 2,
                    "nombre": "En busqueda"
                },
                "nombre": "Salem Nasaneh",
                "created_at": "2024-03-11T14:43:01.544678-04:00",
                "autor": null,
                "editores": []
            },
            {
                "id": 50,
                "email": "",
                "franquicia": {
                    "nombre": "Rosveli Bruzco Deluxe",
                    "descripcion": null,
                    "id": 97,
                    "tipo_franquicia": {
                        "id": 4,
                        "nombre": "Membresía",
                        "descripcion": "Membresía",
                        "slug_name": "membresia"
                    },
                    "usuario_asignado": {
                        "username": null,
                        "email": "rosveli.deluxe@gmail.com",
                        "profile": {
                            "id": 3,
                            "descripcion": "Gerente",
                            "nombre": "Gerente",
                            "codigo": "5625acf1-f0f6-4ce1-b206-c0ab0faf79ca",
                            "slug_name": "gerente",
                            "keys": []
                        },
                        "first_name": "Rosveli",
                        "last_name": "Bruzco",
                        "full_name": "Rosveli Bruzco",
                        "id": 75,
                        "first_image_url": "https://inmobapp-public-bucket.s3.us-east-2.amazonaws.com/static/media/default-user.jpg",
                        "contactos": []
                    },
                    "image": "https://inmobapp-public-bucket.s3.us-east-2.amazonaws.com/static/media/default-user.jpg",
                    "franquicia_comercial": 43,
                    "franquicia_personal": null,
                    "medios_contacto": [],
                    "solicitudes": {
                        "username": null,
                        "email": "rosveli.deluxe@gmail.com",
                        "profile": {
                            "id": 3,
                            "descripcion": "Gerente",
                            "nombre": "Gerente",
                            "codigo": "5625acf1-f0f6-4ce1-b206-c0ab0faf79ca",
                            "slug_name": "gerente",
                            "keys": []
                        },
                        "first_name": "Rosveli",
                        "last_name": "Bruzco",
                        "full_name": "Rosveli Bruzco",
                        "id": 75,
                        "first_image_url": "https://inmobapp-public-bucket.s3.us-east-2.amazonaws.com/static/media/default-user.jpg",
                        "contactos": []
                    },
                    "configuraciones": {}
                },
                "contactos_clientes": [
                    {
                        "id": 50,
                        "tipo": {
                            "id": 1,
                            "descripcion": "Whatsapp",
                            "nombre": "Whatsapp"
                        },
                        "valor": "+58 416 960 4709"
                    }
                ],
                "visitas": [],
                "busquedas": [
                    {
                        "id": 21,
                        "total_seguimientos": 1,
                        "estatus": 1,
                        "descripcion": "Alquiler en Chacao, Santa Eduvigis y Sebucan. Hasta 700$ de canon, perfil jurídico",
                        "habitaciones": null,
                        "numero_banios": null,
                        "precio": "0.00",
                        "ptos_estacionamiento": null,
                        "created_at": "2024-03-11T14:39:55.156436-04:00",
                        "cliente": 50,
                        "tipo_inmueble": null,
                        "ciudad": null,
                        "estado": null,
                        "seguimientos": [
                            {
                                "id": 30,
                                "cliente": {
                                    "id": 50,
                                    "email": "",
                                    "franquicia": 97,
                                    "nombre": "Juan Taylor",
                                    "status": 2,
                                    "created_at": "2024-03-11T14:39:55.106707-04:00",
                                    "autor": null,
                                    "editores": []
                                },
                                "fecha_seguimiento": "2024-03-11T14:39:55.157597-04:00",
                                "nota": "Se crea seguimiento para busqueda de cliente.",
                                "audio": null,
                                "busqueda": 21
                            }
                        ]
                    }
                ],
                "status": {
                    "id": 2,
                    "nombre": "En busqueda"
                },
                "nombre": "Juan Taylor",
                "created_at": "2024-03-11T14:39:55.106707-04:00",
                "autor": null,
                "editores": []
            },
            {
                "id": 49,
                "email": "",
                "franquicia": {
                    "nombre": "Barbara Deluxe",
                    "descripcion": null,
                    "id": 44,
                    "tipo_franquicia": {
                        "id": 4,
                        "nombre": "Membresía",
                        "descripcion": "Membresía",
                        "slug_name": "membresia"
                    },
                    "usuario_asignado": {
                        "username": null,
                        "email": "enlacedeluxebn@gmail.com",
                        "profile": {
                            "id": 3,
                            "descripcion": "Gerente",
                            "nombre": "Gerente",
                            "codigo": "5625acf1-f0f6-4ce1-b206-c0ab0faf79ca",
                            "slug_name": "gerente",
                            "keys": []
                        },
                        "first_name": "Barbara",
                        "last_name": "Novoa",
                        "full_name": "Barbara Novoa",
                        "id": 31,
                        "first_image_url": "https://inmobapp-public-bucket.s3.us-east-2.amazonaws.com/static/media/default-user.jpg",
                        "contactos": []
                    },
                    "image": "https://inmobapp-public-bucket.s3.amazonaws.com/media/franquicia_image/WhatsApp_Image_2024-07-09_at_12.20.13.jpeg",
                    "franquicia_comercial": 43,
                    "franquicia_personal": null,
                    "medios_contacto": [],
                    "solicitudes": {
                        "username": null,
                        "email": "enlacedeluxebn@gmail.com",
                        "profile": {
                            "id": 3,
                            "descripcion": "Gerente",
                            "nombre": "Gerente",
                            "codigo": "5625acf1-f0f6-4ce1-b206-c0ab0faf79ca",
                            "slug_name": "gerente",
                            "keys": []
                        },
                        "first_name": "Barbara",
                        "last_name": "Novoa",
                        "full_name": "Barbara Novoa",
                        "id": 31,
                        "first_image_url": "https://inmobapp-public-bucket.s3.us-east-2.amazonaws.com/static/media/default-user.jpg",
                        "contactos": []
                    },
                    "configuraciones": {
                        "contact_email": "maria@mail.com"
                    }
                },
                "contactos_clientes": [
                    {
                        "id": 49,
                        "tipo": {
                            "id": 1,
                            "descripcion": "Whatsapp",
                            "nombre": "Whatsapp"
                        },
                        "valor": "+58 441 435 63118"
                    }
                ],
                "visitas": [],
                "busquedas": [],
                "status": {
                    "id": 2,
                    "nombre": "En busqueda"
                },
                "nombre": "Eleazar",
                "created_at": "2024-03-08T18:12:47.010565-04:00",
                "autor": null,
                "editores": []
            },
            {
                "id": 48,
                "email": "",
                "franquicia": {
                    "nombre": "Barbara Deluxe",
                    "descripcion": null,
                    "id": 44,
                    "tipo_franquicia": {
                        "id": 4,
                        "nombre": "Membresía",
                        "descripcion": "Membresía",
                        "slug_name": "membresia"
                    },
                    "usuario_asignado": {
                        "username": null,
                        "email": "enlacedeluxebn@gmail.com",
                        "profile": {
                            "id": 3,
                            "descripcion": "Gerente",
                            "nombre": "Gerente",
                            "codigo": "5625acf1-f0f6-4ce1-b206-c0ab0faf79ca",
                            "slug_name": "gerente",
                            "keys": []
                        },
                        "first_name": "Barbara",
                        "last_name": "Novoa",
                        "full_name": "Barbara Novoa",
                        "id": 31,
                        "first_image_url": "https://inmobapp-public-bucket.s3.us-east-2.amazonaws.com/static/media/default-user.jpg",
                        "contactos": []
                    },
                    "image": "https://inmobapp-public-bucket.s3.amazonaws.com/media/franquicia_image/WhatsApp_Image_2024-07-09_at_12.20.13.jpeg",
                    "franquicia_comercial": 43,
                    "franquicia_personal": null,
                    "medios_contacto": [],
                    "solicitudes": {
                        "username": null,
                        "email": "enlacedeluxebn@gmail.com",
                        "profile": {
                            "id": 3,
                            "descripcion": "Gerente",
                            "nombre": "Gerente",
                            "codigo": "5625acf1-f0f6-4ce1-b206-c0ab0faf79ca",
                            "slug_name": "gerente",
                            "keys": []
                        },
                        "first_name": "Barbara",
                        "last_name": "Novoa",
                        "full_name": "Barbara Novoa",
                        "id": 31,
                        "first_image_url": "https://inmobapp-public-bucket.s3.us-east-2.amazonaws.com/static/media/default-user.jpg",
                        "contactos": []
                    },
                    "configuraciones": {
                        "contact_email": "maria@mail.com"
                    }
                },
                "contactos_clientes": [
                    {
                        "id": 48,
                        "tipo": {
                            "id": 1,
                            "descripcion": "Whatsapp",
                            "nombre": "Whatsapp"
                        },
                        "valor": "+58 426-5111396"
                    }
                ],
                "visitas": [
                    {
                        "id": 8,
                        "rating": null,
                        "propiedad": null,
                        "asesor": {
                            "id": 31,
                            "username": null,
                            "email": "enlacedeluxebn@gmail.com",
                            "first_name": "Barbara",
                            "last_name": "Novoa",
                            "profile": {
                                "id": 3,
                                "keys": [],
                                "descripcion": "Gerente",
                                "nombre": "Gerente",
                                "codigo": "5625acf1-f0f6-4ce1-b206-c0ab0faf79ca",
                                "slug_name": "gerente"
                            },
                            "first_image_url": "https://inmobapp-public-bucket.s3.us-east-2.amazonaws.com/static/media/home-default.jpg",
                            "franquicia": {
                                "nombre": "Barbara Deluxe",
                                "descripcion": null,
                                "id": 44,
                                "tipo_franquicia": {
                                    "id": 4,
                                    "nombre": "Membresía",
                                    "descripcion": "Membresía",
                                    "slug_name": "membresia"
                                },
                                "usuario_asignado": {
                                    "username": null,
                                    "email": "enlacedeluxebn@gmail.com",
                                    "profile": {
                                        "id": 3,
                                        "descripcion": "Gerente",
                                        "nombre": "Gerente",
                                        "codigo": "5625acf1-f0f6-4ce1-b206-c0ab0faf79ca",
                                        "slug_name": "gerente",
                                        "keys": []
                                    },
                                    "first_name": "Barbara",
                                    "last_name": "Novoa",
                                    "full_name": "Barbara Novoa",
                                    "id": 31,
                                    "first_image_url": "https://inmobapp-public-bucket.s3.us-east-2.amazonaws.com/static/media/default-user.jpg",
                                    "contactos": []
                                },
                                "image": "https://inmobapp-public-bucket.s3.amazonaws.com/media/franquicia_image/WhatsApp_Image_2024-07-09_at_12.20.13.jpeg",
                                "franquicia_comercial": 43,
                                "franquicia_personal": null,
                                "medios_contacto": [],
                                "solicitudes": {
                                    "username": null,
                                    "email": "enlacedeluxebn@gmail.com",
                                    "profile": {
                                        "id": 3,
                                        "descripcion": "Gerente",
                                        "nombre": "Gerente",
                                        "codigo": "5625acf1-f0f6-4ce1-b206-c0ab0faf79ca",
                                        "slug_name": "gerente",
                                        "keys": []
                                    },
                                    "first_name": "Barbara",
                                    "last_name": "Novoa",
                                    "full_name": "Barbara Novoa",
                                    "id": 31,
                                    "first_image_url": "https://inmobapp-public-bucket.s3.us-east-2.amazonaws.com/static/media/default-user.jpg",
                                    "contactos": []
                                },
                                "configuraciones": {
                                    "contact_email": "maria@mail.com"
                                }
                            },
                            "reset_password": false
                        },
                        "estatus": 1,
                        "titulo": "Visita al cafetal",
                        "start": "2024-01-23T18:00:00-04:00",
                        "end": null,
                        "tipo": 1,
                        "descripcion": "Cafetal remodelado 68",
                        "ubicacion": "San Luis cafetal",
                        "created_at": "2024-01-23T19:24:41.656870-04:00",
                        "link": "https://info.wasi.co/apartamento-venta-el-cafetal-caracas-baruta/7102037?shared=whatsapp",
                        "cliente": 48
                    },
                    {
                        "id": 10,
                        "rating": null,
                        "propiedad": null,
                        "asesor": {
                            "id": 31,
                            "username": null,
                            "email": "enlacedeluxebn@gmail.com",
                            "first_name": "Barbara",
                            "last_name": "Novoa",
                            "profile": {
                                "id": 3,
                                "keys": [],
                                "descripcion": "Gerente",
                                "nombre": "Gerente",
                                "codigo": "5625acf1-f0f6-4ce1-b206-c0ab0faf79ca",
                                "slug_name": "gerente"
                            },
                            "first_image_url": "https://inmobapp-public-bucket.s3.us-east-2.amazonaws.com/static/media/home-default.jpg",
                            "franquicia": {
                                "nombre": "Barbara Deluxe",
                                "descripcion": null,
                                "id": 44,
                                "tipo_franquicia": {
                                    "id": 4,
                                    "nombre": "Membresía",
                                    "descripcion": "Membresía",
                                    "slug_name": "membresia"
                                },
                                "usuario_asignado": {
                                    "username": null,
                                    "email": "enlacedeluxebn@gmail.com",
                                    "profile": {
                                        "id": 3,
                                        "descripcion": "Gerente",
                                        "nombre": "Gerente",
                                        "codigo": "5625acf1-f0f6-4ce1-b206-c0ab0faf79ca",
                                        "slug_name": "gerente",
                                        "keys": []
                                    },
                                    "first_name": "Barbara",
                                    "last_name": "Novoa",
                                    "full_name": "Barbara Novoa",
                                    "id": 31,
                                    "first_image_url": "https://inmobapp-public-bucket.s3.us-east-2.amazonaws.com/static/media/default-user.jpg",
                                    "contactos": []
                                },
                                "image": "https://inmobapp-public-bucket.s3.amazonaws.com/media/franquicia_image/WhatsApp_Image_2024-07-09_at_12.20.13.jpeg",
                                "franquicia_comercial": 43,
                                "franquicia_personal": null,
                                "medios_contacto": [],
                                "solicitudes": {
                                    "username": null,
                                    "email": "enlacedeluxebn@gmail.com",
                                    "profile": {
                                        "id": 3,
                                        "descripcion": "Gerente",
                                        "nombre": "Gerente",
                                        "codigo": "5625acf1-f0f6-4ce1-b206-c0ab0faf79ca",
                                        "slug_name": "gerente",
                                        "keys": []
                                    },
                                    "first_name": "Barbara",
                                    "last_name": "Novoa",
                                    "full_name": "Barbara Novoa",
                                    "id": 31,
                                    "first_image_url": "https://inmobapp-public-bucket.s3.us-east-2.amazonaws.com/static/media/default-user.jpg",
                                    "contactos": []
                                },
                                "configuraciones": {
                                    "contact_email": "maria@mail.com"
                                }
                            },
                            "reset_password": false
                        },
                        "estatus": 1,
                        "titulo": "Visita a El Encantado",
                        "start": "2024-03-09T17:00:00-04:00",
                        "end": null,
                        "tipo": 1,
                        "descripcion": "Primera visita",
                        "ubicacion": "El Encantado",
                        "created_at": "2024-03-09T15:00:58.069715-04:00",
                        "link": "https://mlscaracas.com/apartamento-venta-los-chaguaramos-caracas-libertador/6918952",
                        "cliente": 48
                    }
                ],
                "busquedas": [],
                "status": {
                    "id": 2,
                    "nombre": "En busqueda"
                },
                "nombre": "Jose Flores",
                "created_at": "2024-01-23T19:22:27.890155-04:00",
                "autor": null,
                "editores": []
            },
            {
                "id": 41,
                "email": "",
                "franquicia": {
                    "nombre": "Barbara Deluxe",
                    "descripcion": null,
                    "id": 44,
                    "tipo_franquicia": {
                        "id": 4,
                        "nombre": "Membresía",
                        "descripcion": "Membresía",
                        "slug_name": "membresia"
                    },
                    "usuario_asignado": {
                        "username": null,
                        "email": "enlacedeluxebn@gmail.com",
                        "profile": {
                            "id": 3,
                            "descripcion": "Gerente",
                            "nombre": "Gerente",
                            "codigo": "5625acf1-f0f6-4ce1-b206-c0ab0faf79ca",
                            "slug_name": "gerente",
                            "keys": []
                        },
                        "first_name": "Barbara",
                        "last_name": "Novoa",
                        "full_name": "Barbara Novoa",
                        "id": 31,
                        "first_image_url": "https://inmobapp-public-bucket.s3.us-east-2.amazonaws.com/static/media/default-user.jpg",
                        "contactos": []
                    },
                    "image": "https://inmobapp-public-bucket.s3.amazonaws.com/media/franquicia_image/WhatsApp_Image_2024-07-09_at_12.20.13.jpeg",
                    "franquicia_comercial": 43,
                    "franquicia_personal": null,
                    "medios_contacto": [],
                    "solicitudes": {
                        "username": null,
                        "email": "enlacedeluxebn@gmail.com",
                        "profile": {
                            "id": 3,
                            "descripcion": "Gerente",
                            "nombre": "Gerente",
                            "codigo": "5625acf1-f0f6-4ce1-b206-c0ab0faf79ca",
                            "slug_name": "gerente",
                            "keys": []
                        },
                        "first_name": "Barbara",
                        "last_name": "Novoa",
                        "full_name": "Barbara Novoa",
                        "id": 31,
                        "first_image_url": "https://inmobapp-public-bucket.s3.us-east-2.amazonaws.com/static/media/default-user.jpg",
                        "contactos": []
                    },
                    "configuraciones": {
                        "contact_email": "maria@mail.com"
                    }
                },
                "contactos_clientes": [
                    {
                        "id": 41,
                        "tipo": {
                            "id": 1,
                            "descripcion": "Whatsapp",
                            "nombre": "Whatsapp"
                        },
                        "valor": "+58 424 212 2446"
                    }
                ],
                "visitas": [
                    {
                        "id": 5,
                        "rating": 1,
                        "propiedad": null,
                        "asesor": {
                            "id": 31,
                            "username": null,
                            "email": "enlacedeluxebn@gmail.com",
                            "first_name": "Barbara",
                            "last_name": "Novoa",
                            "profile": {
                                "id": 3,
                                "keys": [],
                                "descripcion": "Gerente",
                                "nombre": "Gerente",
                                "codigo": "5625acf1-f0f6-4ce1-b206-c0ab0faf79ca",
                                "slug_name": "gerente"
                            },
                            "first_image_url": "https://inmobapp-public-bucket.s3.us-east-2.amazonaws.com/static/media/home-default.jpg",
                            "franquicia": {
                                "nombre": "Barbara Deluxe",
                                "descripcion": null,
                                "id": 44,
                                "tipo_franquicia": {
                                    "id": 4,
                                    "nombre": "Membresía",
                                    "descripcion": "Membresía",
                                    "slug_name": "membresia"
                                },
                                "usuario_asignado": {
                                    "username": null,
                                    "email": "enlacedeluxebn@gmail.com",
                                    "profile": {
                                        "id": 3,
                                        "descripcion": "Gerente",
                                        "nombre": "Gerente",
                                        "codigo": "5625acf1-f0f6-4ce1-b206-c0ab0faf79ca",
                                        "slug_name": "gerente",
                                        "keys": []
                                    },
                                    "first_name": "Barbara",
                                    "last_name": "Novoa",
                                    "full_name": "Barbara Novoa",
                                    "id": 31,
                                    "first_image_url": "https://inmobapp-public-bucket.s3.us-east-2.amazonaws.com/static/media/default-user.jpg",
                                    "contactos": []
                                },
                                "image": "https://inmobapp-public-bucket.s3.amazonaws.com/media/franquicia_image/WhatsApp_Image_2024-07-09_at_12.20.13.jpeg",
                                "franquicia_comercial": 43,
                                "franquicia_personal": null,
                                "medios_contacto": [],
                                "solicitudes": {
                                    "username": null,
                                    "email": "enlacedeluxebn@gmail.com",
                                    "profile": {
                                        "id": 3,
                                        "descripcion": "Gerente",
                                        "nombre": "Gerente",
                                        "codigo": "5625acf1-f0f6-4ce1-b206-c0ab0faf79ca",
                                        "slug_name": "gerente",
                                        "keys": []
                                    },
                                    "first_name": "Barbara",
                                    "last_name": "Novoa",
                                    "full_name": "Barbara Novoa",
                                    "id": 31,
                                    "first_image_url": "https://inmobapp-public-bucket.s3.us-east-2.amazonaws.com/static/media/default-user.jpg",
                                    "contactos": []
                                },
                                "configuraciones": {
                                    "contact_email": "maria@mail.com"
                                }
                            },
                            "reset_password": false
                        },
                        "estatus": 1,
                        "titulo": "El cafetal",
                        "start": "2024-01-17T17:00:00-04:00",
                        "end": null,
                        "tipo": 1,
                        "descripcion": null,
                        "ubicacion": "Cafetal",
                        "created_at": "2024-01-17T22:27:21.687525-04:00",
                        "link": null,
                        "cliente": 41
                    },
                    {
                        "id": 6,
                        "rating": null,
                        "propiedad": null,
                        "asesor": {
                            "id": 31,
                            "username": null,
                            "email": "enlacedeluxebn@gmail.com",
                            "first_name": "Barbara",
                            "last_name": "Novoa",
                            "profile": {
                                "id": 3,
                                "keys": [],
                                "descripcion": "Gerente",
                                "nombre": "Gerente",
                                "codigo": "5625acf1-f0f6-4ce1-b206-c0ab0faf79ca",
                                "slug_name": "gerente"
                            },
                            "first_image_url": "https://inmobapp-public-bucket.s3.us-east-2.amazonaws.com/static/media/home-default.jpg",
                            "franquicia": {
                                "nombre": "Barbara Deluxe",
                                "descripcion": null,
                                "id": 44,
                                "tipo_franquicia": {
                                    "id": 4,
                                    "nombre": "Membresía",
                                    "descripcion": "Membresía",
                                    "slug_name": "membresia"
                                },
                                "usuario_asignado": {
                                    "username": null,
                                    "email": "enlacedeluxebn@gmail.com",
                                    "profile": {
                                        "id": 3,
                                        "descripcion": "Gerente",
                                        "nombre": "Gerente",
                                        "codigo": "5625acf1-f0f6-4ce1-b206-c0ab0faf79ca",
                                        "slug_name": "gerente",
                                        "keys": []
                                    },
                                    "first_name": "Barbara",
                                    "last_name": "Novoa",
                                    "full_name": "Barbara Novoa",
                                    "id": 31,
                                    "first_image_url": "https://inmobapp-public-bucket.s3.us-east-2.amazonaws.com/static/media/default-user.jpg",
                                    "contactos": []
                                },
                                "image": "https://inmobapp-public-bucket.s3.amazonaws.com/media/franquicia_image/WhatsApp_Image_2024-07-09_at_12.20.13.jpeg",
                                "franquicia_comercial": 43,
                                "franquicia_personal": null,
                                "medios_contacto": [],
                                "solicitudes": {
                                    "username": null,
                                    "email": "enlacedeluxebn@gmail.com",
                                    "profile": {
                                        "id": 3,
                                        "descripcion": "Gerente",
                                        "nombre": "Gerente",
                                        "codigo": "5625acf1-f0f6-4ce1-b206-c0ab0faf79ca",
                                        "slug_name": "gerente",
                                        "keys": []
                                    },
                                    "first_name": "Barbara",
                                    "last_name": "Novoa",
                                    "full_name": "Barbara Novoa",
                                    "id": 31,
                                    "first_image_url": "https://inmobapp-public-bucket.s3.us-east-2.amazonaws.com/static/media/default-user.jpg",
                                    "contactos": []
                                },
                                "configuraciones": {
                                    "contact_email": "maria@mail.com"
                                }
                            },
                            "reset_password": false
                        },
                        "estatus": 1,
                        "titulo": "Miranda",
                        "start": "2024-01-20T21:30:00-04:00",
                        "end": null,
                        "tipo": 1,
                        "descripcion": null,
                        "ubicacion": null,
                        "created_at": "2024-01-20T18:32:47.041703-04:00",
                        "link": null,
                        "cliente": 41
                    },
                    {
                        "id": 9,
                        "rating": 3,
                        "propiedad": null,
                        "asesor": {
                            "id": 31,
                            "username": null,
                            "email": "enlacedeluxebn@gmail.com",
                            "first_name": "Barbara",
                            "last_name": "Novoa",
                            "profile": {
                                "id": 3,
                                "keys": [],
                                "descripcion": "Gerente",
                                "nombre": "Gerente",
                                "codigo": "5625acf1-f0f6-4ce1-b206-c0ab0faf79ca",
                                "slug_name": "gerente"
                            },
                            "first_image_url": "https://inmobapp-public-bucket.s3.us-east-2.amazonaws.com/static/media/home-default.jpg",
                            "franquicia": {
                                "nombre": "Barbara Deluxe",
                                "descripcion": null,
                                "id": 44,
                                "tipo_franquicia": {
                                    "id": 4,
                                    "nombre": "Membresía",
                                    "descripcion": "Membresía",
                                    "slug_name": "membresia"
                                },
                                "usuario_asignado": {
                                    "username": null,
                                    "email": "enlacedeluxebn@gmail.com",
                                    "profile": {
                                        "id": 3,
                                        "descripcion": "Gerente",
                                        "nombre": "Gerente",
                                        "codigo": "5625acf1-f0f6-4ce1-b206-c0ab0faf79ca",
                                        "slug_name": "gerente",
                                        "keys": []
                                    },
                                    "first_name": "Barbara",
                                    "last_name": "Novoa",
                                    "full_name": "Barbara Novoa",
                                    "id": 31,
                                    "first_image_url": "https://inmobapp-public-bucket.s3.us-east-2.amazonaws.com/static/media/default-user.jpg",
                                    "contactos": []
                                },
                                "image": "https://inmobapp-public-bucket.s3.amazonaws.com/media/franquicia_image/WhatsApp_Image_2024-07-09_at_12.20.13.jpeg",
                                "franquicia_comercial": 43,
                                "franquicia_personal": null,
                                "medios_contacto": [],
                                "solicitudes": {
                                    "username": null,
                                    "email": "enlacedeluxebn@gmail.com",
                                    "profile": {
                                        "id": 3,
                                        "descripcion": "Gerente",
                                        "nombre": "Gerente",
                                        "codigo": "5625acf1-f0f6-4ce1-b206-c0ab0faf79ca",
                                        "slug_name": "gerente",
                                        "keys": []
                                    },
                                    "first_name": "Barbara",
                                    "last_name": "Novoa",
                                    "full_name": "Barbara Novoa",
                                    "id": 31,
                                    "first_image_url": "https://inmobapp-public-bucket.s3.us-east-2.amazonaws.com/static/media/default-user.jpg",
                                    "contactos": []
                                },
                                "configuraciones": {
                                    "contact_email": "maria@mail.com"
                                }
                            },
                            "reset_password": false
                        },
                        "estatus": 1,
                        "titulo": "Visita con yomara",
                        "start": "2024-02-14T23:30:00-04:00",
                        "end": null,
                        "tipo": 1,
                        "descripcion": "Agradable visita, mostró interes sin embargo quiere continuar evaluando opciones",
                        "ubicacion": "Sta paula",
                        "created_at": "2024-02-14T15:31:53.043424-04:00",
                        "link": "https://info.wasi.co/apartamento-venta-santa-paula-caracas-baruta/7093208?shared=whatsapp",
                        "cliente": 41
                    }
                ],
                "busquedas": [],
                "status": {
                    "id": 2,
                    "nombre": "En busqueda"
                },
                "nombre": "yomara",
                "created_at": "2024-01-16T20:58:02.768669-04:00",
                "autor": 31,
                "editores": []
            },
            {
                "id": 39,
                "email": "",
                "franquicia": {
                    "nombre": "Barbara Deluxe",
                    "descripcion": null,
                    "id": 44,
                    "tipo_franquicia": {
                        "id": 4,
                        "nombre": "Membresía",
                        "descripcion": "Membresía",
                        "slug_name": "membresia"
                    },
                    "usuario_asignado": {
                        "username": null,
                        "email": "enlacedeluxebn@gmail.com",
                        "profile": {
                            "id": 3,
                            "descripcion": "Gerente",
                            "nombre": "Gerente",
                            "codigo": "5625acf1-f0f6-4ce1-b206-c0ab0faf79ca",
                            "slug_name": "gerente",
                            "keys": []
                        },
                        "first_name": "Barbara",
                        "last_name": "Novoa",
                        "full_name": "Barbara Novoa",
                        "id": 31,
                        "first_image_url": "https://inmobapp-public-bucket.s3.us-east-2.amazonaws.com/static/media/default-user.jpg",
                        "contactos": []
                    },
                    "image": "https://inmobapp-public-bucket.s3.amazonaws.com/media/franquicia_image/WhatsApp_Image_2024-07-09_at_12.20.13.jpeg",
                    "franquicia_comercial": 43,
                    "franquicia_personal": null,
                    "medios_contacto": [],
                    "solicitudes": {
                        "username": null,
                        "email": "enlacedeluxebn@gmail.com",
                        "profile": {
                            "id": 3,
                            "descripcion": "Gerente",
                            "nombre": "Gerente",
                            "codigo": "5625acf1-f0f6-4ce1-b206-c0ab0faf79ca",
                            "slug_name": "gerente",
                            "keys": []
                        },
                        "first_name": "Barbara",
                        "last_name": "Novoa",
                        "full_name": "Barbara Novoa",
                        "id": 31,
                        "first_image_url": "https://inmobapp-public-bucket.s3.us-east-2.amazonaws.com/static/media/default-user.jpg",
                        "contactos": []
                    },
                    "configuraciones": {
                        "contact_email": "maria@mail.com"
                    }
                },
                "contactos_clientes": [
                    {
                        "id": 39,
                        "tipo": {
                            "id": 1,
                            "descripcion": "Whatsapp",
                            "nombre": "Whatsapp"
                        },
                        "valor": "+58 416 638 5754"
                    }
                ],
                "visitas": [],
                "busquedas": [],
                "status": {
                    "id": 2,
                    "nombre": "En busqueda"
                },
                "nombre": "miguel ferrara",
                "created_at": "2024-01-16T20:55:00.469069-04:00",
                "autor": 31,
                "editores": []
            }
        ]
    },
    {
        "id": 3,
        "title": "Con Ruta",
        "count": 3,
        "child": [
            {
                "id": 40,
                "email": "",
                "franquicia": {
                    "nombre": "Barbara Deluxe",
                    "descripcion": null,
                    "id": 44,
                    "tipo_franquicia": {
                        "id": 4,
                        "nombre": "Membresía",
                        "descripcion": "Membresía",
                        "slug_name": "membresia"
                    },
                    "usuario_asignado": {
                        "username": null,
                        "email": "enlacedeluxebn@gmail.com",
                        "profile": {
                            "id": 3,
                            "descripcion": "Gerente",
                            "nombre": "Gerente",
                            "codigo": "5625acf1-f0f6-4ce1-b206-c0ab0faf79ca",
                            "slug_name": "gerente",
                            "keys": []
                        },
                        "first_name": "Barbara",
                        "last_name": "Novoa",
                        "full_name": "Barbara Novoa",
                        "id": 31,
                        "first_image_url": "https://inmobapp-public-bucket.s3.us-east-2.amazonaws.com/static/media/default-user.jpg",
                        "contactos": []
                    },
                    "image": "https://inmobapp-public-bucket.s3.amazonaws.com/media/franquicia_image/WhatsApp_Image_2024-07-09_at_12.20.13.jpeg",
                    "franquicia_comercial": 43,
                    "franquicia_personal": null,
                    "medios_contacto": [],
                    "solicitudes": {
                        "username": null,
                        "email": "enlacedeluxebn@gmail.com",
                        "profile": {
                            "id": 3,
                            "descripcion": "Gerente",
                            "nombre": "Gerente",
                            "codigo": "5625acf1-f0f6-4ce1-b206-c0ab0faf79ca",
                            "slug_name": "gerente",
                            "keys": []
                        },
                        "first_name": "Barbara",
                        "last_name": "Novoa",
                        "full_name": "Barbara Novoa",
                        "id": 31,
                        "first_image_url": "https://inmobapp-public-bucket.s3.us-east-2.amazonaws.com/static/media/default-user.jpg",
                        "contactos": []
                    },
                    "configuraciones": {
                        "contact_email": "maria@mail.com"
                    }
                },
                "contactos_clientes": [
                    {
                        "id": 40,
                        "tipo": {
                            "id": 1,
                            "descripcion": "Whatsapp",
                            "nombre": "Whatsapp"
                        },
                        "valor": "+58 424 249 7648"
                    }
                ],
                "visitas": [
                    {
                        "id": 4,
                        "rating": 1,
                        "propiedad": null,
                        "asesor": {
                            "id": 31,
                            "username": null,
                            "email": "enlacedeluxebn@gmail.com",
                            "first_name": "Barbara",
                            "last_name": "Novoa",
                            "profile": {
                                "id": 3,
                                "keys": [],
                                "descripcion": "Gerente",
                                "nombre": "Gerente",
                                "codigo": "5625acf1-f0f6-4ce1-b206-c0ab0faf79ca",
                                "slug_name": "gerente"
                            },
                            "first_image_url": "https://inmobapp-public-bucket.s3.us-east-2.amazonaws.com/static/media/home-default.jpg",
                            "franquicia": {
                                "nombre": "Barbara Deluxe",
                                "descripcion": null,
                                "id": 44,
                                "tipo_franquicia": {
                                    "id": 4,
                                    "nombre": "Membresía",
                                    "descripcion": "Membresía",
                                    "slug_name": "membresia"
                                },
                                "usuario_asignado": {
                                    "username": null,
                                    "email": "enlacedeluxebn@gmail.com",
                                    "profile": {
                                        "id": 3,
                                        "descripcion": "Gerente",
                                        "nombre": "Gerente",
                                        "codigo": "5625acf1-f0f6-4ce1-b206-c0ab0faf79ca",
                                        "slug_name": "gerente",
                                        "keys": []
                                    },
                                    "first_name": "Barbara",
                                    "last_name": "Novoa",
                                    "full_name": "Barbara Novoa",
                                    "id": 31,
                                    "first_image_url": "https://inmobapp-public-bucket.s3.us-east-2.amazonaws.com/static/media/default-user.jpg",
                                    "contactos": []
                                },
                                "image": "https://inmobapp-public-bucket.s3.amazonaws.com/media/franquicia_image/WhatsApp_Image_2024-07-09_at_12.20.13.jpeg",
                                "franquicia_comercial": 43,
                                "franquicia_personal": null,
                                "medios_contacto": [],
                                "solicitudes": {
                                    "username": null,
                                    "email": "enlacedeluxebn@gmail.com",
                                    "profile": {
                                        "id": 3,
                                        "descripcion": "Gerente",
                                        "nombre": "Gerente",
                                        "codigo": "5625acf1-f0f6-4ce1-b206-c0ab0faf79ca",
                                        "slug_name": "gerente",
                                        "keys": []
                                    },
                                    "first_name": "Barbara",
                                    "last_name": "Novoa",
                                    "full_name": "Barbara Novoa",
                                    "id": 31,
                                    "first_image_url": "https://inmobapp-public-bucket.s3.us-east-2.amazonaws.com/static/media/default-user.jpg",
                                    "contactos": []
                                },
                                "configuraciones": {
                                    "contact_email": "maria@mail.com"
                                }
                            },
                            "reset_password": false
                        },
                        "estatus": 1,
                        "titulo": "Cafetal",
                        "start": "2024-01-17T17:30:00-04:00",
                        "end": null,
                        "tipo": 1,
                        "descripcion": null,
                        "ubicacion": "El cafetal",
                        "created_at": "2024-01-17T22:25:08.746369-04:00",
                        "link": null,
                        "cliente": 40
                    }
                ],
                "busquedas": [],
                "status": {
                    "id": 3,
                    "nombre": "Con Ruta"
                },
                "nombre": "michael da silva",
                "created_at": "2024-01-16T20:56:29.032532-04:00",
                "autor": 31,
                "editores": []
            },
            {
                "id": 37,
                "email": "",
                "franquicia": {
                    "nombre": "Barbara Deluxe",
                    "descripcion": null,
                    "id": 44,
                    "tipo_franquicia": {
                        "id": 4,
                        "nombre": "Membresía",
                        "descripcion": "Membresía",
                        "slug_name": "membresia"
                    },
                    "usuario_asignado": {
                        "username": null,
                        "email": "enlacedeluxebn@gmail.com",
                        "profile": {
                            "id": 3,
                            "descripcion": "Gerente",
                            "nombre": "Gerente",
                            "codigo": "5625acf1-f0f6-4ce1-b206-c0ab0faf79ca",
                            "slug_name": "gerente",
                            "keys": []
                        },
                        "first_name": "Barbara",
                        "last_name": "Novoa",
                        "full_name": "Barbara Novoa",
                        "id": 31,
                        "first_image_url": "https://inmobapp-public-bucket.s3.us-east-2.amazonaws.com/static/media/default-user.jpg",
                        "contactos": []
                    },
                    "image": "https://inmobapp-public-bucket.s3.amazonaws.com/media/franquicia_image/WhatsApp_Image_2024-07-09_at_12.20.13.jpeg",
                    "franquicia_comercial": 43,
                    "franquicia_personal": null,
                    "medios_contacto": [],
                    "solicitudes": {
                        "username": null,
                        "email": "enlacedeluxebn@gmail.com",
                        "profile": {
                            "id": 3,
                            "descripcion": "Gerente",
                            "nombre": "Gerente",
                            "codigo": "5625acf1-f0f6-4ce1-b206-c0ab0faf79ca",
                            "slug_name": "gerente",
                            "keys": []
                        },
                        "first_name": "Barbara",
                        "last_name": "Novoa",
                        "full_name": "Barbara Novoa",
                        "id": 31,
                        "first_image_url": "https://inmobapp-public-bucket.s3.us-east-2.amazonaws.com/static/media/default-user.jpg",
                        "contactos": []
                    },
                    "configuraciones": {
                        "contact_email": "maria@mail.com"
                    }
                },
                "contactos_clientes": [
                    {
                        "id": 37,
                        "tipo": {
                            "id": 1,
                            "descripcion": "Whatsapp",
                            "nombre": "Whatsapp"
                        },
                        "valor": "+58 412 600 1480"
                    }
                ],
                "visitas": [],
                "busquedas": [],
                "status": {
                    "id": 3,
                    "nombre": "Con Ruta"
                },
                "nombre": "Gian",
                "created_at": "2024-01-16T19:34:21.141850-04:00",
                "autor": 31,
                "editores": []
            },
            {
                "id": 34,
                "email": "",
                "franquicia": {
                    "nombre": "Barbara Deluxe",
                    "descripcion": null,
                    "id": 44,
                    "tipo_franquicia": {
                        "id": 4,
                        "nombre": "Membresía",
                        "descripcion": "Membresía",
                        "slug_name": "membresia"
                    },
                    "usuario_asignado": {
                        "username": null,
                        "email": "enlacedeluxebn@gmail.com",
                        "profile": {
                            "id": 3,
                            "descripcion": "Gerente",
                            "nombre": "Gerente",
                            "codigo": "5625acf1-f0f6-4ce1-b206-c0ab0faf79ca",
                            "slug_name": "gerente",
                            "keys": []
                        },
                        "first_name": "Barbara",
                        "last_name": "Novoa",
                        "full_name": "Barbara Novoa",
                        "id": 31,
                        "first_image_url": "https://inmobapp-public-bucket.s3.us-east-2.amazonaws.com/static/media/default-user.jpg",
                        "contactos": []
                    },
                    "image": "https://inmobapp-public-bucket.s3.amazonaws.com/media/franquicia_image/WhatsApp_Image_2024-07-09_at_12.20.13.jpeg",
                    "franquicia_comercial": 43,
                    "franquicia_personal": null,
                    "medios_contacto": [],
                    "solicitudes": {
                        "username": null,
                        "email": "enlacedeluxebn@gmail.com",
                        "profile": {
                            "id": 3,
                            "descripcion": "Gerente",
                            "nombre": "Gerente",
                            "codigo": "5625acf1-f0f6-4ce1-b206-c0ab0faf79ca",
                            "slug_name": "gerente",
                            "keys": []
                        },
                        "first_name": "Barbara",
                        "last_name": "Novoa",
                        "full_name": "Barbara Novoa",
                        "id": 31,
                        "first_image_url": "https://inmobapp-public-bucket.s3.us-east-2.amazonaws.com/static/media/default-user.jpg",
                        "contactos": []
                    },
                    "configuraciones": {
                        "contact_email": "maria@mail.com"
                    }
                },
                "contactos_clientes": [
                    {
                        "id": 34,
                        "tipo": {
                            "id": 1,
                            "descripcion": "Whatsapp",
                            "nombre": "Whatsapp"
                        },
                        "valor": "+58 412 637 9231"
                    }
                ],
                "visitas": [],
                "busquedas": [],
                "status": {
                    "id": 3,
                    "nombre": "Con Ruta"
                },
                "nombre": "fabiola",
                "created_at": "2024-01-16T19:22:23.806172-04:00",
                "autor": null,
                "editores": []
            }
        ]
    },
    {
        "id": 4,
        "title": "Cultibable",
        "count": 1,
        "child": [
            {
                "id": 44,
                "email": "",
                "franquicia": {
                    "nombre": "Barbara Deluxe",
                    "descripcion": null,
                    "id": 44,
                    "tipo_franquicia": {
                        "id": 4,
                        "nombre": "Membresía",
                        "descripcion": "Membresía",
                        "slug_name": "membresia"
                    },
                    "usuario_asignado": {
                        "username": null,
                        "email": "enlacedeluxebn@gmail.com",
                        "profile": {
                            "id": 3,
                            "descripcion": "Gerente",
                            "nombre": "Gerente",
                            "codigo": "5625acf1-f0f6-4ce1-b206-c0ab0faf79ca",
                            "slug_name": "gerente",
                            "keys": []
                        },
                        "first_name": "Barbara",
                        "last_name": "Novoa",
                        "full_name": "Barbara Novoa",
                        "id": 31,
                        "first_image_url": "https://inmobapp-public-bucket.s3.us-east-2.amazonaws.com/static/media/default-user.jpg",
                        "contactos": []
                    },
                    "image": "https://inmobapp-public-bucket.s3.amazonaws.com/media/franquicia_image/WhatsApp_Image_2024-07-09_at_12.20.13.jpeg",
                    "franquicia_comercial": 43,
                    "franquicia_personal": null,
                    "medios_contacto": [],
                    "solicitudes": {
                        "username": null,
                        "email": "enlacedeluxebn@gmail.com",
                        "profile": {
                            "id": 3,
                            "descripcion": "Gerente",
                            "nombre": "Gerente",
                            "codigo": "5625acf1-f0f6-4ce1-b206-c0ab0faf79ca",
                            "slug_name": "gerente",
                            "keys": []
                        },
                        "first_name": "Barbara",
                        "last_name": "Novoa",
                        "full_name": "Barbara Novoa",
                        "id": 31,
                        "first_image_url": "https://inmobapp-public-bucket.s3.us-east-2.amazonaws.com/static/media/default-user.jpg",
                        "contactos": []
                    },
                    "configuraciones": {
                        "contact_email": "maria@mail.com"
                    }
                },
                "contactos_clientes": [
                    {
                        "id": 44,
                        "tipo": {
                            "id": 1,
                            "descripcion": "Whatsapp",
                            "nombre": "Whatsapp"
                        },
                        "valor": "+58 414 318 0513"
                    }
                ],
                "visitas": [],
                "busquedas": [],
                "status": {
                    "id": 4,
                    "nombre": "Cultibable"
                },
                "nombre": "MARTIN",
                "created_at": "2024-01-17T10:44:57.785995-04:00",
                "autor": 31,
                "editores": []
            }
        ]
    },
    {
        "id": 5,
        "title": "En Auditoria",
        "count": 1,
        "child": [
            {
                "id": 109,
                "email": "",
                "franquicia": {
                    "nombre": "Barbara Deluxe",
                    "descripcion": null,
                    "id": 44,
                    "tipo_franquicia": {
                        "id": 4,
                        "nombre": "Membresía",
                        "descripcion": "Membresía",
                        "slug_name": "membresia"
                    },
                    "usuario_asignado": {
                        "username": null,
                        "email": "enlacedeluxebn@gmail.com",
                        "profile": {
                            "id": 3,
                            "descripcion": "Gerente",
                            "nombre": "Gerente",
                            "codigo": "5625acf1-f0f6-4ce1-b206-c0ab0faf79ca",
                            "slug_name": "gerente",
                            "keys": []
                        },
                        "first_name": "Barbara",
                        "last_name": "Novoa",
                        "full_name": "Barbara Novoa",
                        "id": 31,
                        "first_image_url": "https://inmobapp-public-bucket.s3.us-east-2.amazonaws.com/static/media/default-user.jpg",
                        "contactos": []
                    },
                    "image": "https://inmobapp-public-bucket.s3.amazonaws.com/media/franquicia_image/WhatsApp_Image_2024-07-09_at_12.20.13.jpeg",
                    "franquicia_comercial": 43,
                    "franquicia_personal": null,
                    "medios_contacto": [],
                    "solicitudes": {
                        "username": null,
                        "email": "enlacedeluxebn@gmail.com",
                        "profile": {
                            "id": 3,
                            "descripcion": "Gerente",
                            "nombre": "Gerente",
                            "codigo": "5625acf1-f0f6-4ce1-b206-c0ab0faf79ca",
                            "slug_name": "gerente",
                            "keys": []
                        },
                        "first_name": "Barbara",
                        "last_name": "Novoa",
                        "full_name": "Barbara Novoa",
                        "id": 31,
                        "first_image_url": "https://inmobapp-public-bucket.s3.us-east-2.amazonaws.com/static/media/default-user.jpg",
                        "contactos": []
                    },
                    "configuraciones": {
                        "contact_email": "maria@mail.com"
                    }
                },
                "contactos_clientes": [
                    {
                        "id": 108,
                        "tipo": {
                            "id": 1,
                            "descripcion": "Whatsapp",
                            "nombre": "Whatsapp"
                        },
                        "valor": "+58 414 263 6047"
                    }
                ],
                "visitas": [],
                "busquedas": [
                    {
                        "id": 49,
                        "total_seguimientos": 1,
                        "estatus": 1,
                        "descripcion": "interesada en el apartamento de santa paula, filtrar presupuesto y buscar opciones.",
                        "habitaciones": null,
                        "numero_banios": null,
                        "precio": "0.00",
                        "ptos_estacionamiento": null,
                        "created_at": "2024-07-09T20:06:33.954685-04:00",
                        "cliente": 109,
                        "tipo_inmueble": null,
                        "ciudad": null,
                        "estado": null,
                        "seguimientos": [
                            {
                                "id": 58,
                                "cliente": {
                                    "id": 109,
                                    "email": "",
                                    "franquicia": 44,
                                    "nombre": "Ragil Nieves",
                                    "status": 5,
                                    "created_at": "2024-07-09T20:06:33.913715-04:00",
                                    "autor": null,
                                    "editores": []
                                },
                                "fecha_seguimiento": "2024-07-09T20:06:33.956147-04:00",
                                "nota": "Se crea seguimiento para busqueda de cliente.",
                                "audio": null,
                                "busqueda": 49
                            }
                        ]
                    }
                ],
                "status": {
                    "id": 5,
                    "nombre": "En Auditoria"
                },
                "nombre": "Ragil Nieves",
                "created_at": "2024-07-09T20:06:33.913715-04:00",
                "autor": null,
                "editores": []
            }
        ]
    },
    {
        "id": 6,
        "title": "Con Cierre",
        "count": 0,
        "child": []
    },
    {
        "id": 7,
        "title": "Descartado",
        "count": 5,
        "child": [
            {
                "id": 111,
                "email": "",
                "franquicia": {
                    "nombre": "Yorgelis Arellano Deluxe",
                    "descripcion": null,
                    "id": 102,
                    "tipo_franquicia": {
                        "id": 4,
                        "nombre": "Membresía",
                        "descripcion": "Membresía",
                        "slug_name": "membresia"
                    },
                    "usuario_asignado": {
                        "username": null,
                        "email": "yorgelis.deluxe@gmail.com",
                        "profile": {
                            "id": 3,
                            "descripcion": "Gerente",
                            "nombre": "Gerente",
                            "codigo": "5625acf1-f0f6-4ce1-b206-c0ab0faf79ca",
                            "slug_name": "gerente",
                            "keys": []
                        },
                        "first_name": "Yorgelis",
                        "last_name": "Arellano",
                        "full_name": "Yorgelis Arellano",
                        "id": 80,
                        "first_image_url": "https://inmobapp-public-bucket.s3.us-east-2.amazonaws.com/static/media/default-user.jpg",
                        "contactos": []
                    },
                    "image": "https://inmobapp-public-bucket.s3.amazonaws.com/media/franquicia_image/WhatsApp_Image_2024-07-09_at_11.34.12.jpeg",
                    "franquicia_comercial": 43,
                    "franquicia_personal": null,
                    "medios_contacto": [],
                    "solicitudes": {
                        "username": null,
                        "email": "yorgelis.deluxe@gmail.com",
                        "profile": {
                            "id": 3,
                            "descripcion": "Gerente",
                            "nombre": "Gerente",
                            "codigo": "5625acf1-f0f6-4ce1-b206-c0ab0faf79ca",
                            "slug_name": "gerente",
                            "keys": []
                        },
                        "first_name": "Yorgelis",
                        "last_name": "Arellano",
                        "full_name": "Yorgelis Arellano",
                        "id": 80,
                        "first_image_url": "https://inmobapp-public-bucket.s3.us-east-2.amazonaws.com/static/media/default-user.jpg",
                        "contactos": []
                    },
                    "configuraciones": {}
                },
                "contactos_clientes": [
                    {
                        "id": 110,
                        "tipo": {
                            "id": 1,
                            "descripcion": "Whatsapp",
                            "nombre": "Whatsapp"
                        },
                        "valor": "+58 424 585 2331"
                    }
                ],
                "visitas": [],
                "busquedas": [
                    {
                        "id": 51,
                        "total_seguimientos": 1,
                        "estatus": 1,
                        "descripcion": "cliente interesada en alquileres en ccs, por favor filtrar presupuesto y búsqueda del cliente",
                        "habitaciones": null,
                        "numero_banios": null,
                        "precio": "0.00",
                        "ptos_estacionamiento": null,
                        "created_at": "2024-07-09T20:16:35.079473-04:00",
                        "cliente": 111,
                        "tipo_inmueble": null,
                        "ciudad": null,
                        "estado": null,
                        "seguimientos": [
                            {
                                "id": 60,
                                "cliente": {
                                    "id": 111,
                                    "email": "",
                                    "franquicia": 102,
                                    "nombre": "Azuris Zambrano",
                                    "status": 7,
                                    "created_at": "2024-07-09T20:16:35.063037-04:00",
                                    "autor": null,
                                    "editores": []
                                },
                                "fecha_seguimiento": "2024-07-09T20:16:35.080793-04:00",
                                "nota": "Se crea seguimiento para busqueda de cliente.",
                                "audio": null,
                                "busqueda": 51
                            }
                        ]
                    }
                ],
                "status": {
                    "id": 7,
                    "nombre": "Descartado"
                },
                "nombre": "Azuris Zambrano",
                "created_at": "2024-07-09T20:16:35.063037-04:00",
                "autor": null,
                "editores": []
            },
            {
                "id": 42,
                "email": "",
                "franquicia": {
                    "nombre": "Barbara Deluxe",
                    "descripcion": null,
                    "id": 44,
                    "tipo_franquicia": {
                        "id": 4,
                        "nombre": "Membresía",
                        "descripcion": "Membresía",
                        "slug_name": "membresia"
                    },
                    "usuario_asignado": {
                        "username": null,
                        "email": "enlacedeluxebn@gmail.com",
                        "profile": {
                            "id": 3,
                            "descripcion": "Gerente",
                            "nombre": "Gerente",
                            "codigo": "5625acf1-f0f6-4ce1-b206-c0ab0faf79ca",
                            "slug_name": "gerente",
                            "keys": []
                        },
                        "first_name": "Barbara",
                        "last_name": "Novoa",
                        "full_name": "Barbara Novoa",
                        "id": 31,
                        "first_image_url": "https://inmobapp-public-bucket.s3.us-east-2.amazonaws.com/static/media/default-user.jpg",
                        "contactos": []
                    },
                    "image": "https://inmobapp-public-bucket.s3.amazonaws.com/media/franquicia_image/WhatsApp_Image_2024-07-09_at_12.20.13.jpeg",
                    "franquicia_comercial": 43,
                    "franquicia_personal": null,
                    "medios_contacto": [],
                    "solicitudes": {
                        "username": null,
                        "email": "enlacedeluxebn@gmail.com",
                        "profile": {
                            "id": 3,
                            "descripcion": "Gerente",
                            "nombre": "Gerente",
                            "codigo": "5625acf1-f0f6-4ce1-b206-c0ab0faf79ca",
                            "slug_name": "gerente",
                            "keys": []
                        },
                        "first_name": "Barbara",
                        "last_name": "Novoa",
                        "full_name": "Barbara Novoa",
                        "id": 31,
                        "first_image_url": "https://inmobapp-public-bucket.s3.us-east-2.amazonaws.com/static/media/default-user.jpg",
                        "contactos": []
                    },
                    "configuraciones": {
                        "contact_email": "maria@mail.com"
                    }
                },
                "contactos_clientes": [
                    {
                        "id": 42,
                        "tipo": {
                            "id": 1,
                            "descripcion": "Whatsapp",
                            "nombre": "Whatsapp"
                        },
                        "valor": "+58 414 255 4124"
                    }
                ],
                "visitas": [
                    {
                        "id": 12,
                        "rating": null,
                        "propiedad": 153,
                        "asesor": {
                            "id": 96,
                            "username": null,
                            "email": "Natachaa.deluxe@gmail.com",
                            "first_name": "Natasha",
                            "last_name": "Beleño",
                            "profile": {
                                "id": 3,
                                "keys": [],
                                "descripcion": "Gerente",
                                "nombre": "Gerente",
                                "codigo": "5625acf1-f0f6-4ce1-b206-c0ab0faf79ca",
                                "slug_name": "gerente"
                            },
                            "first_image_url": "https://inmobapp-public-bucket.s3.amazonaws.com/media/user_image/WhatsApp_Image_2024-06-10_at_13.19.47.jpeg",
                            "franquicia": {
                                "nombre": "Natasha Beleño",
                                "descripcion": null,
                                "id": 117,
                                "tipo_franquicia": {
                                    "id": 4,
                                    "nombre": "Membresía",
                                    "descripcion": "Membresía",
                                    "slug_name": "membresia"
                                },
                                "usuario_asignado": {
                                    "username": null,
                                    "email": "Natachaa.deluxe@gmail.com",
                                    "profile": {
                                        "id": 3,
                                        "descripcion": "Gerente",
                                        "nombre": "Gerente",
                                        "codigo": "5625acf1-f0f6-4ce1-b206-c0ab0faf79ca",
                                        "slug_name": "gerente",
                                        "keys": []
                                    },
                                    "first_name": "Natasha",
                                    "last_name": "Beleño",
                                    "full_name": "Natasha Beleño",
                                    "id": 96,
                                    "first_image_url": "https://inmobapp-public-bucket.s3.amazonaws.com/media/user_image/WhatsApp_Image_2024-06-10_at_13.19.47.jpeg",
                                    "contactos": []
                                },
                                "image": "https://inmobapp-public-bucket.s3.amazonaws.com/media/franquicia_image/WhatsApp_Image_2024-07-09_at_12.20.14_2.jpeg",
                                "franquicia_comercial": 43,
                                "franquicia_personal": null,
                                "medios_contacto": [],
                                "solicitudes": {
                                    "username": null,
                                    "email": "Natachaa.deluxe@gmail.com",
                                    "profile": {
                                        "id": 3,
                                        "descripcion": "Gerente",
                                        "nombre": "Gerente",
                                        "codigo": "5625acf1-f0f6-4ce1-b206-c0ab0faf79ca",
                                        "slug_name": "gerente",
                                        "keys": []
                                    },
                                    "first_name": "Natasha",
                                    "last_name": "Beleño",
                                    "full_name": "Natasha Beleño",
                                    "id": 96,
                                    "first_image_url": "https://inmobapp-public-bucket.s3.amazonaws.com/media/user_image/WhatsApp_Image_2024-06-10_at_13.19.47.jpeg",
                                    "contactos": []
                                },
                                "configuraciones": {}
                            },
                            "reset_password": true
                        },
                        "estatus": 1,
                        "titulo": "los palos grandes tal tal o codigo",
                        "start": "2024-06-27T12:00:00-04:00",
                        "end": null,
                        "tipo": 1,
                        "descripcion": "",
                        "ubicacion": "los palos grandes",
                        "created_at": "2024-06-27T15:33:20.180233-04:00",
                        "link": "",
                        "cliente": 42
                    }
                ],
                "busquedas": [],
                "status": {
                    "id": 7,
                    "nombre": "Descartado"
                },
                "nombre": "rene",
                "created_at": "2024-01-16T21:01:04.984824-04:00",
                "autor": 31,
                "editores": []
            },
            {
                "id": 38,
                "email": "",
                "franquicia": {
                    "nombre": "Barbara Deluxe",
                    "descripcion": null,
                    "id": 44,
                    "tipo_franquicia": {
                        "id": 4,
                        "nombre": "Membresía",
                        "descripcion": "Membresía",
                        "slug_name": "membresia"
                    },
                    "usuario_asignado": {
                        "username": null,
                        "email": "enlacedeluxebn@gmail.com",
                        "profile": {
                            "id": 3,
                            "descripcion": "Gerente",
                            "nombre": "Gerente",
                            "codigo": "5625acf1-f0f6-4ce1-b206-c0ab0faf79ca",
                            "slug_name": "gerente",
                            "keys": []
                        },
                        "first_name": "Barbara",
                        "last_name": "Novoa",
                        "full_name": "Barbara Novoa",
                        "id": 31,
                        "first_image_url": "https://inmobapp-public-bucket.s3.us-east-2.amazonaws.com/static/media/default-user.jpg",
                        "contactos": []
                    },
                    "image": "https://inmobapp-public-bucket.s3.amazonaws.com/media/franquicia_image/WhatsApp_Image_2024-07-09_at_12.20.13.jpeg",
                    "franquicia_comercial": 43,
                    "franquicia_personal": null,
                    "medios_contacto": [],
                    "solicitudes": {
                        "username": null,
                        "email": "enlacedeluxebn@gmail.com",
                        "profile": {
                            "id": 3,
                            "descripcion": "Gerente",
                            "nombre": "Gerente",
                            "codigo": "5625acf1-f0f6-4ce1-b206-c0ab0faf79ca",
                            "slug_name": "gerente",
                            "keys": []
                        },
                        "first_name": "Barbara",
                        "last_name": "Novoa",
                        "full_name": "Barbara Novoa",
                        "id": 31,
                        "first_image_url": "https://inmobapp-public-bucket.s3.us-east-2.amazonaws.com/static/media/default-user.jpg",
                        "contactos": []
                    },
                    "configuraciones": {
                        "contact_email": "maria@mail.com"
                    }
                },
                "contactos_clientes": [
                    {
                        "id": 38,
                        "tipo": {
                            "id": 1,
                            "descripcion": "Whatsapp",
                            "nombre": "Whatsapp"
                        },
                        "valor": "+58 424 877 7586"
                    }
                ],
                "visitas": [],
                "busquedas": [],
                "status": {
                    "id": 7,
                    "nombre": "Descartado"
                },
                "nombre": "Luis",
                "created_at": "2024-01-16T19:37:05.304828-04:00",
                "autor": 31,
                "editores": []
            },
            {
                "id": 36,
                "email": "",
                "franquicia": {
                    "nombre": "Barbara Deluxe",
                    "descripcion": null,
                    "id": 44,
                    "tipo_franquicia": {
                        "id": 4,
                        "nombre": "Membresía",
                        "descripcion": "Membresía",
                        "slug_name": "membresia"
                    },
                    "usuario_asignado": {
                        "username": null,
                        "email": "enlacedeluxebn@gmail.com",
                        "profile": {
                            "id": 3,
                            "descripcion": "Gerente",
                            "nombre": "Gerente",
                            "codigo": "5625acf1-f0f6-4ce1-b206-c0ab0faf79ca",
                            "slug_name": "gerente",
                            "keys": []
                        },
                        "first_name": "Barbara",
                        "last_name": "Novoa",
                        "full_name": "Barbara Novoa",
                        "id": 31,
                        "first_image_url": "https://inmobapp-public-bucket.s3.us-east-2.amazonaws.com/static/media/default-user.jpg",
                        "contactos": []
                    },
                    "image": "https://inmobapp-public-bucket.s3.amazonaws.com/media/franquicia_image/WhatsApp_Image_2024-07-09_at_12.20.13.jpeg",
                    "franquicia_comercial": 43,
                    "franquicia_personal": null,
                    "medios_contacto": [],
                    "solicitudes": {
                        "username": null,
                        "email": "enlacedeluxebn@gmail.com",
                        "profile": {
                            "id": 3,
                            "descripcion": "Gerente",
                            "nombre": "Gerente",
                            "codigo": "5625acf1-f0f6-4ce1-b206-c0ab0faf79ca",
                            "slug_name": "gerente",
                            "keys": []
                        },
                        "first_name": "Barbara",
                        "last_name": "Novoa",
                        "full_name": "Barbara Novoa",
                        "id": 31,
                        "first_image_url": "https://inmobapp-public-bucket.s3.us-east-2.amazonaws.com/static/media/default-user.jpg",
                        "contactos": []
                    },
                    "configuraciones": {
                        "contact_email": "maria@mail.com"
                    }
                },
                "contactos_clientes": [
                    {
                        "id": 36,
                        "tipo": {
                            "id": 1,
                            "descripcion": "Whatsapp",
                            "nombre": "Whatsapp"
                        },
                        "valor": "+58 412 283 5214"
                    }
                ],
                "visitas": [],
                "busquedas": [],
                "status": {
                    "id": 7,
                    "nombre": "Descartado"
                },
                "nombre": "Lissa",
                "created_at": "2024-01-16T19:31:59.440937-04:00",
                "autor": 31,
                "editores": []
            },
            {
                "id": 35,
                "email": "",
                "franquicia": {
                    "nombre": "Barbara Deluxe",
                    "descripcion": null,
                    "id": 44,
                    "tipo_franquicia": {
                        "id": 4,
                        "nombre": "Membresía",
                        "descripcion": "Membresía",
                        "slug_name": "membresia"
                    },
                    "usuario_asignado": {
                        "username": null,
                        "email": "enlacedeluxebn@gmail.com",
                        "profile": {
                            "id": 3,
                            "descripcion": "Gerente",
                            "nombre": "Gerente",
                            "codigo": "5625acf1-f0f6-4ce1-b206-c0ab0faf79ca",
                            "slug_name": "gerente",
                            "keys": []
                        },
                        "first_name": "Barbara",
                        "last_name": "Novoa",
                        "full_name": "Barbara Novoa",
                        "id": 31,
                        "first_image_url": "https://inmobapp-public-bucket.s3.us-east-2.amazonaws.com/static/media/default-user.jpg",
                        "contactos": []
                    },
                    "image": "https://inmobapp-public-bucket.s3.amazonaws.com/media/franquicia_image/WhatsApp_Image_2024-07-09_at_12.20.13.jpeg",
                    "franquicia_comercial": 43,
                    "franquicia_personal": null,
                    "medios_contacto": [],
                    "solicitudes": {
                        "username": null,
                        "email": "enlacedeluxebn@gmail.com",
                        "profile": {
                            "id": 3,
                            "descripcion": "Gerente",
                            "nombre": "Gerente",
                            "codigo": "5625acf1-f0f6-4ce1-b206-c0ab0faf79ca",
                            "slug_name": "gerente",
                            "keys": []
                        },
                        "first_name": "Barbara",
                        "last_name": "Novoa",
                        "full_name": "Barbara Novoa",
                        "id": 31,
                        "first_image_url": "https://inmobapp-public-bucket.s3.us-east-2.amazonaws.com/static/media/default-user.jpg",
                        "contactos": []
                    },
                    "configuraciones": {
                        "contact_email": "maria@mail.com"
                    }
                },
                "contactos_clientes": [
                    {
                        "id": 35,
                        "tipo": {
                            "id": 1,
                            "descripcion": "Whatsapp",
                            "nombre": "Whatsapp"
                        },
                        "valor": "+58 414 280 9976"
                    }
                ],
                "visitas": [
                    {
                        "id": 7,
                        "rating": null,
                        "propiedad": null,
                        "asesor": {
                            "id": 31,
                            "username": null,
                            "email": "enlacedeluxebn@gmail.com",
                            "first_name": "Barbara",
                            "last_name": "Novoa",
                            "profile": {
                                "id": 3,
                                "keys": [],
                                "descripcion": "Gerente",
                                "nombre": "Gerente",
                                "codigo": "5625acf1-f0f6-4ce1-b206-c0ab0faf79ca",
                                "slug_name": "gerente"
                            },
                            "first_image_url": "https://inmobapp-public-bucket.s3.us-east-2.amazonaws.com/static/media/home-default.jpg",
                            "franquicia": {
                                "nombre": "Barbara Deluxe",
                                "descripcion": null,
                                "id": 44,
                                "tipo_franquicia": {
                                    "id": 4,
                                    "nombre": "Membresía",
                                    "descripcion": "Membresía",
                                    "slug_name": "membresia"
                                },
                                "usuario_asignado": {
                                    "username": null,
                                    "email": "enlacedeluxebn@gmail.com",
                                    "profile": {
                                        "id": 3,
                                        "descripcion": "Gerente",
                                        "nombre": "Gerente",
                                        "codigo": "5625acf1-f0f6-4ce1-b206-c0ab0faf79ca",
                                        "slug_name": "gerente",
                                        "keys": []
                                    },
                                    "first_name": "Barbara",
                                    "last_name": "Novoa",
                                    "full_name": "Barbara Novoa",
                                    "id": 31,
                                    "first_image_url": "https://inmobapp-public-bucket.s3.us-east-2.amazonaws.com/static/media/default-user.jpg",
                                    "contactos": []
                                },
                                "image": "https://inmobapp-public-bucket.s3.amazonaws.com/media/franquicia_image/WhatsApp_Image_2024-07-09_at_12.20.13.jpeg",
                                "franquicia_comercial": 43,
                                "franquicia_personal": null,
                                "medios_contacto": [],
                                "solicitudes": {
                                    "username": null,
                                    "email": "enlacedeluxebn@gmail.com",
                                    "profile": {
                                        "id": 3,
                                        "descripcion": "Gerente",
                                        "nombre": "Gerente",
                                        "codigo": "5625acf1-f0f6-4ce1-b206-c0ab0faf79ca",
                                        "slug_name": "gerente",
                                        "keys": []
                                    },
                                    "first_name": "Barbara",
                                    "last_name": "Novoa",
                                    "full_name": "Barbara Novoa",
                                    "id": 31,
                                    "first_image_url": "https://inmobapp-public-bucket.s3.us-east-2.amazonaws.com/static/media/default-user.jpg",
                                    "contactos": []
                                },
                                "configuraciones": {
                                    "contact_email": "maria@mail.com"
                                }
                            },
                            "reset_password": false
                        },
                        "estatus": 1,
                        "titulo": "1",
                        "start": "2024-01-23T17:30:00-04:00",
                        "end": null,
                        "tipo": 1,
                        "descripcion": "Apartamento remodelado 68 mil",
                        "ubicacion": "Cafetal, san Luis",
                        "created_at": "2024-01-23T19:15:45.327384-04:00",
                        "link": "https://info.wasi.co/apartamento-venta-el-cafetal-caracas-baruta/7102037?shared=whatsapp",
                        "cliente": 35
                    }
                ],
                "busquedas": [
                    {
                        "id": 19,
                        "total_seguimientos": 1,
                        "estatus": 1,
                        "descripcion": "Apartamento en el Rosal",
                        "habitaciones": 1,
                        "numero_banios": 1,
                        "precio": "45000.00",
                        "ptos_estacionamiento": null,
                        "created_at": "2024-03-09T14:56:56.887125-04:00",
                        "cliente": 35,
                        "tipo_inmueble": 1,
                        "ciudad": 325,
                        "estado": 24,
                        "seguimientos": [
                            {
                                "id": 25,
                                "cliente": {
                                    "id": 35,
                                    "email": "",
                                    "franquicia": 44,
                                    "nombre": "Viviana",
                                    "status": 7,
                                    "created_at": "2024-01-16T19:30:50.534247-04:00",
                                    "autor": 31,
                                    "editores": []
                                },
                                "fecha_seguimiento": "2024-03-09T14:56:56.888485-04:00",
                                "nota": "Se crea seguimiento para busqueda de cliente.",
                                "audio": null,
                                "busqueda": 19
                            }
                        ]
                    }
                ],
                "status": {
                    "id": 7,
                    "nombre": "Descartado"
                },
                "nombre": "Viviana",
                "created_at": "2024-01-16T19:30:50.534247-04:00",
                "autor": 31,
                "editores": []
            }
        ]
    },
    {
        "id": 8,
        "title": "Sin Contacto",
        "count": 0,
        "child": []
    }
]