import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import ReactDragListView from 'react-drag-listview/lib/index.js';
import Dropzone from "react-dropzone";
import { toast } from 'react-toastify';
import {
  Button,
  Card,
  CardBody,
  Col,
  Row
} from "reactstrap";
import { APIClient } from '../../../../helpers/api_helper';
import { FileInput } from '../EcommerceAddProduct';
import SimpleBar from "simplebar-react";

const DocumentsDropzone = forwardRef(({ validation, externalClick, isMultiple = false, ...props }, ref) => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const api = new APIClient();
  const dropZoneRef = useRef(null);
  const fileInputRef = useRef(null);

  const handleClick = () => () => {
    if(fileInputRef.current.toggle){
      fileInputRef.current.toggle();
    }
  };

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  function getFileTypeIcon(type) {
    if (type.includes('pdf')) return 'ri-file-pdf-line';
    if (type.includes('image')) return 'ri-image-line';
    if (type.includes('word')) return 'ri-file-word-line';
    if (type.includes('excel')) return 'ri-file-excel-line';
    if (type.includes('powerpoint')) return 'ri-file-ppt-line';
    if (type.includes('text')) return 'ri-file-text-line';
    return 'ri-file-line';
  }

  function handleExternalFiles(files) {
    let myFiles = [...files];
    handleAcceptedFiles(myFiles);
  }

  function handleAcceptedFiles(files) {
    if (isMultiple) {
      validation.setFieldValue(props?.name, [...selectedFiles, ...files]);
    } else {
      validation.setFieldValue(props?.name, files[0]);
    }

    const updatedFiles = files.map((file) => {
      const { path, name, size, type } = file;
      return {
        path,
        name,
        size,
        type,
        icon: getFileTypeIcon(type),
        formattedSize: formatBytes(file.size),
      };
    });

    let tmpFiles = [...selectedFiles, ...updatedFiles];
    setSelectedFiles(tmpFiles);
  }

  useImperativeHandle(ref, () => ({
    setUploadedFiles,
    setSelectedFiles: handleExternalFiles,
    selectedFiles,
  }));

  const dragProps = {
    onDragEnd(fromIndex, toIndex) {
      const files = [...selectedFiles];
      const item = files.splice(fromIndex, 1)[0];
      files.splice(toIndex, 0, item);
      setSelectedFiles(files);
    },
    nodeSelector: 'div',
    handleSelector: 'div',
  };

  const handleDeleteUploaded = (f, i) => {
    const newFiles = [...uploadedFiles];
    newFiles.splice(i, 1);

    dropZoneRef.current.open();
    api.delete(`eliminar-archivo/${f.id}/`)
      .then((res) => {
        toast.success('Se eliminó un archivo.');
        setUploadedFiles(newFiles);
      })
      .catch(console.error)
      .finally(() => {
      });
  };

  const handleDeleteSelected = (f, i) => {
    const newFiles = [...selectedFiles];
    newFiles.splice(i, 1);
    setSelectedFiles(newFiles);

    if (isMultiple) {
      const validationFiles = [...validation.values[props?.name]];
      validationFiles.splice(i, 1);
      validation.setFieldValue(props?.name, validationFiles);
    } else {
      validation.setFieldValue(props?.name, null);
    }
  };

  const handleFilesChange = (files) => {
    if(files){
      handleExternalFiles(files)
    }
  };


  return (
    <>
        <FileInput accept='files/*'  ref={fileInputRef} onFilesChange={handleFilesChange} />
      <div>
        <h5 className="fs-15 mb-1">{props?.label || ' '}</h5>
        <p className="text-muted">{props?.descripcion || ' '}</p>
        <Row>
          {(!selectedFiles.length || isMultiple) && (
            <>
              <Col xl={(selectedFiles.length > 0) ? 6 : 12}>
                <Dropzone
                  ref={dropZoneRef}
                  multiple={isMultiple}
                  onDrop={(acceptedFiles) => {
                    handleAcceptedFiles(acceptedFiles);
                  }}>
                  {({ getRootProps, open, getInputProps }) => (
                    <div className="dropzone dz-clickable">
                      <div className="dz-message needsclick" {...getRootProps()}>
                        <div className="mb-3">
                          <i className="display-4 text-muted ri-upload-cloud-2-fill" />
                        </div>
                          <Button
                              className="btn btn-light  btn-block w-100"
                              onClick={handleClick()}>
                              <h5>Arrastra archivos en este campo para cargar.</h5>
                          </Button>
                      </div>
                    </div>
                  )}
                </Dropzone>
              </Col>
              {
                selectedFiles.length > 0 &&
                <Col xl={6}>
                  <SimpleBar style={{ maxHeight: "200px" }}>
                    <div className="list-unstyled mb-0" id="file-previews">
                      <ReactDragListView {...dragProps}>
                        {selectedFiles.map((f, i) => {
                          return (
                            <Card
                              className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                              key={i + "-file"}
                            >
                              <div className="p-2">
                                <Row className="align-items-center">
                                  <Col className="col-auto">
                                    <i className={`${f.icon} display-6 text-muted`} />
                                  </Col>
                                  <Col>
                                    <span className="text-muted font-weight-bold">
                                      {f.name}
                                    </span>
                                    <p className="mb-0">
                                      <strong>{f.formattedSize}</strong>
                                    </p>
                                  </Col>
                                  <Col className="col-auto">
                                    <button
                                      type="button"
                                      className="btn btn-danger btn-icon btn-sm ml-2"
                                      onClick={() => handleDeleteSelected(f, i)}>
                                      <i className="ri-delete-bin-line"></i>
                                    </button>
                                  </Col>
                                </Row>
                              </div>
                            </Card>
                          );
                        })}

                        {uploadedFiles.map((f, i) => {
                          return (
                            <Card
                              className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                              key={i + "-file-preloaded"}
                            >
                              <div className="p-2">
                                <Row className="align-items-center">
                                  <Col className="col-auto">
                                    <i className={`${f.icon} display-4 text-muted`} />
                                  </Col>
                                  <Col>
                                    <p className="mb-0">
                                      <strong>{f.name}</strong>
                                    </p>
                                    <p className="mb-0">
                                      <strong>{f.formattedSize}</strong>
                                    </p>
                                  </Col>
                                  <Col className="col-auto">
                                    <button
                                      type="button"
                                      className="btn btn-danger btn-sm ml-2"
                                      onClick={() => {
                                        handleDeleteUploaded(f, i);
                                      }}>
                                      Eliminar
                                    </button>
                                  </Col>
                                </Row>
                              </div>
                            </Card>
                          );
                        })}
                      </ReactDragListView>
                    </div>
                  </SimpleBar>
                </Col>
              }
            </>
          )}
        </Row>


      </div>
    </>
  );
});

DocumentsDropzone.displayName = 'DocumentsDropzone';
export { DocumentsDropzone };
