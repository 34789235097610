import React from 'react';
import { Input, FormFeedback, Label } from 'reactstrap';

export const TextInput = ({ validation, name, placeholder, ...props }) => {
  return (
    <div className="mb-2">
            {(props?.label !== " " && props?.label !== "" && props.label) && (
                <Label htmlFor={props?.name || null} style={{marginBottom:'0px !important'}}>
                    {props?.label || ' '} 
                    {props.required ? <span className="text-danger">*</span> : ' '}    
                </Label>
            )}
      <Input
        type="text"
        className="form-control bg-light border-0"
        name={name}
        placeholder={placeholder}
        value={validation.values[name] || ""}
        onChange={validation.handleChange}
        onBlur={validation.handleBlur}
        invalid={validation.errors[name] && validation.touched[name]}
        {...props}
      />
      {validation.errors[name] && validation.touched[name] && (
        <FormFeedback type="invalid" className="d-block">
          {validation.errors[name]}
        </FormFeedback>
      )}
    </div>
  );
};

export const TextAreaInput = ({ validation, name, placeholder, rows = "3", ...props }) => {
  return (
    <div className="mb-2">
      <Input
        type="textarea"
        className="form-control bg-light border-0"
        name={name}
        placeholder={placeholder}
        rows={rows}
        value={validation.values[name] || ""}
        onChange={validation.handleChange}
        onBlur={validation.handleBlur}
        invalid={validation.errors[name] && validation.touched[name]}
        {...props}
      />
      {validation.errors[name] && validation.touched[name] && (
        <FormFeedback type="invalid" className="d-block">
          {validation.errors[name]}
        </FormFeedback>
      )}
    </div>
  );
};

export const SelectInput = ({ validation, name, options, ...props }) => {
  return (
    <div className="mb-2">
      <Input
        type="select"
        className="form-control bg-light border-0"
        name={name}
        value={validation.values[name] || ""}
        onChange={validation.handleChange}
        onBlur={validation.handleBlur}
        invalid={validation.errors[name] && validation.touched[name]}
        {...props}
      >
        {options.map((option, index) => (
          <option key={index} value={option.value}>
            {option.label}
          </option>
        ))}
      </Input>
      {validation.errors[name] && validation.touched[name] && (
        <FormFeedback type="invalid" className="d-block">
          {validation.errors[name]}
        </FormFeedback>
      )}
    </div>
  );
};

export const DateInput = ({ validation, name, ...props }) => {
  return (
    <div className="mb-2">
      <Input
        type="date"
        className="form-control bg-light border-0"
        name={name}
        value={validation.values[name] || ""}
        onChange={validation.handleChange}
        onBlur={validation.handleBlur}
        invalid={validation.errors[name] && validation.touched[name]}
        {...props}
      />
      {validation.errors[name] && validation.touched[name] && (
        <FormFeedback type="invalid" className="d-block">
          {validation.errors[name]}
        </FormFeedback>
      )}
    </div>
  );
};

export const CheckboxInput = ({ validation, name, label, ...props }) => {
  return (
    <div className="form-check mb-2">
      <Input
        type="checkbox"
        className="form-check-input"
        name={name}
        checked={validation.values[name] || false}
        onChange={validation.handleChange}
        onBlur={validation.handleBlur}
        invalid={validation.errors[name] && validation.touched[name]}
        {...props}
      />
      <label className="form-check-label" htmlFor={name}>
        {label}
      </label>
      {validation.errors[name] && validation.touched[name] && (
        <FormFeedback type="invalid" className="d-block">
          {validation.errors[name]}
        </FormFeedback>
      )}
    </div>
  );
};


// Componente para formatear números y montos en dinero
export const MoneyInput = ({ validation, name, placeholder, ...props }) => {
  // Función para formatear el valor con coma para los decimales
  const formatValue = (value) => {
    // Usar la coma como separador de decimales y el punto como separador de miles
    const formattedValue = parseFloat(value.replace(/,/g, ''))
      .toFixed(2)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      .replace('.', ',');
    
    return formattedValue;
  };

  // Función para manejar cambios en el campo de entrada
  const handleChange = (e) => {
    let value = e.target.value.replace(/\./g, '').replace(',', '.'); // Convertir el valor a un formato numérico sin puntos ni comas
    if (!isNaN(value) || value === '') {
      validation.setFieldValue(name, formatValue(value)); // Actualizar el campo con el valor formateado
    }
  };

  return (
    <div className="mb-2">
      {props?.label !== " " && props?.label !== "" && props.label && (
        <Label htmlFor={props?.name || null} style={{ marginBottom: '0px !important' }}>
          {props?.label || ' '}
          {props.required ? <span className="text-danger">*</span> : ' '}
        </Label>
      )}
      <Input
        type="text"
        className="form-control bg-light border-0"
        name={name}
        placeholder={placeholder}
        value={validation.values[name] || ""}
        onChange={handleChange}
        onBlur={validation.handleBlur}
        invalid={validation.errors[name] && validation.touched[name]}
        {...props}
      />
      {validation.errors[name] && validation.touched[name] && (
        <FormFeedback type="invalid" className="d-block">
          {validation.errors[name]}
        </FormFeedback>
      )}
    </div>
  );
};







export const NumberInput = ({ validation, name, placeholder, ...props }) => {
  // Formatear el valor con separación de miles y coma para decimales
  const formatValue = (value) => {
    if (value === "") return "";
    return new Intl.NumberFormat('es-ES', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(value.replace(/\./g, '').replace(',', '.')); // Convierte decimales con coma
  };

  const handleChange = (e) => {
    const rawValue = e.target.value.replace(/\./g, '').replace(',', '.');
    validation.setFieldValue(name, rawValue);
  };

  return (
    <div className="mb-2">
      {(props?.label !== " " && props?.label !== "" && props.label) && (
        <Label htmlFor={props?.name || null} style={{ marginBottom: '0px !important' }}>
          {props?.label || ' '}
          {props.required ? <span className="text-danger">*</span> : ' '}
        </Label>
      )}
      <Input
        type="text"
        className="form-control bg-light border-0"
        name={name}
        placeholder={placeholder}
        inputMode="decimal" // Para mostrar teclado numérico en móviles
        value={formatValue(validation.values[name] || "")}
        onChange={handleChange}
        onBlur={validation.handleBlur}
        invalid={validation.errors[name] && validation.touched[name]}
        {...props}
      />
      {validation.errors[name] && validation.touched[name] && (
        <FormFeedback type="invalid" className="d-block">
          {validation.errors[name]}
        </FormFeedback>
      )}
    </div>
  );
};