import React, { useEffect, useMemo, useState } from 'react';
import { crmWidgets } from "../../common/data";
import CountUp from "react-countup";
import { createApiMethods } from '../../helpers/api_calls';
import { BUSQUEDAS, CALENDAR, CLIENTES, PROPIEDADES } from '../../helpers/api_urls';
import { NavItem } from 'reactstrap';
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Mousewheel } from "swiper";
import "swiper/css/pagination";
// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";


const Widgets = ({
        data,
        ...props
        }) => {
        const [widgets, setwidgets] = useState([]);
        const [cantidadDePosts, setCantidadDePosts] = useState(1);

        const ajustarCantidadDePosts = () => {
          const anchoVentana = window.innerWidth;
      
          if (anchoVentana < 600) {
            setCantidadDePosts(1);
          } else if (anchoVentana < 900) {
            setCantidadDePosts(2);
          } else if (anchoVentana < 1200) {
            setCantidadDePosts(3);
          } else {
            setCantidadDePosts(3);
          }
        };
    
        useEffect(() => {
            // Llamar a la función inicialmente
            ajustarCantidadDePosts();
        
            // Agregar un listener para cambios en el tamaño de la ventana
            window.addEventListener('resize', ajustarCantidadDePosts);
        
            // Limpiar el listener cuando el componente se desmonta
            return () => {
              window.removeEventListener('resize', ajustarCantidadDePosts);
            };
        }, []);

        useEffect(()=>{
            if(data){
                const crmWidgets = [
                    {
                        id: 1,
                        label: "Propiedades",
                        // badge: "ri-arrow-up-circle-line text-success",
                        icon: "ri-home-4-line",
                        counter: data.property_stats.total_properties,
                        decimals: 0,
                        suffix: "",
                        prefix: "",
                        description: 'Propiedades totales'
                    },
                    {
                        id: 2,
                        label: "Prom. Propiedades",
                        // badge: "ri-arrow-up-circle-line text-success",
                        icon: "ri-home-4-line",
                        counter: data.property_stats.average_price ? data.property_stats.average_price : 0,
                        decimals: 0,
                        suffix: "",
                        description: 'Precio promedio de propiedades',
                        prefix: "$ "
                    },
                    {
                        id: 3,
                        label: "Clientes",
                        // badge: "ri-arrow-up-circle-line text-success",
                        icon: " ri-user-2-line",
                        counter: data.client_stats.total_clients,
                        decimals: 0,
                        suffix: "",
                        description: 'Clientes activos',
                        prefix: ""
                    },
                    {
                        id: 4,
                        label: "Franquicias",
                        // badge: "ri-arrow-up-circle-line text-success",
                        icon: "ri-community-line",
                        counter: data.franchise_stats.total_franquises,
                        description: 'Franquicias totales',
                        decimals: 0,
                        prefix: "",
                        separator: ",",
                        suffix: ""
                    },
                    {
                        id: 5,
                        label: "Visitas",
                        // badge: "ri-arrow-down-circle-line text-danger",
                        counter: data.visit_stats.total_visits,
                        icon: "ri-calendar-event-line",
                        decimals: 0,
                        description: 'Visitas totales',
                        suffix: "",
                        prefix: ""
                    },
                    {
                        id: 6,
                        label: "Ratio Visitas",
                        // badge: "ri-arrow-down-circle-line text-danger",
                        counter: data.visit_stats.conversion_rate,
                        icon: "ri-arrow-up-circle-line",
                        decimals: 1,
                        description: 'Ratio de conversion de visitas',
                        suffix: "",
                        prefix: ""
                    },
                ];
                setwidgets(crmWidgets)
            }
        },[data])
        


    return (
        <React.Fragment>
            <div className="d-flex align-items-center mb-3">
                <div className="flex-grow-1">
                    <h5 className="mb-0">Estadisticas</h5>
                </div>
                <div className="flexshrink-0">
                    {/* <button className="btn btn-primary btn-sm"><i className="ri-star-line align-bottom"></i> Crear busqueda</button> */}
                </div>
            </div>            
            <Swiper
                slidesPerView={cantidadDePosts}
                spaceBetween={24}
                mousewheel={true}
                autoplay={{
                    delay: 2000,
                    disableOnInteraction: false,
                }}
                modules={[Autoplay, Mousewheel]}
                className="cryptoSlider">
                {(widgets || []).map((widget, key) => (
                    <SwiperSlide key={key}>
                        <div className="card crm-widget">
                            <div className="card-body p-0">
                                <div className="col">
                                    <div className="py-4 px-3">
                                        <h5 className="text-muted text-uppercase fs-13">{widget.label}<i className={widget.badge + " fs-18 float-end align-middle"}></i></h5>
                                        
                                        <div className="d-flex align-items-center">
                                            <div className="flex-shrink-0">
                                                <i className={widget.icon + " display-6 text-muted"}></i>
                                            </div>
                                            <div className="flex-grow-1 ms-3">
                                                <h2 className="mb-0"><span className="counter-value" data-target="197">
                                                    <CountUp
                                                        start={0}
                                                        prefix={widget.prefix}
                                                        suffix={widget.suffix}
                                                        separator={widget.separator}
                                                        end={widget.counter}
                                                        decimals={widget.decimals}
                                                        duration={4}
                                                    />
                                                </span></h2>
                                            </div>
                                        </div>
                                            <p className="mb-0 text-muted">
                                                <span className="badge bg-light text-success mb-0"> 
                                                    <i className="ri-arrow-up-line align-middle"></i> 
                                                </span> {widget.description}
                                            </p>
                                    </div>
                                </div>                                        
                            </div>
                        </div>                                    
                    </SwiperSlide>
                ))}
            </Swiper>      
        </React.Fragment>
    );
};

export default Widgets;