import { Link } from "react-router-dom"


export const ReservasColumns = [    
    {
      Header: "Usuario",
      accessor: "usuario",
      filterable: false,
      Cell: (cellProps) => {
          const { original } = cellProps.row
          let { propiedad } = original
          return (
              <div className="d-flex align-items-center">
                <div className="flex-shrink-0 me-3">
                  <div className="avatar-container-sm bg-light rounded p-1">
                    <img
                          src={propiedad?.first_image_url}
                          alt=""
                          className="img-fluid d-block"/>
                  </div>
                </div>
                <div className="flex-grow-1">
                  <h5 className="fs-14 mb-1">
                    <Link
                      to={`/inmueble/${propiedad.id}`}
                      className="text-dark"
                    >
                      {" "}
                      {propiedad.nombre}
                    </Link>
                  </h5>
                  <p className="text-muted mb-0">
                    {/* Category :{" "} */}
                    <span className="fw-medium">
                      {" "}
                      {propiedad?.tipo_inmueble?.nombre} - { propiedad?.precio_usd }
                    </span>
                  </p>
                </div>
              </div>
          )
      } 
  },
  {
      Header: "Puntas",
      accessor: "mensaje",
      filterable: false,
      Cell: (cellProps) => {
          const { original } = cellProps.row
          return (
            <>
              <div className="d-flex flex-column">
                <div className="flex-grow-1">
                  <p className="mb-0">
                    <span className="text-muted">
                      Captadora :{" "}
                    </span>
                    <span className="fw-medium">
                      {" "}
                      {original.franquicia_captadora.nombre}
                    </span>
                  </p>
                </div>
                <div className="flex-grow-1">
                  <p className="mb-0">
                    <span className="text-muted">
                      Cerradora :{" "}
                    </span>
                    <span className="fw-medium">
                      {" "}
                      {original.franquicia_compradora.nombre}
                    </span>
                  </p>
                </div>
              </div>
            </>
          )
      } 
  },
  {
      Header: "Estatus",
      Cell: (product) => {
          const { status_reserva } = product.row.original
          return (
            <>
              <div className="d-flex align-items-center">
                <div className="flex-grow-1">
                  <p className="text-muted mb-0">
                    {/* Category :{" "} */}
                    <span className="fw-medium">
                      {" "}
                      {status_reserva.nombre}
                    </span>
                  </p>
                </div>
              </div>
            </>
          )
      } 
  },     
  {
    Header: "% Reserva",
    Cell: (product) => {
        const { porcentaje_reserva,monto_reserva } = product.row.original
        return (
          <>
              <div className="d-flex flex-column">
                <div className="flex-grow-1">
                  <div className="mb-0 d-flex flex-column">
                    <p className="text-muted mb-0">
                      % 
                      <strong>
                        {porcentaje_reserva}
                      </strong>
                    </p>
                    <p className="fw-medium mb-0">
                      {" USD "}
                      {Number(monto_reserva)}
                    </p>
                  </div>
                </div>
              </div>
          </>
        )
    } 
},            
]
