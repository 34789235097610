import React from 'react';
import './inputTextarea.css';
import { FormFeedback, Input, Label } from 'reactstrap';

export const InputTextarea = ({id,className = "", validation, ...props }) => {
    // console.log(props)
    return (
        <div className="mb-3">
            <Label htmlFor="direccion_origen" className="form-label">
                {props?.label || ' '} 
                { props.required ? <span className="text-danger">*</span> : ' ' }    
            </Label>
            <Input
                type="textarea" 
                id={id}
                name      = {props?.name || null}
                className = {`form-control mb-3 ${className}`} 
                onChange  = {validation.handleChange}
                onBlur    = {validation.handleBlur}
                placeholder={props?.placeholder}
                value     = {validation.values[props?.name] || ""}
                invalid   = {
                    validation.touched[props?.name] && validation.errors[props?.name] ? true : false
                }>
            </Input>
            {validation.touched[props?.name] && validation.errors[props?.name] ? (
                <FormFeedback type="invalid" className="d-block"><div>{validation.errors[props?.name]}</div></FormFeedback>
            ) : null}
        </div>
    );
};
