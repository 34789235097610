export const API_RESPONSE_SUCCESS = 'API_RESPONSE_SUCCESS';
export const API_RESPONSE_ERROR = 'API_RESPONSE_ERROR';
export const GET_FRANQUICIAS = 'GET_FRANQUICIAS';
export const GET_FRANQUICIAS_DETAIL = 'GET_FRANQUICIAS_DETAIL';
export const ADD_NEW_FRANQUICIAS = 'ADD_NEW_FRANQUICIAS';
export const ADD_FRANQUICIAS_SUCCESS = 'ADD_FRANQUICIAS_SUCCESS';
export const ADD_FRANQUICIAS_FAIL = 'ADD_FRANQUICIAS_FAIL';
export const UPDATE_FRANQUICIAS = 'UPDATE_FRANQUICIAS';
export const UPDATE_FRANQUICIAS_SUCCESS = 'UPDATE_FRANQUICIAS_SUCCESS';
export const UPDATE_FRANQUICIAS_FAIL = 'UPDATE_FRANQUICIAS_FAIL';
export const DELETE_FRANQUICIAS = 'DELETE_FRANQUICIAS';
export const DELETE_FRANQUICIAS_SUCCESS = 'DELETE_FRANQUICIAS_SUCCESS';
export const DELETE_FRANQUICIAS_FAIL = 'DELETE_FRANQUICIAS_FAIL';
export const IS_LOADING = 'IS_LOADING';
export const IS_NOT_LOADING = 'IS_NOT_LOADING';
export const RESET_NEW = 'RESET_NEW';
