import React, { useState, useEffect, useRef } from "react";
import { Button, ButtonGroup, Collapse, Container, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, NavbarToggler, NavLink, UncontrolledDropdown } from "reactstrap";
import Scrollspy from "react-scrollspy";
import { Link } from "react-router-dom";

// Import Images
import { useAuth } from "../../../AuthContext";
import logoSm from "../../../assets/images/inmoapp/AppLogoNoFondo.png";
import FilterCanvas from "./FilterCanvas/FilterCanvas";

const Navbar = ({setgridStyle, callApi}) => {
    const [isOpenMenu, setisOpenMenu] = useState(false);
    const [lastSearch, setlastSearch] = useState('');
    const [navClass, setnavClass] = useState("");
    const auth = useAuth()
    const [filterMenuToggle, setfilterMenuToggle] = useState(false);
    const toggle = () => setisOpenMenu(!isOpenMenu);
    const filterRef = useRef(null);
    const [searchValue, setSearchValue] = useState('');

    useEffect(() => {
        window.addEventListener("scroll", scrollNavigation, true);
    });

    const scrollNavigation = () => {
        var scrollup = document.documentElement.scrollTop;
        if (scrollup > 50) {
            setnavClass("is-sticky");
        } else {
            setnavClass("");
        }
    }


    const handleInputChange = (event) => {
        let search = event.target.value
        setSearchValue(search);
    };

    const cleanOptions = (options) => {
        return Object.fromEntries(
          Object.entries(options || {}).filter(([_, value]) => value !== null && value !== undefined && value !== '')
        );
    };

    const handleSearch = () => () => {
        let objFilterSearch = cleanOptions({
            ...filterRef.current?.filterValues,
            search: searchValue
        })
        setlastSearch(searchValue)
        callApi(objFilterSearch)
    }

    return (
        <React.Fragment>
            <nav className={"navbar navbar-expand-lg navbar-landing fixed-top " + navClass} id="navbar">
                <Container>
                    <Link className="navbar-brand" to="/">
                        <img src={logoSm} className="card-logo card-logo-dark" alt="logo dark" height="35" />
                        <img src={logoSm} className="card-logo card-logo-light" alt="logo light" height="35" />
                    </Link>

                    <NavbarToggler className="navbar-toggler py-0 fs-20 text-body" onClick={toggle} type="button" data-bs-toggle="collapse"
                        data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                        aria-expanded="false" aria-label="Toggle navigation">
                        <i className="mdi mdi-menu"></i>
                    </NavbarToggler>

                    <Collapse
                        isOpen={isOpenMenu}
                        className="navbar-collapse"
                        id="navbarSupportedContent"
                    >
                        <li className="nav-item" style={{ justifyContent: 'center', flexDirection: 'column', margin: 'auto', width: '350px' }}>
                            <div className="search-box d-flex h100">
                                <input
                                id="search-bar-0"
                                type="text"
                                className="form-control search"
                                value={searchValue}
                                onKeyDown={(event) => {
                                    if(event.key === 'Enter'){
                                        handleSearch()()
                                    }
                                }}
                                onChange={handleInputChange}
                                />
                                <i className="bx bx-search-alt search-icon"></i>
                            </div>
                        </li>
                        <li className="nav-item" style={{justifyContent:'center',flexDirection:'column', margin:'auto'}}>
                            <ButtonGroup className="mt-sm-0" style={{marginRight: '3px'}}>
                                <Button className='btn-light btn-icon' onClick={(e) => {
                                    filterRef.current.toggle()
                                }}>
                                    <i className="bx ri-filter-2-line"></i>
                                </Button>                            
                                <Button className='btn-light' onClick={handleSearch()}>
                                    Buscar
                                </Button>
                            </ButtonGroup>
                        </li>

                        <div className="">
                            <ButtonGroup className="mt-sm-0" style={{marginRight: '3px'}}>
                                <Button onClick={() => setgridStyle(false)} color="light" className="btn-icon"> <i className="ri-layout-grid-line" /> </Button>
                                <Button onClick={() => setgridStyle(true)} color="light" className="btn-icon"> <i className="ri-table-line" /> </Button>
                            </ButtonGroup>                                 
                            {
                                !auth.isAuthenticated ?
                                <>
                                    <Link to="/login" className="btn btn-link fw-medium text-decoration-none text-dark">
                                        Iniciar Sessión
                                    </Link>
                                </>:
                                <>
                                    <Link to="/dashboard" className="btn btn-light">
                                        <i className="bx ri-stack-line"></i>
                                        Admin Area
                                    </Link>
                                </>
                            }                       
                        </div>
                    </Collapse>
                </Container>
            </nav>
            <FilterCanvas 
                callApi={callApi}
                ref={filterRef}>
            </FilterCanvas>
        </React.Fragment>
    );
};

export default Navbar;