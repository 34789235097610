import { useState, useCallback, useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Modal, ModalHeader, ModalBody, Form, FormGroup, Label, Input, FormFeedback, Row, Col, Button } from 'reactstrap';
import { InputSelect } from '../../../Forms/Inputs/InputSelect/InputSelect';
import { useDispatch, useSelector } from 'react-redux';
import { getciudades, getestados, getfranquiciasList, updatefranquicias } from '../../../../store/actions';
import { InputText } from '../../../Forms/Inputs/InputText/InputText';
import { createApiMethods, createFranquicia, updateFranquicia } from '../../../../helpers/api_calls';
import { InputFile } from '../../../Forms/Inputs/InputFile/InputFile';
import { toast } from 'react-toastify';
import { JSONToFormData, convertirAFormData } from '../../../../helpers/api_helper';
import { TIPOSFRANQUICIAS, TIPOSINMUEBLES } from '../../../../helpers/api_urls';

export const AddFranquicias = ({modalState, toggleModal, editObject}) => {
    const [modal, setModal] = useState(modalState);
    const [ciudadesList, setciudadesList] = useState([]);
    const [tiposfranquiciaslist, settiposfranquiciaslist] = useState([]);
    const dispatch = useDispatch();
    const apiTiposFranquicias = createApiMethods(TIPOSFRANQUICIAS)
    const [isLoading, setisLoading] = useState();
    const { propiedades } = useSelector((state) => ({
        propiedades: state.Propiedades,
    }));


    useEffect(() => {
        apiTiposFranquicias.getAll({page_size:1000}).then((res)=>{
            settiposfranquiciaslist(res.results)
        })
    }, [])


    useEffect(() => {
        dispatch(getciudades({page_size: 1000}));
        dispatch(getestados({page_size: 1000}));
      }, [dispatch]);

    useEffect(() => {
        setModal(modalState)
    }, [modalState]);


    const onSubmitHandler = (values) => {


        let userObject = {
            first_name: values.first_name,
            last_name: values.last_name,
            email: values.email,
            password: values.password,
            img_perfil: values.img_perfil,
        }

        const formData = JSONToFormData(values)
        let promise = null
      
        setisLoading(true)
        if (editObject) {
            formData.append("id", editObject.id);
            promise = updateFranquicia(formData)
        } else {
            promise =createFranquicia(formData)
        }


        promise.then((res) => {
            setisLoading(false);
            dispatch(getfranquiciasList({ page_size: 30 }));
            toggleModal();
        }).catch((err) => {
            setisLoading(false);
            console.error('Error desde agregar franquicia');
            toast.error(err)
    
        });
        
        
        
    };

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            image               : undefined,
            tipo_franquicia              : editObject? editObject.tipo_franquicia.id: null,
            ciudad              : editObject? editObject.ciudad.id: 0,
            estado              : editObject? editObject.estado.id: 0,
            direccion           : editObject? editObject.direccion: '',
            nombre              : editObject? editObject.nombre: '',
            descripcion         : editObject? editObject.descripcion :'',
            first_name: '',
            last_name: '',
            email:'',
            password:'',
            img_perfil: null
        },
        validationSchema: Yup.object({
            // image: Yup.mixed().notRequired(),
            nombre: Yup.string().required("Introduzca un nombre de Franquicia"),
            first_name: Yup.string().required("Introduzca un nombre de Asesor Asociado"),
            last_name: Yup.string().required("Introduzca un apellido de Asesor Asociado"),
            email: Yup.string().email('Introduzca un email válido').required('Introduzca un email.'),
            password: Yup.string().required("Introduzca una contraseña"),
            // estado: Yup.string().required("Introduzca un estado"),
        }),
        onSubmit: onSubmitHandler,
    });
    


    const generateRandomPassword = () => {
        const length = 8; // Longitud de la contraseña
        const charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%*()'; // Caracteres permitidos
        let password = '';
      
        for (let i = 0; i < length; i++) {
          const randomIndex = Math.floor(Math.random() * charset.length);
          password += charset.charAt(randomIndex);
        }
        validation.setFieldValue('password',password)
    };


    const  copyToClipboard = () => {
        const passWordValue = validation.values.password
        // Crea un elemento de textarea temporal
        const textarea = document.createElement('textarea');
        
        // Asigna el texto al elemento textarea
        textarea.value = passWordValue;
        
        // Asegúrate de que el elemento textarea esté fuera de la vista
        textarea.style.position = 'fixed';
        textarea.style.top = '-9999px';
        
        // Agrega el elemento textarea al DOM
        document.body.appendChild(textarea);
        
        // Selecciona el texto en el elemento textarea
        textarea.select();
        
        // Copia el texto al portapapeles del sistema
        document.execCommand('copy');
        
        // Elimina el elemento textarea del DOM
        document.body.removeChild(textarea);
        toast.success('Constraseña copiada!, Asegura de entregarlo a la persona correcta. Ctrl -V para pegar')
    }


    const toggle = useCallback(() => {
        toggleModal();
    }, [toggleModal]);


    const estadoValue = Number(validation.values.estado)
    useEffect(() => {
        if (propiedades.ciudades && propiedades.ciudades.results) {
            if (estadoValue !== null || estadoValue!== undefined || estadoValue!==0){
                let filters = propiedades.ciudades.results.filter((e) => e.estado.id === estadoValue)
                setciudadesList(filters)
            }
        }
    }, [estadoValue, propiedades.ciudades]);
    
    return (
        <>
            <Modal
                isOpen={modalState}
                toggle={toggleModal}
                centered
                size="lg"
                className="border-0"
                modalClassName='modal fade zoomIn'>
                <ModalHeader className="p-3 bg-soft-info" toggle={toggleModal}>
                {editObject ? "Editar Franquicia" : "Crear Franquicia"}
                </ModalHeader>
                <Form className="tablelist-form" onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit();
                }}>
                    <ModalBody className="modal-body">
                        <Row>
                            <Col xl={12}>
                                <h6 className="mb-3">Datos de Franquicia</h6>
                            </Col>
                            <Col xl={12}>
                                <InputText
                                    label="Nombre"
                                    name         = "nombre"
                                    validation   = {validation}>
                                </InputText>
                            </Col>
                            <Col xl={4}>
                                <InputSelect
                                    name         = "estado"
                                    label        = "Estado"
                                    validation   = {validation}
                                    optionsProps = {
                                        {
                                            value:'id',
                                            label:'nombre',
                                            items:propiedades.estados.results || []
                                        }}>
                                </InputSelect>  
                                {validation.touched.ciudad && validation.errors.ciudad && (
                                    <FormFeedback type="invalid" className="d-block">{validation.errors.ciudad}</FormFeedback>
                                )}                                
                            </Col>
                            <Col xl={4}>
                                <InputSelect
                                    disabled={!!!validation.values.estado}
                                    name         = "ciudad"
                                    label        = "Ciudades"
                                    validation   = {validation}
                                    optionsProps = {
                                        {
                                            value:'id',
                                            label:'nombre',
                                            items:ciudadesList || []
                                        }}>
                                </InputSelect>  
                                {validation.touched.ciudad && validation.errors.ciudad && (
                                    <FormFeedback type="invalid" className="d-block">{validation.errors.ciudad}</FormFeedback>
                                )}                                
                            </Col>
                            <Col xl={4}>
                                <InputSelect
                                    name         = "tipo_franquicia"
                                    label        = "Tipo"
                                    validation   = {validation}
                                    optionsProps = {
                                        {
                                            value:'id',
                                            label:'nombre',
                                            items:tiposfranquiciaslist || []
                                        }}>
                                </InputSelect>                           
                            </Col>
                            <Col xl={12}>
                                <h6 className="mb-3">Datos de Acceso</h6>
                            </Col>
                            <Col xl={6}>
                                <InputText
                                    label="Nombre"
                                    name         = "first_name"
                                    validation   = {validation}>
                                </InputText>
                            </Col>
                            <Col xl={6}>
                                <InputText
                                    label="Apellido"
                                    name         = "last_name"
                                    validation   = {validation}>
                                </InputText>
                            </Col>
                            <Col xl={12}>
                                <InputText
                                    label="Email"
                                    name         = "email"
                                    validation   = {validation}>
                                </InputText>
                            </Col>
                            <Col xl={7}>
                                <InputText
                                    disabled
                                    label="Contraseña"
                                    name         = "password"
                                    validation   = {validation}>
                                </InputText>
                            </Col>
                            <Col xl={5} className='d-flex flex-column-reverse'>
                            <div className="btn-group mt-4 mt-md-0" role="group" aria-label="Basic example">
                                <Button
                                    onClick={()=> generateRandomPassword()}
                                    className='btn btn-danger btn-block mb-3 btn-label waves-effect right waves-light'>
                                        <i className=" ri-shield-keyhole-line label-icon align-middle fs-16 ms-2"></i>
                                        Crear Clave
                                </Button>
                                <Button
                                    onClick={()=> copyToClipboard()}
                                    className='btn btn-success btn-block mb-3 btn-label waves-effect right waves-light'>
                                        <i className="ri-file-copy-line label-icon align-middle fs-16 ms-2"></i>
                                        Copiar
                                </Button>
                            </div>
                            </Col>
                            <Col>
                                <InputFile
                                    placeholder="<span class='filepond--label-action'>Adjunta</span> una foto de perfil."
                                    name         = "img_perfil"
                                    validation   = {validation}>
                                </InputFile>
                            </Col>
                            <Col>
                                <InputFile
                                    placeholder="<span class='filepond--label-action'>Adjunta</span> una foto de franquicia."
                                    name         = "image"
                                    validation   = {validation}>
                                </InputFile>
                            </Col>
                        </Row>


                    </ModalBody>
                    <div className="modal-footer">
                        <Button color="secondary" className="btn btn-light" onClick={toggle}>
                            Cancel
                        </Button>
                        <Button
                            color="primary"
                            className="btn btn-primary"
                            type="submit"
                            disabled={!validation.isValid || isLoading}>
                                {
                                    ( isLoading) ? (
                                        <span className="d-flex align-items-center">
                                            <span className="flex-grow-1 me-2">
                                                Cargando...
                                            </span>
                                            <span className="spinner-border flex-shrink-0" role="status">
                                                <span className="visually-hidden">Cargando...</span>
                                            </span>
                                        </span>
                                    ) : (
                                        <span className="d-flex align-items-center">
                                            <span className="flex-grow-1 me-2">
                                            {editObject ? "Actualizar" : "Guardar"}
                                            </span>
                                        </span>
                                    )
                                }
                        </Button>
                    </div>
                </Form>
            </Modal>
        </>
    );
};

