import React, { useEffect, useState } from 'react';
import { Row, Col, Label, Input } from 'reactstrap';
import { Link } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { userChangeForm } from '../../../../../../helpers/api_calls';
import { getLoggedinUser } from '../../../../../../helpers/api_helper';
import { toast } from 'react-toastify';

export const ChangePassword = () => {
    const userProfileSession = getLoggedinUser();
    const [userProfileLoaded, setUserProfileLoaded] = useState(false);
    const [userAvailable, setuserAvailable] = useState();
    const [isLoading, setisLoading] = useState();

    const validationSchema = Yup.object().shape({
        old_password: Yup.string()
            .required('La contraseña anterior es necesaria'),
        new_password: Yup.string()
            .required('La nueva contraseña es necesaria')
            .matches(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
                "Debe contener 8 caracteres, un número, una minúscula, una mayúscula y un carácter especial"
            ),
        confirmPassword: Yup.string()
            .oneOf([Yup.ref('new_password'), null], 'Las contraseñas deben coincidir')
            .required('Confirmar contraseña es un campo obligatorio'),
    });

    const formik = useFormik({
        initialValues: {
            old_password: '',
            new_password: '',
            confirmPassword: '',
        },
        validationSchema: validationSchema,
        onSubmit: values => {
            setisLoading(true)
            userChangeForm(userAvailable.id,values).then((res)=>{
                toast.success("Contraseña actualizada con éxito", { autoClose: 3000 });
            }).catch(()=>{
                toast.error("Ocurrió un error al actualizar la contraseña", { autoClose: 3000 });
            }).finally(()=>{
                setisLoading(false)
                resetForm()
            })
        },
    });

    const resetForm = () => {
        formik.resetForm()
    }

    useEffect(() => {
        if (userProfileSession && !userProfileLoaded) {
          setuserAvailable(userProfileSession);
          setUserProfileLoaded(true);

        }
    }, [userProfileSession, userProfileLoaded]);
    return (
        <form onSubmit={formik.handleSubmit}>
            <Row className="g-2">
                <Col lg={4}>
                    <div>
                        <Label htmlFor="old_password" className="form-label">Contraseña anterior*</Label>
                        <Input 
                            type        = "password" 
                            className   = "form-control"
                            id          = "old_password" 
                            name        = "old_password" 
                            onChange    = {formik.handleChange} 
                            onBlur      = {formik.handleBlur} 
                            value       = {formik.values.old_password}
                            placeholder = "Escribe tu contraseña actual" />
                            {formik.errors.old_password && formik.touched.old_password && (
                                <p>{formik.errors.old_password}</p>
                            )}
                    </div>
                </Col>

                <Col lg={4}>
                    <div>
                        <Label htmlFor="new_password" className="form-label">Nueva contraseña*</Label>
                        <Input 
                            type        = "password" 
                            className   = "form-control"
                            id          = "new_password" 
                            name        = "new_password"
                            onBlur      = {formik.handleBlur} 
                            onChange    = {formik.handleChange} 
                            value       = {formik.values.new_password}
                            placeholder = "Escribe tu nueva contraseña" />
                            {formik.errors.new_password && (
                                <p>{formik.errors.new_password}</p>
                            )}
                    </div>
                </Col>

                <Col lg={4}>
                    <div>
                        <Label htmlFor="confirmPassword" className="form-label">Confirmar contraseña*</Label>
                        <Input 
                            type        = "password" 
                            className   = "form-control"
                            id          = "confirmPassword" 
                            name        = "confirmPassword"
                            onChange    = {formik.handleChange}
                            onBlur      = {formik.handleBlur}  
                            value       = {formik.values.confirmPassword}
                            placeholder = "Confirma la contraseña" />
                        {formik.errors.confirmPassword && formik.touched.confirmPassword && (
                            <p>{formik.errors.confirmPassword}</p>
                        )}
                    </div>
                </Col>

                <Col lg={12}>
                    <div className="mb-3">
                        <Link to="#" className="link-primary text-decoration-underline">¿Olvidaste tu contraseña?</Link>
                    </div>
                </Col>

                <Col lg={12}>
                    <div className="text-end">
                        <button type="submit" className="btn btn-primary">Cambiar contraseña</button>
                    </div>
                </Col>
            </Row>
        </form>
    );
};