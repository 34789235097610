// KanbanColumns.js
import React from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import KanbanClienteComp from '../KanbanClienteComp';

const KanbanColumns = ({ columns, filteredColumns, onDragEnd, handleSelection }) => {
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div className="kanban-columns-container">
        {filteredColumns.map((column, key) => (
          <Droppable key={column.id} droppableId={`${column.id}`}>
            {(provided) => (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
                className="kanban-column"
              >
                <KanbanClienteComp
                  refProvided={provided}
                  handleDetalle={handleSelection}
                  column={column}
                  index={key}
                />
              </div>
            )}
          </Droppable>
        ))}
      </div>
    </DragDropContext>
  );
};

export default KanbanColumns;
