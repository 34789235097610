import {
    GET_FRANQUICIAS,
    API_RESPONSE_SUCCESS,
    API_RESPONSE_ERROR,

    ADD_FRANQUICIAS_SUCCESS,
    ADD_FRANQUICIAS_FAIL,
    UPDATE_FRANQUICIAS_SUCCESS,
    UPDATE_FRANQUICIAS_FAIL,
    DELETE_FRANQUICIAS_SUCCESS,
    DELETE_FRANQUICIAS_FAIL,
    GET_FRANQUICIAS_DETAIL,
    IS_LOADING,
    IS_NOT_LOADING,
    RESET_NEW
} from "./franquiciasActionTypes";

const INIT_STATE = {
    franquicia_detail: {},
    franquicias: {
        count      : 0,
        page_number: 1,
        num_pages  : 1,
        per_page   : 10,
        next       : null,
        previous   : null,
        results    : []
    },
    error: {},
    isFranquiciasCreated: false,
    isFranquiciasSuccess: false,
    isLoading: false
};

const Franquicias = (state = INIT_STATE, action) => {
    switch (action.type) {
        case IS_LOADING:
            return {
                ...state,
                isLoading: true,
            };
    
        case IS_NOT_LOADING:
            return {
                ...state,
                isLoading: false,
            };
    
        case RESET_NEW:
            return {
                ...state,
                isFranquiciasCreated: false,
            };


        case API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case GET_FRANQUICIAS:
                    return {
                        ...state,
                        franquicias: action.payload.data,
                        isFranquiciasCreated: false,
                        isFranquiciasSuccess: true
                    };
                case GET_FRANQUICIAS_DETAIL:
                    return {
                        ...state,
                        franquicia_detail: action.payload.data,
                    };
                default:
                    return { ...state };
            }
        case API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case GET_FRANQUICIAS:
                return {
                    ...state,
                    error: action.payload,
                    isFranquiciasCreated: false,
                    isFranquiciasSuccess: false
                };
                default:
                return { ...state };
            }
        case ADD_FRANQUICIAS_SUCCESS:
            return {
                ...state,
                isFranquiciasCreated: true,
                franquicias: [...state.franquicias, action.payload],
            };

        case ADD_FRANQUICIAS_FAIL:
            return {
                ...state,
                error: action.payload,
            };

        case UPDATE_FRANQUICIAS_SUCCESS:
            return {
                ...state,
                franquicias: state.franquicias.map(franquicias =>
                franquicias.id.toString() === action.payload.data.id.toString()
                    ? { ...franquicias, ...action.payload.data }
                    : franquicias
                ),
            };

        case UPDATE_FRANQUICIAS_FAIL:
            return {
                ...state,
                error: action.payload,
            };

        case DELETE_FRANQUICIAS_SUCCESS:
            return {
                ...state,
                franquicias: state.franquicias.filter(
                franquicias => franquicias.id.toString() !== action.payload.franquicias.id.toString()
                ),
                isFranquiciasDelete: true,
                isFranquiciasDeleteFail: false,
            };

        case DELETE_FRANQUICIAS_FAIL:
            return {
                ...state,
                error: action.payload,
                isFranquiciasDelete: false,
                isFranquiciasDeleteFail: true,
            };

        default:
            return { ...state };
    }
};

export default Franquicias;