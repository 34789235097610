import React, { useState, useEffect } from 'react';
import { Card, CardHeader, Col, DropdownMenu, DropdownToggle, Dropdown, DropdownItem, CardBody } from 'reactstrap';
import { useSelector, useDispatch } from "react-redux";
//Import Icons
import FeatherIcon from "feather-icons-react";
import ReactApexChart from "react-apexcharts";

import { getUserDeviceChartsData } from "../../store/dashboardAnalytics/action";

import { UsersByDeviceCharts } from './DashboardAnalyticsCharts';
import { InputDateRange } from '../Forms/Inputs/InputDateRange/InputDateRange';

const UsersByDevice = ({stats, ...props}) => {
    const [isUserDropdown, setUserDropdown] = useState(false);
    const toggleDropdown = () => setUserDropdown(!isUserDropdown);
    const [x, setx] = useState([]);
    const [chartData, setchartData] = useState([]);

    useEffect(()=>{
        if(stats){
            handleData(stats)
        }
    },[stats])

    const handleData = (obj) => {
        if(!obj) return 
        let data = Object.keys(obj).map((key)=>{
            return obj[key]
        })
        setx(Object.keys(obj))
        let series = data
        setchartData(series)
    }
    

    const options = {
        labels: x,
        chart: {
            type: "donut",
            height: 219,
        },
        plotOptions: {
            pie: {
                size: 100,
                donut: {
                    size: "76%",
                },
            },
        },
        dataLabels: {
            enabled: false,
        },
        legend: {
            show: false,
            position: 'bottom',
            horizontalAlign: 'center',
            offsetX: 0,
            offsetY: 0,
            markers: {
                width: 20,
                height: 6,
                radius: 2,
            },
            itemMargin: {
                horizontal: 12,
                vertical: 0
            },
        },
        stroke: {
            width: 0
        },
        yaxis: {
            labels: {
                formatter: function (value) {
                    return value + 'k Users';
                }
            },
            tickAmount: 4,
            min: 0
        }
    };

    

    return (
        <React.Fragment>
            <Col xl={6}>
                <Card className="card-height-100">
                    <CardHeader className="align-items-center d-flex">
                        <h4 className="card-title mb-0 flex-grow-1">Status visitas</h4>
                        <div className="flex-shrink-0">
                            <InputDateRange></InputDateRange>
                        </div>
                    </CardHeader>
                    <CardBody>
                        <div dir="ltr">
                        <ReactApexChart
                            options={options}
                            series={chartData}
                            type="donut"
                            height="219"
                            className="apex-charts"/>
                        </div>

                        <div className="table-responsive mt-3">
                            <table className="table table-borderless table-sm table-centered align-middle table-nowrap mb-0">
                                <tbody className="border-0">
                                    {
                                        (stats) && <>
                                            {Object.keys(stats).map((item,index)=>{
                                                return  <tr key={index}>
                                                        <td>
                                                            <h4 className="text-truncate fs-14 fs-medium mb-0">
                                                                <i className="ri-stop-fill align-middle fs-18 text-primary me-2"></i>
                                                                {item}
                                                            </h4>
                                                        </td>
                                                        <td>
                                                            <p className="text-muted mb-0">
                                                                <FeatherIcon
                                                                    icon="users"
                                                                    className="me-2 icon-sm"/>
                                                            </p>
                                                        </td>
                                                        <td className="text-end">
                                                            <p className="text-success fw-medium fs-12 mb-0">
                                                                <i className="ri-arrow-up-s-fill fs-5 align-middle"></i>
                                                                {stats[item]}
                                                            </p>
                                                        </td>
                                                </tr>
                                                
                                            })}
                                        </>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </CardBody>
                </Card>
            </Col>
        </React.Fragment>
    );
};

export default UsersByDevice;