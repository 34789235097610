import React, { useRef, useImperativeHandle, forwardRef, useState } from 'react';
import PropTypes from 'prop-types';

export const ImprovedFileInput = forwardRef(({
  onFilesChange,
  accept = 'image/*',
  multiple = true,
  maxFileSize = null, // in bytes
  validTypes = null, // e.g., ['image/jpeg', 'image/png']
  label = undefined,
  buttonLabel = 'Browse',
  errorMessage = 'Invalid file type or size.',
  showButton = true, // Flag to show or hide the button
  showLabel = true, // Flag to show or hide the label
  silentMode = false, // Silent mode flag
}, ref) => {
  const inputRef = useRef(null);
  const [error, setError] = useState(null);

  const handleFilesChange = (e) => {
    const files = e.target.files;
    if (validateFiles(files)) {
      onFilesChange(files);
      setError(null);
    } else {
      setError(errorMessage);
    }
  };

  const validateFiles = (files) => {
    if (!files.length) return true;

    for (const file of files) {
      if (maxFileSize && file.size > maxFileSize) return false;
      if (validTypes && !validTypes.includes(file.type)) return false;
    }

    return true;
  };

  useImperativeHandle(ref, () => ({
    openFileDialog: () => {
      inputRef.current?.click();
    },
    reset: () => {
      inputRef.current.value = '';
      setError(null);
    }
  }));

  return (
    <div className={`file-input-wrapper ${silentMode ? 'silent-mode' : ''}`}>
      {showButton || !silentMode && (
        <button type="button" onClick={() => inputRef.current?.click()}>
          {buttonLabel}
        </button>
      )}
      <input
        style={{ display: silentMode ? 'none' : 'block' }} // Hide input in silent mode
        ref={inputRef}
        type="file"
        onChange={handleFilesChange}
        accept={accept}
        multiple={multiple}
      />
      {(label) && <label>{label}</label>}
      {!silentMode && error && <p className="file-input-error">{error}</p>}
    </div>
  );
});

ImprovedFileInput.displayName = 'ImprovedFileInput';

ImprovedFileInput.propTypes = {
  onFilesChange: PropTypes.func.isRequired,
  accept: PropTypes.string,
  multiple: PropTypes.bool,
  maxFileSize: PropTypes.number,
  validTypes: PropTypes.arrayOf(PropTypes.string),
  label: PropTypes.string,
  buttonLabel: PropTypes.string,
  errorMessage: PropTypes.string,
  showButton: PropTypes.bool, // Prop to control button visibility
  showLabel: PropTypes.bool, // Prop to control label visibility
  silentMode: PropTypes.bool, // Prop to enable silent mode
};
