import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { Button, Card, CardBody, Col, Container, Row } from 'reactstrap';
import { Link } from 'react-router-dom';

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/pagination";

import { PROPIEDADES, PROPIEDADESPUBLICAS } from '../../../helpers/api_urls';
import { createApiMethods } from '../../../helpers/api_calls';
import { PublicCardTemplate } from '../../../Components/PublicCardTemplate/PublicCardTemplate';
import { SkeletonCard } from '../../../Components/SkeletonCard/SkeletonCard';


const Home = forwardRef(({gridStyle},ref) => {
    const apiPropiedadesPublicas = useMemo(() => createApiMethods(PROPIEDADESPUBLICAS), []);
    const [propiedadesApiResponde, setpropiedadesApiResponde] = useState(null);
    const [propiedadeslist, setpropiedadeslist] = useState([]);
    const [filterSet, setfilterSet] = useState({});
    const [isLoading, setisLoading] = useState(false);

    const colXS = 12 //1
    const colMD = 6 //2
    const colLG = 3 //3
    const colXL = 3 //4

    const [XS, setXS] = useState(colXS);
    const [MD, setMD] = useState(colMD);
    const [LG, setLG] = useState(colLG);
    const [XL, setXL] = useState(colXL);

    useEffect(()=>{
        if(propiedadesApiResponde === null){
            callPaginationPublicList(filterSet)
        }
    },[])

    useEffect(()=>{
        if(propiedadesApiResponde && propiedadesApiResponde.results){
            setpropiedadeslist([...propiedadeslist,...propiedadesApiResponde.results])
        }
    },[propiedadesApiResponde]) 

    const callPaginationPublicList = (filterSet) => {
        setisLoading(true)
        apiPropiedadesPublicas.getAll(filterSet)
        .then(res => {
            setpropiedadesApiResponde(res)
        })
        .catch(console.error)
        .finally(()=>{
            const anchor = propiedadeslist[propiedadeslist.length - 1]
            if(anchor){
                const targetElement = document.getElementById(`${anchor.id}-card`);
                targetElement.scrollIntoView();
            }            
            setisLoading(false)
        })
    }

    const cleanOptions = (options) => {
        // Filtrar las opciones para eliminar las claves con valores nulos o undefined
        return Object.fromEntries(
          Object.entries(options || {}).filter(([_, value]) => value !== null && value !== undefined)
        );
    };

    const callFilterConfigSet = (filterObj) => {
        setisLoading(true)
        let filters = {
            ...cleanOptions(filterObj),
            page: 1
        }
        setfilterSet(filters);
        apiPropiedadesPublicas.getAll(filters)
        .then(res => {
            setpropiedadeslist([])
            setpropiedadesApiResponde(res)

            const anchor = res.results[0]
            const targetElement = document.getElementById(`${anchor?.id}-card`);
            if(anchor && targetElement){
                targetElement.scrollIntoView();
            }               
        })
        .catch(console.error)
        .finally(()=>{
            setisLoading(false)
        })
    }


    useEffect(()=>{
        if(gridStyle){
            setXS(colXS)
            setMD(colMD)
            setLG(colLG)
            setXL(colXL)
        } else {
            setXS(12)
            setMD(8)
            setLG(8)
            setXL(8)
        }
    },[gridStyle])

    useImperativeHandle(ref, () => ({
        callFilterConfigSet,
    }));
    

    return (
        <React.Fragment>
            <section className="section pb-0 hero-section" id="hero">
                <div style={{minHeight: '90vh'}} className="bg-overlay bg-overlay-pattern"></div>
                <Container style={{paddingBottom: '20rem'}}>
                    <Row className="justify-content-center">
                        {
                            propiedadeslist.map((item, idx)=>{
                                return (
                                    <Col key={idx} sm={XS} md={MD} lg={LG} xl={XL} xxl={XL} >
                                        <PublicCardTemplate 
                                            item={item} 
                                            gridStyle={gridStyle}>
                                        </PublicCardTemplate>
                                    </Col>                                    
                                )
                            })                            
                        }
                        {
                            isLoading &&
                            loadingSkeletons()
                        }
                    </Row>
                    {
                        (propiedadesApiResponde !== null && propiedadesApiResponde?.next) &&
                        <Row className="justify-content-center">
                            <Col lg={8}>
                                <Button onClick={()=>{
                                    let prevPage = filterSet.page || 1
                                    let newFilterset = {
                                        ...filterSet,
                                        page: prevPage + 1
                                    }
                                    setfilterSet(newFilterset);
                                    callPaginationPublicList(newFilterset)                                    
                                }} className='btn btn-light btn-block w-100 mb-5'>
                                    Cargar mas...
                                </Button>
                            </Col>                        
                        </Row>
                    }
                </Container>
            </section>
        </React.Fragment>
    );

    function loadingSkeletons() {
        const skeletons = [];
      
        for (let i = 0; i < 3; i++) {
          skeletons.push(
            <Col key={i} sm={XS} md={MD} lg={LG} xl={XL} xxl={XL}>
              <SkeletonCard gridStyle={gridStyle}></SkeletonCard>
            </Col>
          );
        }
      
        return skeletons;
    }
      
});

Home.displayName = 'Home';

export default Home