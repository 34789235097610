import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { FormFeedback, Label, Input } from 'reactstrap';
import './InputFormated.css';
import Cleave from 'cleave.js/react';

const InputFormated = forwardRef(({ validation, placeholder = "" , optionsProps, type = 'text', handler, ...props },ref) => {
    const validationValue = validation.values[props?.name]
    const [rawInputValue, setrawInputValue] = useState(validationValue);

    const formatValue = (value) => {
        if(value !== null){
            if (props.format === 'phone') {
                const phoneNumber = value.replace(/\D/g, ''); // Eliminar todos los caracteres no numéricos
                return `+${phoneNumber.slice(0, 2)} ${phoneNumber.slice(2, 5)} ${phoneNumber.slice(5, 8)} ${phoneNumber.slice(8)}`;
            } else if (props.format === 'instagram') {
                return `@${value}`;
            }
            return value; // Mantener el valor sin cambios si no hay formato específico
        }
        else{
            return value
        }
    };

    function onNumberChange(e) {
        setrawInputValue(e.target.rawValue);
        validation.setFieldValue(props?.name,e.target.rawValue)
        handler(e.target.rawValue)
    }

    const resetInput = () => {
        setrawInputValue(''); // Establece el valor en blanco
        validation.setFieldValue(props?.name, ''); // También puedes establecer el valor en blanco en el estado de validación si es necesario
        handler(''); // Llama a tu manejador con un valor en blanco si es necesario
    };


    useImperativeHandle(ref, () => ({
        reset : resetInput,
    }));


    useEffect(()=>{
        if(validationValue === null){
            setrawInputValue(null)
        }
    },[validationValue])
    return (
        <div>
            {
                (props?.label !== " ") &&
                <Label htmlFor={props?.name || null} className="form-label">
                    {props?.label || ' '} 
                    { props.required ? <span className="text-danger">*</span> : ' ' }    
                </Label>
            }
            <Cleave
                placeholder={placeholder}
                options={{
                    numeral: true,
                    numeralThousandsGroupStyle: 'thousand'
                }}
                value={rawInputValue}
                onChange={e => onNumberChange(e)}
                className="form-control form-control-sm"/>
            {validation.touched[props?.name] && validation.errors[props?.name] ? (
                <FormFeedback type="invalid" className="d-block"><div>{validation.errors[props?.name]}</div></FormFeedback>
            ) : null}
        </div>
    );
});

InputFormated.displayName = 'InputFormated';
export { InputFormated }