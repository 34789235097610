import React from 'react';
import './SkeletonCard.css';
import { Card, CardBody } from 'reactstrap';

export const SkeletonCard = ({gridStyle}) => {
    return (
        <>
            <Card className="explore-box">
                <CardBody>
                    <div className="d-flex align-items-center mb-3 w-100">
                        <img style={{width: '2rem', height: '2rem'}} className={`card___header avatar-xs rounded-circle header__img skeleton`} id="logo-img" alt="" />
                        <div className="ms-2 flex-grow-1">
                            <h6 className="mb-1 fs-15 text-ellipsis">
                                <div className="skeleton skeleton-text"></div>

                            </h6>
                            <p className="mb-0 fs-14 text-muted text-ellipsis">
                                <div className="skeleton skeleton-text"></div>
                            </p>
                        </div>
                    </div>
                    <div className={`overflow-hidden rounded inmob-img-parent ${gridStyle ? 'h-80':'h-800'}`}>
                        <img className={`${gridStyle ? 'h-80 w-80':'h-800 w-800'} skeleton`} id="logo-img" alt="" />
                    </div>
                    <div className="mt-3">
                        <div className="skeleton skeleton-text"></div>

                        <div className="skeleton skeleton-text"></div>

                        <div className="skeleton skeleton-text"></div>

                    </div>
                </CardBody>
            </Card>        
        </>
    );
};
