import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import './FilterCanvas.css';
import { Card, CardBody, Col, Offcanvas, OffcanvasBody, OffcanvasHeader, Row } from 'reactstrap';
import SimpleBar from "simplebar-react";
import { FilterComponent } from '../../../Ecommerce/EcommerceProducts/FilterComponent/FilterComponent';
import { createApiMethods } from '../../../../helpers/api_calls';
import { ESTADOS, INMUEBLES, TIPOSINMUEBLES } from '../../../../helpers/api_urls';
import { useDispatch } from 'react-redux';

const FilterCanvas = forwardRef(({callApi,...props},ref) => {
    const canvasRef = useRef(null);
    const [show, setshow] = useState(false);
    const filterFormRef = useRef(null);
    const [estadosList, setestadosList] = useState();
    const [tiposInmueblesList, settiposInmueblesList] = useState([]);
    const [franquiciasList, setfranquiciasList] = useState([]);
    const apiEstados = useMemo(() => createApiMethods(ESTADOS), []);
    const apiTiposInmuebles = useMemo(() => createApiMethods(TIPOSINMUEBLES), []);
    const [filterValues, setfilterValues] = useState({});
    const dispatch = useDispatch();

    useEffect(() => {
        apiEstados.getAll({page_size: 1000}).then((res)=>{
          setestadosList(res.results)
        }).catch((err)=>{
          console.log(err)
        }).finally(()=>{
    
        })
    
        apiTiposInmuebles.getAll({page_size: 1000}).then((res)=>{
          settiposInmueblesList(res.results)
        }).catch((err)=>{
          console.log(err)
        }).finally(()=>{
    
        })
      }, [dispatch]);


    const toggle = () =>{
        setshow(!show)
    }

    const _toggle = () => () =>{
        setshow(!show)
    }

    useImperativeHandle(ref, () => ({
        toggle,
        filterValues
    }));

    const handleSelectionsFilters = (filters) => {
        callApi(filters)
        setfilterValues(filters)
    }

    return (
        <>
            <Offcanvas 
                offcanvasTransition={{ timeout: 300 }}
                innerRef={canvasRef} 
                isOpen={show} 
                toggle={toggle} 
                direction="end" 
                className="offcanvas-end border-0">
                <OffcanvasHeader className="d-flex align-items-center bg-primary bg-gradient p-3 offcanvas-header-dark" toggle={toggle}>
                    <span className="m-0 me-2 text-white">Filtros</span>
                </OffcanvasHeader>
                <OffcanvasBody className="p-0">
                    <SimpleBar className="h-100">
                        <Row>
                            <Col sm={12}>
                                <Card id="contact-view-detail">
                                    <CardBody className="text-center">
                                    <FilterComponent
                                        notitle={true}
                                        ref={filterFormRef}
                                        estados={estadosList}
                                        tiposinmuebles={tiposInmueblesList}
                                        handler={handleSelectionsFilters}
                                        franquicias={franquiciasList}>
                                    </FilterComponent>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </SimpleBar>
                </OffcanvasBody>
            </Offcanvas>        
        </>
    );
});

FilterCanvas.displayName = 'FilterCanvas';

export default FilterCanvas