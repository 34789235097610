import React, { forwardRef, useImperativeHandle, useState } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

export const PropertyReservaModal = forwardRef(({ inmueble }, ref) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const dispatch = useDispatch();

  useImperativeHandle(ref, () => ({
    openForm: () => setIsOpen(true),
  }));

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const validation = useFormik({
    initialValues: {
      cliente: "",
      fechaReserva: "",
      comentarios: "",
    },
    validationSchema: Yup.object({
      cliente: Yup.string().required("Seleccionar un cliente es obligatorio."),
      fechaReserva: Yup.date().required("Seleccionar una fecha es obligatorio."),
      comentarios: Yup.string().optional(),
    }),
    onSubmit: async (values) => {
      setIsSubmitting(true);
      // try {
      //   await dispatch(reserveProperty(inmueble.id, values));
      //   toast.success("Reserva realizada con éxito", { autoClose: 3000 });
      //   setIsOpen(false);
      // } catch (error) {
      //   toast.error("Error al realizar la reserva", { autoClose: 3000 });
      // } finally {
      //   setIsSubmitting(false);
      // }
    },
  });

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Reservar Inmueble</ModalHeader>
      <ModalBody>
        <form onSubmit={validation.handleSubmit}>
          <div className="mb-3">
            <label htmlFor="cliente" className="form-label">
              Cliente
            </label>
            <input
              id="cliente"
              name="cliente"
              type="text"
              className={`form-control ${validation.touched.cliente && validation.errors.cliente ? "is-invalid" : ""}`}
              value={validation.values.cliente}
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
            />
            {validation.touched.cliente && validation.errors.cliente ? (
              <div className="invalid-feedback">{validation.errors.cliente}</div>
            ) : null}
          </div>
          <div className="mb-3">
            <label htmlFor="fechaReserva" className="form-label">
              Fecha de Reserva
            </label>
            <input
              id="fechaReserva"
              name="fechaReserva"
              type="date"
              className={`form-control ${validation.touched.fechaReserva && validation.errors.fechaReserva ? "is-invalid" : ""}`}
              value={validation.values.fechaReserva}
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
            />
            {validation.touched.fechaReserva && validation.errors.fechaReserva ? (
              <div className="invalid-feedback">{validation.errors.fechaReserva}</div>
            ) : null}
          </div>
          <div className="mb-3">
            <label htmlFor="comentarios" className="form-label">
              Comentarios
            </label>
            <textarea
              id="comentarios"
              name="comentarios"
              className="form-control"
              rows="3"
              value={validation.values.comentarios}
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
            />
          </div>
        </form>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggle}>
          Cancelar
        </Button>
        <Button color="primary" onClick={validation.handleSubmit} disabled={isSubmitting}>
          {isSubmitting ? (
            <Spinner size="sm" className="me-2" />
          ) : (
            <i className="ri-check-double-line label-icon align-middle fs-16 me-2"></i>
          )}
          Reservar
        </Button>
      </ModalFooter>
    </Modal>
  );
});

PropertyReservaModal.displayName = "PropertyReservaModal";
