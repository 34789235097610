import {
  API_RESPONSE_SUCCESS, 
  API_RESPONSE_ERROR, 
  GET_PROPIEDADES, 
  ADD_NEW_PROPIEDADES, 
  ADD_PROPIEDADES_SUCCESS, 
  ADD_PROPIEDADES_FAIL, 
  UPDATE_PROPIEDADES, 
  UPDATE_PROPIEDADES_SUCCESS, 
  UPDATE_PROPIEDADES_FAIL, 
  DELETE_PROPIEDADES, 
  DELETE_PROPIEDADES_SUCCESS, 
  DELETE_PROPIEDADES_FAIL,
  GET_ESTADOS,
  GET_CIUDADES,
  GET_FRANQUICIAS,
  GET_TIPOSINMUEBLES,
  IS_LOADING,
  IS_NOT_LOADING,
  GET_TIPOSNEGOCIACION,
  GET_PROPIEDADES_DETAIL,
  RESET_NEW
} from  "./propiedadesActionTypes.js";

export const clearResetNew = () => ({
  type: RESET_NEW,
});

export const isLoadingNow = () => ({
  type: IS_LOADING,
});

export const isNotLoading = () => ({
  type: IS_NOT_LOADING,
});

export const propiedadesApiResponseSuccess = (actionType, data) => ({
  type: API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});

export const propiedadesApiResponseError = (actionType, error) => ({
  type: API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const getpropiedades = (urlParams) =>  ({
  type: GET_PROPIEDADES,
  urlParams
})

export const getpropiedaddetails = (idInmueble) =>  ({
  type: GET_PROPIEDADES_DETAIL,
  idInmueble
})


export const updatepropiedades = propiedades => ({
  type: UPDATE_PROPIEDADES,
  payload: propiedades,
});

export const updatepropiedadesSuccess = propiedades => ({
  type: UPDATE_PROPIEDADES_SUCCESS,
  payload: propiedades,
});

export const updatepropiedadesFail = error => ({
  type: UPDATE_PROPIEDADES_FAIL,
  payload: error,
});

export const addNewpropiedades = propiedades => ({
  type: ADD_NEW_PROPIEDADES,
  payload: propiedades,
});

export const addpropiedadesSuccess = propiedades => ({
  type: ADD_PROPIEDADES_SUCCESS,
  payload: propiedades,
});

export const addpropiedadesFail = error => ({
  type: ADD_PROPIEDADES_FAIL,
  payload: error,
});

export const deletepropiedades = propiedades => ({
  type: DELETE_PROPIEDADES,
  payload: propiedades,
});

export const deletepropiedadesSuccess = propiedades => ({
  type: DELETE_PROPIEDADES_SUCCESS,
  payload: propiedades,
});

export const deletepropiedadesFail = error => ({
  type: DELETE_PROPIEDADES_FAIL,
  payload: error,
});


export const getestados = (urlParams) => ({
  type: GET_ESTADOS,
  urlParams
});

export const getciudades = (urlParams) => ({
  type: GET_CIUDADES,
  urlParams
});

export const getfranquicias = (urlParams) => ({
  type: GET_FRANQUICIAS,
  urlParams
});

export const gettiposinmuebles = (urlParams) => ({
  type: GET_TIPOSINMUEBLES,
  urlParams
});

export const gettiposnegociacion = (urlParams) => ({
  type: GET_TIPOSNEGOCIACION,
  urlParams
});