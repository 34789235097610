import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import './PublicDetailCard.css';
import Home from '../../pages/Job_Landing/Home';
import PublicDetailCardTemplate from '../PublicDetailCardTemplate/PublicDetailCardTemplate';
import { useParams } from 'react-router-dom';
import Navbar from '../../pages/Landing/OnePage/navbar';
import Footer from '../../pages/Landing/OnePage/footer';
import { createApiMethods } from '../../helpers/api_calls';
import { PROPIEDADESPUBLICAS } from '../../helpers/api_urls';
import { AbilityContext } from '../../helpers/acl/Can';
import { PROPIEDAD_ACL } from '../../helpers/entities';
import { toast } from "react-toastify";


export const PublicDetailCard = () => {
    const [gridStyle, setgridStyle] = useState(true);
    document.title =" TimeLine | Inmoapp";
    const detailRef = useRef(null);
    const { idinmueble } = useParams();
    console.log(idinmueble)
    const apiPropiedadesPublicas = useMemo(() => createApiMethods(PROPIEDADESPUBLICAS), []);
    const [inmueble, setInmueble] = useState(null);
    const ability = useContext(AbilityContext)
    
    const callFilterSelection = (filterSet) => {
    }

    useEffect(() => {
        detailRef.current.setisLoading(true)
        apiPropiedadesPublicas.getDetails(idinmueble).then((p) =>{
          setInmueble({
            obj_type: PROPIEDAD_ACL,
            ...p
          })
        }).catch(toast.error).finally(()=>{
            detailRef.current.setisLoading(false)
        })
    }, []);

    document.title="Detalles de Inmueble | Inmoapp";
    
    return (
        <React.Fragment>            
            <div className="layout-wrapper landing">
                <Navbar setgridStyle={true} />
                    <PublicDetailCardTemplate item={inmueble} ref={detailRef} />
                <Footer />
            </div>
        </React.Fragment>
    );
};
