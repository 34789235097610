import React, { useContext, useEffect } from "react";
import { Navigate, Route, useNavigate } from "react-router-dom";
import {setAuthorization} from "../helpers/api_helper";
import { useDispatch } from "react-redux";

import { useProfile } from "../Components/Hooks/UserHooks";

import { logoutUser } from "../store/actions";
import { AuthContext } from "../AuthContext";

const AuthProtected = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userProfile, loading, token } = useProfile();

  const isAccessTokenExpired = (accessTokenExpiresTimestamp) => {
    const currentTime = Date.now(); // Obtiene la marca de tiempo actual en milisegundos
    return currentTime >= accessTokenExpiresTimestamp * 1000;
  };

  const { isAuthenticated } = useContext(AuthContext);


  if (!isAuthenticated) {
    return (
      <Navigate to={{ pathname: "/login", state: { from: props.location } }} />
    );
  }

  return <>{props.children}</>;
};

const AccessRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => {
        return (<> <Component {...props} /> </>);
      }}
    />
  );
};

export { AuthProtected, AccessRoute };