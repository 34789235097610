import React from 'react';
import './InputSwitch.css';

import { FormFeedback, Label, Input } from 'reactstrap';

export const InputSwitch = ({ validation, optionsProps, ...props }) => {
    return (
        <div className="mb-3">
            <Label htmlFor={props?.name || null} className="form-label">
                {props?.label || ' '} 
                { props.required ? <span className="text-danger">*</span> : ' ' }    
            </Label>
            <div className="form-check form-switch form-switch-lg" dir="ltr">
                {
                    (validation.values[props?.name] === true) ? (
                        <Label htmlFor={props?.name || null} className="form-label">
                            Si
                        </Label>
                    ) : (
                        <Label htmlFor={props?.name || null} className="form-label">
                            No
                        </Label>
                    )                    
                }
                <Input 
                    type="checkbox" 
                    name      = {props?.name || null}
                    className = "form-control mb-3" 
                    onChange  = {validation.handleChange}
                    onBlur    = {validation.handleBlur}
                    placeholder={props?.placeholder}
                    checked     = {validation.values[props?.name] || false}
                    invalid   = {
                        validation.touched[props?.name] && validation.errors[props?.name] ? true : false
                    }>
                </Input>
            </div>
        </div>
    );
};