import axios from "axios";
import { api } from "../config";
import { logoutUser } from "../store/actions";
import { toast } from "react-toastify";
import { formatDistanceToNow } from 'date-fns';
import { useAuth } from "../AuthContext";
import { es } from 'date-fns/locale';

export function formatDateFromNow(date) {
  const distance = formatDistanceToNow(new Date(date), { locale: es, addSuffix: true });
  return `${distance.charAt(0).toUpperCase()}${distance.slice(1)}`;
}

// default
axios.defaults.baseURL = process.env.REACT_APP_API_URL;
// content type
axios.defaults.headers.post["Content-Type"] = "application/json";

// content type
const token = JSON.parse(sessionStorage.getItem("authUser")) ? JSON.parse(sessionStorage.getItem("authUser")).token : null;
if(token)
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;


export const setAxiosInterceptors = (store, history) => {
  axios.interceptors.response.use(
    function (response) {
      if (response.status >= 200 && response.status < 300) {
        return response.data ? response.data : response;
      }
      return response;
    },
    function (error) {
      console.log(error)
      let message;
      switch (error.response?.status) {
        case 500:
          message = "Internal Server Error";
          break;
        case 401:
          store.dispatch(logoutUser());
          history.push('/login')
          window.location.reload();
          message = "Credenciales Inválidas";
          break;
        case 404:
          message = "Sorry! the data you are looking for could not be found";
          break;
        case 400:
          message = "Sorry! the data you are looking for could not be found";
          break;
        default:
          message = error.message || error;
      }
      if(error.response?.data){
        let { data } = error.response
        message=''
        for (const key in data) {
          message+= `${key}:${data[key]}`
        }
      }
      
      return Promise.reject(message);
    }
  );
};

/**
 * Sets the default authorization
 * @param {*} token
 */
const setAuthorization = (token) => {
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;
};

class APIClient {
  /**
   * Fetches data from given url
   */

  //  get = (url, params) => {
  //   return axios.get(url, params);
  // };
  // get = (url, params) => {
  //   let response;

  //   let paramKeys = [];
  //   if (params) {
  //     Object.keys(params).map(key => {
  //       paramKeys.push(key + '=' + params[key]);
  //       return paramKeys;
  //     });

  //     const queryString = paramKeys && paramKeys.length ? paramKeys.join('&') : "";
  //     response = axios.get(`${url}?${queryString}`, params);
  //   } else {
  //     response = axios.get(`${url}`, params);
  //   }

  //   return response;
  // };

  get = (url, params) => {
    let response;
    const defaultParams = {
      page_size: 10,
      page: 1,
    };

    if(params === undefined){
      return axios.get(`${url}`);
    }
    params = {...defaultParams,...params} 
  
    let paramKeys = [];

    if (params) {
      Object.keys(params).map(key => {
        paramKeys.push(key + '=' + params[key]);
        return paramKeys;
      });

      const queryString = paramKeys && paramKeys.length ? paramKeys.join('&') : "";
      response = axios.get(`${url}?${queryString}`, params);
    } else {
      response = axios.get(`${url}`, params);
    }

    return response;
  };

  /**
   * post given data to url
   */
  create = (url, data,props = {}) => {
    return axios.post(url, data,props);
  };
  /**
   * Updates data
   */
  update = (url, data,options) => {
    // const filteredData = Object.keys(data).reduce((acc, key) => {
    //   if (data[key] !== null && data[key] !== "") {
    //     acc[key] = data[key];
    //   }
    //   return acc;
    // }, {});
  
    return axios.patch(url, data,{...options});
  };

  put = (url, data) => {
    return axios.put(url, data);
  };
  /**
   * Delete
   */
  delete = (url, config) => {
    return axios.delete(url, { ...config });
  };
}
const getLoggedinUser = () => {
  const user = localStorage.getItem("authUser");
  if (!user) {
    return null;
  } else {
    return JSON.parse(user);
  }
};


export const userValidators = (user) => {
  return {
    isSuperAdmin: () => (user.profile.slug_name === "superusuario" || user.profile.slug_name === "superusuario") ,
    isAdmin: () => (user.profile.slug_name === "superusuario" || user.profile.slug_name === "superusuario") ,
    isGerente: () => user.profile.slug_name === "gerente" ,
    isAsesor: () => user.profile.slug_name === "asesor" ,
    isAuditor: () => user.profile.slug_name === "auditor" ,
    isAutor: (id) => user.id === Number(id),
    user : user,
    id : user.id,
    franquicia: () => user.franquicia ? user.franquicia :false,
    isFranquiciaGeneral: () => user.franquicia ? user.franquicia.tipo_franquicia.slug_name === 'franquicia_general' : false,
    isFranquiciaComercial: () => user.franquicia ? user.franquicia.tipo_franquicia.slug_name === 'franquicia_comercial' : false,
    isFranquiciaPersonal: () => user.franquicia ? user.franquicia.tipo_franquicia.slug_name === 'franquicia_personal' : false,
    isMembresia: () => user.franquicia ? user.franquicia.tipo_franquicia.slug_name === 'membresia' : false,
  };
};

export { APIClient, setAuthorization, getLoggedinUser };

export function handleError(error) {
  let errorMessage = '';
  return error;
}

export function JSONToFormData(objeto) {
  const formData = new FormData();

  for (const [key, value] of Object.entries(objeto)) {
    if (value !== null && value !== "" && value !== undefined) {
      if (typeof value === 'object' && !(value instanceof File)) {
        // Si el valor es un objeto pero no es un archivo, lo convertimos a FormData
        const subFormData = JSONToFormData(value);
        formData.append(key, subFormData);
      } else {
        // Si el valor no es un objeto, lo agregamos directamente al FormData
        formData.append(key, value);
      }
    }
  }

  return formData;
}

export function convertirAFormData(objeto, formData = new FormData(), prefijo = '') {
  for (const [key, value] of Object.entries(objeto)) {
    const nombrePropiedad = prefijo ? `${prefijo}[${key}]` : key;

    if (value !== null && value !== "" && value !== undefined) {
      if (typeof value === 'object' && !(value instanceof File)) {
        // Si el valor es un objeto pero no es un archivo, llamamos de forma recursiva a la función
        convertirAFormData(value, formData, nombrePropiedad);
      } else {
        // Si el valor no es un objeto, lo agregamos directamente al FormData
        formData.append(nombrePropiedad, value);
      }
    }
  }

  return formData;
}

export function crearFormData(objeto, formData = new FormData(), prefijo = '') {
  for (const [key, value] of Object.entries(objeto)) {
    const nombrePropiedad = prefijo ? `${prefijo}[${key}]` : key;

    if (value !== null && value !== undefined && value !== "") {
      if (Array.isArray(value)) {
        if (value.length > 0 && value[0] instanceof File) {
          // Si es un arreglo de archivos, los manejamos directamente
          value.forEach((file, index) => {
            formData.append(`${nombrePropiedad}[${index}]`, file);
          });
        } else if (value.length > 0 && typeof value[0] === 'object') {
          // Si es un arreglo de objetos, manejamos cada objeto de forma recursiva
          value.forEach((elemento, index) => {
            crearFormData(elemento, formData, `${nombrePropiedad}[${index}]`);
          });
        } else {
          // Si es un arreglo de valores primitivos, los agregamos directamente
          value.forEach((elemento, index) => {
            formData.append(`${nombrePropiedad}[${index}]`, elemento);
          });
        }
      } else if (typeof value === 'object' && !(value instanceof File)) {
        // Si el valor es un objeto pero no es un archivo, lo manejamos recursivamente
        crearFormData(value, formData, nombrePropiedad);
      } else {
        // Si es un valor primitivo o un archivo, lo agregamos directamente
        formData.append(nombrePropiedad, value);
      }
    }
  }

  return formData;
}




export const generateRandomPassword = () => {
  const length = 8; // Longitud de la contraseña
  const charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%*()'; // Caracteres permitidos
  let password = '';

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * charset.length);
    password += charset.charAt(randomIndex);
  }

  return password
};


export const  copyToClipboard = (value) => {
  const passWordValue = value
  // Crea un elemento de textarea temporal
  const textarea = document.createElement('textarea');
  
  // Asigna el texto al elemento textarea
  textarea.value = passWordValue;
  
  // Asegúrate de que el elemento textarea esté fuera de la vista
  textarea.style.position = 'fixed';
  textarea.style.top = '-9999px';
  
  // Agrega el elemento textarea al DOM
  document.body.appendChild(textarea);
  
  // Selecciona el texto en el elemento textarea
  textarea.select();
  
  // Copia el texto al portapapeles del sistema
  document.execCommand('copy');
  
  // Elimina el elemento textarea del DOM
  document.body.removeChild(textarea);
  toast.success('Constraseña copiada!, Asegura de entregarlo a la persona correcta. Ctrl -V para pegar')
}

export const tipoFranquiciasList = [
  {
      "id": 1,
      "nombre": "Franquicia General",
      "descripcion": "Franquicia general",
      "slug_name": "franquicia_general"
  },
  {
      "id": 2,
      "nombre": "Franquicia Comercial",
      "descripcion": "Franquicia comercial",
      "slug_name": "franquicia_comercial"
  },
  {
      "id": 3,
      "nombre": "Franquicia Personal",
      "descripcion": "Franquicia personal",
      "slug_name": "franquicia_personal"
  },
  {
      "id": 4,
      "nombre": "Membresía",
      "descripcion": "Membresia",
      "slug_name": "membresia"
  }
]

export function removeNullOrUndefinedProperties(obj) {
  for (const key in obj) {
      // eslint-disable-next-line no-prototype-builtins
      if (obj.hasOwnProperty(key)) {
          if (obj[key] === null || obj[key] === undefined) {
              delete obj[key];
          }
      }
  }

  return obj
}

export const paginationModel ={
  count: 0,
  page_number: 1,
  num_pages: 1,
  per_page: 10,
  next: null,
  previous: null,
  results: []
}

export const tipoEventos =[
  {
    id: 1,
    nombre: 'Visita',
    value: 'visita'
  },
  {
    id: 2,
    nombre: 'Otros',
    value: 'otros'
  }
]

export const str_dt = (date) => {
  var monthNames = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];
  var d = new Date(date),
    month = "" + monthNames[d.getMonth()],
    day = "" + d.getDate(),
    year = d.getFullYear();
  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;
  return [day + " " + month, year].join(",");
};

export const date_r = (date) => {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();
  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;
  return [year, month, day].join("-");
};


export const getTime = (params) => {
  params = new Date(params);
  if (params.getHours() != null) {
    const hour = params.getHours();
    const minute = params.getMinutes() ? params.getMinutes() : "00";
    return hour + ":" + minute;
  }
};

export const getTime__ = (params) => {
  params = new Date(params);
  if (params.getHours() != null) {
    const hour = params.getHours();
    const minute = params.getMinutes() ? params.getMinutes() : "00";
    return hour + ":" + minute;
  }
};


export const tConvert = (time) => {
  if (!time)
    return ""
  const t = time.split(":");
  var hours = t[0];
  var minutes = t[1];
  var newformat = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12;
  minutes = minutes < 10 ? "0" + minutes : minutes;
  return hours + ":" + minutes + " " + newformat;
};

export function extractInitials(name) {
  const words = name.split(" ");
  let initials = "";

  for (let i = 0; i < words.length && i < 2; i++) {
    if (words[i]) {
      initials += words[i][0];
    }
  }

  return initials.toUpperCase();
}

export function organizeContactsByFirstLetter(arr) {
  const result = [];

  // Obtener una lista de letras iniciales únicas
  const uniqueLetters = [...new Set(arr.map(item => item.full_name[0]))];

  // Ordenar las letras iniciales
  uniqueLetters.sort();

  // Organizar los contactos en la estructura deseada
  uniqueLetters.forEach(letter => {
    const contacts = arr.filter(item => item.full_name[0] === letter);
    result.push({ title: letter, contacts });
  });

  return result;
}

export function formatDateCustom(date) {
  const fecha = new Date(date);
  const opciones = { 
    weekday: 'long', 
    year: 'numeric', 
    month: 'long', 
    day: 'numeric' 
  };
  return fecha.toLocaleDateString('es-VE', opciones);
}

export function convertToAMPM(isoDate) {

  const date = new Date(isoDate);

  let hours = date.getHours();
  const minutes = date.getMinutes();
  const ampm = hours >= 12 ? 'PM' : 'AM';
  
  hours = hours % 12;
  hours = hours ? hours : 12; // las horas 0 serán 12
  
  const strTime = `${hours}:${minutes} ${ampm}`;

  return strTime;

}

// function formatDate(date) {
//   const distance = formatDistanceToNow(new Date(date), { locale: es, addSuffix: true });
//   return `${distance.charAt(0).toUpperCase()}${distance.slice(1)}`;
// }

export function checkPermissions(obj, currentUser) {
  // // Revisar si es el autor
  // if(obj.autor === currentUser.id) {
  //   return true;
  // }

  // Revisar si está en editores
  if(obj.editores.includes(currentUser.id)) {
    return true;
  }

  return false;

}


export function isClienteAuth(cliente, currentUser) {
  if (!cliente) return false
  
  if(cliente?.franquicia?.usuario_asignado?.id === currentUser.user.id) {
    return true;
  }


  if(cliente.autor === currentUser.user.id) {
    return true;
  }

  // Revisar si está en editores
  if(cliente.editores.includes(currentUser.user.id)) {
    return true;
  }

  return false;

}

export function formatDateToYYYYMMDD(date) {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Los meses son 0-indexados, así que sumamos 1 y formateamos como cadena.
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
}

export function generateUniqueId() {
  const timestamp = new Date().getTime(); // Marca de tiempo actual en milisegundos
  const randomValue = Math.floor(Math.random() * 1000); // Número aleatorio entre 0 y 999

  // Concatenar la marca de tiempo y el valor aleatorio para crear un ID único
  const uniqueId = `${timestamp}${randomValue}`;

  return uniqueId;
}

// Uso del generador de ID único
const randomUniqueId = generateUniqueId();
