import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody, CardHeader, Col, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledDropdown } from 'reactstrap';
import { activities } from "../../common/data";
import { toast } from 'react-toastify';
import { createApiMethods } from '../../helpers/api_calls';
import { CALENDAR } from '../../helpers/api_urls';
import { paginationModel, str_dt, tConvert } from '../../helpers/api_helper';
import { InputModalVisita } from '../Forms/Inputs/InputModalVisita/InputModalVisita';
import { date } from 'yup';

const UpcomingActivities = () => {
  const modalEventoCalendar = useRef(null);
  const apiCalendar = useMemo(() => createApiMethods(CALENDAR), []);
  const [calendarResponse, setcalendarResponse] = useState(paginationModel);
  const [isLoading, setisLoading] = useState(false);
  const [events, setevents] = useState();

  useEffect(() => {
    setisLoading(true)
    apiCalendar.getAll({page_size:1000}).then((res)=>{
      setcalendarResponse(res)
      let eventstocalendar = res.results.map((item)=>{
        return {
          title:item.titulo,
          ...item,
          obj_type: 'VisitaAgendada'
        }
      })
      setevents(eventstocalendar)
    }).catch(toast.error).finally(()=>{
      setisLoading(false)
    })
  }, []);

  const toggleNuevoEvento = () => {
    modalEventoCalendar.current.toggle()
    modalEventoCalendar.current.setEvent(null)
    modalEventoCalendar.current.setIsEdit(false)
    modalEventoCalendar.current.setshowForm(true)
  };

  const handleDateClick = (arg) => {
    modalEventoCalendar.current.handleDateClick(arg)
    modalEventoCalendar.current.toggle()
  };

  const reloadResults = () => () => {
    setisLoading(true)
    apiCalendar.getAll({page_size:1000}).then((res)=>{
      setcalendarResponse(res)
      let eventstocalendar = res.results.map((item)=>{
        return {
          title:item.titulo,
          ...item
        }
      })
      setevents(eventstocalendar)
    }).catch(toast.error).finally(()=>{
      setisLoading(false)
    })
  }

  const reloadResultsChild = () => {
    setisLoading(true)
    apiCalendar.getAll({page_size:1000}).then((res)=>{
      setcalendarResponse(res)
      let eventstocalendar = res.results.map((item)=>{
        return {
          title:item.titulo,
          ...item
        }
      })
      setevents(eventstocalendar)
    }).catch(toast.error).finally(()=>{
      setisLoading(false)
    })
  }

  const eventClick = (event) =>{
    console.log(event)
    modalEventoCalendar.current.handleEventClick(event)
    modalEventoCalendar.current.toggle()
  }

  function convertoDate(dateString){
    return new Date(date)
  }
  const handleEventMenu = (event) =>{
    event._def = {extendedProps : event}
    eventClick({event: event})
  }
    return (
        <React.Fragment>
      <InputModalVisita
        reloader={reloadResults()}
        ref={modalEventoCalendar}>
      </InputModalVisita>


            <Col xxl={5}>
                <Card>
                    <CardHeader className="align-items-center d-flex">
                        <h4 className="card-title mb-0 flex-grow-1">Proximas Actividades</h4>
                        <div className="flex-shrink-0">
                            <UncontrolledDropdown className="card-header-dropdown" direction="start">
                                <DropdownToggle className="text-reset dropdown-btn" tag="a" role="button">
                                    <span className="text-muted fs-18"><i className="mdi mdi-dots-vertical"></i></span>
                                </DropdownToggle>
                                <DropdownMenu className="dropdown-menu dropdown-menu-end">
                                    <DropdownItem>Edit</DropdownItem>
                                    <DropdownItem>Remove</DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </div>
                    </CardHeader>
                    <CardBody className="card-body pt-0">
                        <ul className="list-group list-group-flush border-dashed">
                        {
                            calendarResponse.results.map((event, key) => {
                                let thisDate = new Date(event.start)
                                const numeroDeSemana = thisDate.getDay();
                                const startDate = event.start ? str_dt(event.start) : null;

                                return <li className="list-group-item ps-0" key={key}>
                                        <Row className="align-items-center g-3">
                                            <div className="col-auto">
                                                <div className="avatar-sm p-1 py-2 h-auto bg-light rounded-3">
                                                    <div className="text-center">
                                                        <h5 className="mb-0">{numeroDeSemana}</h5>
                                                        <div className="text-muted">{event.weekDay}</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col">
                                                <h5 className="text-muted mt-0 mb-1 fs-13">{startDate}</h5>
                                                <Link to="#" className="text-reset fs-14 mb-0">{event.descripcion}</Link>
                                            </div>
                                            <div className="col-sm-auto">
                                                <div className="avatar-group">
                                                    {/* {(event.subItem || []).map((subItem, key) => (
                                                        <React.Fragment key={key}>
                                                            <div className="avatar-group-item">
                                                                <Link to="#" className="d-inline-block" data-bs-toggle="tooltip" data-bs-placement="top" title="" data-bs-original-title="Stine Nielsen">
                                                                    <img src={subItem.img} alt="" className="rounded-circle avatar-xxs" />
                                                                </Link>
                                                            </div>
                                                        </React.Fragment>
                                                    ))} */}
                                                    <div className="avatar-group-item">
                                                        <Link to="#">
                                                            <div className="avatar-xxs">
                                                                <span className={"avatar-title rounded-circle  text-white"}>
                                                                    {event.imgNumber}
                                                                </span>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </Row>
                                    </li>
                                
                            })
                        }
                        </ul>
                        <div className="align-items-center mt-2 row g-3 text-center text-sm-start">
                            <div className="col-sm">
                                <div className="text-muted">Showing <span className="fw-semibold"> 4</span> of <span className="fw-semibold">125</span> Results
                                </div>
                            </div>
                            <div className="col-sm-auto">
                                <ul className="pagination pagination-separated pagination-sm justify-content-center justify-content-sm-start mb-0">
                                    <li className="page-item disabled">
                                        <Link to="#" className="page-link">←</Link>
                                    </li>
                                    <li className="page-item">
                                        <Link to="#" className="page-link">1</Link>
                                    </li>
                                    <li className="page-item active">
                                        <Link to="#" className="page-link">2</Link>
                                    </li>
                                    <li className="page-item">
                                        <Link to="#" className="page-link">3</Link>
                                    </li>
                                    <li className="page-item">
                                        <Link to="#" className="page-link">→</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </CardBody>
                </Card>
            </Col>
        </React.Fragment>
    );
};

export default UpcomingActivities;