import React, { useCallback, useEffect, useState } from 'react';
import './ModalContactos.css';
import { Button, Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import * as Yup from "yup";
import { getLoggedinUser } from '../../../../../../helpers/api_helper';
import { createApiMethods } from '../../../../../../helpers/api_calls';
import { InputSelect } from '../../../../../Forms/Inputs/InputSelect/InputSelect';
import { InputText } from '../../../../../Forms/Inputs/InputText/InputText';
import { CONTACTOS, MEDIOS_DE_CONTACTO } from '../../../../../../helpers/api_urls';


export const ModalContactos = ({modalState, toggleModal, editObject}) => {
    const userProfileSession = getLoggedinUser();
    const [userAvailable, setuserAvailable] = useState();
    const [userProfileLoaded, setUserProfileLoaded] = useState(false);
    const [tiposMediosContacto, settiposMediosContacto] = useState([]);
    const dispatch = useDispatch();
    const [isLoading, setisLoading] = useState();
    const apiContactos = createApiMethods(CONTACTOS)
    const apiMediosContacto = createApiMethods(MEDIOS_DE_CONTACTO)

    const onSubmitHandler = (values) => {
        setisLoading(true)
        apiContactos.create(values).then((res)=>{
            console.log(res)
        }).catch(console.log).finally(()=>{
            toggle()
            setisLoading(false)
        })
    };
    useEffect(() => {
        apiMediosContacto.getAll({page_size: 1000}).then((res)=>{
            console.log(res)
            settiposMediosContacto(res.results)
        }).catch(console.log)

    }, [])
    useEffect(() => {
        if (userProfileSession && !userProfileLoaded) {
          setuserAvailable(userProfileSession);
          setUserProfileLoaded(true);
        }
    }, [userProfileSession, userProfileLoaded]);

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            usuario               : userAvailable ? userAvailable.id : null,
            tipo              : undefined,
            valor              : undefined,
        },
        validationSchema: Yup.object({

        }),
        onSubmit: onSubmitHandler,
    });

    const handleCancel = () => () => {
        toggle()
        validation.resetForm()
    }

    const toggle = useCallback(() => {
        toggleModal();
    }, [toggleModal]);

    return (
        <>
            <Modal
                isOpen={modalState}
                toggle={toggleModal}
                centered
                size="xs"
                className="border-0"
                modalClassName='modal fade zoomIn'>
                <ModalHeader className="p-3 bg-soft-info" toggle={toggleModal}>
                    Crear medio de contacto
                </ModalHeader>
                <ModalBody className="modal-body">
                    <Row>
                        <Col lg={12}>
                            <p>
                                Este medio de contacto sera usado para que los clientes potenciales se contacten con usted. El formato del numero de telefono debe incluir el codigo de pais ejemplo: <strong>585558899</strong>
                            </p>
                            <p>
                                No debe incluir letras ni signos, de lo contrario el contacto no sera efectivo.
                            </p>
                        </Col>                        
                        <Col lg={12}>
                            <InputSelect
                                name         = "tipo"
                                label        = "Tipo de contacto"
                                validation   = {validation}
                                optionsProps = {
                                    {
                                        value:'id',
                                        label:'nombre',
                                        items:tiposMediosContacto || []
                                    }}>
                            </InputSelect>
                        </Col>
                        <Col lg={12}>
                            <InputText
                                name="valor"
                                label="Valor"
                                validation={validation}
                            ></InputText>   
                        </Col>
                    </Row>

                </ModalBody>
                <div className="modal-footer">
                    <Button color="secondary" className="btn btn-light" onClick={handleCancel()}>
                        Cancelar
                    </Button>
                    <Button
                        color="primary"
                        onClick={() => validation.submitForm()}
                        className="btn btn-primary"
                        disabled={isLoading}>
                            {
                                ( isLoading) ? (
                                    <span className="d-flex align-items-center">
                                        <span className="flex-grow-1 me-2">
                                            Cargando...
                                        </span>
                                        <span className="spinner-border flex-shrink-0" role="status">
                                            <span className="visually-hidden">Cargando...</span>
                                        </span>
                                    </span>
                                ) : (
                                    <span className="d-flex align-items-center">
                                        <span className="flex-grow-1 me-2">
                                            Guardar
                                        </span>
                                    </span>
                                )
                            }
                    </Button>
                </div>
            </Modal>
        </>
    );
};

