import { createContext, useContext, useCallback, useMemo } from 'react';
import { createApiMethods } from './helpers/api_calls';

const createDataManager = () => {
  let observers = {};
  let cachedData = {};

  const getList = async (endpoint) => {
    if (cachedData[endpoint]) {
      return cachedData[endpoint];
    }

    const apiCaller = createApiMethods(endpoint);
    const res = await apiCaller.getAll({pageSize: 1000});
    const data = res;
    cachedData[endpoint] = data;

    return data;
  };

  const notifyObservers = (endpoint, data) => {
    if (observers[endpoint]) {
      observers[endpoint].forEach(observer => observer(data));
    }
  };

  const subscribe = (endpoint, callback) => {
    if (!observers[endpoint]) {
      observers[endpoint] = [];
    }
    observers[endpoint].push(callback);

    if (observers[endpoint].length === 1) {
      getList(endpoint)
        .then(data => notifyObservers(endpoint, data))
        .catch(error => console.error(`Error fetching data for ${endpoint}:`, error));
    }

    return () => {
      observers[endpoint] = observers[endpoint].filter(observer => observer !== callback);
    };
  };

  return { getList, subscribe };
};

const DataContext = createContext(null);

const DataProvider = ({ children }) => {
  const dataManager = useMemo(() => createDataManager(), []);

  return (
    <DataContext.Provider value={dataManager}>
      {children}
    </DataContext.Provider>
  );
};

const useDataManager = () => {
  const context = useContext(DataContext);
  if (!context) {
    throw new Error('useDataManager must be used within a DataProvider');
  }
  return context;
};

export { DataProvider, useDataManager };