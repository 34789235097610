import React, { useContext, useEffect, useState } from "react";
import { Card, CardBody, CardHeader, Col, Container, Row, Modal, ModalBody } from "reactstrap";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import { Link, useParams } from "react-router-dom";
import { createApiMethods } from "../../../helpers/api_calls";
import { RESERVAS } from "../../../helpers/api_urls";
import { inmoAppLogoSinFondo } from "../../../assets/images";
import './DetalleReservaView.css';
import Can, { AbilityContext } from "../../../helpers/acl/Can";
import { RESERVA_ACL } from "../../../helpers/entities";

export const DetalleReservaView = () => {
    const { idreserva } = useParams();
    const [reserva, setReserva] = useState(null);
    const [isHovered, setisHovered] = useState(false);
    const [hoveredReserva, setHoveredReserva] = useState(null);
    const [modal, setModal] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const apiReservas = createApiMethods(RESERVAS);
    const ability = useContext(AbilityContext)

    useEffect(() => {
        apiReservas.getDetails(idreserva).then(response => {
            response.obj_type = RESERVA_ACL
            setReserva(response);
        });
    }, [idreserva]);

    const toggleModal = (image) => {
        setSelectedImage(image);
        setModal(!modal);
    };

    const handleMouseEnter = (id) => {
        // setHoveredReserva(id);
        setisHovered(true)
    };

    const handleMouseLeave = () => {
        // setHoveredReserva(null);
        setisHovered(false)
    };

    if (!reserva) return <div>Loading...</div>;

    return (
        <div className="page-content">
            <Container fluid>
                <BreadCrumb title="Detalle de Reserva" pageTitle="Reservas" />
                <Row className="justify-content-center">
                    <Col xxl={9}>
                        <Card id="demo">
                            <CardHeader className="border-bottom-dashed p-4">
                                <div className="d-sm-flex">
                                    <div className="flex-grow-1">
                                        <img src={inmoAppLogoSinFondo} alt="logo" height="55" />
                                        <div className="mt-sm-5 mt-4">
                                            <h6 className="text-muted text-uppercase fw-semibold">Propiedad</h6>
                                            <p className="text-muted mb-1">{reserva.propiedad.nombre}</p>
                                            <p className="text-muted mb-0">
                                                {reserva.propiedad.ciudad.nombre}, {reserva.propiedad.estado.nombre}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="flex-shrink-0 mt-sm-0 mt-3 text-end">
                                        <h6>
                                            <span className="text-muted fw-normal">ID de Reserva:</span> {reserva.id}
                                        </h6>
                                        <h6 className="mb-0"
                                            onMouseEnter={() => handleMouseEnter(reserva.id)}
                                            onMouseLeave={handleMouseLeave}>
                                                <Can I="update" this={reserva} ability={ability}>
                                                    {(isHovered && idreserva) && (
                                                        <div className="hover-link">
                                                            <Link to={`/reservas/${idreserva}/editar`}>Editar reserva</Link>
                                                        </div>
                                                    )}    
                                                </Can>
                                                {
                                                    !isHovered && (
                                                        <>
                                                            <span className="text-muted fw-normal">Estado:</span> {reserva.status_reserva.nombre}
                                                        </>
                                                    )
                                                }                                                
                                        </h6>
                                    </div>
                                </div>
                            </CardHeader>
                            <CardBody className="p-4">
                                <Row className="g-3">
                                    <Col lg={4} xs={6}>
                                        <p 
                                            className="text-muted mb-2 text-uppercase fw-semibold">
                                            Monto de Venta
                                        </p>
                                        <h5 className="fs-14 mb-0">${reserva.monto_venta}</h5>
                                    </Col>
                                    <Col lg={4} xs={6}>
                                        <p className="text-muted mb-2 text-uppercase fw-semibold">Monto de Reserva</p>
                                        <h5 className="fs-14 mb-0">${reserva.monto_reserva}</h5>
                                    </Col>
                                    <Col lg={4} xs={6}>
                                        <p className="text-muted mb-2 text-uppercase fw-semibold">Fecha de Reserva</p>
                                        <h5 className="fs-14 mb-0">{new Date(reserva.fecha_reserva).toLocaleDateString()}</h5>
                                    </Col>
                                </Row>
                            </CardBody>
                            <CardBody className="p-4 border-top border-top-dashed">
                                <h6 className="text-muted text-uppercase fw-semibold mb-3">Documentos</h6>
                                <ul>
                                    {(reserva.documentos  || []).map((doc, index) => (
                                        <li key={index}>
                                            <a href={doc.archivo} target="_blank" rel="noopener noreferrer">
                                                {doc.tipo_documento}
                                            </a>
                                        </li>
                                    ))}
                                </ul>
                            </CardBody>
                            <CardBody className="p-4">
                                <h6 className="text-muted text-uppercase fw-semibold mb-3">Detalles de la Propiedad</h6>
                                <p dangerouslySetInnerHTML={{ __html: reserva.propiedad.descripcion }}></p>
                                <div className="table-responsive mt-3 layout">
                                    {reserva.propiedad.imagenes.map((img, index) => (
                                        <div 
                                            key={index} 
                                            className="avatar-container-md cursor-pointer" 
                                            onClick={() => toggleModal(img.image)}
                                            onMouseEnter={() => handleMouseEnter(reserva.id)}
                                            onMouseLeave={handleMouseLeave}
                                        >
                                            <img src={img.image} alt="Propiedad" className="img-fluid" />
                                        </div>
                                    ))}
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>

            <Modal isOpen={modal} toggle={toggleModal} size="lg">
                <ModalBody>
                    {selectedImage && (
                        <img src={selectedImage} alt="Vista previa" className="img-fluid" style={{ width: '100%' }} />
                    )}
                </ModalBody>
            </Modal>

        </div>
    );
};
