export const POST_LOGIN = "/token/";
export const GET_SERVICES_LIST = "/services/";
export const REGISTER_NEW_USER = "/register/";
export const SERVICES_REQUEST = "/servicerequests/";
export const CIUDADES = "/ciudades/";
export const ESTADOS = "/estados/";
export const INMUEBLES = "/inmuebles/";
export const FRANQUICIAS = "/franquicias/";
export const TIPOSINMUEBLES = "/tiposinmuebles/";
export const TIPOSNEGOCIACION = "/tiposnegociacion/";
export const USERS = "/users/";
export const CONTACTOS = "/contacto/"
export const DOCUMENTS = "/documents/"
export const MEDIOS_DE_CONTACTO = "/tipomedio/"
export const BUSQUEDAS = "/busquedas/"
export const CLIENTES = "/clientes/"
export const SEGUIMIENTOS = "/seguimientos/"
export const VISITAS = "/visitas/"
export const CONTACTOCLIENTES = "/contactoclientes/"
export const TIPOSFRANQUICIAS = "/tiposfranquicias/"
export const PROPIEDADES = "/propiedades/"
export const PROPIEDADESPUBLICAS = "/propiedadespublicas/"
export const CALENDAR = "/calendar/"
export const RATINGVISITAS = "/visitasrating/"
export const CHAT = "/chats/"
export const SOLICITUDESCONTACTO = "/solicitudcontacto/"
export const WEB_SYNC_URL = "/websync/sync_to_web/"
export const INTEGRACIONES = "/integraciones/"
export const RESERVAS = "/reservas/"
export const GASTOSRESERVAS = "/gastos-reserva/"