import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import Dropzone from "react-dropzone";
import { toast } from 'react-toastify';
import { Button, Card, Row, Col } from "reactstrap";
import SimpleBar from "simplebar-react";
import { APIClient } from '../../../../helpers/api_helper';

const DocumentsUploader = forwardRef(({ validation, isMultiple = false, endpoint, additionalData = {},endpointdelete, ...props }, ref) => {
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const api = new APIClient();

  useImperativeHandle(ref, () => ({
    setUploadedFiles,
    selectedFiles,
  }));

  const handleAcceptedFiles = async (files) => {
    setSelectedFiles([...selectedFiles, ...files]);
    for (const file of files) {
      await uploadFile(file);
    }
  };

  const uploadFile = async (file) => {
    const formData = new FormData();
    formData.append(props?.name, file);  // Nombre del archivo
    
    // Añadir datos adicionales al FormData
    Object.keys(additionalData).forEach((key) => {
      formData.append(key, additionalData[key]);  // Adjunta datos adicionales
    });
  
    setIsUploading(true);
    try {
      const response = await api.create(endpoint, formData);
      
      // Usa el estado previo para asegurar que no se sobrescriben los archivos anteriores
      setUploadedFiles(prevFiles => [...(prevFiles || []), { ...response, file }]);
  
      toast.success("Archivo subido con éxito");
    } catch (error) {
      toast.error("Error al subir archivo");
    } finally {
      setIsUploading(false);
    }
  };
  
  

  const handleDeleteFile = async (fileId, index) => {
    try {
      await api.delete(`${endpointdelete}/${fileId}`);  // DELETE para eliminar el archivo en el backend
      const updatedFiles = [...uploadedFiles];
      updatedFiles.splice(index, 1);
      setUploadedFiles(updatedFiles);
      toast.success("Archivo eliminado");
    } catch (error) {
      toast.error("Error al eliminar archivo");
    }
  };

  const handleFilesChange = (files) => {
    if (files) {
      handleAcceptedFiles(files);
    }
  };

  return (
    <>
      <Dropzone
        multiple={isMultiple}
        onDrop={(acceptedFiles) => handleAcceptedFiles(acceptedFiles)}
      >
        {({ getRootProps, getInputProps }) => (
          <div {...getRootProps()} className="dropzone dz-clickable">
            <input {...getInputProps()} />
            <div className="dz-message needsclick">
              <Button className="btn btn-light w-100">
                {isUploading ? 'Subiendo...' : 'Arrastra archivos o haz clic aquí para cargar'}
              </Button>
            </div>
          </div>
        )}
      </Dropzone>

      {uploadedFiles?.length > 0 && (
        <SimpleBar style={{ maxHeight: "200px" }}>
          <div className="list-unstyled mb-0" id="file-previews">
            {uploadedFiles.map((file, index) => (
              <Card className="mt-1 mb-0 shadow-none border" key={index}>
                <div className="p-2">
                  <Row className="align-items-center">
                    <Col>
                      {
                        file?.nombre &&
                        <p className="text-muted font-weight-bold mb-0">{`(${file.id}) - ${file.nombre}${file?.tipo_extension}`}</p>
                      }
                    </Col>
                    <Col className="col-auto">
                      <button
                        className="btn btn-danger btn-sm"
                        onClick={() => handleDeleteFile(file.id, index)}
                      >
                        Eliminar
                      </button>
                    </Col>
                  </Row>
                </div>
              </Card>
            ))}
          </div>
        </SimpleBar>
      )}
    </>
  );
});

DocumentsUploader.displayName = 'DocumentsUploader';
export { DocumentsUploader };
