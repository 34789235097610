import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import './CheckxFilterComponent.css';
import { Button, Col, Row } from 'reactstrap';
import SimpleBar from 'simplebar-react';


const CheckxFilterComponent = forwardRef(({items, nameProp, search = false, handler},ref) => {
    const [checkedIds, setCheckedIds] = useState([]);
    const [searchText, setSearchText] = useState('');
  
    const toggleCheck = (id) => {
      setCheckedIds(prev => {
        if (prev.includes(id)) {
          return prev.filter(x => x !== id);
        } else {
          return [...prev, id];
        }
      });
    }

    useEffect(() => {
        handler(checkedIds)
    }, [checkedIds]);
  
    const handleSearch = (e) => {
      setSearchText(e.target.value);
    }
  
    const filteredItems = items.filter(item => {
      return item[nameProp]?.toLowerCase().includes(searchText.toLowerCase());
    });

    const handleReset = () => {
        setCheckedIds([]); // Deselecciona todos los elementos seleccionados
    }
    
    const handleClearSearch = () => {
        setSearchText('');
      } 

      useImperativeHandle(ref, () => ({
        checkedIds: checkedIds,
        reset: handleReset
    }));
    
    return (
        <Row>
            {
                search &&
                <Col xl={12}>
                    <div 
                        className="input-group input-group-sm mb-3">
                        <span className="input-group-text" id="inputGroup-sizing-sm">
                            <i className="bx bx-search-alt search-icon"></i>
                        </span>
                        <input 
                            type="text"
                            value={searchText}
                            className="form-control" 
                            onChange={handleSearch} 
                            />
                        <button 
                            onClick={handleClearSearch}
                            id='eraser' 
                            className="btn btn-primary btn-icon" 
                            type="button">
                            <i className="bx bx-eraser search-icon"></i>
                        </button>
                    </div>            
                </Col>
            }
            <Col xl={12}>
                <SimpleBar autoHide={false} style={{ maxHeight: "220px" }} className="px-3">
                    {
                        filteredItems.map(item => (
                            <div className="form-check" key={item.id}>
                                <input 
                                    className="form-check-input"
                                    type="checkbox" 
                                    id={`check-${item.id}`}
                                    checked={checkedIds.includes(item.id)}
                                    onChange={() => toggleCheck(item.id)} 
                                />

                                <label className="form-check-label" htmlFor={`check-${item.id}`}>
                                    {item[nameProp]}
                                </label>
                            </div>
                        ))
                    }
                </SimpleBar>
                <div className="d-flex gap-2 align-items-right mt-2" style={{justifyContent: 'space-between'}}>
                    <Button
                        className='btn-sm btn-danger' 
                        onClick={handleReset}>
                        Limpiar
                    </Button>
                </div>  
            </Col>
        </Row>
    );
});


CheckxFilterComponent.displayName = 'CheckxFilterComponent';
export { CheckxFilterComponent }