import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import './AlternativasComponent.css';
import { Col, Row, Button } from 'reactstrap'; // Asegúrate de importar Button de 'reactstrap'
import SimpleBar from 'simplebar-react';

const AlternativasComponent = forwardRef(({ items, nameProp, search = false, handler }, ref) => {
    const [selectedId, setSelectedId] = useState();
    const [searchText, setSearchText] = useState('');


    const handleSelect = (id) => {
        setSelectedId(id);
    }

    const handleSearch = (e) => {
        setSearchText(e.target.value);
    }

    const handleClearSearch = () => {
        setSearchText('');
    }

    const handleReset = () => {
        setSelectedId(null); // Deselecciona cualquier opción seleccionada
    }

    useEffect(() => {
        handler(selectedId)
    }, [selectedId]);

    const filteredItems = items.filter((item) => {
        return item[nameProp].toLowerCase().includes(searchText.toLowerCase());
    });

    useImperativeHandle(ref, () => ({
        selectedId: selectedId,
        reset: handleReset
    }));

    function generateUniqueId() {
        const timestamp = Date.now(); // Obtiene la marca de tiempo actual en milisegundos
        const random = Math.floor(Math.random() * 1000); // Genera un número aleatorio entre 0 y 999
        return `${timestamp}-${random}`;
    }

    return (
        <Row>
            {search && (
                <Col xl={12}>
                    <div className="input-group input-group-sm mb-3">
                        <span className="input-group-text" id="inputGroup-sizing-sm">
                            <i className="bx bx-search-alt search-icon"></i>
                        </span>
                        <input
                            type="text"
                            value={searchText}
                            onChange={handleSearch}
                            className="form-control"/>
                        <button
                            id='eraser'
                            onClick={handleClearSearch}
                            className="btn btn-primary btn-icon"
                            type="button">
                            <i className="bx bx-eraser search-icon"></i>
                        </button>
                    </div>
                </Col>
            )}
            <Col xl={12}>
                <SimpleBar autoHide={false} style={{ maxHeight: "220px" }} className="px-3">
                    {filteredItems.map((item) => (
                        <div className="form-check" key={item.id}>
                            <input
                                className="form-check-input"
                                type="radio"
                                checked={item.id === selectedId}
                                onChange={() => handleSelect(item.id)}
                                id={`${nameProp}radio-${item.id}`}
                            />
                            <label className="form-check-label" htmlFor={`${nameProp}radio-${item.id}`}>
                                {item[nameProp]}
                            </label>
                        </div>
                    ))}
                </SimpleBar>
                <div className="d-flex gap-2 align-items-right mt-2" style={{justifyContent: 'space-between'}}>
                    <Button
                        className='btn-sm btn-danger' 
                        onClick={handleReset}>
                        Limpiar
                    </Button>
                </div>  
            </Col>
        </Row>
    );
});

AlternativasComponent.displayName = 'AlternativasComponent';
export { AlternativasComponent };
